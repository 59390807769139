import { Box, styled, Stack, AppBar as MuiAppBar } from '@mui/material';
import { COLORS } from '../../themes';
import { SUB_HEADER_PAGE_ELEMENT_ID } from '../../constants';

export const PageContainer = styled(Box)(() => ({
  display: 'flex',
}));

export const MainContent = styled(Box)(() => ({
  flexGrow: 1,
  minWidth: 0,
  '& > *': {
    paddingRight: 24,
    paddingLeft: 24,
  },
  [`& #${SUB_HEADER_PAGE_ELEMENT_ID}`]: {
    padding: 24,
  },
}));

export const StyledStack = styled(Stack)`
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  flex: 1;
`;

export const AppBar = styled(MuiAppBar)`
  background: ${COLORS.WHITE};
  justify-content: center;
  min-height: 109px;
  box-shadow: none;
  border-bottom: 1px solid ${COLORS.MONOCHROMATIC.GRAY2};
`;
