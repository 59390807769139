import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

const MuiTextFieldTheme: {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
} = {
  variants: [],
  styleOverrides: {
    root: {
      '& .MuiInputBase-root': {
        borderRadius: 0,
      },
      '& .MuiInputBase-input': {
        paddingTop: 12,
        paddingBottom: 12,
        height: 24,
      },
    },
  },
};

export default MuiTextFieldTheme;
