import { Stack } from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';
import { PartnerCompanyListRouter } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/router';
import { useAuth } from '@hooks';

import { StepIcon } from './components';

export function ContractSelectionSteps() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCompany } = useAuth();

  const currentPath = location.pathname;

  const steps = [
    {
      path: CompanySetupListRouter.path,
      label: 'Seleção de matriz',
      stepNumber: 1,
      completed: selectedCompany,
    },
    {
      path: PartnerCompanyListRouter.path,
      label: 'Seleção de contrato',
      stepNumber: 2,
      disabled: !selectedCompany,
    },
  ];

  const handleStepClick = (
    isSelected: boolean,
    route: string,
    disabled?: boolean,
  ) => {
    if (!isSelected && !disabled) {
      navigate(route);
    }
  };

  return (
    <Stack gap={1}>
      {steps.map(({ path, label, stepNumber, disabled, completed }) => {
        const isSelected = Boolean(matchPath(path, currentPath));
        return (
          <SidebarCore.Item
            key={path}
            iconComponent={
              <StepIcon
                isSelected={isSelected}
                stepNumber={stepNumber}
                isCompleted={Boolean(completed)}
              />
            }
            isSelected={isSelected}
            onClick={() => handleStepClick(isSelected, path, disabled)}
            label={label}
            disabled={disabled}
          />
        );
      })}
    </Stack>
  );
}
