import { useState } from 'react';

import { ProfileProps } from '@services/UserService';

import InfoOutlined from '@assets/images/info-outlined.svg';
import { ProfileResourcesModal } from '@components';
import { useProfileDetails } from '@hooks';

import { StyledButton } from './styles';

interface ProfileDetailsButtonProps {
  profileName: string;
  profileId: string;
}
export function ProfileDetailsButton({
  profileId,
  profileName,
}: ProfileDetailsButtonProps) {
  const { getUserProfileDetails } = useProfileDetails();
  const [modalOpen, setModalOpen] = useState(false);

  const [currentProfileDetails, setCurrentProfileDetails] =
    useState<ProfileProps>();

  const handleClickProfile = () => {
    getUserProfileDetails(profileId).then(profileDetails => {
      if (profileDetails) {
        setCurrentProfileDetails(profileDetails);
        setModalOpen(true);
      }
    });
  };

  return (
    <>
      <StyledButton onClick={() => handleClickProfile()}>
        {profileName}
        <img src={InfoOutlined} alt="info" width={16} height={16} />
      </StyledButton>
      {currentProfileDetails && (
        <ProfileResourcesModal
          profile={currentProfileDetails}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
