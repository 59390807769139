import { styled as muiStyled } from '@mui/material';

import { COLORS, Typography } from '@hapvida/hapvida-core-components';

interface LetterLabelProps {
  stayLetterFile?: string;
  onClick?: () => void;
}

export const LetterLabel = muiStyled(Typography)(
  ({ stayLetterFile }: LetterLabelProps) => ({
    display: 'flex',
    gap: '4px',
    color: COLORS.MONOCHROMATIC.GRAY4,
    ...(stayLetterFile && {
      color: COLORS.PRIMARY.BLUE.MAIN,
      textDecoration: 'underline',
      cursor: 'pointer',
    }),
  }),
);
