export const PASSWORD_DEFINITION_STEPS_KEYS = {
  PASSWORD_DEFINITION: 'criar-senha',
  REGISTRATION_COMPLETE: 'cadastro-concluido',
};

export const PASSWORD_DEFINITION_STEPS_VALUES = {
  [PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION]: 0,
  [PASSWORD_DEFINITION_STEPS_KEYS.REGISTRATION_COMPLETE]: 1,
};

export const PASSWORD_DEFINITION_STEPS_ORDER = [
  PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION,
  PASSWORD_DEFINITION_STEPS_KEYS.REGISTRATION_COMPLETE,
];
