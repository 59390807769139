import IDragAndDropFile from '../components/DragAndDrop/dtos/IDragDropFile';
import { removeCaracterSpecial } from './removeCaracterSpecial';

const isFirefox = /Firefox/i.test(navigator.userAgent);

export class Base64File {
  constructor(
    public name: string,
    public data: string,
    public size: number | string,
  ) {}

  get url(): string {
    return `${this.data}`;
  }

  get extension(): string | false {
    return false;
  }

  static fromFile(file: File): Promise<IDragAndDropFile> {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = () => {
        let fileResult = reader.result?.toString();
        if (
          isFirefox &&
          fileResult?.startsWith('data:application/vnd.ms-excel')
        ) {
          fileResult = fileResult.replace(
            'data:application/vnd.ms-excel',
            'data:text/csv',
          );
        }

        const path = fileResult;
        res({
          date: new Date().toISOString(),
          fileName: removeCaracterSpecial(file.name),
          path: path || '',
          size: file.size,
        });
      };

      reader.onerror = error => rej(error);

      reader.readAsDataURL(file);
    });
  }
}
