import { Stack, Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';
import { ExternalLinksEnum } from '@constants';

import { StyledTypography } from './styles';

export function IncomeReportsContent() {
  const { redirectUser } = useRedirectUser();

  const handleRedirectLegacyPortal = () => {
    redirectUser(UserRedirectsEnum.INCOME_REPORT);
  };

  const handleRedirectPortalNDI = () => {
    window.open(ExternalLinksEnum.NDI, '_blank');
  };

  return (
    <Stack gap={3}>
      <Typography variant="text">
        Para o período de janeiro a novembro de 2024{' '}
        <StyledTypography
          onClick={handleRedirectPortalNDI}
          variant="link"
          fontWeight={700}
          color={COLORS.PRIMARY.BLUE.MAIN}
        >
          acesse esse link
        </StyledTypography>{' '}
        e faça login com sua credencial anterior
      </Typography>
      <Typography variant="text">
        Para o período a partir dezembro de 2024{' '}
        <StyledTypography
          onClick={handleRedirectLegacyPortal}
          variant="link"
          fontWeight={700}
          color={COLORS.PRIMARY.BLUE.MAIN}
        >
          acesse esse link
        </StyledTypography>
      </Typography>
    </Stack>
  );
}
