import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { formatISO, startOfMonth } from 'date-fns';

import { FilterKeyEnum } from '@hapvida/hapvida-core-components';

import MovimentationService, {
  MovementsSummaryProps,
} from '@services/MovimentationService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';

export function useMovementsSummaryState() {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  const currentMonth = useMemo(
    () => ({
      [FilterKeyEnum.START_DATE]: formatISO(startOfMonth(new Date())),
      [FilterKeyEnum.END_DATE]: formatISO(new Date()),
    }),
    [],
  );

  const homeMovementsSummaryState = useQuery<MovementsSummaryProps[]>({
    queryKey: [
      'homeMovementsSummaryState',
      currentMonth,
      companyId,
      partnerCompany,
    ],
    queryFn: () => {
      const movementService = new MovimentationService();
      return movementService.fetchMovementsSummary({
        ...currentMonth,
        companyId,
      });
    },
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshMovementsSummaryStates = () => {
    homeMovementsSummaryState.remove();

    homeMovementsSummaryState.refetch();
  };

  return {
    homeMovementsSummaryState,
    refreshMovementsSummaryStates,
  };
}
