import { MovementStatusEnum, BeneficiaryMovementTypeEnum } from '@constants';

export const movementsTypes = [
  {
    label: '1 - Inclusão de titular',
    value: BeneficiaryMovementTypeEnum.HOLDER_INCLUSION,
  },
  {
    label: '2 - Inclusão de dependente',
    value: BeneficiaryMovementTypeEnum.DEPENDENT_INCLUSION,
  },
  {
    label: '3 - Alteração cadastral',
    value: BeneficiaryMovementTypeEnum.CADASTRAL_CHANGE,
  },
  {
    label: '4 - Transferência de contrato',
    value: BeneficiaryMovementTypeEnum.CONTRACT_TRANSFER,
  },
  {
    label: '5 - Troca de plano',
    value: BeneficiaryMovementTypeEnum.PLAN_CHANGE,
  },
  { label: '6 - Reativação', value: BeneficiaryMovementTypeEnum.REACTIVATION },
  { label: '7 - Exclusão', value: BeneficiaryMovementTypeEnum.EXCLUSION },
  {
    label: '8 - Cancelar Exclusão',
    value: BeneficiaryMovementTypeEnum.EXCLUSION_CANCELATION,
  },
  {
    label: 'Aposentado e/ou demitido',
    value: BeneficiaryMovementTypeEnum.RETIRED,
  },
];

export const statuses = [
  { label: 'Em processamento', value: MovementStatusEnum.PROCESS_FILE },
  { label: 'Finalizado', value: MovementStatusEnum.FILE_PROCESSED },
  {
    label: 'Interrompido por erro',
    value: MovementStatusEnum.ERROR,
  },
  { label: 'Impossível iniciar', value: MovementStatusEnum.UNABLE_TO_START },
  {
    label: 'Finalizado com críticas',
    value: MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW,
  },
  {
    label: 'Processamento com pendência',
    value: MovementStatusEnum.PROCESS_FILE_WITH_PENDENCY,
  },
];
