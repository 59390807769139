import { Stack, styled } from '@mui/material';

export const BorderedLogoContainer = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.stroke.default}`,
  backgroundColor: theme.palette.background.default,
  justifyContent: ' center',
  alignItems: 'center',
  borderRadius: 8,
  padding: 7,
}));
