import { styled as muiStyled, Stack } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Circle = muiStyled(Stack)({
  backgroundColor: COLORS.PRIMARY.ORANGE.MAIN,
  minWidth: '8px',
  height: '8px',
  borderRadius: '50%',
});
