import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { UserListProps, UserService } from '@services/UserService';

import { BackofficeUserQueryKeysEnum, ITEMS_LIMIT_PER_PAGE } from '@constants';
import { toastifyApiErrors } from '@utils';
import { useFilterParams, useAuth } from '@hooks';

export function useBackofficeUserState() {
  const { currentPage, tableOrder } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const userService = useMemo(() => new UserService(), []);

  const backofficeCompanyId = user?.companyId ?? '';

  const backofficeUserState = useQuery<UserListProps>({
    queryKey: [
      BackofficeUserQueryKeysEnum.USERS_STATE,
      backofficeCompanyId,
      currentPage,
      tableOrder,
    ],
    queryFn: () =>
      userService.getBackofficeAccounts({
        ...tableOrder,
        companyId: backofficeCompanyId,
        offset: ITEMS_LIMIT_PER_PAGE * (currentPage - 1),
        limit: ITEMS_LIMIT_PER_PAGE,
      }),
    enabled: Boolean(backofficeCompanyId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBackofficeUserList = () => {
    backofficeUserState.remove();
    backofficeUserState.refetch();
  };

  return {
    refreshBackofficeUserList,
    backofficeUserState,
  };
}
