export enum SalesChannelEnum {
  COLLECTIVE_AGREEMENT = 'COLLECTIVE AGREEMENT',
  CORPORATE = 'CORPORATE (acima de 300 vidas)',
  SPECIAL_PROJECTS = 'specialProjects',
  ADMINISTRATOR = 'ADMINISTRADORA',
  BIDDING = 'bidding',
}

export const SALES_CHANNEL_NAME: Record<SalesChannelEnum, string> = {
  [SalesChannelEnum.COLLECTIVE_AGREEMENT]: 'Convenção Coletiva',
  [SalesChannelEnum.SPECIAL_PROJECTS]: 'Projetos Especiais',
  [SalesChannelEnum.ADMINISTRATOR]: 'Administradora',
  [SalesChannelEnum.CORPORATE]: 'Grandes empresas',
  [SalesChannelEnum.BIDDING]: 'Licitação',
};
