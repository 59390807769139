import { ButtonBaseProps, Typography } from '@mui/material';

import { COLORS, Icon, IconName } from '@hapvida/hapvida-core-components';

import { IconContainer, ButtonContainer } from './styles';

interface QuickActionButtonProps extends ButtonBaseProps {
  iconName: string;
  text: string;
}

const QuickActionButton = ({
  iconName,
  text,
  ...buttonBaseProps
}: QuickActionButtonProps) => {
  return (
    <ButtonContainer {...buttonBaseProps}>
      <IconContainer>
        <Icon
          color={COLORS.PRIMARY.ORANGE.MAIN}
          name={iconName as IconName}
          size={24}
        />
      </IconContainer>

      <Typography variant="body2" noWrap flex={1} textAlign="left">
        {text}
      </Typography>

      <Icon
        color={COLORS.MONOCHROMATIC.GRAY4}
        name={'chevron-right' as IconName}
        size={24}
      />
    </ButtonContainer>
  );
};

export default QuickActionButton;
