import { UserPartnerCompanyProps } from '@services/UserService';

import { ExtraUserPartnerCompanyProps } from '../types';

export const addIsOldOnList = (
  originalItem: UserPartnerCompanyProps,
): ExtraUserPartnerCompanyProps => ({
  ...originalItem,
  isOldOnList: true,
});
