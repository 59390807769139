import { ButtonBaseProps, Stack, Typography } from '@mui/material';
import { ImgHTMLAttributes } from 'react';

import BallsImageSource from '@assets/images/icon-container.svg';

import { BallsIcon, ButtonContainer, IconContainer } from './styles';

export interface SelectionCardProps extends ButtonBaseProps {
  imageProps: ImgHTMLAttributes<HTMLImageElement>;
  subtitle: string;
  title: string;
}

export function SelectionCard({
  imageProps,
  subtitle,
  title,
  ...buttonBaseProps
}: SelectionCardProps) {
  return (
    <ButtonContainer {...buttonBaseProps}>
      <IconContainer>
        <BallsIcon
          src={BallsImageSource}
          alt="Icon container"
          draggable={false}
        />
        {imageProps.src && (
          <img
            alt={imageProps.alt}
            draggable={false}
            width={30}
            {...imageProps}
          />
        )}
      </IconContainer>
      <Stack gap={0.5} alignItems="flex-start">
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Stack>
    </ButtonContainer>
  );
}
