import { Box, styled } from '@mui/material';

export const FilterLoadingContainer = styled(Box)`
  place-items: center;
  position: absolute;
  display: grid;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`;
