import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes/types';

import MovimentationStepPage from '.';

export const MovimentationStepRouter: IAppDynamicRoute = {
  Page: MovimentationStepPage,
  path: '/movimentacao/:movimentationStep',
  name: 'Nova movimentação',
  isPrivate: true,
  dynamicPath: ({ movimentationStep }) => `/movimentacao/${movimentationStep}`,
  Icon: <Icon name="clipboard-text-outline" />,
  disabled: false,
};
