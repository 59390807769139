import { useCallback } from 'react';

const MODEL_FILES = [
  '/files/Modelo_Alteracao.xlsx',
  '/files/Modelo_Exclusao_V1.xlsx',
  '/files/Modelo_Inclusao_V1.xlsx',
  '/files/Modelo_Transferencia_V1.xlsx',
  '/files/sib_complementar_V6.pdf',
  '/files/SIB_IN35_DIDES.pdf',
];

export const useDownloadModelFiles = () => {
  const handleDownloadFiles = useCallback(() => {
    MODEL_FILES.forEach(file => {
      const link = document.createElement('a');
      link.href = file;
      link.download = file.split('/').pop() ?? '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }, []);

  return {
    handleDownloadFiles,
  };
};
