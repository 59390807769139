import React from 'react';
import { Grid } from '@mui/material';

import { Mask } from '@hapvida/hapvida-core-components';

import {
  CompanyName,
  Container,
  ItemData,
  ItemName,
  StyledAvatar,
} from './styles';

interface CompanyInfoProps {
  name?: string;
  logo?: string;
  document?: string;
  fantasyName?: string;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({
  name,
  document,
  fantasyName,
  logo,
}) => (
  <Container>
    <Grid container direction="row" justifyContent="space-between" mb="1.25rem">
      <Grid item>
        <ItemName>Razão social</ItemName>
        <CompanyName>{name || '-'}</CompanyName>
      </Grid>
      <Grid item marginY="auto">
        <StyledAvatar src={logo} />
      </Grid>
    </Grid>
    <Grid container direction="row">
      <Grid item xs={6}>
        <ItemName>CNPJ</ItemName>
        <ItemData>{document ? Mask.cnpj(document) : '-'}</ItemData>
      </Grid>
      <Grid item xs={6}>
        <ItemName>Nome fantasia</ItemName>
        <ItemData>{fantasyName || '-'}</ItemData>
      </Grid>
    </Grid>
  </Container>
);

export default CompanyInfo;
