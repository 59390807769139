import { Stack, Typography } from '@mui/material';

import { BackofficeUserCreationForm } from './components';

export function CreateBackofficeUserLayout() {
  return (
    <Stack maxWidth="md" margin="auto" gap={3} py={3}>
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        gap={1}
      >
        <Typography variant="h5">Novo usuário backoffice</Typography>
        <Typography color="primary" variant="caption">
          Campos com * são obrigatórios
        </Typography>
      </Stack>
      <Typography variant="body2">
        Coloque as informações do novo usuário.
      </Typography>
      <BackofficeUserCreationForm />
    </Stack>
  );
}
