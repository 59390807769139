import {
  Box,
  CSSObject,
  styled as muiStyled,
  Theme,
  Drawer as MuiDrawer,
  Stack,
  StackProps,
} from '@mui/material';
import { ElementType } from 'react';
import { COLORS } from '../../../themes/colors';
import Typography from '../../Typography';

const drawerWidth = 340;
const drawerClose = 112;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${drawerClose}px + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${drawerClose}px + 1px)`,
  },
});

export const PageContainer = muiStyled(Box)(() => ({
  display: 'flex',
}));

export const DrawerHeader = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = muiStyled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: theme.zIndex.drawer,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
    '.MuiPaper-root': {
      borderRight: 'none',
    },
    '.MuiDrawer-paper': {
      borderTopRightRadius: '32px',
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        background: 'rgba(255, 255, 255, 0.88)',
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': { ...closedMixin(theme) },
    }),
  },
}));

export const MainContent = muiStyled(Box)(() => ({
  flexGrow: 1,
  minWidth: 0,
}));

interface DrawerContainerProps {
  open: boolean;
  backofficeVision: boolean | undefined;
}

export const DrawerContainer = muiStyled(Stack, {
  shouldForwardProp: prop => prop !== 'backofficeVision',
})<DrawerContainerProps>(({ open, backofficeVision }) => ({
  padding: open ? '24px 28px' : '16px',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',

  ...(backofficeVision && {
    background: COLORS.MONOCHROMATIC.GRAY4,
  }),
}));

interface RouteGroupNameProps {
  open?: boolean;
}

export const RouteGroupName = muiStyled(Typography)<RouteGroupNameProps>(
  ({ open }) => ({
    textTransform: 'uppercase',
    color: COLORS.MONOCHROMATIC.GRAY3,
    width: '100%',
    textAlign: open ? 'left' : 'center',
    paddingLeft: open ? 16 : 0,
  }),
);

interface RouteItemInterface extends StackProps<ElementType> {
  selected: boolean;
  open: boolean;
  backofficeVision: boolean;
}

export const RouteItem = muiStyled(
  ({ backofficeVision, ...props }: RouteItemInterface) => (
    <Stack component="li" {...props} />
  ),
)(({ open, selected, backofficeVision }) => {
  return {
    padding: 16,
    borderRadius: '8px',

    svg: {
      color: COLORS.MONOCHROMATIC.GRAY4,
    },

    ':hover': {
      cursor: 'pointer',
    },

    ...(selected && {
      background: 'rgba(237, 171, 56, 0.08)',
      '.MuiTypography-root': {
        fontWeight: 600,
      },
      svg: {
        color: COLORS.PRIMARY.ORANGE.MAIN,
      },
    }),

    ...(!open && {
      height: 32,
      width: 32,
    }),

    ...(backofficeVision && {
      '.MuiTypography-root': {
        color: COLORS.WHITE,
      },
      svg: {
        color: COLORS.PRIMARY.ORANGE.MAIN,
      },
    }),

    ...(backofficeVision &&
      selected && {
        background: 'rgba(255, 255, 255, 0.08)',
      }),
  };
});
export const RouteList = muiStyled(({ ...props }) => (
  <Stack component="ul" {...props} />
))<StackProps>(() => ({
  width: '100%',
  paddingLeft: 0,
}));

export const StyledStack = muiStyled(Stack)`
	flex-direction: column;
	position: relative;
	overflow-y: auto;
	display: flex;
	height: 100vh;
	flex: 1;
`;
