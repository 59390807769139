enum SearchParamEnum {
  ORDER_BY = 'order-by',
  SORT_BY = 'sort-by',
  SEARCH = 'search',
  REVIEW = 'review',
  PAGE = 'page',
}

enum FilterKeyEnum {
  ORGANIZATIONAL_GROUP = 'organizational-group',
  BENEFICIARIES_TYPE = 'beneficiaries-type',
  HEALTH_OPERATOR = 'health-operator-types',
  BENEFICIARIES_STATUS = 'status',
  MOVEMENTS_STATUS = 'status',
  START_DATE = 'start-date',
  END_DATE = 'end-date',
  REVIEW = 'review',
  USER_STATUS = 'status',
  PROFILE_ID = 'profile-id',
  CONTRACT_TYPE = 'contract-type',
}
interface TableOrderParams {
  [SearchParamEnum.ORDER_BY]?: 'asc' | 'desc';
  [SearchParamEnum.SORT_BY]?: string;
}

export { SearchParamEnum, FilterKeyEnum, TableOrderParams };
