import { IAppRoute } from 'routes/types';

import { AuthenticatingPage } from '.';

export const AuthenticatingRouter: IAppRoute = {
  Page: AuthenticatingPage,
  path: '/autenticando',
  name: '',
  Icon: <></>,
  disabled: false,
};
