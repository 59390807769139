import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

import { COLORS } from '../colors';
const MuiCheckboxTheme: {
  defaultProps?: ComponentsProps['MuiCheckbox'];
  styleOverrides?: ComponentsOverrides['MuiCheckbox'];
  variants?: ComponentsVariants['MuiCheckbox'];
} = {
  variants: [],
  styleOverrides: {
    root: {},
    colorPrimary: {
      color: COLORS.MONOCHROMATIC.GRAY4,
      fontSize: '1.5em',
      '&.Mui-checked': {
        color: COLORS.PRIMARY.ORANGE.MAIN,
      },
      '& .MuiSvgIcon-root': {
        // fontSize: 18,
      },
      '&.Mui-disabled': {
        width: 16,
        height: 16,
        margin: 12,
        borderRadius: 0,
        backgroundColor: COLORS.MONOCHROMATIC.GRAY2,
        color: COLORS.MONOCHROMATIC.GRAY3,
      },
      ':hover': {
        color: COLORS.PRIMARY.ORANGE.MAIN,
      },
      '&.Mui-focusVisible': {
        color: COLORS.PRIMARY.BLUE['-2'],
        // fontSize: "1.6rem",
      },
    },
  },
};

export default MuiCheckboxTheme;
