export const PASSWORD_RECOVERY_STEPS_KEYS = {
  PASSWORD_RECOVERY: 'criar-senha',
  RECOVERY_COMPLETE: 'recuperacao-concluida',
};

export const PASSWORD_RECOVERY_STEPS_VALUES = {
  [PASSWORD_RECOVERY_STEPS_KEYS.PASSWORD_RECOVERY]: 0,
  [PASSWORD_RECOVERY_STEPS_KEYS.RECOVERY_COMPLETE]: 1,
};

export const PASSWORD_RECOVERY_STEPS_ORDER = [
  PASSWORD_RECOVERY_STEPS_KEYS.PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_STEPS_KEYS.RECOVERY_COMPLETE,
];
