import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { MovementDetailsLayout } from './layout';

export function MovementDetailsPage() {
  return (
    <DrawerFilterProvider>
      <MovementDetailsLayout />
    </DrawerFilterProvider>
  );
}
