import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { useConfirmation } from '@hapvida/hapvida-core-components';

import BeneficiaryService from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';

const BENEFICIARIES_FILE_STATE_KEY = 'beneficiariesFileState';

export function useExportBeneficiariesFile() {
  const { searchParam, mappedFilters } = useFilterParams();
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { selectedCompany } = useAuth();

  const isExportingBeneficiaries =
    useIsFetching({
      queryKey: [BENEFICIARIES_FILE_STATE_KEY],
    }) > 0;

  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

  const companyId = selectedCompany?.id ?? '';

  const handleDownloadBeneficiariesFile = async () => {
    try {
      const beneficiariesFileId =
        await beneficiaryService.getIdExportBeneficiaries({
          filters: {
            ...mappedFilters,
            ...searchParam,
          },
          companyId,
        });

      const beneficiariesFileResponse = await queryClient.fetchQuery({
        queryKey: [
          BENEFICIARIES_FILE_STATE_KEY,
          beneficiariesFileId,
          companyId,
        ],
        queryFn: () =>
          beneficiaryService.beneficiaryExport({
            fileNameIdentifier: beneficiariesFileId,
            companyId,
          }),
        retry: 5,
        retryDelay: 2000,
      });

      window.open(beneficiariesFileResponse.content, '_blank');
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  };

  const handleExportBeneficiariesFile = async () => {
    handleDownloadBeneficiariesFile();

    showConfirmation({
      title: 'Aguarde! Estamos exportando o seu arquivo.',
      confirmText: 'Fechar',
      type: 'default',
      content: (
        <Typography variant="text">
          Estamos exportando o seu arquivo e em breve tudo estará pronto para
          você.
          <br />
          <b>Atenção!</b> Quando finalizarmos a exportação o download iniciará
          automaticamente.
          <b> Caso você decida sair da página a exportação será perdida.</b>
        </Typography>
      ),
    });
  };

  return {
    handleExportBeneficiariesFile,
    isExportingBeneficiaries,
  };
}
