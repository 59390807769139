import { UserRedirectsEnum } from '@services/UserService';

import { IAppSubRoute } from 'routes/types';

import { BENEFICIARY_REDIRECTS } from '.';

const AuthenticateCertificateRouter: IAppSubRoute = {
  name: 'Autenticar atestado',
  path: UserRedirectsEnum.AUTHENTICATE_ATTESTATION,
  isPrivate: true,
  sidebarModalProps: BENEFICIARY_REDIRECTS.authenticateAttestation,
};

const OnlineCardRouter: IAppSubRoute = {
  name: 'Carteirinha Digital',
  path: UserRedirectsEnum.ONLINE_CARD,
  isPrivate: true,
  sidebarModalProps: BENEFICIARY_REDIRECTS.onlineCard,
};

export { AuthenticateCertificateRouter, OnlineCardRouter };
