import { Button } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { useProfileCard } from '../../hooks';
import { RemoveProfileButtonProps } from './types';

export function RemoveProfileButton({
  handleRemoveProfile,
  profileCardId,
}: Readonly<RemoveProfileButtonProps>) {
  const { selectedProfile } = useProfileCard();
  return (
    <Button
      onClick={() => handleRemoveProfile(profileCardId, selectedProfile?.id)}
      variant="outlined"
      color="primary"
    >
      Excluir
      <Icon name="delete" size={16} />
    </Button>
  );
}
