import { styled, ButtonBase, Typography, css } from '@mui/material';

export const CompanyButton = styled(ButtonBase)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.highlight};
    border-radius: 8px;
    text-align: start;
    padding: 16px;
    width: 100%;
  `,
);

export const StyledTypography = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
