import { COLORS } from '@hapvida/hapvida-core-components';

enum PartnerCompaniesTypeEnum {
  MOTHER = 'Mother',
  CHILD = 'Child',
}

type PartnerCompaniesType = Record<PartnerCompaniesTypeEnum, string>;

const PARTNER_COMPANIES_TYPE_NAME: PartnerCompaniesType = {
  [PartnerCompaniesTypeEnum.MOTHER]: 'Mãe',
  [PartnerCompaniesTypeEnum.CHILD]: 'Filho',
};

const PARTNER_COMPANIES_TYPE_COLOR: PartnerCompaniesType = {
  [PartnerCompaniesTypeEnum.MOTHER]: COLORS.SECONDARY.YELLOW.MAIN,
  [PartnerCompaniesTypeEnum.CHILD]: COLORS.SECONDARY.BLUE['+1'],
};

const PARTNER_COMPANIES_TYPE_TEXT_COLOR: PartnerCompaniesType = {
  [PartnerCompaniesTypeEnum.MOTHER]: COLORS.MONOCHROMATIC.GRAY4,
  [PartnerCompaniesTypeEnum.CHILD]: COLORS.WHITE,
};

export {
  PARTNER_COMPANIES_TYPE_NAME,
  PARTNER_COMPANIES_TYPE_COLOR,
  PARTNER_COMPANIES_TYPE_TEXT_COLOR,
  PartnerCompaniesTypeEnum,
};
