import { useCallback, useState } from 'react';

import { DataGrid } from '@hapvida/hapvida-core-components';

import type { FileData } from '@services/FinancialService';

import { useBillData, useBillState } from '../../hooks';
import { ButtonLink, OperationalCostModal } from './components';
import {
  getGridRows,
  getReportFileRows,
  getReportFilesCarnetComplementRow,
} from './helpers';
import { BillDetailsCardProps } from './types';
import { BillTypeEnum } from './constants';

export function BillDetailsCard({
  billingFile,
  bill,
  reportFiles,
}: BillDetailsCardProps) {
  const { commonData } = useBillData(bill);
  const { fetchReportWithFileType } = useBillState();
  const [openOperationalCostModal, setOpenOperationalCostModal] =
    useState(false);
  const toggleModal = useCallback(
    () => setOpenOperationalCostModal(!openOperationalCostModal),
    [openOperationalCostModal],
  );

  const { csvFiles, pdfFiles, txtFiles } = reportFiles;

  const { billingNumber, billType } = bill;

  const pdfReportState = fetchReportWithFileType('PDF', billingNumber);
  const csvReportState = fetchReportWithFileType('CSV', billingNumber);
  const txtReportState = fetchReportWithFileType('TXT', billingNumber);

  const isOperationalCost = billType === BillTypeEnum.OPERATIONAL_COST;
  const isCarnetComplement = billType === BillTypeEnum.CARNET_COMPLEMENT;

  const handleOpenFile = (file?: FileData) => {
    if (file?.path) window.open(file.path, '_blank');
  };

  const handleOpenFiles = (files?: FileData[]) => {
    files?.forEach(file => handleOpenFile(file));
  };

  const operationalCostRow = {
    label: 'Relatório analítico',
    xs: 3,
    value: (
      <ButtonLink
        onClick={toggleModal}
        text="Acessar relatório"
        iconName="link"
      />
    ),
  };

  const reportMonthlyFilesRows = [
    {
      label: 'Relatório analítico (PDF)',
      xs: 3,
      value: getReportFileRows('PDF', pdfReportState),
    },
    {
      label: 'Relatório analítico (CSV)',
      xs: 3,
      value: getReportFileRows('CSV', csvReportState),
    },
    {
      label: 'Relatório analítico (TXT)',
      xs: 3,
      value: getReportFileRows('TXT', txtReportState),
    },
  ];

  const isCarnetComplementRow = getReportFilesCarnetComplementRow({
    pdfFiles,
    csvFiles,
    txtFiles,
    handleOpenFiles,
  });

  const carnetOrReportRows = isCarnetComplement
    ? isCarnetComplementRow
    : reportMonthlyFilesRows;
  const newVisibleRows = isOperationalCost
    ? [operationalCostRow]
    : carnetOrReportRows;

  const gridRows = getGridRows(billingFile, bill, newVisibleRows);

  return (
    <>
      <DataGrid
        rows={[...commonData, ...gridRows]}
        gridContainerProps={{ rowSpacing: 3 }}
      />
      <OperationalCostModal
        openModal={openOperationalCostModal}
        toggleModal={toggleModal}
      />
    </>
  );
}
