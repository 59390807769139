import { FormControlLabel, Radio, Typography } from '@mui/material';
import { useState } from 'react';

import { ProfileProps } from '@services/UserService';

import { UserProfileEnum } from '@constants';

import { BoxItem } from './styles';
import { MasterAlertModal } from './components';
import { ResourcesModalButton } from '../ResourcesModalButton';

interface ProfileTypeItemProps {
  profile: ProfileProps;
  onSelectProfile: (profile: ProfileProps) => void;
}

export function ProfileTypeItem({
  profile,
  onSelectProfile,
}: ProfileTypeItemProps) {
  const [masterAlertModalOpen, setMasterAlertModalOpen] = useState(false);

  const handleProfileSelectionConfirmation = () => {
    onSelectProfile(profile);
    setMasterAlertModalOpen(false);
  };

  const handleToggleMasterModal = () => {
    setMasterAlertModalOpen(!masterAlertModalOpen);
  };

  const isMasterProfile = profile.name === UserProfileEnum.MASTER;

  const handleOnChange = () => {
    if (isMasterProfile) {
      handleToggleMasterModal();
    } else {
      handleProfileSelectionConfirmation();
    }
  };

  return (
    <BoxItem
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
    >
      <FormControlLabel
        value={profile.name}
        control={<Radio />}
        sx={{ width: '100%' }}
        label={
          <Typography variant="text" width="100%">
            {profile.name}
          </Typography>
        }
        onChange={handleOnChange}
      />
      <MasterAlertModal
        open={masterAlertModalOpen}
        onClose={handleToggleMasterModal}
        onContinue={handleProfileSelectionConfirmation}
      />
      <ResourcesModalButton profile={profile} />
    </BoxItem>
  );
}
