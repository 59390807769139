import { ReactNode } from 'react';

import { DrawerContainer } from './styles';
import { useSidebar } from '../../hooks';

interface SidebarRootProps {
  children: ReactNode;
}

export function SidebarRoot({ children }: Readonly<SidebarRootProps>) {
  const { drawerOpen, isMobile } = useSidebar();

  return (
    <DrawerContainer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={drawerOpen}
    >
      {children}
    </DrawerContainer>
  );
}
