import { UserPartnerCompanyProps } from '@services/UserService';

import { ExtraUserPartnerCompanyProps } from '../types';

export const removeIsOldOnList = (
  item: ExtraUserPartnerCompanyProps,
): UserPartnerCompanyProps => {
  const newItem = item;
  delete newItem.isOldOnList;
  return newItem;
};
