import { ButtonHTMLAttributes, ClassAttributes, FC, ReactNode } from 'react';

import { Theme } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system';
import usePagination from '@mui/material/usePagination';

import Icon from '@hapvida/hapvida-core-components/src/components/Icon';

import { List, PaginationItem, Ellipsis } from './styles';
import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes';

interface CustomPaginationProps {
  count: number;
  currentPage?: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  height?: number;
}

const CustomPagination: FC<CustomPaginationProps> = ({
  count,
  currentPage,
  onChange,
  height,
}) => {
  const { items } = usePagination({
    count,
    hideNextButton: false,
    hidePrevButton: false,
    showFirstButton: true,
    showLastButton: true,
    siblingCount: 1,
    boundaryCount: 1,
    page: currentPage,
    onChange,
  });

  const pagItem = (
    item: JSX.IntrinsicAttributes &
      MUIStyledCommonProps<Theme> &
      ClassAttributes<HTMLButtonElement> &
      ButtonHTMLAttributes<HTMLButtonElement> & { children?: ReactNode },
    icon: IconName,
    page: number,
  ) => (
    <PaginationItem
      type="button"
      {...item}
      height={height}
      key={Math.random() * (10 - page) + page}
    >
      <Icon name={icon} color={COLORS.MONOCHROMATIC.GRAY3} size={24} />
    </PaginationItem>
  );

  const navItem = (
    type: string,
    page: number | null,
    item: JSX.IntrinsicAttributes &
      MUIStyledCommonProps<Theme> &
      ClassAttributes<HTMLButtonElement> &
      ButtonHTMLAttributes<HTMLButtonElement> & { children?: ReactNode },
  ) => {
    let children = null;
    switch (type) {
      case 'start-ellipsis':
      case 'end-ellipsis':
        children = <Ellipsis key={type + page}>...</Ellipsis>;
        break;

      case 'page':
        children = (
          <PaginationItem height={height} key={type + page} {...item}>
            {page}
          </PaginationItem>
        );
        break;

      case 'first':
        children = pagItem(item, 'chevron-bar-left', page ?? 1);
        break;
      case 'last':
        children = pagItem(item, 'chevron-bar-right', page ?? 1);
        break;
      case 'next':
        children = pagItem(item, 'chevron-right', page ?? 1);
        break;
      case 'previous':
        children = pagItem(item, 'chevron-left', page ?? 1);
        break;
    }

    return children;
  };

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }) => {
          const children = navItem(type, page, item);

          return children;
        })}
      </List>
    </nav>
  );
};

export default CustomPagination;
