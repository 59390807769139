import { useState } from 'react';

import {
  NoSearchResults,
  useDrawerFilter,
  SummaryCardNew,
  FilterSummary,
  Loading,
  Icon,
} from '@hapvida/hapvida-core-components';

import { BENEFICIARIES_SUMMARY_ITEMS, BeneficiaryTypeEnum } from '@constants';

import {
  BeneficiariesFiltersDrawer,
  BeneficiariesChart,
  EmptyBeneficiaries,
} from './components';
import { useBeneficiariesSummaryState } from './hooks';

export function BeneficiariesSummary() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { beneficiariesSummaryState } = useBeneficiariesSummaryState();
  const { filterCount } = useDrawerFilter();

  const handleToggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer);
  };

  if (beneficiariesSummaryState?.isLoading) {
    return (
      <SummaryCardNew.Container>
        <Loading minHeight={200} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (beneficiariesSummaryState?.isError) {
    return null;
  }

  const summaryValues = {} as Record<BeneficiaryTypeEnum, number>;

  Object.values(BeneficiaryTypeEnum).forEach(beneficiaryType => {
    summaryValues[beneficiaryType] = 0;
  });

  beneficiariesSummaryState?.data.beneficiaries.forEach(beneficiary => {
    summaryValues[beneficiary.type] += beneficiary.totalBeneficiaries;
  });

  const totalBeneficiaries = Object.values(summaryValues).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  const isSummaryEmpty = totalBeneficiaries === 0 && !filterCount;

  return (
    <SummaryCardNew.Container gap={2}>
      <SummaryCardNew.Header
        buttonProps={
          !isSummaryEmpty
            ? {
                content: !filterCount
                  ? 'Aplicar filtro'
                  : 'Ver filtros aplicados',
                endIcon: !filterCount && <Icon name="filter" size={20} />,
                variant: !filterCount ? 'outlined' : 'contained',
                onClick: handleToggleDrawer,
              }
            : undefined
        }
        title="Resumo de beneficiários"
      />
      {!isSummaryEmpty && <FilterSummary />}
      {isSummaryEmpty && <EmptyBeneficiaries />}
      {!isSummaryEmpty && (
        <>
          {totalBeneficiaries === 0 ? (
            <NoSearchResults />
          ) : (
            <>
              <BeneficiariesChart
                totalBeneficiaries={totalBeneficiaries}
                summaryValues={summaryValues}
              />

              <SummaryCardNew.Content
                perLine={BENEFICIARIES_SUMMARY_ITEMS.length}
                summary={BENEFICIARIES_SUMMARY_ITEMS}
                summaryValues={summaryValues}
                pillColor
              />
            </>
          )}
          <BeneficiariesFiltersDrawer
            toggleDrawer={handleToggleDrawer}
            visibleDrawer={visibleDrawer}
          />
        </>
      )}
    </SummaryCardNew.Container>
  );
}
