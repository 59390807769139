const invoiceMock = {
  content: [
    {
      id: '1',
      summary: {
        invoiceNumber: '167894563878',
        status: 'paidOut',
        additionalInformation: 'IDIN01',
        company: 'NDI',
        invoiceType: 'Fatura mensal',
        dateOfEmission: '2022-06-10T10:36:38.666',
        dueDate: '2022-06-10T10:36:38.666',
        value: 9,
        document: '000000000001',
        demonstrative: {
          name: 'Demonstrativo.pdf',
          path: 'http://localhost:3000/boleto/desmostrativo.pdf',
        },
        bill: {
          name: 'Boleto.pdf',
          path: 'http://localhost:3000/boleto/boleto.pdf',
        },
        receipt: {
          name: 'Nota-fiscal.pdf',
          path: 'http://localhost:3000/boleto/nota-fiscal.pdf',
        },
        leafFile: {
          name: 'Arquivo-folha.pdf',
          path: 'http://localhost:3000/boleto/arquivo-folha.pdf',
        },
        otherDemonstratives: {
          name: 'Outros-demostrativos.pdf',
          path: 'http://localhost:3000/boleto/outros.pdf',
        },
        analyticReport: {
          name: 'Outros-demostrativos.pdf',
          path: 'http://localhost:3000/boleto/outros.pdf',
        },
      },
      beneficiaries: [
        {
          id: '1',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 200000,
        },
        {
          id: '2',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 2000,
        },
        {
          id: '3',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 2000,
        },
      ],
    },
    {
      id: '2',
      summary: {
        invoiceNumber: '167894563878',
        status: 'opened',
        additionalInformation: 'IDIN01',
        company: 'Hapvida',
        invoiceType: 'Fatura mensal',
        dateOfEmission: '2022-06-10T10:36:38.666',
        dueDate: '2022-06-10T10:36:38.666',
        value: 1000,
        document: 'XX.XXX.XXX/0001-XX',
        demonstrative: {
          name: 'Demonstrativo.pdf',
          path: 'http://localhost:3000/boleto/desmostrativo.pdf',
        },
        bill: {
          name: 'Boleto.pdf',
          path: 'http://localhost:3000/boleto/boleto.pdf',
        },
        receipt: {
          name: 'Nota-fiscal.pdf',
          path: 'http://localhost:3000/boleto/nota-fiscal.pdf',
        },
        leafFile: {
          name: 'Arquivo-folha.pdf',
          path: 'http://localhost:3000/boleto/arquivo-folha.pdf',
        },
        otherDemonstratives: {
          name: 'Outros-demostrativos.pdf',
          path: 'http://localhost:3000/boleto/outros.pdf',
        },
        analyticReport: {
          name: 'Outros-demostrativos.pdf',
          path: 'http://localhost:3000/boleto/outros.pdf',
        },
      },
      beneficiaries: [
        {
          id: '1',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 200000,
        },
        {
          id: '2',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 90000,
        },
        {
          id: '3',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 300000,
        },
      ],
    },
    {
      id: '3',
      summary: {
        invoiceNumber: '167894563878',
        status: 'opened',
        additionalInformation: 'IDIN01',
        company: 'Hapvida',
        invoiceType: 'Fatura mensal',
        dateOfEmission: '2022-06-10T10:36:38.666',
        dueDate: '2022-06-10T10:36:38.666',
        value: 100000,
        document: 'XX.XXX.XXX/0001-XX',
        demonstrative: {
          name: 'Demonstrativo.pdf',
          path: 'http://localhost:3000/boleto/desmostrativo.pdf',
        },
        bill: {
          name: 'Boleto.pdf',
          path: 'http://localhost:3000/boleto/boleto.pdf',
        },
        receipt: {},
        leafFile: {
          name: 'Arquivo-folha.pdf',
          path: 'http://localhost:3000/boleto/arquivo-folha.pdf',
        },
        otherDemonstratives: {
          name: 'Outros-demostrativos.pdf',
          path: 'http://localhost:3000/boleto/outros.pdf',
        },
        analyticReport: {},
      },
      beneficiaries: [
        {
          id: '1',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 200000,
        },
        {
          id: '2',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 90000,
        },
        {
          id: '3',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 300000,
        },
      ],
    },
    {
      id: '4',
      summary: {
        invoiceNumber: '167894563878',
        status: 'canceled',
        additionalInformation: 'IDIN01',
        company: 'Hapvida',
        invoiceType: 'Fatura mensal',
        dateOfEmission: '2022-06-10T10:36:38.666',
        dueDate: '2022-06-10T10:36:38.666',
        value: 100000,
        document: '00000000000001',
        demonstrative: {
          name: 'Demonstrativo.pdf',
          path: 'http://localhost:3000/boleto/desmostrativo.pdf',
        },
        bill: {
          name: 'Boleto.pdf',
          path: 'http://localhost:3000/boleto/boleto.pdf',
        },
        receipt: {},
        leafFile: {
          name: 'Arquivo-folha.pdf',
          path: 'http://localhost:3000/boleto/arquivo-folha.pdf',
        },
        otherDemonstratives: {
          name: 'Outros-demostrativos.pdf',
          path: 'http://localhost:3000/boleto/outros.pdf',
        },
        analyticReport: {},
      },
      beneficiaries: [
        {
          id: '1',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 200000,
        },
        {
          id: '2',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 90000,
        },
        {
          id: '3',
          beneficiaryName: 'João Gomes da Costa',
          cpf: '12345678901',
          registrationNumber: '123456',
          beneficiaryType: 'Titular',
          patient: 'João Gomes da Costa',
          description: 'Hosp do Bosque',
          expenses: 'Pronto Socorro',
          factorValue: 300000,
        },
      ],
    },
  ],
};

export default invoiceMock;
