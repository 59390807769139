import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import BeneficiaryService, {
  BeneficiariesSummaryProps,
} from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams, useAuth } from '@hooks';

export function useBeneficiariesSummaryState() {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();
  const { mappedFilters } = useFilterParams();

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  const beneficiariesSummaryState = useQuery<BeneficiariesSummaryProps>({
    queryKey: [
      'beneficiariesSummaryState',
      mappedFilters,
      companyId,
      partnerCompany,
    ],
    queryFn: () => {
      const beneficiaryService = new BeneficiaryService();
      return beneficiaryService.fetchBeneficiariesSummary({
        ...mappedFilters,
        companyId,
      });
    },
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBeneficiariesSummaryStates = () => {
    beneficiariesSummaryState.remove();

    beneficiariesSummaryState.refetch();
  };

  return {
    beneficiariesSummaryState,
    refreshBeneficiariesSummaryStates,
  };
}
