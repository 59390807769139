import { styled as muiStyled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const Field = muiStyled(Stack)(() => ({
  marginRight: '1rem',
  '& .MuiTypography-root:nth-of-type(2)': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const FieldTitle = muiStyled(Typography)(() => ({
  marginBottom: '0.5rem',
}));
