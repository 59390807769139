import { Typography } from '@mui/material';
import { useState } from 'react';

import { Modal } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { HealthOperatorEnum } from '@constants';

import { InvoiceInfo } from '../InvoiceInfo';
import { ButtonLink } from '../ButtonLink';
import { InvoiceButtonProps } from './types';
import { CITY_HALL_LINK } from './constants/links';

export function InvoiceButton({ bill }: Readonly<InvoiceButtonProps>) {
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  const { healthOperator, companyDocument, verificationCode, invoice } = bill;

  const handleOpenLink = () => {
    window.open(CITY_HALL_LINK[healthOperator], '_blank');
  };

  const handleInvoiceClick = () => {
    if (invoice?.path) {
      window.open(invoice.path, '_blank');
      return;
    }

    setInvoiceModalOpen(true);
  };

  if (!verificationCode && !invoice?.path) {
    return <Typography variant="body2">-</Typography>;
  }

  return (
    <>
      <ButtonLink
        onClick={handleInvoiceClick}
        text="Acessar Nota Fiscal"
        iconName="link"
      />
      {verificationCode && (
        <Modal
          title="Você está prestes a ser direcionado ao site de emissão NFS-e."
          handleCloseModal={() => setInvoiceModalOpen(false)}
          mainButtonText="Consultar NFS-e"
          secondaryButtonText="Fechar"
          mainAction={handleOpenLink}
          open={invoiceModalOpen}
          contentProps={{
            gap: 1.5,
          }}
          icon="alert"
        >
          <Typography variant="body2">
            Em virtude da implantação do novo sistema de nota fiscal eletrônica,
            o acesso às notas fiscais deverá ser realizado através do portal de
            emissão de NFS-e.
          </Typography>
          {invoice?.number && (
            <InvoiceInfo
              title="Número da NFS-e"
              value={String(invoice.number)}
            />
          )}
          <InvoiceInfo title="Código de Verificação" value={verificationCode} />
          {companyDocument && (
            <InvoiceInfo title="CNPJ do Prestador" value={companyDocument} />
          )}
          {healthOperator === HealthOperatorEnum.HAPVIDA && (
            <Typography variant="body2">
              <strong style={{ color: COLORS.SECONDARY.RED.MAIN }}>
                Importante!
              </strong>{' '}
              Após ser direcionado, selecione a opção{' '}
              <strong>Validar NFS-e</strong> para realizar a consulta.
            </Typography>
          )}
        </Modal>
      )}
    </>
  );
}
