import {
  FormControl,
  InputLabel,
  NativeSelect,
  NativeSelectProps,
  styled as muiStyled,
} from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../themes/colors';

export const StyledFormControl = styled(FormControl)`
  width: 100%;

  .MuiInputBase-formControl {
    margin-top: 0;
  }

  .MuiInput-root {
    :after {
      display: none;
    }

    :before {
      display: none;
    }
  }
`;

interface SyledInputLabelProps {
  paddingInput?: string;
}

export const StyledInputLabel = muiStyled(InputLabel)<SyledInputLabelProps>(
  ({ paddingInput }) => ({
    paddingLeft: paddingInput,
    fontSize: '14px',
  }),
);

interface LabelSpanProps {
  required?: boolean;
}

export const LabelSpan = muiStyled('span')<LabelSpanProps>(({ required }) => ({
  color: required ? COLORS.PRIMARY.BLUE['+1'] : COLORS.MONOCHROMATIC.GRAY4,
}));

interface StyledNativeSelectProps extends NativeSelectProps {
  borderless?: boolean;
}

export const StyledNativeSelect = muiStyled(NativeSelect, {
  shouldForwardProp: prop => prop !== 'borderless',
})<StyledNativeSelectProps>(({ value, borderless }) => ({
  padding: '8px 12px',
  fontSize: '14px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  ...(!!value
    ? { border: `1px solid ${COLORS.PRIMARY.BLUE['-1']}` }
    : { borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }),
  ...(borderless && {
    borderWidth: 1,
    borderColor: 'transparent',
    fontWeight: 900,
  }),
  '&.Mui-focused': {
    borderColor: COLORS.PRIMARY.BLUE['-1'],
  },
  color: !!value ? 'inherit' : COLORS.MONOCHROMATIC.GRAY3,
}));
