import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { Mask } from '@hapvida/hapvida-core-components';

import { useAuth } from '@hooks';
import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';

import { HeaderItem } from '../HeaderItem';
import { CompanyName } from './styles';

export function SelectedCompany() {
  const { selectedCompany } = useAuth();

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(CompanySetupListRouter.path);
  }, [navigate]);

  if (!selectedCompany) {
    return null;
  }

  const { legal, name, document } = selectedCompany;
  const companyLabel = legal ?? name;
  const documentLabel = Mask.cnpj(document);

  return (
    <HeaderItem icon="building" onClickEdit={onClick} title="Matriz:">
      <CompanyName variant="text" title={companyLabel}>
        {companyLabel}
      </CompanyName>
      <Typography variant="text">-</Typography>
      <Typography fontWeight={900} variant="text">
        {documentLabel}
      </Typography>
    </HeaderItem>
  );
}
