import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';

import {
  ErrorIcon,
  SuccessIcon,
} from '@hapvida/hapvida-core-components/src/components/TextInput/styles';

interface CombinedAdornmentPasswordProps {
  colorField: TextFieldProps['color'];
}

const CombinedAdornmentPassword = ({
  colorField,
}: CombinedAdornmentPasswordProps) => {
  if (colorField === 'success') {
    return (
      <InputAdornment position="end">
        <SuccessIcon name="check" />
      </InputAdornment>
    );
  }
  if (colorField === 'error') {
    return (
      <InputAdornment position="end">
        <ErrorIcon name="alert" />
      </InputAdornment>
    );
  }
  return null;
};

export default CombinedAdornmentPassword;
