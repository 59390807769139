import { styled as muiStyled } from '@mui/material';

export const Wrapper = muiStyled('div')(() => ({
  padding: '1.5rem',
}));

export const StyledForm = muiStyled('form')({
  marginTop: 12,
  marginBottom: 40,
  padding: '0 100px',
});
