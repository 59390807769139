import { Pill } from '@hapvida/hapvida-core-components';

import { MOVEMENT_STATUS_COLOR, MOVEMENT_STATUS_NAME } from '@constants';

import { CustomTableCell } from '../TableRows/styles';
import type { StatusPillProps } from './types';

export const StatusPill = ({ status }: Readonly<StatusPillProps>) => {
  return (
    <CustomTableCell align="left">
      <Pill
        color={MOVEMENT_STATUS_COLOR[status]}
        text={MOVEMENT_STATUS_NAME[status]}
        fitContent
      />
    </CustomTableCell>
  );
};
