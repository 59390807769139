import { FC } from 'react';

import Grid, { GridProps } from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { Container, Title, Label, Value } from './styles';

export interface DataGridItem {
  value?: string | number | React.JSX.Element | boolean;
  label: string;
  xs?: number;
  sm?: number;
  md?: number;
}

interface DataGridProps {
  title?: string;
  rows: DataGridItem[];
  customSpacing?: number;
  containerStyles?: React.CSSProperties;
  gridContainerProps?: GridProps;
}

const DataGrid: FC<DataGridProps> = ({
  title,
  rows,
  customSpacing = 2,
  containerStyles,
  gridContainerProps,
}) => {
  return (
    <Container direction="column" sx={containerStyles}>
      {title && <Title>{title}</Title>}
      <Grid container spacing={customSpacing} {...gridContainerProps}>
        {rows.map((item, index) => (
          <Grid
            key={`row-${String(index)}`}
            item
            xs={item.xs}
            md={item.md}
            sm={item.sm}
          >
            <Stack direction="column" spacing={1}>
              <Label>{item.label}</Label>

              {typeof item.value === 'string' ||
              typeof item.value === 'number' ? (
                <Value>{item.value}</Value>
              ) : (
                item.value ?? '-'
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DataGrid;
