import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Wrapper = muiStyled(Stack)({
  padding: '1rem',
  borderRadius: '4px',
  border: `1px solid ${COLORS.SECONDARY.RED['+1']}`,

  background: 'rgba(201, 54, 0, 0.04)',
});

export const IconContainer = muiStyled(Box)({
  width: '2.5rem',
  height: '2.5rem',

  background: 'rgba(201, 54, 0, 0.08)',

  borderRadius: '8px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Text = muiStyled(Typography)({
  color: COLORS.SECONDARY.RED['+1'],
  fontStyle: 'normal',
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
});
