import { UserFirstAccessRouter } from '@flows/public/pages/UserFirstAccess/router';
import { StartPasswordRecoveryRouter } from '@flows/public/pages/PasswordRecovery/StartPasswordRecovery/router';
import { PasswordRecoveryRouter } from '@flows/public/pages/PasswordRecovery/PasswordRecovery/router';
import { PasswordDefinitionRouter } from '@flows/public/pages/PasswordDefinition/router';
import { AuthenticatingRouter } from '@flows/public/pages/Authenticating/router';
import { RegisterRouter } from '@flows/public/pages/RegisterSteps/router';
import { IAppRoute } from 'routes/types';

export const publicRoutes: IAppRoute[] = [
  UserFirstAccessRouter,
  StartPasswordRecoveryRouter,
  PasswordDefinitionRouter,
  PasswordRecoveryRouter,
  AuthenticatingRouter,
  RegisterRouter,
];
