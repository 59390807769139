import { Mask } from '@hapvida/hapvida-core-components';

import { FilterKeyEnum } from '@hapvida/hapvida-core-components/src/constants/searchParams';

import { HapvidaApi } from '@http';

import {
  CompaniesListProps,
  CompaniesListRequestDTO,
  CompaniesListResponseDTO,
} from './dtos/CompaniesListDTO';
import {
  BeneficiaryNumbersRequestDTO,
  BeneficiaryNumbersResponseDTO,
  BeneficiaryNumbersSummaryProps,
} from './dtos/CompanyBeneficiariesDTO';
import {
  CompanyDetails,
  CompanyDetailRequestDTO,
  CompanyDetailResponseDTO,
} from './dtos/CompanyDetailDTO';
import { IntegrationPendingRequestDTO } from './dtos/IntegrationPendingDTO';
import MockApi from '../../http/MockApi';
import {
  PendenciesRequestDTO,
  PendenciesResponseDTO,
  PendenciesProps,
} from './dtos/PendenciesDTO';
import { RemoveIsNewCompanyStatusDTO } from './dtos/RemoveIsNewCompanyStatusDTO';
import {
  CompanySummaryDetailRequestDTO,
  CompanySummaryDetailResponseDTO,
} from './dtos/CompanySummaryDetailDTO';

export * from './dtos';

export default class CompanyService {
  private readonly endpoints = {
    companyDetail: (id: string) => `/api/v1/companies/${id}/company-details`,
    list: (id: string) => `/api/v1/companies/${id}/relates`,
    pendencies: (id: string) => `/api/v1/companies/${id}/pendencies`,
    beneficiaries: (companyId: string) =>
      `/api/v1/companies/${companyId}/beneficiaries/summary`,
    backToIntegrationPending: (id: string) =>
      `/api/v1/companies/${id}/back-to-integration-pending`,
    removeIsNewCompanyStatus: (id: string) =>
      `/api/v1/users/company/${id}/update-new-company-flag`,
    companySummaryDetail: (id: string) => `/api/v1/companies/${id}`,
  };

  public async fetchCompaniesList({
    id,
    ...rest
  }: CompaniesListRequestDTO): Promise<CompaniesListProps> {
    const { data } = await HapvidaApi.get<CompaniesListResponseDTO>(
      this.endpoints.list(id),
      {
        params: rest,
      },
    );

    return data.content;
  }

  public async fetchCompanyDetail({
    id,
  }: CompanyDetailRequestDTO): Promise<CompanyDetailResponseDTO['content']> {
    const {
      data: { content },
    } = await HapvidaApi.get<CompanyDetailResponseDTO>(
      this.endpoints.companyDetail(id),
    );

    return content;
  }

  public async fetchCompanySummaryDetail({
    id,
  }: CompanySummaryDetailRequestDTO): Promise<
    CompanySummaryDetailResponseDTO['content']
  > {
    const {
      data: { content },
    } = await HapvidaApi.get<CompanySummaryDetailResponseDTO>(
      this.endpoints.companySummaryDetail(id),
    );

    return content;
  }

  public async fetchPendencies({
    id,
  }: PendenciesRequestDTO): Promise<PendenciesProps> {
    const {
      data: { content },
    } = await HapvidaApi.get<PendenciesResponseDTO>(
      this.endpoints.pendencies(id),
    );

    return content;
  }

  public async updateCompany(requestData: CompanyDetails) {
    const { data } = await HapvidaApi.put(
      `/api/v1/companies/${requestData.id}`,
      {
        ...requestData,
        document: Mask.numbers(requestData.document),
      },
    );

    return data;
  }

  public async fetchCompanyBeneficiaryNumbers({
    companyId,
    afiliateId,
  }: BeneficiaryNumbersRequestDTO): Promise<BeneficiaryNumbersSummaryProps> {
    const { data } = await HapvidaApi.get<BeneficiaryNumbersResponseDTO>(
      this.endpoints.beneficiaries(companyId),
      {
        params: {
          [FilterKeyEnum.ORGANIZATIONAL_GROUP]: afiliateId,
        },
      },
    );

    return data.content;
  }

  public async backToIntegrationPending({ id }: IntegrationPendingRequestDTO) {
    const { data } = await HapvidaApi.patch(
      this.endpoints.backToIntegrationPending(id),
    );

    return data;
  }

  public async removeIsNewCompanyStatus({ id }: RemoveIsNewCompanyStatusDTO) {
    const { data } = await HapvidaApi.put(
      this.endpoints.removeIsNewCompanyStatus(id),
    );

    return data;
  }

  public async companyDataMock(): Promise<any> {
    return MockApi.fetchMock('companiesData');
  }

  public async affiliatesDataMock(): Promise<any> {
    return MockApi.fetchMock('affiliateList');
  }
}
