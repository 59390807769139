import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { FiltersGroup, useDrawerFilter } from '../../hooks/useDrawerFilter';
import { COLORS } from '../../themes/colors';
import { DateRangePicker } from '../DateRangePicker';
import Icon from '../Icon';
import RadioInput from '../RadioInput';
import { DATE_KEYS, DATE_VALUES } from './periodKeys';
import {
  Calendar,
  FilterButton,
  Header,
  StyledCollapse,
  Wrapper,
} from './styles';
import { FilterKeyEnum } from '../../constants';

const DateFilter = () => {
  const [open, setOpen] = useState(false);

  const {
    setInitialFilters,
    setProvFilters,
    initialFilters,
    activeFilters,
    unregister,
    register,
  } = useDrawerFilter();

  const { control, watch, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      date: '',
    },
  });

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const date = watch('date');

  const handleChange = () => {
    setProvFilters(prev => ({
      ...prev,
      date: {
        startDate: null,
        endDate: null,
      },
    }));
    setValue('date', DATE_VALUES[DATE_KEYS.ALL].name);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setProvFilters(prev => ({
        ...prev,
        date: {
          startDate,
          endDate,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const fillOption = (start: Date | null, end: Date | null) => {
    if (start && end) {
      var timeDiff = Math.abs(end.getTime() - start.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setStartDate(start);
      setEndDate(end);

      const today = new Date();
      const endDateIsEqualToday =
        end.getDate() + end.getMonth() + end.getFullYear() ===
        today.getDate() + today.getMonth() + today.getFullYear();

      if (
        diffDays === DATE_VALUES[DATE_KEYS.LAST_MONTH].value &&
        endDateIsEqualToday
      ) {
        setValue('date', DATE_VALUES[DATE_KEYS.LAST_MONTH].name);
      } else if (
        diffDays === DATE_VALUES[DATE_KEYS.LAST_WEEK].value &&
        endDateIsEqualToday
      ) {
        setValue('date', DATE_VALUES[DATE_KEYS.LAST_WEEK].name);
      } else {
        setValue('date', DATE_VALUES[DATE_KEYS.CUSTOM].name);
      }
    } else {
      setValue('date', DATE_VALUES[DATE_KEYS.ALL].name);
    }
  };

  useEffect(() => {
    switch (date) {
      case DATE_VALUES[DATE_KEYS.ALL].name: {
        setStartDate(new Date());
        setEndDate(null);
        setProvFilters(prev => {
          const { date, ...otherFilters } = prev;

          return otherFilters as FiltersGroup;
        });
        break;
      }
      case DATE_VALUES[DATE_KEYS.LAST_WEEK].name: {
        const today = new Date();
        const minus7days = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 7,
        );
        setStartDate(minus7days);
        setEndDate(today);
        break;
      }
      case DATE_VALUES[DATE_KEYS.LAST_MONTH].name: {
        const today = new Date();
        const minus30days = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 30,
        );
        setStartDate(minus30days);
        setEndDate(today);
        break;
      }
      case DATE_VALUES[DATE_KEYS.CUSTOM].name: {
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    const start = activeFilters.date?.startDate ?? null;
    const end = activeFilters.date?.endDate ?? null;
    register([FilterKeyEnum.START_DATE, FilterKeyEnum.END_DATE]);
    setStartDate(start);
    setEndDate(end);
    fillOption(start, end);

    if (initialFilters.date === undefined) {
      setInitialFilters(prev => ({
        ...prev,
        date: {
          endDate: null,
          startDate: null,
        },
      }));
    }

    return () => {
      unregister([FilterKeyEnum.START_DATE, FilterKeyEnum.END_DATE]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper bgcolor={open ? COLORS.WHITE : COLORS.MONOCHROMATIC.GRAY1}>
      <Header
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Stack>
          <Typography variant="text" mb={0.5}>
            Datas
          </Typography>
          {startDate && endDate ? (
            <FilterButton mt={1}>
              <Typography>{`Início: ${format(
                startDate,
                'dd/MM/yyyy',
              )} • Término: ${format(endDate, 'dd/MM/yyyy')}`}</Typography>
              <Icon
                onClick={e => {
                  e.stopPropagation();
                  handleChange();
                }}
                name="close"
                size={16}
                color={COLORS.WHITE}
              />
            </FilterButton>
          ) : (
            <Typography variant="smallText">Todo período</Typography>
          )}
        </Stack>

        <Icon name={open ? 'chevron-up' : 'chevron-down'} size={24} />
      </Header>

      <StyledCollapse in={open}>
        <Calendar>
          <Stack sx={{ paddingX: '15px', paddingY: '5px' }}>
            <RadioInput
              options={[
                {
                  key: DATE_VALUES[DATE_KEYS.ALL].name,
                  value: DATE_VALUES[DATE_KEYS.ALL].name,
                },
                {
                  key: DATE_VALUES[DATE_KEYS.LAST_WEEK].name,
                  value: DATE_VALUES[DATE_KEYS.LAST_WEEK].name,
                },
                {
                  key: DATE_VALUES[DATE_KEYS.LAST_MONTH].name,
                  value: DATE_VALUES[DATE_KEYS.LAST_MONTH].name,
                },
                {
                  key: DATE_VALUES[DATE_KEYS.CUSTOM].name,
                  value: DATE_VALUES[DATE_KEYS.CUSTOM].name,
                },
              ]}
              control={control}
              name="date"
            />
          </Stack>
          {date !== DATE_VALUES[DATE_KEYS.ALL].name && (
            <DateRangePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
        </Calendar>
      </StyledCollapse>
    </Wrapper>
  );
};

export default DateFilter;
