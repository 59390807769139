import { FiltersConfigProps } from '@hapvida/hapvida-core-components';

export const movementDetailsFilterConfig: FiltersConfigProps = {
  review: [
    {
      label: 'Crítica',
      value: 'true',
    },
  ],
};
