import { COLORS, IconName } from '@hapvida/hapvida-core-components';

import { DependentProps, HolderProps } from '@services/BeneficiaryService';

interface DetailProps {
  data: HolderProps | DependentProps;
}

interface IconStatusProps {
  [key: string]: {
    icon: IconName;
    color: string;
    value: string;
  };
}

const iconStatus: IconStatusProps = {
  Canceled: {
    icon: 'progress-alert',
    color: COLORS.SECONDARY.RED['+1'],
    value: 'Cancelado',
  },
  Active: {
    icon: 'marked-circle-outline',
    color: COLORS.SECONDARY.GREEN['+1'],
    value: 'Ativo',
  },
};

export { iconStatus, DetailProps };
