import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { CompanyListSubHeader } from './components';
import { CompanyListLayout } from './layout';

export function CompanyListPage() {
  return (
    <DrawerFilterProvider>
      <CompanyListSubHeader />
      <CompanyListLayout />
    </DrawerFilterProvider>
  );
}
