import { DataGrid } from '@hapvida/hapvida-core-components';

import { useBeneficiaryDetailsState } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/hooks';

import { getBeneficiarySummaryRows } from './utils';

export function BeneficiarySummaryCard() {
  const { beneficiaryDetailsState } = useBeneficiaryDetailsState();

  if (!beneficiaryDetailsState.isSuccess) {
    return null;
  }

  const beneficiary = beneficiaryDetailsState.data;

  const rows = getBeneficiarySummaryRows(beneficiary);

  return <DataGrid title="Resumo" rows={rows} />;
}
