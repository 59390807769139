import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { UserListPage } from '.';

export const UserListRouter: IAppRoute = {
  Page: UserListPage,
  path: '/usuarios',
  name: 'Usuários',
  isPrivate: true,
  Icon: <Icon name="account-multiple" />,
  disabled: false,
};
