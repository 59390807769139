import { FormState, UseFormHandleSubmit, UseFormReset } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useState } from 'react';

import { Button, Modal } from '@hapvida/hapvida-core-components';

import { CircularProgress } from '@hapvida/hapvida-core-components/src/components/CircularProgress';

import { UserActionsOptionsEnum } from '../../constants/userActionsOptions';
import { useBackofficeUserDetailsState } from '../../../../hooks';
import { BackofficeUserDetailsFormProps } from '../../types';
import { useUserActionsState } from '../../hooks';
import { useEditBackofficeUser } from './hooks';

interface SubmitFormUserButtonProps {
  handleSubmit: UseFormHandleSubmit<BackofficeUserDetailsFormProps>;
  formState: FormState<BackofficeUserDetailsFormProps>;
  reset: UseFormReset<BackofficeUserDetailsFormProps>;
}

export function SubmitBackofficeUserFormButton({
  handleSubmit,
  formState,
  reset,
}: Readonly<SubmitFormUserButtonProps>) {
  const [updatedUserSuccessfully, setUpdatedUserSuccessfully] = useState(false);
  const [updateConfirmationOpen, setUpdateConfirmationOpen] = useState(false);
  const { backofficeUserDetailsState } = useBackofficeUserDetailsState();
  const { getIsDisabled, getIsLoading } = useUserActionsState();

  const {
    handleCloseUpdatedSuccessfully,
    updatedSuccessfullyOpen,
    handleUpdateUser,
  } = useEditBackofficeUser({ formState });

  if (!backofficeUserDetailsState.isSuccess) {
    return null;
  }

  const userData = backofficeUserDetailsState.data.content;

  const toggleUpdatedUserSuccessfully = () =>
    setUpdatedUserSuccessfully(!updatedUserSuccessfully);
  const toggleUpdateConfirmation = () =>
    setUpdateConfirmationOpen(!updateConfirmationOpen);

  const { dirtyFields, isDirty, isValid } = formState;

  const isDisabled =
    getIsDisabled(UserActionsOptionsEnum.EDIT) || !isValid || !isDirty;

  const isLoading = getIsLoading(UserActionsOptionsEnum.EDIT);

  const handleSubmitForm = async (data: BackofficeUserDetailsFormProps) => {
    if (data.email !== userData?.email) {
      toggleUpdateConfirmation();
      return;
    }

    const hasUpdated = await handleUpdateUser(data);
    if (hasUpdated) {
      if (!dirtyFields.email) {
        toggleUpdatedUserSuccessfully();
      }
      reset(data);
    }
  };

  const handleConfirmedUpdate = () => {
    handleSubmit(handleUpdateUser)();
    toggleUpdateConfirmation();
  };

  return (
    <>
      <Button
        endIcon={isLoading ? <CircularProgress size={16} /> : undefined}
        onClick={handleSubmit(handleSubmitForm)}
        disabled={isDisabled || isLoading}
        variant="contained"
        color="success"
        type="button"
      >
        Atualizar acesso
      </Button>
      <Modal
        handleCloseModal={toggleUpdatedUserSuccessfully}
        title="Dados do usuário editados com sucesso"
        mainAction={toggleUpdatedUserSuccessfully}
        open={updatedUserSuccessfully}
        mainButtonText="Fechar"
        type="success"
        icon="check"
      >
        <Typography variant="body2">
          Atualizamos o usuário selecionado com as novas informações editadas
        </Typography>
      </Modal>
      <Modal
        title="Edição do e-mail do usuário com acesso pendente"
        handleCloseModal={toggleUpdateConfirmation}
        mainAction={handleConfirmedUpdate}
        open={updateConfirmationOpen}
        secondaryButtonText="Fechar"
        mainButtonText="Continuar"
        icon="alert"
      >
        <Typography variant="body2">
          Percebemos que o acesso desse usuário ainda está pendente e você está
          tentando editar o e-mail do usuário. Isso gerará o reenvio de um novo
          e-mail de primeiro acesso para o endereço atualizado. Deseja
          continuar?
        </Typography>
      </Modal>
      <Modal
        handleCloseModal={handleCloseUpdatedSuccessfully}
        title="Confirmação de edição de e-mail enviada"
        mainAction={handleCloseUpdatedSuccessfully}
        open={updatedSuccessfullyOpen}
        mainButtonText="Fechar"
        type="success"
        icon="check"
      >
        <Typography variant="body2">
          Um alerta de confirmação foi enviado para o novo e-mail do usuário.
          Peça para o usuário verificar a caixa de entrada para prosseguir com o
          primeiro acesso.
        </Typography>
      </Modal>
    </>
  );
}
