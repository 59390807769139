import { Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Container = styled(Stack)`
  border: 1px solid ${COLORS.PRIMARY.ORANGE.MAIN};
  background: rgba(245, 182, 127, 0.08);
  border-radius: 4px;
  padding: 16px;
  gap: 16px;
`;
