import { Grid, styled as muiStyled } from '@mui/material';

export const Wrapper = muiStyled('div')(() => ({
  padding: '1.5rem',
}));

export const Container = muiStyled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  paddingLeft: '4rem',
  paddingTop: '1rem',
  gap: '1rem',
}));
