import { Filter, FiltersGroup } from '../../../hooks/useDrawerFilter';

interface UpdateFiltersProps {
  previousFiltersGroup: FiltersGroup;
  newFilters: Filter[];
  filterKey: string;
}
export function updateFilters({
  previousFiltersGroup,
  newFilters,
  filterKey,
}: UpdateFiltersProps): FiltersGroup {
  const currentFilters = previousFiltersGroup[filterKey]?.filters ?? [];

  if (currentFilters.length === 0) {
    return previousFiltersGroup;
  }

  const updatedFilters = currentFilters.map(currentFilter => {
    const newFilter = newFilters.find(
      apiFilter => apiFilter.value === currentFilter.value,
    );
    return newFilter ?? currentFilter;
  });

  return {
    ...previousFiltersGroup,
    [filterKey]: {
      ...previousFiltersGroup[filterKey],
      filters: updatedFilters,
    },
  };
}
