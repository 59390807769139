import { FC } from 'react';
import { Grid, Stack } from '@mui/material';

import { Icon, Button, Typography } from '@hapvida/hapvida-core-components';

import RegistrationCompletedLogo from './assets/registration-completed-logo.svg';
import { Wrapper } from './styles';

const RegistrationCompleted: FC = () => {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Wrapper>
        <img
          src={RegistrationCompletedLogo}
          alt="Desenho de uma prancheta com uma lista de itens com 'checks' indicando que o cadastro foi finalizado"
        />
      </Wrapper>

      <Stack gap={1} display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h5">Cadastro finalizado com sucesso</Typography>
        <Typography variant="text">
          Sua área está te esperando, desejamos ótimos negócios
        </Typography>
      </Stack>

      <Wrapper>
        <Button
          color="success"
          variant="contained"
          endIcon={<Icon name="chevron-right" size={16} />}
          href={process.env.REACT_APP_LOGIN_URL}
        >
          Acessar portal empresa
        </Button>
      </Wrapper>
    </Grid>
  );
};

export default RegistrationCompleted;
