import { IconName } from '@hapvida/hapvida-core-components';

import { BaseProfileResourceEnum } from '@constants';

interface ProfileResourceInfosProps {
  icon: IconName;
  title: string;
  permissionsResource: string[];
}

export const ProfileResourceInfos: Record<
  BaseProfileResourceEnum,
  ProfileResourceInfosProps
> = {
  [BaseProfileResourceEnum.MOVEMENT]: {
    title: 'Movimentações',
    icon: 'clipboard-text-outline',
    permissionsResource: ['Nova movimentação', 'Lista de movimentações'],
  },
  [BaseProfileResourceEnum.BENEFICIARIES]: {
    title: 'Beneficiários',
    icon: 'clipboard-pulse',
    permissionsResource: [
      'Lista de beneficiários',
      'Carta de permanência',
      'Carteirinha digital',
      'Autenticar atestado',
    ],
  },
  [BaseProfileResourceEnum.FINANCIAL]: {
    title: 'Financeiro',
    icon: 'money',
    permissionsResource: [
      'Lista de faturas',
      'Informe de rendimentos',
      'Número de pedido da nota fiscal',
      'Relatório de histórico financeiro',
    ],
  },
  [BaseProfileResourceEnum.USERS]: {
    title: 'Usuários',
    icon: 'account-multiple',
    permissionsResource: ['Criar novo usuário', 'Excluir usuário'],
  },
  [BaseProfileResourceEnum.COMPANIES]: {
    title: 'Empresas',
    icon: 'home-work',
    permissionsResource: ['Lista de empresas', 'Consultar empresas'],
  },
  [BaseProfileResourceEnum.MORE_SERVICES]: {
    title: 'Mais Serviços',
    icon: 'table-view',
    permissionsResource: ['Redirecionamento para ferramentas externas'],
  },
};
