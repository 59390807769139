import { ListGridProps } from './types';
import { GridHeader, GridItem, List } from './components';

const GRID_MAX_SIZE = 12;

export function ListGrid({
  columns,
  rows,
  paginationProps,
  containerProps,
  headerProps,
  listProps,
}: Readonly<ListGridProps>) {
  const totalColumnSize = columns.reduce(
    (acc, column) => acc + (column.gridSize ?? 0),
    0,
  );

  const totalRemainingColumnSize = GRID_MAX_SIZE - totalColumnSize;

  const columnsWithoutGridSize = columns.filter(column => !column.gridSize);

  const defaultColumnSizePerItem =
    totalRemainingColumnSize / columnsWithoutGridSize.length;

  return (
    <List
      containerProps={containerProps}
      headerProps={headerProps}
      listProps={listProps}
      data={rows}
      header={
        <GridHeader
          defaultColumnSizePerItem={defaultColumnSizePerItem}
          columns={columns}
        />
      }
      keyExtractor={item => item.id}
      renderItem={item => {
        return (
          <GridItem
            columns={columns}
            defaultColumnSizePerItem={defaultColumnSizePerItem}
            row={item}
          />
        );
      }}
      paginationProps={paginationProps}
    />
  );
}
