import { RouteProps, Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import { PrivateTemplate } from '@templates';
import { HomeRouter } from '@flows/private/home/router';
import { useAuth } from '@hooks';
import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';
import { CompanyStatusEnum } from '@constants';
import { CompanySetupDetailsRouter } from '@flows/private/companySetup/pages/CompanySetupDetails/router';
import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import { BillListRouter } from '@flows/private/financial/pages/List/router';
import { BeneficiaryListRouter } from '@flows/private/beneficiaries/pages/BeneficiaryList/router';
import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';
import { UserType } from '@contexts';
import { PartnerCompanyListRouter } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/router';

interface ProxyRouteProps extends Omit<RouteProps, 'element'> {
  isPrivate?: boolean;
  path?: string;
  hideSidebar?: boolean;
  hideHeader?: boolean;
}

export function ProxyRoute({
  isPrivate,
  path,
  hideHeader,
  hideSidebar,
}: Readonly<ProxyRouteProps>) {
  const { user, selectedCompany, selectedPartnerCompany } = useAuth();

  const isCompanySetupList = path === CompanySetupListRouter.path;
  const isPartnerCompanySetupList = path === PartnerCompanyListRouter.path;
  const isCompanySetupDetails = path === CompanySetupDetailsRouter.path;
  const includesBackofficeUsers = Boolean(
    path?.includes(BackofficeUserListRouter.path),
  );

  const includesMovements = Boolean(path?.includes(MovementListRouter.path));
  const includesFinancial = Boolean(path?.includes(BillListRouter.path));
  const includesBeneficiaries = Boolean(
    path?.includes(BeneficiaryListRouter.path),
  );
  const hasUser = Boolean(user);
  const hasSelectedCompany = Boolean(selectedCompany);
  const hasSelectedPartnerCompany = Boolean(selectedPartnerCompany);
  const backofficeUser = user?.companyType === UserType.Backoffice;

  const isRouteAccessAllowed =
    !isPrivate ||
    ((isCompanySetupList ||
      (isCompanySetupDetails &&
        hasSelectedCompany &&
        hasSelectedPartnerCompany)) &&
      hasUser) ||
    (isPartnerCompanySetupList && hasSelectedCompany && hasUser) ||
    (backofficeUser && includesBackofficeUsers) ||
    (isPrivate &&
      hasUser &&
      hasSelectedCompany &&
      hasSelectedPartnerCompany &&
      !includesBackofficeUsers);

  const isFirefox = () => {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  };

  useEffect(() => {
    const handlePopstate = () => {
      if (!isRouteAccessAllowed || isFirefox()) window.location.reload();
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  if (isRouteAccessAllowed) {
    if (
      !isCompanySetupDetails &&
      !isCompanySetupList &&
      !isPartnerCompanySetupList &&
      !includesBackofficeUsers &&
      hasSelectedCompany &&
      hasSelectedPartnerCompany &&
      selectedCompany?.status === CompanyStatusEnum.REGISTER_ACCESS
    ) {
      return (
        <>
          {window.location.replace(
            CompanySetupDetailsRouter.dynamicPath({
              companyId: selectedCompany.id,
            }),
          )}
        </>
      );
    }

    if (
      selectedCompany?.status === CompanyStatusEnum.PENDING_ACCESS &&
      (includesMovements || includesFinancial || includesBeneficiaries)
    ) {
      return <>{window.location.replace(HomeRouter.path)}</>;
    }

    if (isPrivate) {
      return (
        <PrivateTemplate hideHeader={hideHeader} hideSidebar={hideSidebar} />
      );
    }
    return <Outlet />;
  }

  if (
    !isCompanySetupList &&
    isPrivate &&
    hasUser &&
    (!hasSelectedCompany || includesBackofficeUsers)
  ) {
    return <>{window.location.replace(CompanySetupListRouter.path)}</>;
  }

  if (
    !isPartnerCompanySetupList &&
    isPrivate &&
    hasUser &&
    (!hasSelectedPartnerCompany || includesBackofficeUsers)
  ) {
    return <>{window.location.replace(PartnerCompanyListRouter.path)}</>;
  }

  if (isPrivate && hasUser && isCompanySetupList) {
    return <>{window.location.replace(HomeRouter.path)}</>;
  }

  return <>{window.location.replace(process.env.REACT_APP_LOGIN_URL)}</>;
}
