import { Stack, StackProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { PulsarAnimation, PulsarAnimationProps } from './styles';

export * from './styles';

interface LoadingProps extends StackProps {
  animationProps?: PulsarAnimationProps;
  message?: ReactNode | string;
}

export function Loading({
  animationProps,
  message,
  ...stackProps
}: LoadingProps) {
  return (
    <Stack gap={3} alignItems="center" {...stackProps}>
      <PulsarAnimation {...animationProps} />

      {message && (
        <Typography variant="h5" textAlign="center">
          {message}
        </Typography>
      )}
    </Stack>
  );
}
