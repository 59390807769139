import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { ProfileProps } from '@services/UserService';

import {
  PartnerCompaniesSelection,
  ProfileSelection,
  RemoveProfileButton,
  SummarySelectedPartnerCompanies,
} from './components';
import { ProfileCardProvider } from './hooks';

interface ProfileCardProps {
  profile?: ProfileProps;
  profileCardId: string;
  handleRemoveProfile: (profileCardId: string, profileId?: string) => void;
}

export function ProfileCard({
  profile,
  handleRemoveProfile,
  profileCardId,
}: ProfileCardProps) {
  return (
    <ProfileCardProvider initialSelectedProfile={profile}>
      <SummaryCardNew.Container>
        <ProfileSelection />
        <PartnerCompaniesSelection />
        <SummarySelectedPartnerCompanies />
        <RemoveProfileButton
          handleRemoveProfile={handleRemoveProfile}
          profileCardId={profileCardId}
        />
      </SummaryCardNew.Container>
    </ProfileCardProvider>
  );
}
