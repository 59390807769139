import { Grid } from '@mui/material';

import { MovementSummaryLivesProps } from '@services/MovimentationService';

import { LifeCard } from './components';

interface MovementBeneficiariesSummaryProps {
  summaryLives: MovementSummaryLivesProps;
}
export function MovementBeneficiariesSummary({
  summaryLives,
}: MovementBeneficiariesSummaryProps) {
  const widthCardInfo = 2.4;

  return (
    <Grid container spacing={3} columns={12} alignItems="center">
      <Grid item md={widthCardInfo}>
        <LifeCard
          value={summaryLives?.totalLives ?? '-'}
          label="Total de beneficiários"
        />
      </Grid>
      <Grid item md={widthCardInfo}>
        <LifeCard
          value={summaryLives?.processingLives ?? '-'}
          label="Beneficiários em processamento"
          type="process"
        />
      </Grid>
      <Grid item md={widthCardInfo}>
        <LifeCard
          value={summaryLives?.processedLives ?? '-'}
          label="Beneficiários processados"
          type="success"
        />
      </Grid>
      <Grid item md={widthCardInfo}>
        <LifeCard
          value={summaryLives?.reviewLives ?? '-'}
          label="Beneficiários criticados"
          type="error"
        />
      </Grid>
      <Grid item md={widthCardInfo}>
        <LifeCard
          value={summaryLives?.canceledLives ?? '-'}
          label="Beneficiários cancelados"
          type="canceled"
        />
      </Grid>
    </Grid>
  );
}
