import Grid from '@mui/material/Grid';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import {
  AccessProfileDrawerFilter,
  FiltersDrawerButton,
  SearchField,
  SubHeader,
  UserStatusDrawerFilter,
} from '@components';
import { useUsersState } from '@flows/private/users/pages/UserList/hooks/index';

export function UserListSubHeader() {
  const { hasFilters } = useFilterParams();
  const { filterCount } = useDrawerFilter();
  const { usersState } = useUsersState();

  const { isError, isLoading } = usersState;

  const totalUsersListLength = usersState?.data?.total ?? 0;

  const isResultEmpty = totalUsersListLength === 0;

  const searchButtonDisabled = isLoading && hasFilters;

  const filtersDrawerButtonDisabled =
    !filterCount && (isLoading || isResultEmpty);

  const isLoadingWithoutFilters = isLoading && !hasFilters;

  const isEmptyWithoutFilters = isResultEmpty && !hasFilters;

  const isErrorWithoutFilters = isError && !hasFilters;

  if (
    isLoadingWithoutFilters ||
    isErrorWithoutFilters ||
    isEmptyWithoutFilters
  ) {
    return null;
  }

  return (
    <SubHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={10}>
          <SearchField
            currentResultLength={totalUsersListLength}
            placeholder="Buscar nome do usuário, e-mail ou CPF"
            disableSearch={searchButtonDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={2}>
          <FiltersDrawerButton
            subtitle="Selecione como deseja filtrar os usuários"
            title="Filtrar usuários"
            disabled={filtersDrawerButtonDisabled}
          >
            <UserStatusDrawerFilter />
            <AccessProfileDrawerFilter />
          </FiltersDrawerButton>
        </Grid>
      </Grid>
    </SubHeader>
  );
}
