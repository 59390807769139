export enum BeneficiaryMovementTypeEnum {
  EXCLUSION_CANCELATION = 'ExclusionCancelation',
  DEPENDENT_INCLUSION = 'DependentInclusion',
  CONTRACT_TRANSFER = 'ContractTransfer',
  CADASTRAL_CHANGE = 'CadastralChange',
  HOLDER_INCLUSION = 'HolderInclusion',
  REACTIVATION = 'Reactivation',
  PLAN_CHANGE = 'PlanChange',
  EXCLUSION = 'Exclusion',
  RETIRED = 'Retired',
}
