import { TableHeadParams } from '@hapvida/hapvida-core-components/src/components/BaseTable/types';

enum StayLetterListColumnNameEnum {
  WALLET_NUMBER = 'walletNumber',
  FULL_NAME = 'fullName',
  CPF = 'cpf',
  TYPE = 'type',
  STAY_LETTER = 'stayLetter',
}

const stayLetterListTableHeadColumns: TableHeadParams[] = [
  {
    id: 1,
    name: StayLetterListColumnNameEnum.WALLET_NUMBER,
    label: 'N° carteirinha',
    sortable: false,
  },
  {
    id: 2,
    name: StayLetterListColumnNameEnum.FULL_NAME,
    label: 'Nome completo',
    sortable: false,
  },
  {
    id: 3,
    name: StayLetterListColumnNameEnum.CPF,
    label: 'CPF',
    sortable: false,
  },
  {
    id: 4,
    name: StayLetterListColumnNameEnum.TYPE,
    label: 'Tipo',
    sortable: false,
  },
  {
    id: 5,
    name: StayLetterListColumnNameEnum.STAY_LETTER,
    label: 'Carta de permanência',
    sortable: false,
  },
];

export { StayLetterListColumnNameEnum, stayLetterListTableHeadColumns };
