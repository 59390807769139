import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

import { COLORS } from '../colors';
const MuiFormHelperTextTheme: {
  defaultProps?: ComponentsProps['MuiFormHelperText'];
  styleOverrides?: ComponentsOverrides['MuiFormHelperText'];
  variants?: ComponentsVariants['MuiFormHelperText'];
} = {
  variants: [],

  defaultProps: {},
  styleOverrides: {
    root: {
      marginLeft: 12,
      color: COLORS.MONOCHROMATIC.GRAY4,
      '&.Mui-disabled': {
        color: COLORS.MONOCHROMATIC.GRAY3,
      },
      '&.Mui-error': {
        color: COLORS.SECONDARY.RED.MAIN,
      },
    },
  },
};

export default MuiFormHelperTextTheme;
