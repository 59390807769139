export const singularTreatmentShippingReviews = (
  totalShippingReviews: number,
) => {
  const isOneShippingReview = totalShippingReviews === 1;

  if (isOneShippingReview) {
    return `Encontramos erro em ${totalShippingReviews} linha que não foi processada, `;
  }

  return `Encontramos erros em ${totalShippingReviews} linhas que não foram processadas, `;
};
