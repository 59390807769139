import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import {
  SummaryCardNew,
  Collapse,
  Company,
  Mask,
} from '@hapvida/hapvida-core-components';

import { CompaniesListProps } from '@services/CompanyService';

import { CompanyDetailsRouter } from '@flows/private/companies/pages/CompanyDetails/router';
import { useFilterParams } from '@hooks';

import {
  companyListTableHeadColumns,
  CompanyListColumnNameEnum,
} from '../../constants';

interface CompanyListCardProps {
  companyListData: CompaniesListProps;
}

export function CompanyListCard({ companyListData }: CompanyListCardProps) {
  const { handlePageChange, currentPage } = useFilterParams();
  const navigateTo = useNavigate();

  const companyListTableRows = companyListData.companies?.map(company => ({
    id: company.id,
    main: {
      [CompanyListColumnNameEnum.COMPANY]: (
        <Stack>
          <Typography fontSize={14} fontWeight={700}>
            {company.name?.trade ?? company.name?.legal}
          </Typography>
          <Typography fontSize={12} fontWeight={400}>
            {Mask.cnpj(company.document)}
          </Typography>
        </Stack>
      ),
      [CompanyListColumnNameEnum.CONTRACT_NUMBER]: (
        <Typography fontSize={14}>{company.partnerCompany}</Typography>
      ),
      [CompanyListColumnNameEnum.UNIT_CODE]: (
        <Typography fontSize={14}>{company.unitCode}</Typography>
      ),
      [CompanyListColumnNameEnum.CUTOFF_DATE]: (
        <Typography fontSize={14}>
          {company.cutoffDate
            ? new Date(company.cutoffDate).toLocaleDateString()
            : '-'}
        </Typography>
      ),
      [CompanyListColumnNameEnum.HEALTH_OPERATOR]: (
        <Company company={company.healthOperator ?? ''} />
      ),
    },
    dependents: [],
  }));

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleEdit = useCallback(
    (id: string) => {
      navigateTo(CompanyDetailsRouter.dynamicPath({ id }));
    },
    [navigateTo],
  );

  const totalCompaniesPerPage = companyListData?.totalPerPage ?? 0;
  const totalCompaniesLength = companyListData?.total ?? 0;

  const viewedCompaniesLength = 10 * (currentPage - 1) + totalCompaniesPerPage;
  const hasMultiPages = totalCompaniesLength > totalCompaniesPerPage;

  return (
    <SummaryCardNew.Container>
      <Typography variant="subtitle1">Lista de empresas</Typography>
      <Collapse
        TableHeadColumns={companyListTableHeadColumns}
        count={Math.ceil(totalCompaniesLength / 10)}
        onPageChange={handleChangePage}
        from={viewedCompaniesLength}
        data={companyListTableRows}
        showFooter={hasMultiPages}
        to={totalCompaniesLength}
        currentPage={currentPage}
        onEdit={handleEdit}
        baseTableProps={{
          tableContainerHeight: '100%',
          style: {
            padding: 0,
          },
        }}
        hasCellAction
        width="auto"
      />
    </SummaryCardNew.Container>
  );
}
