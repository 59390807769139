const movimentationsMock = {
  content: [
    {
      id: 45326515,
      fileData: {
        fileName: 'IDXXXXXX.xlsx',
        type: 'XLSX',
        path: 'path',
        size: 8000,
        createdAt: '2022-06-02T13:27:31.212Z',
      },
      type: 'Movimentação via layout',
      loadDate: '2022-06-02T13:27:31.212Z',
      effectiveDate: '2022-06-02T13:27:31.212Z',
      status: 'processing',
      isError: false,
      lives: 1230,
      companyDetail: {
        fantasyName: 'Nome fantasia',
        document: '00000000000001',
        corporateName: 'Nome da empresa',
        state: 'SP',
        city: 'São Paulo',
        exclusivity: 'Sim',
        commissioning: 'mais de 3.000 beneficiários',
        affiliatesData: [
          {
            fantasyName: 'Nome fantasia 2',
            document: '00000000000001',
            corporateName: 'Nome da empresa 2',
            state: 'SP',
            city: 'São Paulo',
          },
        ],
        createdAt: '2022-01-24T13:15:30Z',
        lastModifiedAt: '2022-01-24T13:15:30Z',
      },
    },
    {
      id: 453265136,
      fileData: {
        fileName: 'IDXXXXXX.xlsx',
        type: 'XLSX',
        path: 'path',
        size: 8000,
        createdAt: '2022-06-02T13:27:31.212Z',
      },
      type: 'Movimentação via layout',
      loadDate: '2022-06-02T13:27:31.212Z',
      effectiveDate: '2022-06-02T13:27:31.212Z',
      status: 'finished',
      isError: false,
      lives: 1230,
      proccessedLives: 1220,
      refusedLives: 10,
      livesData: [
        {
          code: 1,
          type: 'secondCopyOfCard',
          beneficiaryData: {
            status: '453265158133542',
            code: '453265158133542',
            name: 'LUCAS T S A EL MOUTAQI',
            gender: 'MASCULINO',
            cpf: '11111111111',
            type: 'Titular',
            plan: '5252 - Nosso Plano',
          },
        },
        {
          code: 1,
          type: 'registrationChange',
          beneficiaryData: {
            status: '453265158133542',
            code: '453265158133542',
            name: 'LUCAS T S A EL MOUTAQI',
            gender: 'MASCULINO',
            cpf: '11111111111',
            type: 'Titular',
            plan: '5252 - Nosso Plano',
          },
          isError: true,
          errorMessage:
            '145 - PENDECIA PARA DEPENDENTE - MOVIMENTAÇÃO SIB // RECEBIDO DECLARAÇÃO DE SAÚDE E CARTA ANS, FECHA DO ASSOCIADO E EXTRATO DE PAGAMENTO',
        },
      ],
      companyDetail: {
        fantasyName: 'Nome fantasia',
        document: '00000000000001',
        corporateName: 'Nome da empresa',
        state: 'SP',
        city: 'São Paulo',
        exclusivity: 'Sim',
        commissioning: 'mais de 3.000 beneficiários',
        affiliatesData: [
          {
            fantasyName: 'Nome fantasia 2',
            document: '00000000000002',
            corporateName: 'Nome da empresa 2',
            state: 'SP',
            city: 'São Paulo',
          },
          {
            fantasyName: 'Nome fantasia 3',
            document: '00000000000003',
            corporateName: 'Nome da empresa 3',
            state: 'SP',
            city: 'São Paulo',
          },
        ],
        createdAt: '2022-01-24T13:15:30Z',
        lastModifiedAt: '2022-01-24T13:15:30Z',
      },
    },
    {
      id: 453265135,
      type: 'Movimentação via layout',
      loadDate: '2022-06-02T13:27:31.212Z',
      effectiveDate: '2022-06-02T13:27:31.212Z',
      status: 'interruptedByError',
      fileData: {
        fileName: 'IDXXXXXX.xlsx',
        type: 'XLSX',
        path: 'path',
        size: 8000,
        createdAt: '2022-06-02T13:27:31.212Z',
      },
      isError: true,
      companyDetail: {
        fantasyName: 'Nome fantasia',
        document: '00000000000001',
        corporateName: 'Nome da empresa',
        state: 'SP',
        city: 'São Paulo',
        exclusivity: 'Sim',
        commissioning: 'mais de 3.000 beneficiários',
        affiliatesData: [
          {
            fantasyName: 'Nome fantasia 2',
            document: '00000000000001',
            corporateName: 'Nome da empresa 2',
            state: 'SP',
            city: 'São Paulo',
          },
        ],
        createdAt: '2022-01-24T13:15:30Z',
        lastModifiedAt: '2022-01-24T13:15:30Z',
      },
    },
    {
      id: 45326517,
      type: 'Movimentação via layout',
      loadDate: '2022-06-02T13:27:31.212Z',
      effectiveDate: '2022-06-02T13:27:31.212Z',
      status: 'unableToStart',
      isError: true,
      companyDetail: {
        fantasyName: 'Nome fantasia',
        document: '00000000000001',
        corporateName: 'Nome da empresa',
        state: 'SP',
        city: 'São Paulo',
        exclusivity: 'Sim',
        commissioning: 'mais de 3.000 beneficiários',
        affiliatesData: [
          {
            fantasyName: 'Nome fantasia 2',
            document: '00000000000001',
            corporateName: 'Nome da empresa 2',
            state: 'SP',
            city: 'São Paulo',
          },
        ],
        createdAt: '2022-01-24T13:15:30Z',
        lastModifiedAt: '2022-01-24T13:15:30Z',
      },
    },
  ],
};

export default movimentationsMock;
