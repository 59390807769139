import * as yup from 'yup';

export const schema = yup.object({
  city: yup.string().required(),
  state: yup.string().required(),
  number: yup.string().required(),
  zipCode: yup.string().required(),
  street: yup.string().required(),
  complement: yup.string(),
});
