import {
  Box,
  styled as muiStyled,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  Stack,
} from '@mui/material';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const PageContainer = muiStyled(Box)(() => ({
  display: 'flex',
}));

interface DrawerProps {
  startImage?: string;
  isFirstStep: boolean;
  showImage?: boolean;
  firstStepColor?: string;
}

export const Drawer = muiStyled(
  ({
    startImage,
    isFirstStep,
    showImage,
    firstStepColor,
    ...props
  }: DrawerProps & MuiDrawerProps) => <MuiDrawer {...props} />,
)<DrawerProps>(({ theme }) => ({
  width: 500,
  zIndex: theme.zIndex.drawer,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  '.MuiPaper-root': {
    borderRight: 'none',
  },
  '.MuiDrawer-paper': {
    [theme.breakpoints.up('md')]: {
      background: 'none',
      backgroundPosition: '100% 0px',
      backgroundSize: 'contain',
      backgroundAttachment: 'fixed',
      width: 500,
    },
  },
}));

export const StyledStack = muiStyled(Stack)`
	flex-direction: column;
	position: relative;
	overflow-y: auto;
	display: flex;
	height: 100vh;
	flex: 1;
`;

export const LogoContainer = muiStyled(Stack)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    img: {
      width: 102,
    },
  },
}));

interface FirstStepContentProps {
  textContentColor?: string;
}

export const FirstStepContent = muiStyled(Stack)<FirstStepContentProps>(
  ({ theme, textContentColor }) => ({
    marginTop: 120,
    padding: '16px 80px',
    maxWidth: 500,
    overflowWrap: 'break-word',
    whiteSpace: 'break-spaces',
    color: COLORS.WHITE,
    h4: {
      width: '100%',
      maxWidth: 280,
      wordWrap: 'break-word !important',
      overflowWrap: 'break-word !important',
      wordBreak: 'break-word !important',
      color: textContentColor ?? COLORS.WHITE,
    },
    span: {
      color: textContentColor ?? COLORS.WHITE,
    },

    marginLeft: 95,

    [theme.breakpoints.down('sm')]: {
      width: '70% !important',
      padding: '0px !important',
      marginLeft: '50px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const StartImage = muiStyled('img')(() => ({}));
