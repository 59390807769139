import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Theme } from '@mui/material';

import { darkTheme, lightTheme } from '@hapvida/hapvida-core-components';

import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';

export enum ThemesEnum {
  LIGHT_THEME = 'lightTheme',
  DARK_THEME = 'darkTheme',
}
interface CurrentThemeStateContextData {
  currentTheme: Theme;
  changeCurrentTheme: (theme: ThemesEnum) => void;
}

const CurrentThemeStateContext = createContext<CurrentThemeStateContextData>(
  {} as CurrentThemeStateContextData,
);

const themes = {
  lightTheme,
  darkTheme,
};

function CurrentThemeStateProvider({
  children,
}: Readonly<PropsWithChildren<{}>>) {
  const { user } = useAuth();
  const isBackoffice = user?.companyType === UserType.Backoffice;
  const defaultTheme = isBackoffice
    ? themes[ThemesEnum.DARK_THEME]
    : themes[ThemesEnum.LIGHT_THEME];
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);

  const changeCurrentTheme = (theme: ThemesEnum) => {
    setCurrentTheme(themes[theme]);
  };

  const valueContextProvider = useMemo(
    () => ({
      currentTheme,
      changeCurrentTheme,
    }),
    [currentTheme, changeCurrentTheme],
  );

  return (
    <CurrentThemeStateContext.Provider value={valueContextProvider}>
      {children}
    </CurrentThemeStateContext.Provider>
  );
}

function useCurrentThemeState() {
  const context = useContext(CurrentThemeStateContext);

  if (!context) {
    throw new Error(
      'useCurrentThemeState must be used within CurrentThemeStateProvider',
    );
  }

  return context;
}

export { useCurrentThemeState, CurrentThemeStateProvider };
