import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { CompanyProps } from '@services/AuthService/dtos';
import CompanyService from '@services/CompanyService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { PartnerCompanyListRouter } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/router';

import { useCompanySetupListState } from '../useCompanySetupListState';

export function useSelectCompany() {
  const { refreshCompanySetupListStates } = useCompanySetupListState();
  const { updateAccount } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleRemoveFromNewCompanies = useCallback(
    async (companyId: string) => {
      try {
        const companyService = new CompanyService();
        await companyService.removeIsNewCompanyStatus({ id: companyId });
        refreshCompanySetupListStates();
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      }
    },
    [],
  );

  const handleSelectCompany = useCallback(
    async (selectedCompany?: CompanyProps) => {
      const updatedAccount = {
        selectedCompany,
        selectedPartnerCompany: undefined,
      };

      if (selectedCompany?.isNewCompany) {
        handleRemoveFromNewCompanies(selectedCompany.id);
      }

      updateAccount(updatedAccount);

      if (selectedCompany) {
        navigate(PartnerCompanyListRouter.path);
      }
    },
    [handleRemoveFromNewCompanies, navigate],
  );

  return {
    handleSelectCompany,
  };
}
