import { useNavigate } from 'react-router-dom';

import {
  Collapse,
  Loading,
  NoSearchResults,
} from '@hapvida/hapvida-core-components';

import { UserDetailsRouter } from '@flows/private/users/pages/UserDetails/router';
import { EmptyResult } from '@components';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';
import { useFilterParams, useAuth } from '@hooks';
import { useUsersState } from '@flows/private/users/pages/UserList/hooks/index';

import { ErrorLoading } from '../ErrorLoading';
import { userListTableHeadColumns } from '../../constants';
import { useUserListTableRows } from './hooks';

export function UserListCardTable() {
  const {
    handleTableOrderChange,
    handlePageChange,
    currentPage,
    tableOrder,
    hasFilters,
  } = useFilterParams();
  const { usersState, refreshUsersStates } = useUsersState();
  const { user: loggedUser } = useAuth();

  const { loadingState, userListTableRows } = useUserListTableRows({
    users: usersState.data?.users,
  });

  const navigate = useNavigate();

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleEditUser = (id: string) => {
    navigate(UserDetailsRouter.dynamicPath({ id }));
  };

  if (usersState.isLoading || loadingState) {
    return (
      <Loading
        padding={5}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (usersState.isError) {
    return <ErrorLoading onReloadClick={refreshUsersStates} />;
  }

  const totalUsersLength = usersState.data.total ?? 0;
  const isEmptyUsers = totalUsersLength === 0 && !hasFilters;

  if (isEmptyUsers) {
    return (
      <EmptyResult
        message={`${
          loggedUser?.name ?? ''
        }, você ainda não tem nenhum usuário cadastrado.`}
      />
    );
  }

  const isSearchResultEmpty = totalUsersLength === 0 && hasFilters;

  if (isSearchResultEmpty) {
    return <NoSearchResults padding={5} />;
  }

  const viewedUsersLength =
    ITEMS_LIMIT_PER_PAGE * (currentPage - 1) + usersState.data.totalPerPage;
  const hasMultiPages = totalUsersLength > ITEMS_LIMIT_PER_PAGE;

  return (
    <Collapse
      TableHeadColumns={userListTableHeadColumns}
      count={Math.ceil(totalUsersLength / ITEMS_LIMIT_PER_PAGE)}
      onOrderChange={handleTableOrderChange}
      onPageChange={handleChangePage}
      showFooter={hasMultiPages}
      currentPage={currentPage}
      data={userListTableRows}
      onEdit={handleEditUser}
      from={viewedUsersLength}
      tableOrder={tableOrder}
      to={totalUsersLength}
      baseTableProps={{
        tableContainerHeight: '100%',
        style: {
          padding: 0,
        },
      }}
      hasCellAction
      width="100%"
    />
  );
}
