import { Stack, StackProps } from '@mui/material';

export function BaseListHeader({
  children,
  ...stackProps
}: Readonly<StackProps>) {
  return (
    <Stack flexDirection="row" {...stackProps}>
      {children}
    </Stack>
  );
}
