import { Button, Stack, StackProps, Typography } from '@mui/material';

import alertSource from '../../assets/images/alert.svg';
import Icon from '../Icon';

interface ErrorLoadingPageProps extends StackProps {
  onReloadClick: () => void;
}

export function ErrorLoadingPage({
  onReloadClick,
  ...stackProps
}: ErrorLoadingPageProps) {
  return (
    <Stack gap={3} alignItems="center" padding={5} {...stackProps}>
      <img src={alertSource} alt="Falha ao carregar página" height={90} />
      <Typography variant="h5" textAlign="center">
        Atenção! Não conseguimos carregar a página.
      </Typography>
      <Button
        endIcon={<Icon name="refresh" size={16} />}
        onClick={onReloadClick}
        variant="outlined"
      >
        Recarregar página
      </Button>
    </Stack>
  );
}
