import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { formatISO, startOfMonth } from 'date-fns';

import { FilterKeyEnum } from '@hapvida/hapvida-core-components';

import { BillListProps } from '@services/FinancialService/dtos/BillsListDTO';
import FinancialService from '@services/FinancialService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

export function useBillsSummaryState() {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  const currentMonth = useMemo(
    () => ({
      [FilterKeyEnum.START_DATE]: formatISO(startOfMonth(new Date())),
      [FilterKeyEnum.END_DATE]: formatISO(new Date()),
    }),
    [],
  );

  const billsSummaryState = useQuery<BillListProps>({
    queryKey: ['billsSummaryState', currentMonth, companyId, partnerCompany],
    queryFn: () => {
      const financialService = new FinancialService();
      return financialService.getBillsList({
        ...currentMonth,
        companyId,
        offset: 0,
        limit: ITEMS_LIMIT_PER_PAGE,
      });
    },
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBillsSummaryStates = () => {
    billsSummaryState.remove();

    billsSummaryState.refetch();
  };

  return {
    billsSummaryState,
    refreshBillsSummaryStates,
  };
}
