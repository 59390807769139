import { Grid, Stack, Typography, Button } from '@mui/material';

import { Loading } from '@hapvida/hapvida-core-components';

import FormPassword from '@flows/public/pages/components/FormPassword';

import { useUserPassword } from './hooks';

export function UserPassword() {
  const {
    control,
    errors,
    handleSubmit,
    isValid,
    passwordConfirmWatch,
    passwordWatch,
    trigger,
    loading,
    handleRegisterDataAccessUser,
  } = useUserPassword();

  if (loading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  return (
    <Stack gap={2}>
      <Typography variant="h5">Crie uma senha forte</Typography>
      <Grid component="form" container spacing={{ xs: 5, sm: 3 }}>
        <Grid item xs={12}>
          <Typography variant="text">
            Crie uma senha forte combinando letras maiúscula e minúscula,
            números e símbolos.
          </Typography>
        </Grid>
        <FormPassword
          passwordWatch={passwordWatch}
          passwordConfirmWatch={passwordConfirmWatch}
          controlForm={control}
          errorsForm={errors}
          triggerForm={trigger}
        />

        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="success"
            onClick={handleSubmit(handleRegisterDataAccessUser)}
            disabled={!isValid}
          >
            Criar nova senha
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}
