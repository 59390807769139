import { Grid } from '@mui/material';

import { SearchField, SubHeader } from '@components';
import { useFilterParams } from '@hooks';

import { useStayLetter } from '../../hooks/useStayLetter';

export function LetterOfStaySubHeader() {
  const { hasFilters } = useFilterParams();
  const { loadingState, handleSearch } = useStayLetter();

  const disableButtonSearch = loadingState && hasFilters;
  return (
    <SubHeader>
      <Grid spacing={3} container item alignItems="center">
        <Grid item xs={12}>
          <SearchField
            placeholder="Buscar beneficiário pelo n° da carteirinha ou CPF"
            disableSearch={disableButtonSearch}
            onSubmit={handleSearch}
          />
        </Grid>
      </Grid>
    </SubHeader>
  );
}
