import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Mask } from '@hapvida/hapvida-core-components';

import { useUserDetailsState } from '@flows/private/users/pages/UserDetails/hooks';
import { ProfileSelectionCard } from '@components';
import { AccessManagementProvider } from 'components/ProfileSelectionCard/hooks';

import {
  DeleteUserButton,
  SubmitFormUserButton,
  EditFormFields,
} from './components';
import { UserForm } from './types';
import { schema } from './validation';
import { UserActionsStateProvider } from './hooks';

export function UserEditForm() {
  const { userDetailsState } = useUserDetailsState();

  if (!userDetailsState.isSuccess) {
    return null;
  }

  const userData = userDetailsState.data.content;

  const userForm = useForm<UserForm>({
    defaultValues: {
      phone: userData?.phone ? Mask.phone(userData?.phone) : '',
      document: userData?.document ?? '',
      email: userData?.email ?? '',
      name: userData?.name ?? '',
    },
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { formState, control, reset, handleSubmit } = userForm;

  const preventEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <UserActionsStateProvider>
      <Grid
        component={'form'}
        onKeyDown={preventEnter}
        container
        spacing={3}
        my={3}
      >
        <EditFormFields formState={formState} control={control} />
        <AccessManagementProvider
          initialSelectedProfiles={userData.profiles}
          userId={userData.id}
        >
          <Grid item xs={12}>
            <ProfileSelectionCard />
          </Grid>

          <Grid item xs={12} container justifyContent="space-between">
            <DeleteUserButton />
            <SubmitFormUserButton
              formState={formState}
              reset={reset}
              handleSubmit={handleSubmit}
            />
          </Grid>
        </AccessManagementProvider>
      </Grid>
    </UserActionsStateProvider>
  );
}
