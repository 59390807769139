import { Stack, Typography } from '@mui/material';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import RegistrationImage from '@assets/images/registration.svg';
import { CompanyStatusEnum } from '@constants';
import { useAuth } from '@hooks';
import { UserType } from '@contexts';

import { useHomeState } from './hooks';
import { HomeItemsList } from './components';

export function HomeLayout() {
  const { companySummaryDetailsState, handleRefreshCompanyStates } =
    useHomeState();
  const { user, selectedCompany } = useAuth();

  const activeCompany = selectedCompany?.status === CompanyStatusEnum.ACTIVE;
  const backofficeUser = user?.companyType === UserType.Backoffice;

  const hasError = companySummaryDetailsState?.isError;

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={handleRefreshCompanyStates} />;
  }

  if (companySummaryDetailsState?.isFetching) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (!activeCompany && !backofficeUser) {
    return (
      <Stack alignItems="center" gap={3} padding={8}>
        <img alt="Cadastro em andamento" src={RegistrationImage} height={112} />
        <Typography variant="h6" textAlign="center" maxWidth={472}>
          Estamos finalizando a configuração da sua conta, em breve, tudo estará
          pronto para você.
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={3} paddingTop={3} paddingBottom={8}>
      <HomeItemsList />
    </Stack>
  );
}
