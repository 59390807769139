import React from 'react';

import IDragAndDropFile from '@hapvida/hapvida-core-components/src/components/DragAndDrop/dtos/IDragDropFile';

import { MovimentationFileLayout } from './layout';

type MovimentationFileProps = {
  movimentationFile?: IDragAndDropFile;
};

const MovimentationFileStep: React.FC<MovimentationFileProps> = ({
  movimentationFile,
}) => {
  return <MovimentationFileLayout movimentationFile={movimentationFile} />;
};

export default MovimentationFileStep;
