import React from 'react';
import { Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { IntegrationStatusEnum } from '@services/MovimentationService/dtos/MovimentationDetailDTO';

export const INTEGRATION_STATUS_ERROR: {
  [x: string]: {
    label: React.ReactElement;
  };
} = {
  [IntegrationStatusEnum.Error]: {
    label: (
      <Typography color={COLORS.MONOCHROMATIC.GRAY4} variant="text">
        A movimentação foi processada,{' '}
        <b>porém nem todos os beneficiários foram efetivados.</b> Fique a
        vontade para ver o resultado da sua movimentação.
      </Typography>
    ),
  },
  [IntegrationStatusEnum.ErrorHapvida]: {
    label: (
      <Typography color={COLORS.MONOCHROMATIC.GRAY4} variant="text">
        A movimentação foi processada,{' '}
        <b>
          porém não conseguimos processar os beneficiários da operadora Hapvida.
        </b>{' '}
        Fique a vontade para ver o resultado da sua movimentação.
      </Typography>
    ),
  },
  [IntegrationStatusEnum.ErrorNdi]: {
    label: (
      <Typography color={COLORS.MONOCHROMATIC.GRAY4} variant="text">
        A movimentação foi processada,{' '}
        <b>
          porém não conseguimos processar os beneficiários da operadora NDI.
        </b>{' '}
        Fique a vontade para ver o resultado da sua movimentação.
      </Typography>
    ),
  },
};
