import { StartMovimentationLayout } from './layout';

type StartMovimentationProps = {
  onStepUpdate(step: number, data: any): void;
};

export function StartMovimentationStepPage({
  onStepUpdate,
}: StartMovimentationProps) {
  return <StartMovimentationLayout onStepUpdate={onStepUpdate} />;
}
