import InfiniteScroll from 'react-infinite-scroll-component';

import { FilterContentContainer } from './styles';
import { FilterList } from '../FilterList';
import { useFilter } from '../../hooks';

interface FilterContentProps {
  infiniteScroll?: boolean;
}

export function FilterContent({
  infiniteScroll,
}: Readonly<FilterContentProps>) {
  const { filters, handleNextPage, hasMoreToLoad } = useFilter();

  return (
    <FilterContentContainer>
      {infiniteScroll ? (
        <InfiniteScroll
          dataLength={filters.length}
          hasMore={hasMoreToLoad}
          next={handleNextPage}
          loader={<></>}
          height={265}
        >
          <FilterList />
        </InfiniteScroll>
      ) : (
        <FilterList />
      )}
    </FilterContentContainer>
  );
}
