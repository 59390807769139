import { SessionExpiredRouter } from '@flows/others/errors/pages/SessionExpired/router';
import { SalesChannelRouter } from '@flows/others/selection/pages/SalesChannel/router';
import { ServerErrorRouter } from '@flows/others/errors/pages/ServerError/router';
import { LinkExpiredRouter } from '@flows/others/errors/pages/LinkExpired/router';
import { IAppRoute } from 'routes/types';

export const otherRoutes: IAppRoute[] = [
  SessionExpiredRouter,
  SalesChannelRouter,
  ServerErrorRouter,
  LinkExpiredRouter,
];
