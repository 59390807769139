import { FilterKeyEnum } from '@hapvida/hapvida-core-components';

import { HapvidaApi } from '../../http/Hapvida';
import type {
  BillDetails,
  BillDetailsRequestDTO,
  BillDetailsResponseDTO,
  DiscountLetterRequestDTO,
  DiscountLetterResponseDTO,
  GetReportMonthlyRequestDTO,
  ReportMonthlyProps,
} from './dtos';
import type {
  BillListProps,
  GetBillListRequestDTO,
  GetBillListResponseDTO,
} from './dtos/BillsListDTO';
import type {
  BillingRequestDTO,
  BillingResponseDTO,
} from './dtos/GetBillingDTO';
import type {
  BillsSummaryRequestDTO,
  BillsSummaryResponseDTO,
  BillSummariesDTO,
} from './dtos/GetBillsSummaryDTO';
import type {
  GetCoPaymentReportRequestDTO,
  GetCoPaymentReportResponseDTO,
} from './dtos/GetCoPaymentReport';
import type {
  InvoiceRequestDTO,
  InvoiceResponseDTO,
} from './dtos/GetInvoiceDTO';
import type {
  GetNDIFileRequestDTO,
  GetNDIFileResponseDTO,
} from './dtos/GetNDIBillFileDTO';

export * from './dtos';

export default class FinancialService {
  private endpoints = {
    summary: (companyId: string) =>
      `/api/v1/billings/${companyId}/bills/summary`,
    list: (companyId: string) => `/api/v1/billings/${companyId}/bills/search`,
    bill: (billNumber: string) => `/api/v1/billings/bill/${billNumber}`,
    billing: (companyId: string) => `/api/v1/url/billing/${companyId}`,
    invoice: (companyId: string) => `/api/v1/url/invoice/${companyId}`,
    reportMonthly: ({ billingNumber, fileType }: GetReportMonthlyRequestDTO) =>
      `/api/v1/billings/report-monthly/billing-number/${billingNumber}/report-type/${fileType}`,
    discountLetter: ({ companyId, invoiceNumber }: DiscountLetterRequestDTO) =>
      `/api/v1/billings/companies/${companyId}/invoice-number/${invoiceNumber}/discount-letters`,
  };

  public getDiscountLetters = async (props: DiscountLetterRequestDTO) => {
    const { data } = await HapvidaApi.get<DiscountLetterResponseDTO>(
      this.endpoints.discountLetter(props),
    );

    return data.content;
  };

  public getBillsSummary = async ({
    companyId,
    ...body
  }: BillsSummaryRequestDTO): Promise<BillSummariesDTO[]> => {
    const { data } = await HapvidaApi.post<BillsSummaryResponseDTO>(
      this.endpoints.summary(companyId),
      {
        ...body,
        'organization-groups':
          body[FilterKeyEnum.ORGANIZATIONAL_GROUP]?.split(','),
        'health-operator-types': body['health-operator-types']?.split(','),
      },
    );

    return data.content;
  };

  public getBillsList = async ({
    companyId,
    ...body
  }: GetBillListRequestDTO): Promise<BillListProps> => {
    const { data } = await HapvidaApi.post<GetBillListResponseDTO>(
      this.endpoints.list(companyId),
      {
        ...body,
        'organization-groups':
          body[FilterKeyEnum.ORGANIZATIONAL_GROUP]?.split(','),
        'health-operator-types': body['health-operator-types']?.split(','),
      },
    );

    return data.content;
  };

  public getBill = async ({
    billNumber,
    companyId,
    year,
  }: BillDetailsRequestDTO): Promise<BillDetails> => {
    const { data } = await HapvidaApi.get<BillDetailsResponseDTO>(
      this.endpoints.bill(billNumber),
      {
        params: {
          'company-id': companyId,
          year,
        },
      },
    );

    return data.content;
  };

  public getBilling = async ({
    billingNumber,
    companyId,
    personNumber,
  }: BillingRequestDTO): Promise<string> => {
    const { data } = await HapvidaApi.get<BillingResponseDTO>(
      this.endpoints.billing(companyId),
      {
        params: {
          'billing-number': billingNumber,
          'person-number': personNumber,
        },
      },
    );

    return data.content;
  };

  public getInvoice = async ({
    billingNumber,
    companyId,
  }: InvoiceRequestDTO): Promise<string> => {
    const { data } = await HapvidaApi.get<InvoiceResponseDTO>(
      this.endpoints.invoice(companyId),
      {
        params: {
          'billing-number': billingNumber,
        },
      },
    );

    return data.content;
  };

  public getNdiFile = async ({ companyId, fileName }: GetNDIFileRequestDTO) => {
    const { data } = await HapvidaApi.get<GetNDIFileResponseDTO>(
      `/api/v1/companies/${companyId}/file/${fileName}`,
    );

    return data.content;
  };

  public reportMonthly = async ({
    billingNumber,
    fileType,
  }: GetReportMonthlyRequestDTO): Promise<ReportMonthlyProps> => {
    const { data } = await HapvidaApi.get<ReportMonthlyProps>(
      this.endpoints.reportMonthly({ billingNumber, fileType }),
    );

    return data;
  };

  public getCoPaymentReport = async ({
    personNumber,
    billingExtractId,
    fileName,
  }: GetCoPaymentReportRequestDTO) => {
    const { data } = await HapvidaApi.get<GetCoPaymentReportResponseDTO>(
      `/api/v1/billings/coparticipation`,
      {
        params: {
          'person-number': personNumber,
          'billing-extract-id': billingExtractId,
          'file-name': fileName,
        },
      },
    );
    return data.content;
  };
}
