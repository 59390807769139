import { Typography } from '@mui/material';

import { ModalNew } from '@hapvida/hapvida-core-components';

interface ProfileResourcesModalProps {
  open: boolean;
  onContinue: () => void;
  onClose: () => void;
}

export function MasterAlertModal({
  open,
  onContinue,
  onClose,
}: ProfileResourcesModalProps) {
  return (
    <>
      <ModalNew.Container open={open} handleCloseModal={onClose}>
        <ModalNew.Content
          subtitle="Selecionar o perfil Master terá alguns impactos"
          icon="alert"
        >
          <Typography variant="smallText">
            Selecionar o perfil Master desabilita os demais perfis, pois ele tem
            acesso padrão a todos os contratos disponíveis. Deseja continuar com
            essa seleção?
          </Typography>
        </ModalNew.Content>
        <ModalNew.Footer
          secondaryButtonProps={{ children: 'Cancelar', onClick: onClose }}
          mainButtonProps={{ children: 'Continuar', onClick: onContinue }}
        />
      </ModalNew.Container>
    </>
  );
}
