import { HapvidaApi } from '@http';

export default class FileService {
  private readonly endpoints = {
    getFile: (requestSufix: string, id: string) =>
      `/api/v1/${requestSufix}/file/${id}`,
    getExport: (id: string) => `/api/v1/quotations/${id}/export`,
  };

  public async getFileUrl(requestSufix: string, id: string): Promise<string> {
    const { data } = await HapvidaApi.get(
      this.endpoints.getFile(requestSufix, id),
    );

    return data.content;
  }

  public async getQuotationLivesExport(id: string): Promise<any> {
    const { data } = await HapvidaApi.post(this.endpoints.getExport(id));

    return data;
  }
}
