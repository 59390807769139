import {
  ButtonBase,
  ButtonBaseProps,
  styled as muiStyled,
} from '@mui/material';
import { COLORS } from '../../../../../themes/colors';

interface RouteItemInterface {
  backofficeVision?: boolean;
  open: boolean;
}

export const MenuButton = muiStyled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'backofficeVision',
})<ButtonBaseProps & RouteItemInterface>(
  ({ open, disabled, backofficeVision }) => {
    return {
      padding: 16,

      borderRadius: '8px',

      justifyContent: open ? 'flex-start' : 'center',
      flexDirection: open ? 'row' : 'column',
      alignItems: 'center',
      gap: '8px',

      svg: {
        color: COLORS.MONOCHROMATIC.GRAY4,
        minHeight: open ? 0 : 32,
      },

      '&.Mui-disabled': {
        pointerEvents: 'auto',
        cursor: 'not-allowed',
      },

      ':hover': {
        background: disabled ? 'transparent' : 'rgba(237, 171, 56, 0.08)',
      },

      ...(disabled && {
        opacity: 0.5,
      }),

      ...(backofficeVision && {
        '.MuiTypography-root': {
          color: COLORS.WHITE,
        },

        svg: {
          minHeight: open ? 0 : 32,
          color: disabled
            ? COLORS.MONOCHROMATIC.GRAY2
            : COLORS.PRIMARY.ORANGE.MAIN,
        },
      }),
    };
  },
);
