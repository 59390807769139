export const REGISTER_STEPS_KEYS = {
  COMPANY_INFO: 'informacoes-da-empresa',
  COMPANY_MANAGER: 'responsavel-da-empresa',
  EMAIL_CONFIRMATION: 'confirmacao-de-cadastro',
};

export const REGISTER_STEPS_VALUES = {
  [REGISTER_STEPS_KEYS.COMPANY_INFO]: 0,
  [REGISTER_STEPS_KEYS.COMPANY_MANAGER]: 1,
  [REGISTER_STEPS_KEYS.EMAIL_CONFIRMATION]: 2,
};

export const REGISTER_STEPS_ORDER = [
  REGISTER_STEPS_KEYS.COMPANY_INFO,
  REGISTER_STEPS_KEYS.COMPANY_MANAGER,
  REGISTER_STEPS_KEYS.EMAIL_CONFIRMATION,
];
