import { IAppRoute } from 'routes/types';

import { LetterOfStayPage } from './index';

export const LetterOfStayRouter: IAppRoute = {
  Page: LetterOfStayPage,
  path: '/carta-de-permanencia',
  name: 'Carta de permanência',
  isPrivate: true,
};
