import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { CompanyStatusEnum } from '@constants';
import { PartnerCompanyListRouter } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/router';

import { CompanySetupDetailsLayout } from './layout';
import { useCompanySetupDetailsState } from './hooks';

export function CompanySetupDetailsPage() {
  const { companySetupDetailsState } = useCompanySetupDetailsState();

  const companySetupDetailsData = companySetupDetailsState.data?.content;

  const navigate = useNavigate();

  useEffect(() => {
    const status = companySetupDetailsData?.status;

    const validStatusList = [
      CompanyStatusEnum.ACTIVE,
      CompanyStatusEnum.CANCELED,
      CompanyStatusEnum.PENDING_ACCESS,
    ];

    const statusRedirect = status && validStatusList.includes(status);

    if (statusRedirect) {
      navigate(PartnerCompanyListRouter.path);
    }
  }, [companySetupDetailsData?.status]);

  return <CompanySetupDetailsLayout />;
}
