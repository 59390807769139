import { SearchParamEnum } from '@hapvida/hapvida-core-components';

import { UserCompanyTypeEnum, UserStatusEnum } from '@constants';

import { ProfileProps } from './index';

enum GetAccountVersionEnum {
  Company = 'v2',
  Backoffice = 'v1',
}

interface Account {
  companyType: UserCompanyTypeEnum;
  document: string;
  email: string;
  id: string;
  name: string;
  status: UserStatusEnum;
  profile: ProfileProps;
}

interface UserListProps {
  users: Account[];
  total: number;
  totalPerPage: number;
}

interface UsersResponseDTO {
  content: UserListProps;
}

interface GetAccountsRequestDTO {
  companyId?: string;
  offset?: number;
  limit: number;
  [SearchParamEnum.ORDER_BY]?: string;
  [SearchParamEnum.SORT_BY]?: string;
}

interface GetCompanyAccountsRequestDTO extends GetAccountsRequestDTO {
  partnerCompany?: string;
}

type GetBackofficeAccountsRequestDTO = GetAccountsRequestDTO;

export {
  UsersResponseDTO,
  UserListProps,
  Account,
  GetAccountVersionEnum,
  GetCompanyAccountsRequestDTO,
  GetBackofficeAccountsRequestDTO,
};
