import { Filter } from '../../../hooks/useDrawerFilter';
import { isFilterSelected } from './is-filter-selected';

interface ChangeFiltersProps {
  currentFilters: Filter[];
  filterToChange: Filter;
  isMultiple?: boolean;
}

function removeFilter(filters: Filter[], filterToRemove: Filter): Filter[] {
  return filters.filter(f => f.value !== filterToRemove.value);
}

export function changeFilters({
  currentFilters,
  filterToChange,
  isMultiple,
}: ChangeFiltersProps) {
  const filterIsSelected = isFilterSelected(currentFilters, filterToChange);

  if (!isMultiple) {
    return filterIsSelected ? [] : [filterToChange];
  }

  return filterIsSelected
    ? removeFilter(currentFilters, filterToChange)
    : [...currentFilters, filterToChange];
}
