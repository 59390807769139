import { styled, Stack, Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const TooltipWrapper = styled(Stack)(() => ({
  backgroundColor: `${COLORS.MONOCHROMATIC.GRAY1}`,
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '16px',
  gap: '13px',
}));

export const CheckAllCompanyTooltip = styled(Typography)(() => ({
  color: `${COLORS.BLACK}`,
  fontSize: '14px',
  fontWeight: 400,
}));
