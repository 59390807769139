import { FieldError } from 'react-hook-form';

interface InputErrorRenderProps {
  passwordContent: string;
  passwordContentConfirmation: string;
  errors: {
    password?: FieldError;
    passwordConfirmation?: FieldError;
  };
}

const usePasswordValidateRender = ({
  passwordContent,
  passwordContentConfirmation,
  errors,
}: InputErrorRenderProps) => {
  const errorPassword =
    passwordContent?.length > 0 && Boolean(errors?.password?.message);

  const errorPasswordMsg = errorPassword ? errors?.password?.message : '';

  const errorPasswordConfirmation =
    passwordContentConfirmation?.length > 0 &&
    Boolean(errors?.passwordConfirmation?.message);

  const errorPasswordConfirmationMsg = errorPasswordConfirmation
    ? errors?.passwordConfirmation?.message
    : '';

  const handleMessageSuccess = () => {
    if (passwordContent?.length === 0) return false;
    if (passwordContentConfirmation?.length === 0 && !errorPassword) {
      return true;
    }
    return false;
  };

  const hasIconSuccessInput = passwordContent.length > 0 && !errorPasswordMsg;

  const hasConfirmedInputSuccess =
    passwordContentConfirmation.length > 0 &&
    passwordContentConfirmation === passwordContent;

  const hasIconSuccessConfirmInput =
    hasConfirmedInputSuccess && hasIconSuccessInput;

  return {
    errorPassword,
    errorPasswordMsg,
    errorPasswordConfirmation,
    errorPasswordConfirmationMsg,
    handleMessageSuccess,
    hasIconSuccessInput,
    hasIconSuccessConfirmInput,
  };
};

export default usePasswordValidateRender;
