import { Button, ButtonProps } from '@mui/material';
import { Footer } from './styles';
import { CircularProgress } from '@hapvida/hapvida-core-components';

interface ModalFooterProps {
  loading?: boolean;
  mainButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
}

export function ModalFooter({
  loading,
  mainButtonProps,
  secondaryButtonProps,
}: Readonly<ModalFooterProps>) {
  return (
    <Footer disableSpacing>
      {secondaryButtonProps?.children && secondaryButtonProps.onClick && (
        <Button
          variant="outlined"
          size="small"
          {...secondaryButtonProps}
          disabled={secondaryButtonProps.disabled || loading}
        >
          {secondaryButtonProps.children}
        </Button>
      )}
      {mainButtonProps?.children && mainButtonProps.onClick && (
        <Button
          variant="contained"
          endIcon={loading ? <CircularProgress size={16} /> : undefined}
          size="small"
          {...mainButtonProps}
          disabled={mainButtonProps.disabled || loading}
        >
          {mainButtonProps.children}
        </Button>
      )}
    </Footer>
  );
}
