import { Button, Stack, Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { PartnerCompanyProps } from '@services/PartnerCompanyService/dtos';

import { PartnerCompanyTypeChip, ProfileDetailsButton } from '@components';
import { ListGridRowProps } from 'components/ListGrid/types';

import { PartnerCompanyListColumnNameEnum } from './index';
import { useSelectPartnerCompany } from '../hooks';

export function getPartnerCompanyListRows(
  partnerCompanyList: PartnerCompanyProps[],
): ListGridRowProps[] {
  const { handleSelectPartnerCompany } = useSelectPartnerCompany();

  return partnerCompanyList.map(partnerCompanyData => {
    const {
      isMainPartnerCompany,
      mainPartnerCompany,
      partnerCompany,
      profile,
    } = partnerCompanyData;

    const mainPartnerCompanyText = isMainPartnerCompany
      ? '-'
      : mainPartnerCompany;

    return {
      id: partnerCompanyData.partnerCompany,
      items: {
        [PartnerCompanyListColumnNameEnum.PARTNER_COMPANY]: (
          <Stack alignItems="center" flexDirection="row" gap={1}>
            <Typography fontWeight={700}>{partnerCompany}</Typography>
            <PartnerCompanyTypeChip
              isMainPartnerCompany={isMainPartnerCompany}
            />
          </Stack>
        ),
        [PartnerCompanyListColumnNameEnum.MAIN_PARTNER_COMPANY]: (
          <Typography fontWeight={700}>{mainPartnerCompanyText}</Typography>
        ),
        [PartnerCompanyListColumnNameEnum.PROFILE]: (
          <ProfileDetailsButton
            profileId={profile?.id ?? ''}
            profileName={profile?.name ?? ''}
          />
        ),
        [PartnerCompanyListColumnNameEnum.ACCESS_ACTION]: (
          <Stack alignSelf="flex-end">
            <Button
              variant="outlined"
              endIcon={<Icon name="right" size={20} />}
              onClick={() =>
                handleSelectPartnerCompany({
                  ...partnerCompanyData,
                  partnerCompany: partnerCompanyData.partnerCompany,
                })
              }
            >
              Acessar
            </Button>
          </Stack>
        ),
      },
    };
  });
}
