import { Step, Stepper, StepProps, styled as muiStyled } from '@mui/material';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const StyledStepper = muiStyled(Stepper)(() => ({
  width: '100%',
}));

interface StyledStepProps {
  selected: boolean;
  lastStep: boolean;
}

export const StyledStep = muiStyled(
  ({ lastStep, ...props }: StyledStepProps & StepProps) => <Step {...props} />,
)<StyledStepProps>(({ selected, completed, active, lastStep }) => ({
  maxWidth: '380px',
  width: '100%',
  padding: 16,
  borderRadius: 8,
  marginBottom: 8,

  ...(lastStep && {
    '.MuiSvgIcon-root': {
      fill: `${COLORS.PRIMARY.ORANGE.MAIN} !important`,
      border: `1px solid ${COLORS.PRIMARY.ORANGE.MAIN}`,
      borderRadius: '50%',
    },
  }),

  ...(active && {
    cursor: 'pointer',
  }),

  '.MuiSvgIcon-root': {
    transition: 'all 0s !important',

    color: `${selected ? COLORS.PRIMARY.ORANGE.MAIN : COLORS.WHITE} !important`,
    text: {
      fill: selected ? COLORS.WHITE : COLORS.MONOCHROMATIC.GRAY4,
    },
    ...(!selected &&
      !completed && {
        border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,
        borderRadius: '50%',
        text: {
          fill: COLORS.MONOCHROMATIC.GRAY4,
        },
      }),
  },

  ...(completed && {
    '& .Mui-completed': {
      color: `${COLORS.PRIMARY.GREEN['+1']} !important`,
    },
    '.MuiStepLabel-label': {
      color: `${COLORS.MONOCHROMATIC.GRAY4} !important`,
      fontWeight: 'regular !important',
      fontSize: '14px !important',
    },
  }),
  ...(selected && {
    background: 'rgba(237, 171, 56, 0.08)',
    '.Mui-completed': {
      color: `${COLORS.PRIMARY.ORANGE.MAIN} !important`,
    },
    '.MuiStepLabel-label': {
      color: `${COLORS.MONOCHROMATIC.GRAY4} !important`,
      fontWeight: 'bold !important',
      fontSize: '14px !important',
    },
  }),
}));
