import { Icon, Typography } from '@hapvida/hapvida-core-components';

import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';

import { MovimentationTypesEnum } from '@services/MovimentationService/dtos/ListMovimentationsDTO';

import { IconDiv } from './styles';

interface MovimentTypeProps {
  movimentType?: MovimentationTypesEnum;
}

const MovimentTypeValues: Record<
  MovimentationTypesEnum,
  {
    icon: IconName;
    value: string;
  }
> = {
  MovementThroughLayout: {
    icon: 'file-excel',
    value: 'Movimentação via layout',
  },
  IndividualMovement: {
    icon: 'file-excel',
    value: 'Movimentação via layout',
  },
  SecondCopyOfCard: {
    icon: 'file-excel',
    value: 'Movimentação via layout',
  },
};

function MovimentType({ movimentType }: MovimentTypeProps) {
  return (
    <IconDiv>
      {movimentType ? (
        <>
          <Icon
            size={20}
            name={MovimentTypeValues[movimentType].icon}
            style={{ minWidth: 20 }}
          />
          <Typography>{MovimentTypeValues[movimentType].value}</Typography>
        </>
      ) : (
        <Typography>-</Typography>
      )}
    </IconDiv>
  );
}

export default MovimentType;
