export const MOVIMENTATION_STEPS_KEYS = {
  NEW_MOVIMENTATION: 'nova',
  MOVIMENTATION_FILE: 'arquivo',
  CONFIRMATION: 'confirmacao',
};

export const MOVIMENTATION_STEPS_VALUES = {
  [MOVIMENTATION_STEPS_KEYS.NEW_MOVIMENTATION]: 0,
  [MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE]: 1,
  [MOVIMENTATION_STEPS_KEYS.CONFIRMATION]: 2,
};

export const MOVIMENTATION_STEPS_ORDER = [
  MOVIMENTATION_STEPS_KEYS.NEW_MOVIMENTATION,
  MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE,
  MOVIMENTATION_STEPS_KEYS.CONFIRMATION,
];
