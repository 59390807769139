import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import {
  IconName,
  COLORS,
  Button,
  Icon,
} from '@hapvida/hapvida-core-components';

import { Pendency } from '@services/CompanyService/dtos/PendenciesDTO';

import { BeneficiaryListRouter } from '@flows/private/beneficiaries/pages/BeneficiaryList/router';
import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import { BillListRouter } from '@flows/private/financial/pages/List/router';
import { MovementStatusEnum } from '@constants';

import { Wrapper, IconContainer, Text } from './styles';

export enum PendenciesEnum {
  BENEFICIARIES = 'BeneficiaryReviewPendency',
  MOVEMENTS = 'MovementPendency',
  FINANCIAL = 'BillingPendency',
}

export const PendenciesValues: {
  [x: string]: {
    color: string;
    iconName: IconName;
    label: { singular: string; plural: string };
  };
} = {
  [PendenciesEnum.BENEFICIARIES]: {
    color: COLORS.SECONDARY.RED['+1'],
    label: { singular: 'beneficiário', plural: 'beneficiários' },
    iconName: 'clipboard-pulse',
  },
  [PendenciesEnum.FINANCIAL]: {
    color: COLORS.SECONDARY.RED['+1'],
    label: { singular: 'financeira', plural: 'financeiras' },
    iconName: 'money',
  },
  [PendenciesEnum.MOVEMENTS]: {
    color: COLORS.SECONDARY.RED['+1'],
    label: { singular: 'movimentação', plural: 'movimentações' },
    iconName: 'clipboard-text-outline',
  },
};

interface PendenciesProps {
  pendencies: Pendency[];
}

export function Pendencies({ pendencies }: PendenciesProps) {
  const navigate = useNavigate();
  const order = Object.values(PendenciesEnum) as string[];

  const PendenciesRoutes: {
    [x: string]: string;
  } = {
    [PendenciesEnum.BENEFICIARIES]: `${BeneficiaryListRouter.path}?review=true`,
    [PendenciesEnum.MOVEMENTS]: `${MovementListRouter.path}?status=${MovementStatusEnum.UNABLE_TO_START}`,
    [PendenciesEnum.FINANCIAL]: `${BillListRouter.path}`,
  };
  const pendenciesSorted = [...pendencies];
  pendenciesSorted.sort(
    (a, b) => order.indexOf(a.pendencyType) - order.indexOf(b.pendencyType),
  );

  return (
    <Stack direction="column" spacing={2}>
      {pendenciesSorted
        .filter(({ count }) => count > 0)
        .map(pendency => {
          const value = PendenciesValues[pendency.pendencyType];

          return (
            <Wrapper
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              key={`${pendency.pendencyType}-${pendency.count}`}
            >
              <Stack direction="row" gap={2} alignItems="center">
                <IconContainer>
                  <Icon name={value.iconName} color={value.color} size={24} />
                </IconContainer>
                <Text>
                  {pendency.count}{' '}
                  {pendency.count > 1 ? 'pendências em' : 'pendência'}{' '}
                  <strong>
                    {pendency.count > 1
                      ? value.label.plural
                      : value.label.singular}
                  </strong>
                </Text>
              </Stack>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(PendenciesRoutes[pendency.pendencyType]);
                }}
              >
                Acessar pendências
              </Button>
            </Wrapper>
          );
        })}
    </Stack>
  );
}
