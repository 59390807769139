import {
  Box,
  Divider,
  Stack,
  StackProps,
  styled as muiStyled,
} from '@mui/material';

import Button from '../Button';
import Icon from '../Icon';
import Typography from '../Typography';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

interface ContainerProps {
  ithaserror?: string;
  containerstyles?: Object;
}

interface DropAreaProps extends StackProps {
  errorFile?: boolean;
  innerRef?: any;
  dropAreaStyles?: Object;
}
interface DescriptionProps {
  columInfo?: boolean;
}

export const Container = muiStyled(Stack)<ContainerProps>(
  ({ ithaserror, containerstyles }) => ({
    ...containerstyles,
  }),
);

export const InnerContainer = muiStyled(Stack)(() => ({
  width: '100%',
  position: 'relative',
}));

export const SubmittingContent = muiStyled(Box)(() => ({
  width: '100%',
  height: '100%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  left: '0',
  bottom: '0',
  position: 'absolute',

  zIndex: 10,

  background: 'rgba(255,255,255,0.9)',
}));

export const SubmittingText = muiStyled(Typography)(() => ({
  marginTop: '20px',

  fontWeight: 900,
  fontSize: '16px',
  lineHeight: '20px',
  textAlign: 'center',
}));

export const Heading = muiStyled(Typography)(() => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '900',
  color: COLORS.MONOCHROMATIC.GRAY4,
}));

export const Content = muiStyled(Box)(() => ({
  marginTop: '16px',
}));

export const Title = muiStyled(Typography)(
  ({ theme: { breakpoints, spacing } }) => ({
    marginBottom: 8,
    [breakpoints.down('sm')]: {
      '&::after': {
        content: '":"',
        marginRight: spacing(1),
      },
    },
  }),
);

export const Description = muiStyled(
  ({ columInfo, ...props }: DescriptionProps) => <Typography {...props} />,
)<DescriptionProps>(({ columInfo, theme: { breakpoints } }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  color: COLORS.MONOCHROMATIC.GRAY4,
  [breakpoints.up('sm')]: {
    ':after': {
      content: '""',
      display: columInfo ? 'inline-flex' : 'none',
      width: '8px',
      height: '8px',
      background: COLORS.PRIMARY.BLUE.MAIN,
      borderRadius: '2px',
      marginLeft: '4px',
    },
  },
}));

export const ButtonDownload = muiStyled(Button)(
  ({ theme: { breakpoints, spacing } }) => ({
    width: '100%',
    height: '56px',
    border: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
    color: COLORS.PRIMARY.BLUE.MAIN,
    background: COLORS.LIGHT.MAIN,
    fontSize: '14px',
    minWidth: '10rem',
    paddingTop: '20px',
    paddingBottom: '24px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    justifyContent: 'space-between',

    [breakpoints.down('sm')]: {
      marginTop: spacing(2),
    },
  }),
);

export const IconButton = muiStyled(Icon)(() => ({
  paddingLeft: '7px',
}));

export const DropArea = muiStyled(
  ({ errorFile, innerRef, ...props }: DropAreaProps) => <Stack {...props} />,
)<DropAreaProps>(({ errorFile, dropAreaStyles }) => ({
  ...dropAreaStyles,
  padding: 12,
  background: errorFile
    ? 'linear-gradient(0deg, rgba(201, 54, 0, 0.02), rgba(201, 54, 0, 0.02))'
    : COLORS.WHITE,
  border: errorFile
    ? `1px dashed ${COLORS.SECONDARY.RED.MAIN}`
    : `1px dashed ${COLORS.MONOCHROMATIC.GRAY4}`,
  borderRadius: '4px',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ArchiveArea = muiStyled(Stack)(() => ({
  width: '100%',
}));

export const SelectFileText = muiStyled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 700,
  color: COLORS.PRIMARY.ORANGE.MAIN,
}));

export const DragFileText = muiStyled(Typography)(
  ({ theme: { breakpoints } }) => ({
    fontSize: 12,
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      textAlign: 'unset',
    },
  }),
);

export const DividerArea = muiStyled(Divider)(() => ({
  width: '100%',
}));

export const ArchiveIcon = muiStyled(Icon)(() => ({
  color: COLORS.PRIMARY.BLUE.MAIN,
}));

export const ArchiveTitle = muiStyled(Typography)(() => ({
  color: COLORS.PRIMARY.BLUE.MAIN,
  fontSize: '16px',
  fontWeight: '900',
}));

export const ArchiveDescription = muiStyled(Typography)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  fontSize: '12px',
  fontWeight: '400',
}));

export const ActionArea = muiStyled(Stack)(() => ({}));

export const ActionButton = muiStyled(Stack)(() => ({
  width: '32px',
  height: '32px',
  borderRadius: '80px',
  border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ':hover': {
    cursor: 'pointer',
    background: 'rgba(25, 118, 210, 0.04)',
  },
}));

export const ActionIcon = muiStyled(Icon)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
}));

export const ErrorArea = muiStyled(Stack)(() => ({
  paddingTop: '7px',
  paddingLeft: '4px',
}));

export const ErrorIcon = muiStyled(Icon)(() => ({
  color: COLORS.SECONDARY.RED.MAIN,
}));

export const ErrorMessage = muiStyled(Typography)(() => ({
  color: COLORS.SECONDARY.RED.MAIN,
  fontSize: '14px',
}));

export const AnimationArea = muiStyled(Stack)(() => ({
  width: '72px',
  height: '72px',
  margin: '16px',
}));

export const PulsarAnimation = muiStyled(Stack)(() => ({
  backgroundColor: COLORS.PRIMARY.ORANGE['-2'],
  width: '70px',
  height: '70px',
  borderRadius: '50px',
  animation: 'pulsate 1s infinite ease',
  '@keyframes pulsate': {
    from: {
      opacity: 1,
      width: '35px',
      height: '35px',
    },
    to: {
      opacity: 0,
      width: '70px',
      height: '70px',
    },
  },

  ':after': {
    content: '""',
    display: 'block',
    backgroundColor: COLORS.PRIMARY.ORANGE['-1'],
    width: '35px',
    height: '35px',
    borderRadius: '50px',
  },
}));

export const InfoStack = muiStyled(Stack)(
  ({ theme: { breakpoints, spacing } }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      columnGap: spacing(8),
    },
  }),
);

export const InfoItem = muiStyled(Stack)(({ theme: { breakpoints } }) => ({
  minHeight: 60,
  flexDirection: 'row',
  [breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));

export const ProcessedFileErrorMessageArea = muiStyled(Stack)(() => ({}));

export const RightInfo = muiStyled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 400,
  color: COLORS.PRIMARY.BLUE['-2'],
}));
