import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const IconDiv = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.25rem',
  color: COLORS.PRIMARY.BLUE.MAIN,
  '& .MuiTypography-root': {
    color: COLORS.PRIMARY.BLUE.MAIN,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    margin: '2px 0 0 0.5rem',
  },
}));
