import { UserPartnerCompanyProps } from '@services/UserService';

import { getIsMainPartnerCompanyRemovedAsMaster } from './getIsMainPartnerCompanyRemovedAsMaster';
import { mapOriginalProfile } from './mapOriginalProfile';

export const getWithoutAccess = (
  editedPartnerCompanies: UserPartnerCompanyProps[],
  originalPartnerCompanies: UserPartnerCompanyProps[],
) => {
  return editedPartnerCompanies
    .filter(partnerCompany => {
      const isIdenticalInOriginalPartnerCompanies =
        originalPartnerCompanies.some(item => {
          const isTheSamePartnerCompany =
            item.partnerCompany === partnerCompany.partnerCompany;
          const isTheSameProfile =
            item.profile?.id === partnerCompany.profile?.id;
          const isTheSameAccess = item.hasAccess === partnerCompany.hasAccess;
          const hasProfile = Boolean(item.profile);

          return (
            (isTheSamePartnerCompany && isTheSameProfile && isTheSameAccess) ||
            (isTheSamePartnerCompany && !hasProfile && isTheSameAccess)
          );
        });

      if (isIdenticalInOriginalPartnerCompanies) return false;

      const { hasAccess, isMainPartnerCompany, mainPartnerCompany } =
        partnerCompany;

      const isMainPartnerCompanyRemovedAsMaster =
        getIsMainPartnerCompanyRemovedAsMaster(
          editedPartnerCompanies,
          originalPartnerCompanies,
          isMainPartnerCompany,
          mainPartnerCompany,
        );

      return !hasAccess && !isMainPartnerCompanyRemovedAsMaster;
    })
    .map(partnerCompany =>
      mapOriginalProfile(partnerCompany, originalPartnerCompanies),
    );
};
