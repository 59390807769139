import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { BeneficiaryTypeEnum } from '@constants';

export const BeneficiaryDetailStatus: {
  [key: string]: {
    color: string;
    label: string;
    iconName: IconName;
  };
} = {
  Active: {
    color: COLORS.SECONDARY.GREEN['+1'],
    label: 'Ativo',
    iconName: 'marked-circle-outline',
  },
  Registered: {
    color: COLORS.SECONDARY.GREEN['+1'],
    label: 'Registrado',
    iconName: 'marked-circle-outline',
  },
  Pending: {
    color: COLORS.PRIMARY.BLUE.MAIN,
    label: 'Pendente',
    iconName: 'progress-clock',
  },
  Suspended: {
    color: COLORS.SECONDARY.RED['+1'],
    label: 'Suspenso',
    iconName: 'progress-clock',
  },
  Canceled: {
    color: COLORS.MONOCHROMATIC.GRAY4,
    label: 'Cancelado',
    iconName: 'progress-alert',
  },
  None: {
    color: COLORS.MONOCHROMATIC.GRAY3,
    label: 'Nenhum',
    iconName: 'progress-alert',
  },
};

interface StatusDetail {
  code: number;
  description: string;
  type: string;
}

export enum GenderEnum {
  F = 'F',
  M = 'M',
}

interface Gender {
  type: GenderEnum;
  socialType: GenderEnum;
}

interface Name {
  full: string;
  social: string;
  mom: string;
  dad: string;
}
interface Document {
  identityCard: string;
  personalDocument: string;
  bornAliveDeclaration: string;
  identityCardIssuerCode: string;
  identityCardEmissionAt: string;
  nationalHealthCard: string;
  numberSocialIntegrationProgram: string;
}

interface Phone {
  number: string;
  directDialingFromDistance: string;
}

interface Contact {
  phone?: Phone;
  email: string;
  mobile?: Phone;
  allowsSendingEmail: boolean;
  allowsSendingSmS: boolean;
}

interface Address {
  zipCode: string;
  street: string;
  city: string;
  stateCode: string;
  district: string;
  number: string;
  complement: string;
}

interface Treatment {
  code: string;
  municipalityCode: string;
}

interface ProfessionalInformation {
  registrationNumber: string;
  sequentialRegistrationNumber: string;
  holder: { admissionAt: string; startAt: string };
  lotation: {
    startAt: string;
    code: string;
    name: string;
  };
  position: {
    typeCode: string;
    typeName: string;
    code: string;
    name: string;
  };
  costCenter: {
    code: string;
    name: string;
  };
  job: {
    typeCode: string;
    typeName: string;
    code: string;
    name: string;
    locationCode: string;
    locationName: string;
  };
}

interface HolderInactive {
  inactiveAt: string;
  contributionAt: string;
}

interface HolderBankData {
  code: string;
  agency: string;
  digitAgency: string;
  currentAccountNumber: string;
  digitCurrentAccountNumber: string;
}

interface BeneficiaryDetailsPlanHealth {
  coverage?: string;
  ansCode?: string;
  name?: string;
  code: string;
}

interface BeneficiaryDetailsCompanyName {
  legal: string;
  trade: string;
}

interface BeneficiaryDetailsContractDetail {
  companyName: BeneficiaryDetailsCompanyName;
  planHealth: BeneficiaryDetailsPlanHealth;
  companyDocument: string;
  companyAddress: Address;
  partnerCompany: string;
  companyStatus: string;
  unitDocument: string;
  companyType: string;
  unitCode: string;
  unitName: {
    trade: string;
  };
  companyGroupCode?: string;
  number?: string;
}

interface WaitingPeriod {
  code: string;
  startDate: string;
}

export interface BeneficiaryDetailsProps {
  createdAt: string;
  updatedAt: string;
  healthOperator: string;
  holderCode?: string;
  statusDetail: StatusDetail;
  type: BeneficiaryTypeEnum;
  code: string;
  expirationDay: string;
  birthDate: string;
  bornAliveDeclaration: string;
  dependencyCode: number;
  dependencyName: string;
  marriedAt: string;
  nationality: string;
  professionalInformation: ProfessionalInformation;
  treatment: Treatment;
  gender: Gender;
  name: Name;
  document: Document;
  contractDetail: BeneficiaryDetailsContractDetail;
  contact: Contact;
  address: Address;
  holderInactive: HolderInactive;
  holderBankData: HolderBankData;
  civilStatus: number;
  waitingPeriod: WaitingPeriod;
  startAt: string;
  includedAt: string;
  cancellationAt: string;
}

export interface BeneficiaryDetailsResponseDTO {
  content: BeneficiaryDetailsProps;
}
