import { Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { StyledBox, StyledIcon } from './styles';

interface StepIconProps {
  isSelected: boolean;
  isCompleted: boolean;
  stepNumber: number;
}

export function StepIcon({
  isSelected,
  isCompleted,
  stepNumber,
}: Readonly<StepIconProps>) {
  if (isCompleted) {
    return (
      <StyledBox>
        <Icon name="check" size={24} />
      </StyledBox>
    );
  }

  return (
    <StyledIcon active={isSelected}>
      <Typography>{stepNumber}</Typography>
    </StyledIcon>
  );
}
