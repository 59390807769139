import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  Form,
  Icon,
  Mask,
  TextInput,
  Typography,
} from '@hapvida/hapvida-core-components';

import {
  REGISTER_STEPS_KEYS,
  REGISTER_STEPS_VALUES,
} from '../../constants/keys';
import { COMPANY_REGISTER } from '../../../../../../constants/sessionStorageKeys';
import AuthService from '../../../../../../infra/services/AuthService';
import { useScreenLoading } from '../../../../../../hooks/useScreenLoading';
import { validateCPF } from '../../../../../../utils/validateCpf';
import { StyledForm } from './styles';
import { schema } from './validation';

interface CompanyManagerForms {
  name: string;
  document: string;
  phone: string;
  email: string;
  confirmEmail: string;
}

interface CompanyManagerProps {
  onStepUpdate(step: number, data: any): void;
  companyId: string;
}

const CompanyManagerLayout: React.FC<CompanyManagerProps> = ({
  onStepUpdate,
  companyId,
}) => {
  const [disableButton, setDisableButton] = useState(true);

  const errorRef = useRef(false);

  const { onScreenLoading } = useScreenLoading();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<CompanyManagerForms>({
    mode: 'all',
    defaultValues: {
      name: '',
      document: '',
      phone: '',
      email: '',
      confirmEmail: '',
    },
    resolver: yupResolver(schema),
  });

  const document = watch('document');

  const isValidCpf = useMemo(() => {
    if (document) {
      return validateCPF(document);
    }
    return false;
  }, [document]);

  useEffect(() => {
    if (isValid && isValidCpf) setDisableButton(false);
    else setDisableButton(true);
  }, [isValid]);

  const onSubmit: SubmitHandler<CompanyManagerForms> = async data => {
    try {
      onScreenLoading(true);
      const authService = new AuthService();

      const sessionStorageData = JSON.parse(
        sessionStorage.getItem(COMPANY_REGISTER) ?? '{}',
      );

      const step = {
        companyId: companyId || sessionStorageData.companyId,
        companyManager: data,
      };

      if (errorRef.current === false) {
        await authService.registerCompany(sessionStorageData);
      }
      await authService.createCompanyManager(step);

      onStepUpdate(
        REGISTER_STEPS_VALUES[REGISTER_STEPS_KEYS.COMPANY_MANAGER],
        step,
      );
    } catch (error) {
      errorRef.current = true;
    } finally {
      onScreenLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Form title="Responsável da empresa">
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Typography variant="text">
              Cadastre o usuário que irá administrar essa empresa
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="name"
              label="Nome completo"
              control={control}
              placeholder="Digite"
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="document"
              label="CPF"
              control={control}
              placeholder="Digite"
              mask={Mask.cpf}
              error={document.length !== 0 && !validateCPF(document)}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="phone"
              label="Telefone"
              control={control}
              placeholder="Digite"
              mask={Mask.phone}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="email"
              label="E-mail"
              control={control}
              placeholder="Digite"
              type="email"
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="confirmEmail"
              label="Confirmar e-mail"
              control={control}
              placeholder="Digite"
              type="email"
              required
              fullWidth
            />
          </Grid>

          <Grid item container justifyContent="flex-end">
            <Button
              disabled={disableButton}
              color="success"
              variant="contained"
              endIcon={<Icon name="chevron-right" size={16} />}
              type="submit"
            >
              Proxima etapa
            </Button>
          </Grid>
        </Grid>
      </Form>
    </StyledForm>
  );
};

export default CompanyManagerLayout;
