import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@hooks';
import { PartnerCompanyTypeChip } from '@components';
import { PartnerCompanyListRouter } from '@flows/private/partnerCompanies/pages/PartnerCompanyList/router';

import { HeaderItem } from '../HeaderItem';

export function SelectedPartnerCompany() {
  const { selectedPartnerCompany } = useAuth();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(PartnerCompanyListRouter.path);
  }, [navigate]);

  if (!selectedPartnerCompany) {
    return null;
  }

  const { partnerCompany, isMainPartnerCompany } = selectedPartnerCompany;

  return (
    <HeaderItem icon="file-document" onClickEdit={onClick} title="Contrato:">
      <Typography variant="text" fontWeight={900}>
        {partnerCompany}
      </Typography>
      <PartnerCompanyTypeChip isMainPartnerCompany={isMainPartnerCompany} />
    </HeaderItem>
  );
}
