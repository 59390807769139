const companiesListMock = {
  content: [
    {
      id: 1,
      name: 'Hapvida',
      avatar:
        'https://logodownload.org/wp-content/uploads/2019/12/hapvida-logo.png',
      document: '57430497000103',
    },
    {
      id: 2,
      name: 'Teste',
      avatar:
        'https://t.ctcdn.com.br/P7-_JvQTS4U7-if6zHyXjyMiNQ8=/400x400/smart/i606944.png',
      document: '27829064000156',
    },
    {
      id: 3,
      name: 'NDI',
      avatar: '',
      document: '29507337000171',
    },
  ],
};

export default companiesListMock;
