import { CSSObject, styled as muiStyled, Theme, Drawer } from '@mui/material';

const drawerWidth = 258;
const drawerClose = 92;

const openedMixin = (theme: Theme): CSSObject => ({
  padding: '40px 28px 20px 32px',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  padding: '40px 4px 20px 8px',
  width: drawerClose,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

export const DrawerContainer = muiStyled(Drawer)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer,
  whiteSpace: 'nowrap',
  overflow: 'hidden',

  ' .MuiDrawer-paper': {
    borderRightColor: theme.palette.grey[200],
    height: 'unset',
    bottom: 0,
  },

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      background: theme.palette.background.paper,
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      background: theme.palette.background.paper,
    },
  }),
}));
