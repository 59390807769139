/* eslint-disable no-param-reassign */
import { Alert, Stack, TableCell } from '@mui/material';

import {
  Icon,
  Mask,
  Pill,
  Typography,
  Company,
} from '@hapvida/hapvida-core-components';

import { MovementBeneficiaryProps } from '@services/MovimentationService/dtos/MovementLivesDTO';

import { MovementActionType } from '@components';
import {
  BENEFICIARY_TYPE_COLOR,
  BENEFICIARY_TYPE_NAME,
  MovementStatusEnum,
} from '@constants';

import {
  StyledActionCellCancel,
  TypographControl,
  StyledTableCell,
  StyledTableRow,
  ErrorTableRow,
  EditButton,
} from './styles';
import StatusMovementLife from '../StatusMovementLife';

interface MovementBeneficiaryRowProps {
  data: MovementBeneficiaryProps;
  cancelSolicitationMovement: (
    lifeSummaryData: MovementBeneficiaryProps,
  ) => void;
  movementStatus?: MovementStatusEnum;
  columnsIsCancelling?: number;
}

export const MovementBeneficiaryRow = ({
  data,
  cancelSolicitationMovement,
  movementStatus,
  columnsIsCancelling,
}: MovementBeneficiaryRowProps) => {
  const hasError = data.reviews.length > 0;

  const movimentationStatusAllowedCancel = [
    MovementStatusEnum.PROCESS_FILE,
    MovementStatusEnum.PROCESS_FILE_WITH_PENDENCY,
  ];

  const allowCancelling = data?.allowedCancellation === true;

  const hasCancelSolicitationMovement =
    movementStatus &&
    movimentationStatusAllowedCancel.includes(movementStatus) &&
    allowCancelling;

  return (
    <>
      <StyledTableRow
        error={`${hasError}`}
        statusProcessed={hasCancelSolicitationMovement}
      >
        <StyledTableCell>
          <StatusMovementLife statusType={data?.status} />
        </StyledTableCell>
        <StyledTableCell>
          <MovementActionType
            operationCode={data?.operationCode}
            movementType={data?.type}
          />
          <TypographControl fontSize={12}>
            Controle:<strong> {data?.user?.controlCode ?? '-'}</strong>
          </TypographControl>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Typography fontSize={14} fontWeight="bold">
              {data?.user?.code}
            </Typography>
            <Typography fontSize={12}>
              {Mask.cnpj(data?.contractDetail?.unitDocument)}
            </Typography>
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Typography fontSize={12} textTransform="uppercase">
              {data?.user?.gender === 'M' ? 'Masculino' : 'Feminino'}
            </Typography>
            <Typography fontSize={14} fontWeight="bold">
              {data?.user?.name}
            </Typography>
            {data?.user?.document?.length === 11 ? (
              <Typography fontSize={12}>
                {Mask.cpf(data?.user?.document)}
              </Typography>
            ) : (
              <Typography fontSize={12}>
                {Mask.cpfNDITenDigits(data?.user?.document)}
              </Typography>
            )}
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Pill
            color={BENEFICIARY_TYPE_COLOR[data.user.type]}
            text={BENEFICIARY_TYPE_NAME[data.user.type]}
            fitContent
          />
        </StyledTableCell>
        <StyledTableCell>
          <Company company={data.healthOperator} />
        </StyledTableCell>
        <StyledTableCell>
          <Typography fontSize={14} fontWeight="bold">
            {data?.contractDetail?.planHealth?.code}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography fontSize={14} fontWeight="600">
            {data?.contractDetail?.unitCode ?? '-'}
          </Typography>
        </StyledTableCell>
        <StyledActionCellCancel
          allowedCancel={hasCancelSolicitationMovement}
          error={`${hasError}`}
        >
          {hasCancelSolicitationMovement && (
            <EditButton onClick={() => cancelSolicitationMovement(data)}>
              <Icon name="hap-b2b-delete-outlined" size={20} />
            </EditButton>
          )}
        </StyledActionCellCancel>
      </StyledTableRow>
      {hasError && (
        <ErrorTableRow error="true">
          <TableCell colSpan={columnsIsCancelling}>
            <Alert severity="error">
              {data?.reviews.map((review, index) => (
                <Typography fontSize={14} key={`review-${String(index)}`}>
                  {review} <br />
                </Typography>
              ))}
            </Alert>
          </TableCell>
        </ErrorTableRow>
      )}
    </>
  );
};
