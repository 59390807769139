import { COLORS } from '../../themes/colors';

import { PulsarAnimation } from './styles';

interface PulsarAnimationProps {
  width?: string;
  height?: string;
  color?: string;
}

export const PulsarAnimationLoading = ({
  width = '10px',
  height = '10px',
  color = COLORS.PRIMARY.ORANGE.MAIN,
}: PulsarAnimationProps) => {
  return <PulsarAnimation width={width} height={height} color={color} />;
};
