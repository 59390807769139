import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { CompanyListPage } from '.';

export const CompanyListRouter: IAppRoute = {
  Page: CompanyListPage,
  path: '/dados-empresa',
  name: 'Empresas',
  isPrivate: true,
  Icon: <Icon name="home-work" />,
};
