import { Stack, Typography } from '@mui/material';

import {
  ErrorLoadingPage,
  NoSearchResults,
  FilterSummary,
  Loading,
} from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';

import {
  MovementsSummaryCard,
  MovementListCard,
  EmptyMovements,
} from './components';
import { useMovementsState } from './hooks';

export function MovementListLayout() {
  const { movementsState, movementsSummaryState, refreshMovementsStates } =
    useMovementsState();
  const { hasFilters } = useFilterParams();

  if (movementsState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (movementsState.isError || movementsSummaryState.isError) {
    return <ErrorLoadingPage onReloadClick={refreshMovementsStates} />;
  }

  const isEmpty = movementsState.data.movements.length === 0 && !hasFilters;
  const isSearchResultEmpty = movementsState.data.movements.length === 0;

  if (isEmpty) {
    return <EmptyMovements />;
  }

  const totalMovementsLength = movementsState.data.total ?? 0;

  const filterSummaryLabel = `${totalMovementsLength} ${
    totalMovementsLength === 1 ? 'movimentação' : 'movimentações'
  }`;

  return (
    <Stack paddingTop={3} gap={3}>
      <FilterSummary totalLabel={filterSummaryLabel} />

      {isSearchResultEmpty && <NoSearchResults padding={5} />}

      {!isSearchResultEmpty && (
        <Stack gap={3} paddingBottom={8}>
          <MovementsSummaryCard />

          <Typography variant="subtitle1">Lista de movimentações</Typography>

          <MovementListCard movementList={movementsState.data} />
        </Stack>
      )}
    </Stack>
  );
}
