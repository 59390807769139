import { Button, Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const MenuButton = styled(Button)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  fontWeight: 400,
  fontSize: 14,
  '& .MuiButton-startIcon': {
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
}));

export const MobileHiddenStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flex: 1,
  gap: 3,
  [theme.breakpoints.down(830)]: {
    display: 'none',
  },
}));
