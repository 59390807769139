import { styled as muiStyled } from '@mui/material';

export const LetterTable = muiStyled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  height: '266px',
  border: '1px solid black',
});

export const Th = muiStyled('th')({
  verticalAlign: 'baseline',
  padding: '8px 12px',
  border: '1px solid black',
  fontWeight: 400,
});

export const TdSubHeader = muiStyled('td')({
  height: '18px',
  textAlign: 'center',
  verticalAlign: 'middle',
  padding: '8px 12px',
  border: '1px solid',
  whiteSpace: 'nowrap',
});

export const Td = muiStyled('td')({
  fontSize: '12px',
  textAlign: 'center',
  padding: '8px 12px',
  border: '1px solid black',
  verticalAlign: 'baseline',
  whiteSpace: 'nowrap',
});

export const DiscriminationTd = muiStyled('td')({
  textAlign: 'left',
  padding: '16px',
  border: '1px solid black',
  verticalAlign: 'baseline',
});

export const Footer = muiStyled('div')({
  display: 'flex',
  position: 'relative',
  minHeight: '90px',
  alignItems: 'flex-end',
  gap: '64px',
  paddingBottom: '16px',
});

export const PrintImg = muiStyled('img')({
  width: '148px',
  position: 'absolute',
  top: '0',
});
