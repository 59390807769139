import { TextFieldProps } from '@mui/material';
import React from 'react';
import { IconName } from '../Icon/types';
import ControlledTextInput from './components/ControlledTextInput';
import UncontrolledTextInput from './components/UncontrolledTextInput';

export type Props = TextFieldProps & {
  control?: any;
  errorMessage?: string;
  mask?: Function;
  iconStart?: IconName;
  iconEnd?: IconName;
  errorPassword?: boolean;
  success?: boolean;
  successMessage?: string;
  onChangeElementInput?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

const TextInput: React.FC<Props> = props => {
  return props.control && props.name ? (
    <ControlledTextInput {...props} control={props.control} name={props.name} />
  ) : (
    <UncontrolledTextInput {...props} />
  );
};

export default TextInput;
