import { Chip, styled } from '@mui/material';

interface StyledChipProps {
  bgColor: string;
  textColor: string;
}
export const StyledChip = styled(Chip)<StyledChipProps>(
  ({ bgColor, textColor }) => ({
    backgroundColor: bgColor,
    color: textColor,
    fontWeight: 700,
  }),
);
