import { FilterKeyEnum, DrawerFilter } from '@hapvida/hapvida-core-components';

import { PartnerCompaniesTypeEnum } from '@constants';

import { mappedPartner } from './mappedPartnerCompanies';

export function TypeOfPartnerCompaniesDrawerFilter() {
  const partnerList = Object.values(PartnerCompaniesTypeEnum);
  const typeOfPartnerCompaniesFilters = partnerList.map(mappedPartner);

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.CONTRACT_TYPE}
      filters={typeOfPartnerCompaniesFilters}
      title="Tipo de contrato"
      label="tipos de contrato"
      disabledSelectAll
    />
  );
}
