import { IconName } from '../components/Icon/types';
import { COLORS } from '../themes/colors';
import { FEATURE_TOGGLE_CORE as feature } from '../utils/featureToggle';

export enum QuotationStatusEnum {
  InEdition = 'InEdition', //Em edição
  AwaitingPricing = 'AwaitingPricing', // Aguardando precificação
  InNegotiation = 'InNegotiation', // Em negociação
  // RevaluationRequest = 'RevaluationRequest', // Pedido de reavaliação
  AvailableOffer = 'AvailableOffer', // Proposta disponível
  ProposalInApproval = 'ProposalInApproval', // Proposta em aprovação
  AwaitingContract = 'AwaitingContract', // Aguardando contrato V1
  ContractAvailable = 'ContractAvailable', // Contrato disponível V1
  ContractUnderApproval = 'ContractUnderApproval', // Contrato em aprovação V1
  PendingLoadLife = 'PendingLoadLife', // Carga de vida pendente
  // LoadLifeInApproval = 'PendingCompanyDetails', //Carga de vida em aprovação V1
  LifeLoadOnApproval = 'LifeLoadOnApproval', //Carga de vida em aprovação V2
  PendingCompanyDetails = 'PendingCompanyDetails', // Dados da empresa pendente
  CompanyDetailsAvailable = 'CompanyDetailsAvailable', // Dados da empresa disponível
  Active = 'Active', // Empresa implantada V1
  ImplantedCompany = 'ImplantedCompany', // Empresa implantada V2
  ActiveWithoutContract = 'ActiveWithoutContract', // Implantada sem contrato V1 - nao existe do filtro
  CompanyDeployedWithoutContract = 'CompanyDeployedWithoutContract', // Implantada sem contrato V2
  Canceled = 'Canceled', // Cancelado
  Expired = 'Expired', // Expirado
}
export const quotationEnumKeys: {
  [x: string]: {
    icon: IconName;
    name: string;
    color: string;
  };
} = {
  [QuotationStatusEnum.InEdition]: {
    icon: 'edit',
    name: 'Em edição',
    color: COLORS.MONOCHROMATIC.GRAY4,
  },

  [QuotationStatusEnum.AwaitingPricing]: {
    icon: 'timer',
    name: 'Aguardando precificação',
    color: COLORS.PRIMARY.BLUE['+1'],
  },
  [QuotationStatusEnum.InNegotiation]: {
    icon: 'coins',
    name: 'Em negociação',
    color: COLORS.PRIMARY.BLUE['+1'],
  },

  [QuotationStatusEnum.AvailableOffer]: {
    icon: 'clipboard-pulse',
    name: 'Proposta disponível',
    color: COLORS.PRIMARY.BLUE.MAIN,
  },
  [QuotationStatusEnum.ProposalInApproval]: {
    icon: 'timer',
    name: 'Proposta em aprovação',
    color: COLORS.PRIMARY.BLUE.MAIN,
  },

  ...(!feature.QUOTATIONS_STATUS
    ? {
        [QuotationStatusEnum.ContractAvailable]: {
          icon: 'file-check',
          name: 'Contrato disponível',
          color: COLORS.PRIMARY.ORANGE['+1'],
        },
      }
    : undefined),

  ...(!feature.QUOTATIONS_STATUS
    ? {
        [QuotationStatusEnum.AwaitingContract]: {
          icon: 'timer',
          name: 'Aguardando contrato',
          color: COLORS.PRIMARY.ORANGE['+1'],
        },
      }
    : undefined),

  ...(!feature.QUOTATIONS_STATUS
    ? {
        [QuotationStatusEnum.ContractUnderApproval]: {
          icon: 'file-check',
          name: 'Contrato em aprovação',
          color: COLORS.PRIMARY.ORANGE['+1'],
        },
      }
    : undefined),

  [QuotationStatusEnum.PendingLoadLife]: {
    icon: 'clipboard-pulse',
    name: 'Carga de beneficiários pendentes',
    color: COLORS.PRIMARY.ORANGE['+1'],
  },

  ...(feature.QUOTATIONS_STATUS
    ? {
        [QuotationStatusEnum.LifeLoadOnApproval]: {
          icon: 'clipboard-pulse',
          name: 'Carga de beneficiários em aprovação',
          color: COLORS.PRIMARY.ORANGE['+1'],
        },
      }
    : undefined),

  [QuotationStatusEnum.CompanyDetailsAvailable]: {
    icon: 'building',
    name: 'Dados da empresa disponível',
    color: COLORS.PRIMARY.GREEN['+1'],
  },

  [feature.QUOTATIONS_STATUS
    ? QuotationStatusEnum.ImplantedCompany
    : QuotationStatusEnum.Active]: {
    icon: 'check',
    name: 'Empresa implantada',
    color: COLORS.PRIMARY.GREEN['+1'],
  },
  [feature.QUOTATIONS_STATUS
    ? QuotationStatusEnum.CompanyDeployedWithoutContract
    : QuotationStatusEnum.ActiveWithoutContract]: {
    icon: 'check',
    name: 'Implantada sem contrato',
    color: COLORS.PRIMARY.GREEN['+1'],
  },

  [QuotationStatusEnum.Canceled]: {
    icon: 'close',
    name: 'Cancelado',
    color: COLORS.SECONDARY.RED['+1'],
  },
  [QuotationStatusEnum.Expired]: {
    icon: 'close',
    name: 'Expirado',
    color: COLORS.SECONDARY.RED['+1'],
  },
};

export enum ContractTypeEnum {
  Business = 'Business',
  Membership = 'Accession',
}

export const ContractTypes = {
  [ContractTypeEnum.Business]: 'Empresarial',
  [ContractTypeEnum.Membership]: 'Adesão',
};

export const AgencyStatusWithAlert: Partial<
  Record<QuotationStatusEnum, string | ((...args: any) => string)>
> = {
  [QuotationStatusEnum.InNegotiation]: 'Pendente aceite da proposta comercial',
  [QuotationStatusEnum.AvailableOffer]: 'Pendente assinatura da proposta',
  [QuotationStatusEnum.ContractAvailable]: 'Pendente assinatura do contrato',
  [QuotationStatusEnum.PendingLoadLife]:
    'Pendente envio da carga de beneficiários',
  [QuotationStatusEnum.LifeLoadOnApproval]:
    'Pendente aprovação da carga de beneficiários',

  [QuotationStatusEnum.CompanyDetailsAvailable]: quotation => {
    if (quotation?.keyAttachments?.lenght > 0) {
      return 'Pendente primeiro acesso da empresa\nPendente assinatura do contrato';
    }

    return 'Pendente primeiro acesso da empresa';
  },
};

export const BackOfficeStatusWithAlert: Partial<
  Record<QuotationStatusEnum, string | ((...args: any) => string)>
> = {
  [QuotationStatusEnum.AwaitingPricing]:
    'Envie o arquivo da proposta comercial',
  [QuotationStatusEnum.AwaitingContract]: 'Pendente envio do contrato',
  [QuotationStatusEnum.ContractUnderApproval]: 'Pendente aprovação do contrato',
  [QuotationStatusEnum.ActiveWithoutContract]: 'Implantada sem contrato',
  [QuotationStatusEnum.PendingCompanyDetails]:
    'Pendente envio dos dados de acesso',
  [QuotationStatusEnum.LifeLoadOnApproval]:
    'Pendente aprovação da carga de beneficiários',
  [QuotationStatusEnum.ProposalInApproval]: 'Pendente aprovação da assinatura',
  [QuotationStatusEnum.CompanyDetailsAvailable]: quotation => {
    if (quotation?.keyAttachments?.lenght > 0) {
      return 'Pendente primeiro acesso da empresa\nPendente validação do contrato';
    }

    return 'Pendente primeiro acesso da empresa';
  },
};

export enum Gender {
  Masculine = 'masculine',
  Feminine = 'feminine',
}
