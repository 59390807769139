import { FilterKeyEnum } from '@hapvida/hapvida-core-components';

import {
  FiltersGroup,
  NormalGroup,
} from '@hapvida/hapvida-core-components/src/hooks/useDrawerFilter';

export const filtersToMap = (filtersGroup: FiltersGroup) => {
  const START_DATE_KEY = FilterKeyEnum.START_DATE;
  const END_DATE_KEY = FilterKeyEnum.END_DATE;
  const DATE_KEY = 'date';

  return Object.entries(filtersGroup).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      if (key === DATE_KEY) {
        if (value.startDate)
          acc[START_DATE_KEY] = value.startDate.toISOString();
        if (value.endDate) acc[END_DATE_KEY] = value.endDate.toISOString();
      } else if ((value as NormalGroup)?.filters.length) {
        acc[key] = (value as NormalGroup).filters
          .map(filterGroup => filterGroup.value)
          .join(',');
      }

      return acc;
    },
    {},
  );
};
