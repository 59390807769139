import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';

import { Filter } from '@hapvida/hapvida-core-components';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';

export function useAccessProfile() {
  const { enqueueSnackbar } = useSnackbar();

  const loadProfilesFilterList = async () => {
    try {
      const service = new UserService();

      const { profiles } = await service.getProfiles();

      const filters: Filter[] =
        profiles?.map(profile => ({
          content: <Typography variant="text">{profile.name}</Typography>,
          label: profile.name,
          value: profile.id,
        })) ?? [];

      return filters;
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return [];
    }
  };

  return { loadProfilesFilterList };
}
