import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().required(),
  document: yup.string().required().min(14).max(15),
  phone: yup.string().required().min(14).max(16),
  email: yup.string().required().email(),
  confirmEmail: yup
    .string()
    .required()
    .oneOf([yup.ref('email')], 'Os e-mails precisam ser iguais.'),
});
