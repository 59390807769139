import { SummaryCardNew, Loading } from '@hapvida/hapvida-core-components';

import { BeneficiariesSummary } from '@components';
import { calculateSummaryValues, calculateTotalBeneficiaries } from '@utils';

import { useBeneficiariesState } from '../../hooks';
import { initialSummaryValues } from './constants';

export function BeneficiariesSummaryCard() {
  const { beneficiariesSummaryState } = useBeneficiariesState();

  if (beneficiariesSummaryState?.isLoading) {
    return (
      <SummaryCardNew.Container success>
        <Loading minHeight={64} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (beneficiariesSummaryState.isError) {
    return null;
  }

  const summaryValues = calculateSummaryValues(
    beneficiariesSummaryState.data.beneficiaries,
    initialSummaryValues,
  );

  summaryValues.totalBeneficiaries = calculateTotalBeneficiaries(summaryValues);

  return <BeneficiariesSummary summaryValues={summaryValues} />;
}
