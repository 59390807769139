import { Company } from '@hapvida/hapvida-core-components';

import { BeneficiaryDependentProps } from '@services/BeneficiaryService';

import {
  BeneficiaryType,
  Detail,
  DetailContract,
  Person,
  Plan,
} from '@flows/private/beneficiaries/components';

import { DependentsAggregatedListTableNameEnum } from '../constants';

export const getBeneficiaryDependentsAggregatedListTableRows = (
  beneficiaryDependents: BeneficiaryDependentProps[],
) => {
  return beneficiaryDependents?.map(dependent => ({
    id: dependent.id,
    main: {
      [DependentsAggregatedListTableNameEnum.DETAIL]: (
        <Detail data={dependent} />
      ),
      [DependentsAggregatedListTableNameEnum.PERSON]: (
        <Person data={dependent.user} />
      ),
      [DependentsAggregatedListTableNameEnum.TYPE]: (
        <BeneficiaryType dependentsAggregates={dependent.type} />
      ),
      [DependentsAggregatedListTableNameEnum.COMPANY]: (
        <Company company={dependent.healthOperator} />
      ),
      [DependentsAggregatedListTableNameEnum.PLAN]: (
        <Plan
          code={dependent.contractDetail.planHealth.code}
          name={dependent.contractDetail.planHealth.name}
        />
      ),
      [DependentsAggregatedListTableNameEnum.PARTNER_COMPANY]: (
        <DetailContract data={dependent.contractDetail} />
      ),
    },
    dependents: [],
  }));
};
