import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { Typography, useConfirmation } from '@hapvida/hapvida-core-components';

import { UserService, AddAccountFormProps } from '@services/UserService';
import { CompanyDetailMock } from '@services/CompanySetupService/dtos/GetCompanyDetailDTO';

import { useScreenLoading } from '@hooks';
import { handleProfiles, toastifyApiErrors } from '@utils';
import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';
import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

interface UseRegisterUserCompanyProps {
  companyDetails?: CompanyDetailMock;
}

export function useRegisterUserCompany({
  companyDetails,
}: UseRegisterUserCompanyProps) {
  const { onScreenLoading } = useScreenLoading();
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigateTo = useNavigate();
  const { editedPartnerCompanies, availableProfilesState } =
    useAccessManagement();

  const companyId = companyDetails?.id;

  const handleRedirectCompanySetupList = async () => {
    const companySetupQueryKey = ['companySetupListState'];

    queryClient.removeQueries({
      queryKey: companySetupQueryKey,
      exact: true,
    });

    await queryClient.refetchQueries({
      queryKey: companySetupQueryKey,
    });

    navigateTo(CompanySetupListRouter.path);
  };

  const handleAccessAttachedSuccess = () => {
    showConfirmation({
      title: 'Acesso atrelado com sucesso',
      icon: 'check',
      type: 'success',
      confirmText: 'Prosseguir',
      onConfirm: handleRedirectCompanySetupList,
      content: (
        <Typography variant="text">
          Enviamos um e-mail de confirmação para o usuário.
        </Typography>
      ),
    });
  };

  const onRegisterUserCompany = async (data: AddAccountFormProps) => {
    try {
      if (companyId) {
        onScreenLoading(true);
        const createAccount = new UserService();
        await createAccount.createUser({
          companyId,
          profiles: handleProfiles(
            editedPartnerCompanies,
            availableProfilesState,
          ),
          ...data,
        });

        setTimeout(handleAccessAttachedSuccess, 1000);
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
    }
  };

  return { onRegisterUserCompany };
}
