import React from 'react';
import Typography from '@mui/material/Typography';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { Field, FieldTitle } from './styles';

interface DataFieldProps {
  label: string;
  value: string | React.ReactElement | any;
  width?: string;
}

export function DataField({ label, value, width = 'auto' }: DataFieldProps) {
  return (
    <Field width={width}>
      <FieldTitle variant="link" color={COLORS.PRIMARY.BLUE['-1']}>
        {label}
      </FieldTitle>
      {typeof value === 'string' ? (
        <Typography variant="text">{value}</Typography>
      ) : (
        value
      )}
    </Field>
  );
}
