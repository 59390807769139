import { HapvidaApi } from '@http';

import {
  FetchStateCitiesRequestDTO,
  FetchStateCitiesResponseDTO,
} from './dtos/FetchStateCitiesDTO';

export default class AddressService {
  public async fetchStateCities({
    state,
  }: FetchStateCitiesRequestDTO): Promise<FetchStateCitiesResponseDTO> {
    const uf = state.toLocaleLowerCase();

    const { data } = await HapvidaApi.get<FetchStateCitiesResponseDTO>(
      `/api/v1/cities/${uf}`,
    );

    return data;
  }
}
