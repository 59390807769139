import { IAppDynamicRoute } from 'routes/types';

import PasswordRecoveryStepsPage from '.';

export const PasswordRecoveryRouter: IAppDynamicRoute = {
  Page: PasswordRecoveryStepsPage,
  path: '/recuperacao-de-senha/:passwordDefinitionStep',
  name: 'Recuperação de senha',
  dynamicPath: ({ passwordRecoveryStep }) =>
    `/recuperacao-de-senha/${passwordRecoveryStep}`,
  Icon: <></>,
  disabled: false,
};
