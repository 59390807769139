import { Stack, Button, Typography } from '@mui/material';

import RegistrationCompletedLogo from './assets/registration-completed-logo.svg';

export function UserRegistrationCompleted() {
  return (
    <Stack alignItems="flex-start" gap={3}>
      <img
        src={RegistrationCompletedLogo}
        alt="Desenho de uma prancheta com uma lista de itens com 'checks' indicando que o cadastro foi finalizado"
      />

      <Stack gap={2}>
        <Typography variant="h5">
          Parabéns! Sua senha foi criada com sucesso.
        </Typography>
        <Typography variant="subtitle2">
          Você agora pode acessar o Portal Empresa Hapvida NDI com segurança.
          Para isso, basta inserir o seu e-mail cadastrado e a sua nova senha.
        </Typography>
      </Stack>

      <Button
        color="success"
        variant="contained"
        href={process.env.REACT_APP_LOGIN_URL}
      >
        Ir para login
      </Button>
    </Stack>
  );
}
