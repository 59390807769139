import { IAppDynamicRoute } from 'routes/types';

import RegisterStepsPage from '.';

export const RegisterRouter: IAppDynamicRoute = {
  Page: RegisterStepsPage,
  path: '/cadastro/:registerStep',
  name: 'Cadastrar empresa',
  dynamicPath: ({ registerStep }) => `/cadastro/${registerStep}`,
  Icon: <></>,
};
