import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { useCallback } from 'react';
import { StackProps } from '@mui/material';

import { Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import { IAppSearchRoute, RouteProps, SubRouteProps } from 'routes/types';
import { useSidebarModalState } from '@hooks';

import { RouteGroup } from './components';
import { useRoutes } from '../../hooks';

interface SidebarRouteButtonProps {
  route: RouteProps & Partial<IAppSearchRoute> & SubRouteProps;
  stackProps?: StackProps;
}

export function SidebarRouteButton({
  route,
  stackProps,
}: Readonly<SidebarRouteButtonProps>) {
  const { handleShowModal, currentModalPath } = useSidebarModalState();
  const { handleRouteDisabled } = useRoutes();
  const navigate = useNavigate();

  const resolved = useResolvedPath({ pathname: route.path });
  const match = useMatch({ path: resolved.pathname, end: false });

  const isDisabled = handleRouteDisabled(route);

  const currentRoute = window.location.pathname;

  const isModalOpen = Boolean(currentModalPath);

  const isOnThisRoute = Boolean(route.path) && Boolean(match);

  const isSelected = !isModalOpen && isOnThisRoute;

  const isModalSelected = isModalOpen && currentModalPath === route.path;

  const onClickItem = useCallback(() => {
    if (route.sidebarModalProps) {
      handleShowModal(route.sidebarModalProps, route.path);
      return;
    }

    if (match?.pathname === currentRoute) {
      window.location.reload();
    } else if (!isDisabled) {
      navigate(route.searchPath ? route.searchPath() : route.path);
    }
  }, [currentRoute, isDisabled, match, navigate, route]);

  if (route.subRoutes) {
    return (
      <RouteGroup
        match={match}
        isDisabled={isDisabled}
        onClickItem={onClickItem}
        route={route}
        stackProps={stackProps}
      />
    );
  }

  return (
    <SidebarCore.Item
      {...stackProps}
      iconName={route.Icon?.props.name}
      isSelected={isSelected || isModalSelected}
      disabled={isDisabled}
      onClick={onClickItem}
      label={route.name}
      key={route.path}
    />
  );
}
