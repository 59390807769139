import { FC, useCallback, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  Icon,
  Form,
  Button,
  FormGroup,
  Typography,
} from '@hapvida/hapvida-core-components';

import { toastifyApiErrors } from '@utils';

import { useQuery } from '../../../../../../../hooks/useQuery';
import {
  PASSWORD_RECOVERY_STEPS_VALUES,
  PASSWORD_RECOVERY_STEPS_KEYS,
} from '../../constants/keys';
import { useScreenLoading } from '../../../../../../../hooks/useScreenLoading';
import AuthService from '../../../../../../../infra/services/AuthService';
import { schema } from './validation';
import { Container } from './styles';
import FormPassword from '../../../../components/FormPassword';

export type PasswordDefinitionForm = {
  password: string;
  passwordConfirmation: string;
};

interface PasswordDefinitionProps {
  onStepUpdate(step: number, data: any): void;
}

const PasswordDefinition: FC<PasswordDefinitionProps> = ({ onStepUpdate }) => {
  const loginPageUrl = process.env.REACT_APP_LOGIN_URL;

  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { onScreenLoading } = useScreenLoading();

  const token = query.get('token');
  const userId = query.get('user-id');

  const {
    control,
    watch,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = useForm<PasswordDefinitionForm>({
    mode: 'all',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(schema, { abortEarly: false }),
  });

  const passwordWatch = watch('password');
  const passwordConfirmationWatch = watch('passwordConfirmation');

  const validateToken = useCallback(() => {
    try {
      if (!token) throw new Error('Token inválido');
    } catch (err) {
      window.location.replace(loginPageUrl);
    }
  }, [token, loginPageUrl]);

  useEffect(() => validateToken(), [validateToken]);

  const onSubmit: SubmitHandler<PasswordDefinitionForm> = async data => {
    try {
      onScreenLoading(true);
      const { password: passwordData } = data;

      const authService = new AuthService();

      await authService.recoveryPassword({
        password: passwordData,
        token,
        userId,
      });

      onStepUpdate(
        PASSWORD_RECOVERY_STEPS_VALUES[
          PASSWORD_RECOVERY_STEPS_KEYS.PASSWORD_RECOVERY
        ],
        data,
      );
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginBottom: '40px', marginTop: '52px', padding: '0 100px' }}
    >
      <Form title="Crie uma senha forte">
        <Typography fontSize={14} pb={2}>
          Crie uma senha forte combinando letras maiúscula e minúscula, números
          e símbolos.
        </Typography>

        <Container>
          <FormGroup>
            <FormPassword
              passwordWatch={passwordWatch}
              passwordConfirmWatch={passwordConfirmationWatch}
              controlForm={control}
              errorsForm={errors}
              triggerForm={trigger}
            />
          </FormGroup>
        </Container>

        <Grid container justifyContent="flex-end">
          <Button
            type="submit"
            color="success"
            variant="contained"
            disabled={!isValid}
            endIcon={<Icon name="chevron-right" size={16} />}
          >
            Confirmar
          </Button>
        </Grid>
      </Form>
    </form>
  );
};

export default PasswordDefinition;
