export enum CompanyStatusEnum {
  REGISTER_ACCESS = 'RegisterAccess',
  PENDING_ACCESS = 'PendingAccess',
  CANCELED = 'Canceled',
  ACTIVE = 'Active',
}

export const COMPANY_STATUS_NAME: Record<CompanyStatusEnum, string> = {
  [CompanyStatusEnum.REGISTER_ACCESS]: 'Cadastro pendente',
  [CompanyStatusEnum.PENDING_ACCESS]: 'Acesso pendente',
  [CompanyStatusEnum.CANCELED]: 'Empresa cancelada',
  [CompanyStatusEnum.ACTIVE]: 'Empresa ativa',
};
