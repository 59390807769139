import { UseQueryResult } from '@tanstack/react-query';

import {
  ProfileProps,
  UpdateUserProfileProps,
  UserPartnerCompanyProps,
} from '@services/UserService';

import { getProfiles } from './getProfiles';
import { getRemovedAndRelocated } from './getRemovedAndRelocated';
import { getWithAccess } from './getWithAccess';
import { getWithoutAccess } from './getWithoutAccess';

export const handleEditUserProfiles = (
  editedPartnerCompanies: UserPartnerCompanyProps[],
  originalPartnerCompanies: UserPartnerCompanyProps[],
  availableProfilesState: UseQueryResult<ProfileProps[]>,
) => {
  const filteredPartnerCompaniesWithAccess = getWithAccess(
    editedPartnerCompanies,
    originalPartnerCompanies,
  );

  const filteredPartnerCompaniesWithoutAccess = getWithoutAccess(
    editedPartnerCompanies,
    originalPartnerCompanies,
  );

  const filteredPartnerCompaniesRemovedAndRelocated = getRemovedAndRelocated(
    editedPartnerCompanies,
    originalPartnerCompanies,
  );

  const mergedRemovedPartnerCompanies = [
    ...filteredPartnerCompaniesWithoutAccess,
    ...filteredPartnerCompaniesRemovedAndRelocated,
  ];

  const availableProfiles = availableProfilesState?.data ?? [];

  const profiles: UpdateUserProfileProps[] = getProfiles(
    filteredPartnerCompaniesWithAccess,
    mergedRemovedPartnerCompanies,
    availableProfiles,
  );

  return profiles;
};
