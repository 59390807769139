import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import CompanyService from '@services/CompanyService';
import { PendenciesProps } from '@services/CompanyService/dtos/PendenciesDTO';
import { BillListProps } from '@services/FinancialService/dtos/BillsListDTO';
import { CompanySummaryDetails } from '@services/CompanyService/dtos/CompanySummaryDetailDTO';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';

interface HomeStateContextData {
  companySummaryDetailsState?: UseQueryResult<CompanySummaryDetails>;
  billsSummaryState?: UseQueryResult<BillListProps>;
  pendenciesState?: UseQueryResult<PendenciesProps>;
  handleRefreshCompanyStates: () => void;
}

const HomeStateContext = createContext<HomeStateContextData>(
  {} as HomeStateContextData,
);

function HomeStateProvider({ children }: PropsWithChildren<{}>) {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const companyService = useMemo(() => new CompanyService(), []);

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  const companySummaryDetailsState = useQuery<CompanySummaryDetails>({
    queryKey: ['companySummaryDetailsState', companyId, partnerCompany],
    queryFn: () =>
      companyService.fetchCompanySummaryDetail({
        id: companyId,
      }),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const handleRefreshCompanyStates = () => {
    companySummaryDetailsState?.remove();

    companySummaryDetailsState?.refetch();
  };

  const valueHomeStateContextProvider = useMemo<HomeStateContextData>(() => {
    return {
      companySummaryDetailsState,
      handleRefreshCompanyStates,
    };
  }, [companySummaryDetailsState, handleRefreshCompanyStates]);

  return (
    <HomeStateContext.Provider value={valueHomeStateContextProvider}>
      {children}
    </HomeStateContext.Provider>
  );
}

function useHomeState() {
  const context = useContext(HomeStateContext);

  if (!context) {
    throw new Error('useHomeState must be used within HomeStateProvider');
  }

  return context;
}

export { useHomeState, HomeStateProvider };
