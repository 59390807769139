import { ButtonProps as MuiButtonProps, Stack } from '@mui/material';
import React from 'react';

import { MuiButton } from './styles';

export interface ButtonProps extends MuiButtonProps {
  rightComponent?: React.JSX.Element;
  fontColor?: string;
}

const Button: React.FC<ButtonProps> = ({
  rightComponent,
  fontColor,
  children,
  ...props
}) => {
  return (
    <MuiButton style={{ color: fontColor }} {...props}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        {children}
        {rightComponent}
      </Stack>
    </MuiButton>
  );
};

export default Button;
