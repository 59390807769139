import React, { useContext, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parseISO } from 'date-fns';
import {
  DrawerFilterContextData,
  DrawerFilterContext,
} from '../contexts/DrawerFilterContext';
import { Gender } from '../constants/enums';
import { FilterKeyEnum, SearchParamEnum } from '../constants';

export interface Filter {
  content: string | React.JSX.Element;
  label: string;
  value: string;
}

export interface NormalGroup {
  label: string;
  filters: Filter[];
  singularLabel?: string;
  gender?: Gender;
  hasMore?: boolean;
  isDynamic?: boolean;
}

export interface DateGroup {
  startDate: Date | null;
  endDate: Date | null;
}

type StringLiteralUnion<T extends U, U = string> = T | U;

export type FiltersGroup = {
  [K in StringLiteralUnion<'date'>]: K extends 'date'
    ? DateGroup
    : Partial<DateGroup> &
        Partial<NormalGroup> & {
          search?: string;
        };
};

export interface DrawerFilterProviderProps {
  ignoreFilters?: string[];
}

const DrawerFilterProvider: React.FC<DrawerFilterProviderProps> = ({
  children,
  ignoreFilters = [],
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const blacklist: string[] = useMemo(
    () => [
      ...ignoreFilters,
      SearchParamEnum.ORDER_BY,
      SearchParamEnum.SORT_BY,
      SearchParamEnum.SEARCH,
      SearchParamEnum.PAGE,
    ],
    [ignoreFilters],
  );

  const getInitialFilter: DrawerFilterContextData['getInitialFilter'] =
    filterProps => {
      const { filtersConfig, filtersLabel } = filterProps ?? {};
      let updatedFilters: FiltersGroup = {};

      searchParams.forEach((value, key) => {
        const ignoredFilter = blacklist.some(substring =>
          key.includes(substring),
        );
        if (ignoredFilter) return;

        if (
          key === FilterKeyEnum.START_DATE ||
          key === FilterKeyEnum.END_DATE
        ) {
          const dateKey =
            key === FilterKeyEnum.START_DATE ? 'startDate' : 'endDate';
          const parsedDate = parseISO(value);

          updatedFilters.date = {
            ...updatedFilters.date,
            [dateKey]: parsedDate,
          };

          return;
        }

        const labelConfig: Partial<
          Pick<NormalGroup, 'label' | 'singularLabel' | 'gender'>
        > = {};

        const filterLabel = filtersLabel?.[key];

        if (typeof filterLabel === 'string') {
          labelConfig.label = filterLabel;
        } else if (typeof filterLabel === 'object' && filterLabel !== null) {
          labelConfig.label = filterLabel.plural;
          labelConfig.singularLabel = filterLabel.singular;
          labelConfig.gender = filterLabel.gender;
        }

        updatedFilters[key] = {
          ...labelConfig,
          filters: [
            ...(updatedFilters[key]?.filters ?? []),
            {
              content: '',
              label: filtersConfig
                ? filtersConfig[key]?.find(el => el.value === value)?.label ??
                  ''
                : '',
              value,
            },
          ],
        };
      });

      return updatedFilters;
    };

  const [initialFilters, setInitialFilters] = useState<FiltersGroup>(
    {} as FiltersGroup,
  );
  const [activeFilters, setActiveFilters] = useState<FiltersGroup>(
    getInitialFilter(),
  );
  const [provFilters, setProvFilters] = useState<FiltersGroup>(
    {} as FiltersGroup,
  );

  const [filterKeys, setFilterKeys] = useState<string[]>([]);

  const filterCount = useMemo(() => {
    let count = 0;
    Object.keys(activeFilters).forEach(key => {
      if (key === 'date') {
        if (activeFilters[key].startDate || activeFilters[key].endDate) {
          count++;
        }
      } else {
        const ignoredFilter = blacklist.some(substring =>
          key.includes(substring),
        );

        if (!ignoredFilter) {
          count += activeFilters[key].filters!.length;
        }
      }
    });

    return count;
  }, [activeFilters, blacklist]);

  const parseFilterToQueryParams = (filters: FiltersGroup) => {
    let queryParams: { [key: string]: string | string[] } = {};

    const filtersKey = Object.keys(filters);

    filtersKey.forEach(filterKey => {
      const filter = filters[filterKey];

      if (filter.filters) {
        queryParams = {
          ...queryParams,
          [filterKey]: filter.filters?.map(el => el.value),
        };
      }

      if (filter.startDate) {
        queryParams = {
          ...queryParams,
          [FilterKeyEnum.START_DATE]: filter.startDate.toISOString(),
        };
      }

      if (filter.endDate) {
        queryParams = {
          ...queryParams,
          [FilterKeyEnum.END_DATE]: filter.endDate.toISOString(),
        };
      }
    });

    searchParams.forEach((value, key) => {
      const ignoredFilter = blacklist.some(substring =>
        key.includes(substring),
      );
      if (ignoredFilter && key !== 'page') {
        queryParams[key] = value;
      }
    });

    setSearchParams(queryParams);
  };

  const cleanFilters = () => {
    if (window.location.pathname === pathname) {
      const currentSearchParams = new URLSearchParams(searchParams);

      currentSearchParams.forEach((_, key) => {
        if (filterKeys.includes(key)) {
          searchParams.delete(key);
        }
      });

      setSearchParams(searchParams);
    }

    setInitialFilters({} as FiltersGroup);
    setActiveFilters({} as FiltersGroup);
    setProvFilters({} as FiltersGroup);
  };

  const cleanAllFilters = () => {
    setInitialFilters({});
    setActiveFilters({});
    setSearchParams({});
    setProvFilters({});
  };

  const applyFilters = (cb?: Function) => {
    setActiveFilters(provFilters);
    parseFilterToQueryParams(provFilters);
    if (cb) {
      cb(provFilters);
    }
  };

  const handleFilterChange = (filter: FiltersGroup) => {
    parseFilterToQueryParams(filter);
    setActiveFilters(filter);
  };

  const register = (filterKey: string | string[]) => {
    const newRegisters = Array.isArray(filterKey) ? filterKey : [filterKey];

    setFilterKeys(prev => [...prev, ...newRegisters]);
  };

  const unregister = (filterKey: string | string[]) => {
    const registers = Array.isArray(filterKey) ? filterKey : [filterKey];

    setFilterKeys(prev => prev.filter(key => !registers.includes(key)));
  };

  const drawerFilterValue = useMemo(
    () => ({
      activeFilters,
      setActiveFilters,
      provFilters,
      setProvFilters,
      cleanFilters,
      cleanAllFilters,
      applyFilters,
      initialFilters,
      setInitialFilters,
      filterCount,
      getInitialFilter,
      handleFilterChange,
      ignoreFilters: blacklist,
      register,
      unregister,
    }),
    [
      activeFilters,
      setActiveFilters,
      provFilters,
      setProvFilters,
      cleanFilters,
      cleanAllFilters,
      applyFilters,
      initialFilters,
      setInitialFilters,
      filterCount,
      getInitialFilter,
      handleFilterChange,
      blacklist,
      register,
      unregister,
    ],
  );

  return (
    <DrawerFilterContext.Provider value={drawerFilterValue}>
      {children}
    </DrawerFilterContext.Provider>
  );
};

function useDrawerFilter(): DrawerFilterContextData {
  const context = useContext(DrawerFilterContext);

  if (!context) {
    throw new Error('useDrawerFilter must be used within DrawerFilterProvider');
  }

  return context;
}

export { DrawerFilterProvider, useDrawerFilter };
