import { Avatar, Box, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Container = muiStyled(Box)(() => ({
  background: COLORS.WHITE,
  borderRadius: '0.5rem',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  backdropFilter: 'blur(24px)',
  padding: '1.5rem',
}));

export const ItemName = muiStyled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '0.875rem',
  marginBottom: '0.5rem',
  color: COLORS.PRIMARY.BLUE['-1'],
}));

export const CompanyName = muiStyled(Typography)(() => ({
  fontWeight: 900,
  fontSize: '1.5rem',
  marginBottom: '0.5rem',
  color: COLORS.MONOCHROMATIC.GRAY4,
}));

export const ItemData = muiStyled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '0.875rem',
  marginBottom: '0.5rem',
  color: COLORS.MONOCHROMATIC.GRAY4,
}));

export const StyledAvatar = muiStyled(Avatar)(() => ({
  width: 50,
  height: 50,
}));
