import { FilterKeyEnum, DrawerFilter } from '@hapvida/hapvida-core-components';

export function ReviewDrawerFilter() {
  const reviewFilters = [
    {
      content: <span>Exibir apenas críticas</span>,
      label: 'Crítica',
      value: 'true',
    },
  ];

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.REVIEW}
      filters={reviewFilters}
      isMultiple={false}
      title="Críticas"
      label="crítica"
    />
  );
}
