import { COLORS } from '@hapvida/hapvida-core-components';

import { ProfileProps } from '@services/UserService';

import { PartnerCompaniesStatusEnum } from '@constants';

interface PartnerCompanyProps {
  partnerCompany: string;
  mainPartnerCompany: string;
  isMainPartnerCompany: boolean;
  profile?: ProfileProps;
}

type PartnerCompaniesData = { color: string; text: string };

type PartnerCompaniesStatusType = Record<
  PartnerCompaniesStatusEnum,
  PartnerCompaniesData
>;

interface PartnerCompanyContentProps {
  partnerCompanies: PartnerCompanyProps[];
  total: number;
  totalPerPage: number;
}

interface GetPartnerCompanyResponseDTO {
  content: PartnerCompanyContentProps;
}

interface GetPartnerCompanyRequestDTO {
  companyId: string;
  offset?: number;
  limit: number;
}

const PARTNER_COMPANIES_STATUS_DATA: PartnerCompaniesStatusType = {
  PendingAccess: {
    color: COLORS.PRIMARY.ORANGE.MAIN,
    text: 'Acesso pendente',
  },
  RegisterAccess: {
    color: COLORS.MONOCHROMATIC.GRAY4,
    text: 'Cadastro pendente',
  },
  Canceled: {
    color: COLORS.SECONDARY.RED.MAIN,
    text: 'Cancelado',
  },
  Active: {
    color: COLORS.SECONDARY.GREEN.MAIN,
    text: 'Ativo',
  },
} as const;

export {
  GetPartnerCompanyRequestDTO,
  GetPartnerCompanyResponseDTO,
  PartnerCompanyContentProps,
  PartnerCompanyProps,
  PARTNER_COMPANIES_STATUS_DATA,
};
