import { Container, Typography, styled } from '@mui/material';

export const CardWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
}));

export const TextTitle = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '16px',
}));

export const TextContent = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '14px',
}));

export const ContainerWrapper = styled(Container)(() => ({
  maxWidth: 'md',
  display: 'flex',
  flexDirection: 'column',
  padding: '22px 0',
  gap: '16px',
}));
