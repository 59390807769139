import { useState } from 'react';

import MainLine from './MainLine';
import DependentLine from './DependentLine';

type TableHeadParams = {
  id: number;
  name: string;
};

type Dependent = {
  id: number | string;
  itHasError?: boolean;
  errorMessage?: string;
  components: object;
};

type Item = {
  id: number | string;
  main: object;
  itHasError?: boolean;
  errorMessage?: string;
  dependents: Dependent[];
};

interface RowsProps {
  item: Item;
  TableHeadColumns: TableHeadParams[];
  onEdit?: (id: string) => void;
  onEditChild?: (id: string) => void;
  hasCellAction?: boolean;
  rowColor?: string;
}

const Rows = ({
  item,
  TableHeadColumns,
  onEdit,
  onEditChild,
  hasCellAction,
  rowColor,
}: RowsProps) => {
  const [open, setOpen] = useState(false);

  const toggleDependentLine = () => {
    setOpen(!open);
  };

  return (
    <>
      <MainLine
        item={item}
        collapsed={open}
        tableHeadColumns={TableHeadColumns}
        onCollapse={toggleDependentLine}
        onEdit={onEdit}
        hasCellAction={hasCellAction}
        rowColor={rowColor}
      />

      {item.dependents.length > 0 && (
        <>
          {open && (
            <DependentLine
              dependents={item.dependents}
              TableHeadColumns={TableHeadColumns}
              onEditChild={onEditChild}
            />
          )}
        </>
      )}
    </>
  );
};

export default Rows;
