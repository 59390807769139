import { Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { BeneficiaryMovementTypeEnum } from '@constants';

import { IconDiv } from './styles';

interface MovementActionTypeProps {
  movementType?: BeneficiaryMovementTypeEnum | 'None';
  operationCode?: string;
}

const MovementTypeValues: Record<
  BeneficiaryMovementTypeEnum,
  {
    icon: IconName;
    value: string;
    color: string;
  }
> = {
  HolderInclusion: {
    icon: 'account-plus',
    value: 'Inclusão de titular',
    color: COLORS.SECONDARY.GREEN['+1'],
  },
  DependentInclusion: {
    icon: 'account-multiple-plus',
    value: 'Inclusão de dependente',
    color: COLORS.SECONDARY.GREEN['+1'],
  },
  CadastralChange: {
    icon: 'edit',
    value: 'Alteração cadastral',
    color: COLORS.SECONDARY.BLUE['+1'],
  },
  ContractTransfer: {
    icon: 'file-arrow-left-right-outline',
    value: 'Transferência de contrato',
    color: COLORS.SECONDARY.BLUE['+1'],
  },
  PlanChange: {
    icon: 'swap-horizontal',
    value: 'Troca de plano',
    color: COLORS.PRIMARY.ORANGE['+1'],
  },
  Reactivation: {
    icon: 'refresh',
    value: 'Reativação',
    color: COLORS.PRIMARY.ORANGE['+1'],
  },
  Exclusion: {
    icon: 'delete',
    value: 'Exclusão',
    color: COLORS.SECONDARY.RED['+1'],
  },
  ExclusionCancelation: {
    icon: 'delete-scratched',
    value: 'Cancelar Exclusão',
    color: COLORS.SECONDARY.RED['+1'],
  },
  Retired: {
    icon: 'account-multiple-plus',
    value: 'Aposentado e/ou demitido',
    color: COLORS.MONOCHROMATIC.GRAY4,
  },
};

export function MovementActionType({
  operationCode,
  movementType,
}: MovementActionTypeProps) {
  return (
    <>
      {movementType && movementType !== 'None' && operationCode ? (
        <IconDiv color={MovementTypeValues[movementType]?.color}>
          <Icon name={MovementTypeValues[movementType]?.icon} size={16} />
          <Typography variant="caption" fontWeight={600}>
            {operationCode?.replace(/^0+/, '')} -{' '}
            {MovementTypeValues[movementType]?.value}
          </Typography>
        </IconDiv>
      ) : (
        <Typography variant="caption" fontWeight={700}>
          {operationCode ? operationCode.replace(/^0+/, '') : '-'}
        </Typography>
      )}
    </>
  );
}
