import { PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';

import { COLORS, Icon, IconName } from '@hapvida/hapvida-core-components';

import { EditButton } from './styles';

interface HeaderItemProps {
  icon: IconName;
  title: string;
  onClickEdit: () => void;
}

export function HeaderItem({
  icon,
  title,
  onClickEdit,
  children,
}: PropsWithChildren<HeaderItemProps>) {
  return (
    <Stack alignItems="center" gap={1} direction="row" maxWidth="100%">
      <Icon color={COLORS.MONOCHROMATIC.GRAY4} size={24} name={icon} />
      <Typography variant="text" fontWeight={700}>
        {title}
      </Typography>
      {children}
      <EditButton color="primary" onClick={onClickEdit}>
        <Icon size={14} name="edit" />
      </EditButton>
    </Stack>
  );
}
