import { Navigate, Route, Routes } from 'react-router-dom';

import { PageNotFound } from '@flows/others';

import { ProxyRoute } from './ProxyRoute';
import { privateRoutes } from './private';
import { publicRoutes } from './public';
import { otherRoutes } from './others';
import { IAppRoute } from './types';

export function AppRoutes() {
  const appRoutes: Array<IAppRoute> = [
    ...privateRoutes,
    ...publicRoutes,
    ...otherRoutes,
  ];

  return (
    <Routes>
      {appRoutes.map(({ path, Page, isPrivate, ...rest }) => (
        <Route
          element={<ProxyRoute path={path} isPrivate={isPrivate} {...rest} />}
          path={path}
          key={path}
        >
          <Route path={path} element={Page && <Page />} />
        </Route>
      ))}

      <Route path="/" element={<Navigate to="/autenticando" replace />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
