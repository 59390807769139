import { Grid, Paper, styled } from '@mui/material';

export const StyledPaper = styled(Paper)({
  flex: 1,
  padding: 24,
  boxShadow: 'unset',
  borderRadius: '8px',
});

export const ContentButton = styled(Grid)({
  padding: '24px 0',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'row',
});
