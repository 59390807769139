/* eslint-disable no-restricted-syntax */
import { HapvidaApi } from '@http';

import HapvidaMockApi from '../../http/HapvidaMock';
import { BeneficiaryDetailsResponseDTO } from './dtos/BeneficiaryDetailDTO';
import {
  BeneficiaryMovementRequestDTO,
  BeneficiaryMovementsResponseDTO,
} from './dtos/BeneficiaryMovementDTO';
import {
  GetBeneficiaryRequestDTO,
  BeneficiariesResponseDTO,
  GetSummaryRequestDTO,
  BeneficiariesSummaryResponseDTO,
  BeneficiariesSummaryProps,
  BeneficiariesProps,
} from './dtos/ListBeneficiariesDTO';
import {
  BeneficiaryDependentsRequestDTO,
  BeneficiaryDependentsResponseDTO,
} from './dtos/BeneficiaryDependentAggregateDTO';
import { BeneficiaryReviewsResponseDTO } from './dtos/BeneficiaryReviewsDTO';
import {
  BeneficiaryExport,
  BeneficiaryExportIdRequestDTO,
} from './dtos/BeneficiaryExportDTO';
import { generateUrlParams } from '../../../utils/generateUrlParams';

export * from './dtos';

export default class BeneficiaryService {
  private endpoints = {
    list: '/beneficiaries',
    detail: '/beneficiary',
    movements: '/movements',
    stayLetter: (search: string) =>
      `api/v1/continuity-letter/beneficiary/${search}`,
  };

  public async fetchStayLetterFile(search: string) {
    const { data } = await HapvidaApi.get(this.endpoints.stayLetter(search));
    return data;
  }

  public async fetchBeneficiaries({
    companyId,
    ...filters
  }: GetBeneficiaryRequestDTO): Promise<BeneficiariesProps> {
    const params = generateUrlParams(filters);

    const { data } = await HapvidaApi.get<BeneficiariesResponseDTO>(
      `/api/v1/companies/${companyId}/beneficiaries`,
      {
        params,
      },
    );

    return data.content;
  }

  public async fetchBeneficiariesSummary({
    companyId,
    ...filters
  }: GetSummaryRequestDTO): Promise<BeneficiariesSummaryProps> {
    const params = generateUrlParams(filters);

    const { data } = await HapvidaApi.get<BeneficiariesSummaryResponseDTO>(
      `/api/v1/companies/${companyId}/beneficiaries/summary`,
      {
        params,
      },
    );

    return data.content;
  }

  public async fetchBeneficiaryById(id: string) {
    const { data } = await HapvidaApi.get<BeneficiaryDetailsResponseDTO>(
      `/api/v1/beneficiaries/${id}`,
    );

    return data.content;
  }

  public async fetchMovementsById(id: string) {
    const { data } = await HapvidaMockApi.get(`${this.endpoints.movements}`, {
      params: { id },
    });

    return data;
  }

  public async fetchMovementsTableById({
    beneficiaryId,
    companyId,
    limit,
    offset,
    filters,
  }: BeneficiaryMovementRequestDTO) {
    const { data } = await HapvidaApi.get<BeneficiaryMovementsResponseDTO>(
      `/api/v1/beneficiaries/${beneficiaryId}/${companyId}/movements`,
      {
        params: {
          limit,
          offset,
          ...filters,
        },
      },
    );

    return data.content;
  }

  public async fetchBeneficiaryDependents({
    beneficiaryId,
    filters,
  }: BeneficiaryDependentsRequestDTO) {
    const { data } = await HapvidaApi.get<BeneficiaryDependentsResponseDTO>(
      `/api/v1/beneficiaries/${beneficiaryId}/dependents`,
      {
        params: filters,
      },
    );

    return data.content.beneficiaries;
  }

  public async fetchBeneficiaryReviews(
    beneficiaryId: string,
    organizationalGroupId: string,
  ) {
    const { data } = await HapvidaApi.get<BeneficiaryReviewsResponseDTO>(
      `/api/v1/beneficiaries/${beneficiaryId}/companies/${organizationalGroupId}/reviews`,
    );

    return data.content;
  }

  public async getIdExportBeneficiaries({
    companyId,
    filters,
  }: BeneficiaryExportIdRequestDTO) {
    const params = generateUrlParams(filters);

    const { data } = await HapvidaApi.get(
      `/api/v1/companies/${companyId}/beneficiaries/export`,
      {
        params,
      },
    );

    return data.content.fileNameIdentifier;
  }

  public async beneficiaryExport({
    companyId,
    fileNameIdentifier,
  }: BeneficiaryExport) {
    const { data } = await HapvidaApi.get(
      `/api/v1/companies/${companyId}/beneficiaries/exported/${fileNameIdentifier}`,
    );

    return data;
  }
}
