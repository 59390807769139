import FileService from '@services/FileService';

export async function useGetFile(requestSuffix: string, fileName: string) {
  try {
    const fileService = new FileService();

    const fileUrl = await fileService.getFileUrl(requestSuffix, fileName);

    window.open(fileUrl);
  } catch (error) {
    /* empty */
  }
}
