import { Stack, Button, InputAdornment } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { SearchPartnerCompanyLayoutProps } from './types';
import { StyledTextField } from './styles';

export function SearchPartnerCompanyLayout({
  placeholder,
  inputRef,
  handlePressEnter,
  handleClickMagnifier,
  handleClickClose,
  showCloseButton,
  handleSearch,
  search,
  setSearch,
}: Readonly<SearchPartnerCompanyLayoutProps>) {
  return (
    <Stack spacing={1} direction="row">
      <StyledTextField
        value={search}
        onChange={e => setSearch(e.target.value)}
        inputRef={inputRef}
        onKeyDown={handlePressEnter}
        placeholder={placeholder}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon
                onClick={handleClickMagnifier}
                name="magnifier"
                size={24}
                style={{ cursor: 'text' }}
              />
            </InputAdornment>
          ),
          endAdornment: showCloseButton ? (
            <InputAdornment position="end">
              <Icon onClick={handleClickClose} name="close" size={24} />
            </InputAdornment>
          ) : null,
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        disableRipple
        onClick={handleSearch}
      >
        Buscar
      </Button>
    </Stack>
  );
}
