import { Button } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Icon } from '@hapvida/hapvida-core-components';

import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

import { ProfileCard } from './components';
import { ProfileCardProps } from './types';
import { QUERIES } from './constants';
import { generateRandomId } from './utils';

export function ProfileCardList() {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      QUERIES.forEach(queryKey => {
        queryClient.removeQueries({
          queryKey: [queryKey],
        });
      });
    };
  }, [queryClient]);

  const {
    selectedProfiles,
    availableProfilesState,
    removeSelectedProfile,
    hasError,
  } = useAccessManagement();

  const getInitialProfiles = () => {
    return selectedProfiles.map(profile => ({
      id: generateRandomId(),
      profile,
    }));
  };

  const [profileCards, setProfileCards] =
    useState<ProfileCardProps[]>(getInitialProfiles);

  const availableProfiles = availableProfilesState?.data ?? [];

  const handleAddProfile = useCallback(() => {
    setProfileCards(prevState => {
      return [
        ...prevState,
        {
          id: generateRandomId(),
          profile: undefined,
        },
      ];
    });
  }, [setProfileCards]);

  const handleRemoveProfile = useCallback(
    (profileCardId: string, profileId?: string) => {
      if (profileId) {
        removeSelectedProfile(profileId);
      }

      setProfileCards(prevState => {
        return prevState.filter(
          profileCard => profileCard.id !== profileCardId,
        );
      });
    },
    [setProfileCards, removeSelectedProfile, profileCards, selectedProfiles],
  );

  const maxProfilesLength = availableProfiles.length;
  const currentProfilesLength = profileCards.length;

  const showAddProfileButton = useMemo(() => {
    return currentProfilesLength < maxProfilesLength && !hasError;
  }, [currentProfilesLength, maxProfilesLength, hasError]);

  return (
    <>
      {profileCards.map(profileCard => (
        <ProfileCard
          key={profileCard.id}
          profileCardId={profileCard.id}
          profile={profileCard.profile}
          handleRemoveProfile={handleRemoveProfile}
        />
      ))}
      {showAddProfileButton && (
        <Button
          onClick={handleAddProfile}
          variant="contained"
          fullWidth
          color="secondary"
        >
          Adicionar perfil
          <Icon name="plus" size={14} />
        </Button>
      )}
    </>
  );
}
