import { Paper, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const Container = styled(Paper)`
  background-color: ${COLORS.MONOCHROMATIC.GRAY4};
  flex-direction: column;
  max-width: min-content;
  padding: 40px 80px;
  position: sticky;
  display: flex;
  gap: 112px;
  flex: 1;
  top: 0;
`;
