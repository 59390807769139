import React, { useMemo, useState } from 'react';
import { Grid, Stack, Toolbar } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  PageTitleContainer,
  MobileDivider,
  MenuButton,
  AppBar,
} from './styles';

import UserMenu from '../UserMenu';
import Icon from '../Icon';
import Modal from '../Modal';
import Typography from '../Typography';

export type RouteOverride = {
  route: PageHeaderProps['route'];
  subRoute?: PageHeaderProps['subRoute'];
};

export const isRouteOverride = (value: unknown): value is RouteOverride => {
  return typeof value === 'object' && value !== null && 'route' in value;
};

export interface HeaderRouteProps {
  navigateBackPath?: string;
  icon?: React.JSX.Element;
  name?: string;
}

interface PageHeaderProps {
  route?: HeaderRouteProps;
  subRoute?: string | null;
  user?: {
    profile: string;
    name: string;
  };
  subHeader?: React.JSX.Element;
  drawerOpen?: boolean;
  toggleDrawer?: () => void;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
  drawerOpenWidth?: number;
  drawerCloseWidth?: number;
  onLogOut?(): void;
  totalQuotationsAndCompany?: number;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  route: defaultRoute,
  subRoute: defaultSubRoute,
  user,
  subHeader,
  drawerOpen,
  toggleDrawer,
  innerRef,
  drawerOpenWidth,
  drawerCloseWidth,
  onLogOut,
  totalQuotationsAndCompany,
}) => {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const handleLogoutConfirmation = () =>
    setShowLogoutConfirmation(!showLogoutConfirmation);

  const navigate = useNavigate();

  const { state } = useLocation();
  const route = useMemo(
    () => (isRouteOverride(state) ? state.route : defaultRoute),
    [defaultRoute, state],
  );
  const subRoute = useMemo(
    () => (isRouteOverride(state) ? state.subRoute : defaultSubRoute),
    [defaultSubRoute, state],
  );
  return (
    <>
      <Modal
        icon="alert"
        secondaryButtonText="Fechar"
        mainButtonText="Sair mesmo assim"
        title="Atenção! As informações não finalizadas poderão ser perdidas."
        mainAction={onLogOut}
        open={showLogoutConfirmation}
        handleCloseModal={handleLogoutConfirmation}
      >
        <Typography variant="body1" fontSize={14}>
          Tem certeza que desejar sair?
        </Typography>
        <Typography variant="body1" fontSize={14}>
          Ao sair, informações não finalizadas poderão ser perdidas.
        </Typography>
      </Modal>

      <AppBar
        innerRef={innerRef}
        position="sticky"
        open={drawerOpen}
        drawerOpenWidth={drawerOpenWidth}
        drawerCloseWidth={drawerCloseWidth}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={8} md={6} minHeight={64}>
              {route && (
                <PageTitleContainer
                  gap={0.5}
                  direction="row"
                  alignItems="center"
                >
                  <Stack
                    style={
                      route.navigateBackPath ? { cursor: 'pointer' } : undefined
                    }
                    onClick={
                      route.navigateBackPath
                        ? () => navigate(`${route.navigateBackPath}`)
                        : undefined
                    }
                    alignItems="center"
                    direction="row"
                    gap={0.5}
                  >
                    <Icon
                      name={
                        typeof route.icon === 'string'
                          ? route.icon
                          : route?.icon?.props?.name
                      }
                    />
                    {subRoute && (
                      <Typography variant="body1">{subRoute}</Typography>
                    )}
                  </Stack>
                  {subRoute && <Icon name="chevron-right" size={16} />}
                  <Typography variant="subtitle1">{route.name}</Typography>
                </PageTitleContainer>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              sm={4}
              alignItems="center"
              minHeight={64}
              justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
              order={{ xs: -1, sm: 0 }}
            >
              <Grid item display={{ sm: 'none' }}>
                <MenuButton
                  variant="text"
                  startIcon={<Icon name="drawer" />}
                  onClick={toggleDrawer}
                >
                  Menu
                </MenuButton>
              </Grid>
              <Grid item>
                <UserMenu
                  username={user?.name}
                  role={user?.profile}
                  url=""
                  onLogOut={handleLogoutConfirmation}
                />
              </Grid>
              <MobileDivider />
            </Grid>
            {subHeader}
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PageHeader;
