import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import {
  ProfileProps,
  UserPartnerCompanyProps,
  UserService,
} from '@services/UserService';

import { UpdateProfileSelectionProps } from 'components/ProfileSelectionCard/types';
import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';

import {
  AccessManagementContextData,
  AccessManagementProviderProps,
} from './types';
import { getEditPartnerCompanyList, handleChangeProfile } from './utils';

const AccessManagementContext = createContext<AccessManagementContextData>(
  {} as AccessManagementContextData,
);

function AccessManagementProvider({
  children,
  initialSelectedProfiles = [],
  userId,
}: PropsWithChildren<AccessManagementProviderProps>) {
  const { selectedPartnerCompany } = useAuth();

  const [hasError, setHasError] = useState(false);

  const [selectedProfiles, setSelectedProfiles] = useState<ProfileProps[]>(
    initialSelectedProfiles,
  );

  /** Lista com todos os contratos originais que retornam do backend */
  const [originalPartnerCompanies, setOriginalPartnerCompanies] = useState<
    UserPartnerCompanyProps[]
  >([]);

  /** Lista com todos os contratos editados */
  const [editedPartnerCompanies, setEditedPartnerCompanies] = useState<
    UserPartnerCompanyProps[]
  >([]);

  /** Lista completa dos contratos selecionados */
  const selectedPartnerCompanies = editedPartnerCompanies.filter(
    item => item.hasAccess,
  );

  /** Função responsável por inserir ou atualizar contratos editados na lista de editedPartnerCompanies */
  const editPartnerCompanyList = (
    newPartnerCompany: UserPartnerCompanyProps,
  ) => {
    const mergedPartnerCompanies = getEditPartnerCompanyList({
      newPartnerCompany,
      editedPartnerCompanies,
    });
    setEditedPartnerCompanies(mergedPartnerCompanies);
  };

  const updateProfileSelection = ({
    prevSelectedProfile,
    newSelectedProfile,
  }: UpdateProfileSelectionProps) => {
    if (prevSelectedProfile) {
      const updatedPartnerCompanies = handleChangeProfile(
        prevSelectedProfile,
        newSelectedProfile,
        editedPartnerCompanies,
      );
      setEditedPartnerCompanies(updatedPartnerCompanies);
    }

    setSelectedProfiles(profiles => [
      ...profiles.filter(p => p.id !== prevSelectedProfile?.id),
      newSelectedProfile,
    ]);
  };

  const removeSelectedProfile = (profileId: string) => {
    setSelectedProfiles(profiles =>
      profiles.filter(profile => profile.id !== profileId),
    );

    setEditedPartnerCompanies(partnerCompanies =>
      partnerCompanies.map(partnerCompany =>
        partnerCompany.profile?.id === profileId
          ? {
              ...partnerCompany,
              hasAccess: false,
            }
          : partnerCompany,
      ),
    );
  };

  const userService = useMemo(() => new UserService(), []);
  const { enqueueSnackbar } = useSnackbar();

  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';
  const availableProfilesState = useQuery<ProfileProps[]>({
    queryKey: ['availableProfiles', partnerCompany],
    queryFn: () => userService.getProfilesHierarchy(),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const valueContextProvider = useMemo(
    () => ({
      editPartnerCompanyList,
      updateProfileSelection,
      selectedProfiles,
      selectedPartnerCompanies,
      availableProfilesState,
      removeSelectedProfile,
      currentUserId: userId,
      editedPartnerCompanies,
      setEditedPartnerCompanies,
      originalPartnerCompanies,
      setOriginalPartnerCompanies,
      hasError,
      setHasError,
    }),
    [
      editPartnerCompanyList,
      updateProfileSelection,
      selectedProfiles,
      selectedPartnerCompanies,
      availableProfilesState,
      removeSelectedProfile,
      userId,
      editedPartnerCompanies,
      setEditedPartnerCompanies,
      originalPartnerCompanies,
      setOriginalPartnerCompanies,
      hasError,
      setHasError,
    ],
  );

  return (
    <AccessManagementContext.Provider value={valueContextProvider}>
      {children}
    </AccessManagementContext.Provider>
  );
}

function useAccessManagement() {
  const context = useContext(AccessManagementContext);

  if (!context) {
    throw new Error(
      'useAccessManagement must be used within AccessManagementProvider',
    );
  }

  return context;
}

export { useAccessManagement, AccessManagementProvider };
