import { useCallback, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import { CompanyProps } from '@services/AuthService/dtos';

import BuildingSvg from '@assets/images/building.svg';
import { useNewCompanyModal } from '@flows/private/home/hooks';

import { Circle } from './styles';

interface NewCompanyModalProps {
  companies: CompanyProps[];
}

export function NewCompanyModal({ companies }: NewCompanyModalProps) {
  const { markAsSeen, modalHasBeenSeen } = useNewCompanyModal();

  const newCompanies = companies?.filter(company => company.isNewCompany) ?? [];

  const showModal = !modalHasBeenSeen && newCompanies.length > 0;
  const [isModalOpen, setIsModalOpen] = useState(showModal);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  const isMultipleCompanies = newCompanies.length > 1;

  const title = useMemo(() => {
    if (isMultipleCompanies) return 'Novas empresas disponíveis!';
    return 'Nova empresa disponível!';
  }, [isMultipleCompanies]);

  const description = useMemo(() => {
    if (isMultipleCompanies) {
      return (
        <Typography variant="body1" fontSize={14}>
          <strong>Novas empresas</strong> foram disponibilizadas para seu
          usuário. Para gerenciá-las, basta selecionar através da
          <strong> lista de empresas.</strong>
        </Typography>
      );
    }
    return (
      <Typography variant="body1" fontSize={14}>
        <strong>Uma nova empresa</strong> foi disponibilizada para seu usuário.
        Para gerenciá-la, basta selecionar através da
        <strong> lista de empresas.</strong>
      </Typography>
    );
  }, [isMultipleCompanies]);

  const handleCloseModal = useCallback(() => {
    toggleModal();
    markAsSeen();
  }, [toggleModal, markAsSeen]);

  return (
    <Modal
      handleCloseModal={handleCloseModal}
      mainAction={handleCloseModal}
      mainButtonText="Fechar"
      open={isModalOpen}
      title={title}
      icon="alert"
    >
      <Stack gap={3}>
        {description}
        <Stack gap={3}>
          {newCompanies.map(company => (
            <Stack direction="row" gap={1} alignItems="center" key={company.id}>
              <Circle />
              <img width={32} height={32} src={BuildingSvg} alt="new-company" />
              <Typography
                id="dialog-title"
                variant="subtitle1"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                maxWidth="400px"
                overflow="hidden"
              >
                {company?.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
}
