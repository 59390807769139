import {
  FiltersConfigProps,
  FiltersLabelProps,
  FilterKeyEnum,
  Gender,
} from '@hapvida/hapvida-core-components';

import { HEALTH_OPERATOR_NAME, HealthOperatorEnum } from '@constants';

export const financialFilterValues: FiltersConfigProps = {
  [FilterKeyEnum.HEALTH_OPERATOR]: Object.values(HealthOperatorEnum).map(
    healthOperator => {
      return {
        label: HEALTH_OPERATOR_NAME[healthOperator],
        value: healthOperator,
      };
    },
  ),
};

export const financialFilterLabels: FiltersLabelProps = {
  [FilterKeyEnum.HEALTH_OPERATOR]: {
    gender: Gender.Feminine,
    singular: 'operadora',
    plural: 'operadoras',
  },
};
