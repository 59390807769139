import { SelectionCardProps } from '@flows/others/selection/components/SelectionCard';
import SpecialProjectsIcon from '@assets/images/special-projects-icon.svg';
import AdministratorIcon from '@assets/images/administrator-icon.svg';
import { SALES_CHANNEL_NAME, SalesChannelEnum } from '@constants';
import ConventionIcon from '@assets/images/convention-icon.svg';
import BiddingIcon from '@assets/images/bidding-icon.svg';
import CorporateIcon from '@assets/images/corporate.svg';

export const salesChannelCard: Record<SalesChannelEnum, SelectionCardProps> = {
  [SalesChannelEnum.CORPORATE]: {
    title: SALES_CHANNEL_NAME[SalesChannelEnum.CORPORATE],
    subtitle:
      'Serviços de representação em contratos para mais de 3.000 beneficiários, com foco em estruturas de matriz e coligadas.',
    imageProps: { src: CorporateIcon, alt: 'Corporate' },
  },
  [SalesChannelEnum.ADMINISTRATOR]: {
    title: SALES_CHANNEL_NAME[SalesChannelEnum.ADMINISTRATOR],
    subtitle:
      'Gestão de contratos para empresas de grande porte, otimizando processos e assegurando eficiência.',
    imageProps: { src: AdministratorIcon, alt: 'Administrator' },
  },
  [SalesChannelEnum.BIDDING]: {
    title: SALES_CHANNEL_NAME[SalesChannelEnum.BIDDING],
    subtitle:
      'Serviços de representação em contratos para mais de 3.000 beneficiários, com foco em estruturas de matriz e coligadas.',
    imageProps: { src: BiddingIcon, alt: 'Bidding' },
  },
  [SalesChannelEnum.COLLECTIVE_AGREEMENT]: {
    title: SALES_CHANNEL_NAME[SalesChannelEnum.COLLECTIVE_AGREEMENT],
    subtitle:
      'Serviços de representação em contratos para mais de 3.000 beneficiários, com foco em estruturas de matriz e coligadas.',
    imageProps: { src: ConventionIcon, alt: 'Convention' },
  },
  [SalesChannelEnum.SPECIAL_PROJECTS]: {
    title: SALES_CHANNEL_NAME[SalesChannelEnum.SPECIAL_PROJECTS],
    subtitle:
      'Serviços de representação em contratos para mais de 3.000 beneficiários, com foco em estruturas de matriz e coligadas.',
    imageProps: { src: SpecialProjectsIcon, alt: 'Special Projects' },
  },
};
