import { ButtonBase, Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const ButtonContainer = styled(ButtonBase)`
  border: 1px solid ${COLORS.MONOCHROMATIC.GRAY2};
  color: ${COLORS.PRIMARY.ORANGE['-2']};
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 24px;
  gap: 16px;
  flex: 1;

  &:hover {
    border-color: ${COLORS.PRIMARY.ORANGE.MAIN};
  }
`;

export const IconContainer = styled(Stack)`
  background-color: rgba(244, 159, 0, 0.175);
  border-radius: 8px;
  padding: 8px;
`;
