import { useInfiniteQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { PartnerCompanyContentProps } from '@services/PartnerCompanyService/dtos';
import { PartnerCompanyService } from '@services/PartnerCompanyService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

import { UseRelatedPartnerCompaniesProps } from './types';

const mappedFilters = { 'contract-type': 'Child' };

export function useRelatedPartnerCompanies({
  mainPartnerCompany,
}: Readonly<UseRelatedPartnerCompaniesProps>) {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();

  const partnerCompanyService = useMemo(() => new PartnerCompanyService(), []);

  const companyId = selectedCompany?.id ?? '';

  const searchParam = useMemo(() => {
    return { search: mainPartnerCompany };
  }, [mainPartnerCompany]);

  const relatedPartnerCompanyListState =
    useInfiniteQuery<PartnerCompanyContentProps>({
      queryKey: ['relatedPartnerCompanies', companyId, mainPartnerCompany],
      queryFn: ({ pageParam = 1 }) =>
        partnerCompanyService.getPartnerCompany({
          ...mappedFilters,
          ...searchParam,
          companyId,
          offset: ITEMS_LIMIT_PER_PAGE * (pageParam - 1),
          limit: ITEMS_LIMIT_PER_PAGE,
        }),
      getNextPageParam: (lastPage, allPages) => {
        const companiesHasNextPage =
          lastPage.total > allPages.length * ITEMS_LIMIT_PER_PAGE;

        if (companiesHasNextPage) {
          return allPages.length + 1;
        }

        return undefined;
      },
      onError: (error: unknown) => {
        toastifyApiErrors(error, enqueueSnackbar);
      },
      enabled: Boolean(companyId) && Boolean(mainPartnerCompany),
    });

  return {
    relatedPartnerCompanyListState,
  };
}
