import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import CompanyService from '@services/CompanyService';
import { CompaniesListProps } from '@services/CompanyService/dtos';

import { useFilterParams, useAuth } from '@hooks';
import { toastifyApiErrors } from '@utils';

export function useCompaniesListState() {
  const { currentPage, searchParam } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  const companyService = useMemo(() => new CompanyService(), []);

  const companyListState = useQuery<CompaniesListProps>({
    queryKey: [
      'companyListState',
      searchParam,
      currentPage,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      companyService.fetchCompaniesList({
        id: companyId,
        ...searchParam,
        offset: 10 * (currentPage - 1),
        limit: 10,
      }),
    enabled: isEnabled,
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshCompanyListStates = () => {
    companyListState.remove();

    companyListState.refetch();
  };

  return { companyListState, refreshCompanyListStates };
}
