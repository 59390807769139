import { Button, Divider, Stack } from '@mui/material';

import { ModalNew } from '@hapvida/hapvida-core-components';

import { useRelatedPartnerCompanies } from './hooks';
import { RelatedPartnerCompaniesModalLayout } from './layout';
import { RelatedPartnerCompaniesModalProps } from './types';

export function RelatedPartnerCompaniesModal({
  mainPartnerCompany,
  open,
  toggleModal,
}: Readonly<RelatedPartnerCompaniesModalProps>) {
  const { relatedPartnerCompanyListState } = useRelatedPartnerCompanies({
    mainPartnerCompany,
  });

  const handleCloseModal = () => {
    toggleModal();
  };

  return (
    <ModalNew.Container open={open} handleCloseModal={handleCloseModal}>
      <Stack gap={2} padding={3}>
        <RelatedPartnerCompaniesModalLayout
          mainPartnerCompany={mainPartnerCompany}
          relatedPartnerCompanyListState={relatedPartnerCompanyListState}
        />
      </Stack>
      <Divider />
      <Stack padding={2}>
        <Stack alignSelf="flex-end">
          <Button
            onClick={handleCloseModal}
            type="button"
            color="primary"
            variant="outlined"
          >
            Fechar
          </Button>
        </Stack>
      </Stack>
    </ModalNew.Container>
  );
}
