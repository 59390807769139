import { ProfileProps, UserPartnerCompanyProps } from '@services/UserService';

import { UserProfileEnum } from '@constants';

export function handleChangeProfile(
  prevSelectedProfile: ProfileProps,
  newSelectedProfile: ProfileProps,
  editedPartnerCompanies: UserPartnerCompanyProps[],
) {
  const isNewProfileMaster = newSelectedProfile.name === UserProfileEnum.MASTER;
  const isPrevProfileMaster =
    prevSelectedProfile.name === UserProfileEnum.MASTER;

  const updatedPartnerCompanies = editedPartnerCompanies.map(partnerCompany => {
    if (partnerCompany.profile?.id !== prevSelectedProfile.id) {
      return partnerCompany;
    }

    const { isMainPartnerCompany, hasAccess } = partnerCompany;

    if (isNewProfileMaster) {
      return {
        ...partnerCompany,
        hasAccess: isMainPartnerCompany ? hasAccess : false,
        profile: isMainPartnerCompany ? newSelectedProfile : undefined,
      };
    }

    return { ...partnerCompany, profile: newSelectedProfile };
  });

  if (isNewProfileMaster) {
    return updatedPartnerCompanies.map(partnerCompany => {
      const mainPartnerCompanyAsMaster = updatedPartnerCompanies.find(
        item =>
          item.partnerCompany === partnerCompany.mainPartnerCompany &&
          item.hasAccess &&
          item.profile?.name === UserProfileEnum.MASTER,
      );

      const { isMainPartnerCompany, hasAccess } = partnerCompany;

      if (!mainPartnerCompanyAsMaster?.hasAccess) {
        return partnerCompany;
      }

      return {
        ...partnerCompany,
        hasAccess: isMainPartnerCompany ? hasAccess : true,
        profile: newSelectedProfile,
      };
    });
  }

  if (isPrevProfileMaster) {
    return updatedPartnerCompanies.map(partnerCompany => {
      if (partnerCompany.isMainPartnerCompany) {
        return partnerCompany;
      }

      const hadMainPartnerCompanyAsMaster = editedPartnerCompanies.some(
        item =>
          item.partnerCompany === partnerCompany.mainPartnerCompany &&
          item.hasAccess &&
          item.profile?.name === UserProfileEnum.MASTER,
      );

      if (!hadMainPartnerCompanyAsMaster) {
        return partnerCompany;
      }

      return {
        ...partnerCompany,
        hasAccess: false,
        profile: undefined,
      };
    });
  }

  return updatedPartnerCompanies;
}
