import { IconButton, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const EditButton = styled(IconButton)(() => ({
  '&.MuiIconButton-root': {
    border: `1px solid ${COLORS.PRIMARY.BLUE.MAIN}`,
    width: '20px',
    height: '20px',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
