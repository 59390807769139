import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';
import chroma from 'chroma-js';

import { COLORS } from '../colors';
const MuiAvatarTheme: {
  defaultProps?: ComponentsProps['MuiAvatar'];
  styleOverrides?: ComponentsOverrides['MuiAvatar'];
  variants?: ComponentsVariants['MuiAvatar'];
} = {
  variants: [],
  styleOverrides: {
    root: {
      backgroundColor: chroma(COLORS.PRIMARY.BLUE.MAIN).alpha(0.08).hex(),
      fontSize: '16px',
      color: COLORS.PRIMARY.BLUE.MAIN,
      fontWeight: 900,
      fontFamily: 'Source Sans Pro',
      position: 'inherit',
    },
  },
};

export default MuiAvatarTheme;
