import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { NavigationCard } from '@hapvida/hapvida-core-components';

import { usePrompt } from '@hooks';
import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';

import {
  MOVIMENTATION_STEPS_KEYS,
  MOVIMENTATION_STEPS_VALUES,
} from '../../constants/keys';
import {
  CardWrapper,
  ContainerWrapper,
  TextContent,
  TextTitle,
} from './styles';
import ImagesCardsNavigation from './assets';
import { MovementRedirectModal } from './components';

type StartMovimentationStepProps = {
  onStepUpdate(step: number, data: any): void;
};

export function StartMovimentationLayout({
  onStepUpdate,
}: StartMovimentationStepProps) {
  usePrompt();
  const navigate = useNavigate();

  const [currentModalState, setCurrentModalState] = useState<boolean>(false);

  const toggleModal = () => {
    setCurrentModalState(!currentModalState);
  };

  const onSubmit = () => {
    onStepUpdate(
      MOVIMENTATION_STEPS_VALUES[MOVIMENTATION_STEPS_KEYS.NEW_MOVIMENTATION],
      {},
    );
  };

  const handleBackMovementList = () => {
    navigate(MovementListRouter.path);
  };

  return (
    <ContainerWrapper>
      <Typography variant="h5">Selecione o tipo de movimentação</Typography>
      <CardWrapper>
        <NavigationCard
          enableImage={ImagesCardsNavigation.LayoutImage}
          enableImage2x={ImagesCardsNavigation.LayoutImage2x}
          disabledImage={ImagesCardsNavigation.DisabledLayoutImage}
          disabledImage2x={ImagesCardsNavigation.DisabledLayoutImage2x}
          fullWidth
          messageOne={
            <TextTitle variant="subtitle1" fontWeight={400}>
              <b>Movimentação via arquivo</b> <i>(layout)</i>
            </TextTitle>
          }
          messageTwo={
            <TextContent variant="subtitle1" fontWeight={400} textAlign="left">
              Vou fazer uma movimentação que <b>inclui um ou mais</b>{' '}
              beneficiários de inclusão, exclusão, alteração e/ou transferência.
            </TextContent>
          }
          handlerAction={() => onSubmit()}
        />
        <NavigationCard
          enableImage={ImagesCardsNavigation.IndividualImage}
          enableImage2x={ImagesCardsNavigation.IndividualImage2x}
          disabledImage={ImagesCardsNavigation.DisabledIndividualImage}
          disabledImage2x={ImagesCardsNavigation.DisabledIndividualImage2x}
          fullWidth
          messageOne={
            <TextTitle variant="subtitle1" fontWeight={400}>
              <b>Movimentação via tela</b> <i>(digitação)</i>
            </TextTitle>
          }
          messageTwo={
            <TextContent variant="subtitle1" fontWeight={400}>
              Vou fazer uma movimentação de beneficiário manualmente.
            </TextContent>
          }
          handlerAction={() => setCurrentModalState(true)}
        />
      </CardWrapper>

      <Grid item xs={12} container justifyContent="flex-start" mt={1}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBackMovementList}
        >
          Voltar
        </Button>
      </Grid>

      <MovementRedirectModal
        modalState={currentModalState}
        toggleModal={toggleModal}
      />
    </ContainerWrapper>
  );
}
