import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  SummaryCardNew,
  Collapse,
  Mask,
} from '@hapvida/hapvida-core-components';

import { DependentProps } from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import DownloadOutlined from '@assets/images/download-outlined.svg';
import UnavailableOutlined from '@assets/images/unavailable-outlined.svg';
import { BeneficiaryType } from '@flows/private/beneficiaries/components';

import {
  StayLetterListColumnNameEnum,
  stayLetterListTableHeadColumns,
} from '../../constants';
import { LetterLabel } from './styles';

interface LetterOfStayListCardProps {
  beneficiary: DependentProps;
  stayLetterFile: string;
}

export function LetterOfStayListCard({
  beneficiary,
  stayLetterFile,
}: Readonly<LetterOfStayListCardProps>) {
  const { id, code, user } = beneficiary;
  const { enqueueSnackbar } = useSnackbar();

  async function downloadPdf(data: string) {
    try {
      const blob = new Blob([data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Carta_de_permanencia-${code}.pdf`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  }

  const stayLetterListTableRows = [
    {
      id,
      main: {
        [StayLetterListColumnNameEnum.WALLET_NUMBER]: (
          <Typography variant="text" fontWeight={700}>
            {code}
          </Typography>
        ),
        [StayLetterListColumnNameEnum.FULL_NAME]: (
          <Typography variant="text" fontWeight={700}>
            {user.name}
          </Typography>
        ),
        [StayLetterListColumnNameEnum.CPF]: (
          <Typography variant="text" fontWeight={700}>
            {Mask.cpf(user.document) ?? '-'}
          </Typography>
        ),
        [StayLetterListColumnNameEnum.TYPE]: (
          <BeneficiaryType data={{ beneficiary }} />
        ),
        [StayLetterListColumnNameEnum.STAY_LETTER]: (
          <LetterLabel
            fontSize={14}
            {...(stayLetterFile && {
              onClick: () => downloadPdf(stayLetterFile),
              stayLetterFile,
            })}
          >
            <img
              src={stayLetterFile ? DownloadOutlined : UnavailableOutlined}
              alt="info"
              width={20}
              height={20}
            />

            {stayLetterFile ? 'Baixar' : 'Não disponível'}
          </LetterLabel>
        ),
      },
      dependents: [],
    },
  ];

  const total = 1;
  const totalPerPage = 1;

  return (
    <SummaryCardNew.Container>
      <Collapse
        TableHeadColumns={stayLetterListTableHeadColumns}
        data={stayLetterListTableRows}
        baseTableProps={{
          tableContainerHeight: '100%',
          style: {
            padding: 0,
          },
        }}
        width="auto"
        from={total}
        to={totalPerPage}
      />
    </SummaryCardNew.Container>
  );
}
