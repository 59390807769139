enum BaseProfileResourceEnum {
  MOVEMENT = 'movement',
  BENEFICIARIES = 'beneficiaries',
  FINANCIAL = 'financial',
  USERS = 'users',
  COMPANIES = 'companies',
  MORE_SERVICES = 'moreServices',
}

enum ExtraProfileResourceEnum {
  HOME = 'home',
  DEPRECATED = 'deprecated',
}

type ProfileResourceType = BaseProfileResourceEnum | ExtraProfileResourceEnum;

export {
  ProfileResourceType,
  BaseProfileResourceEnum,
  ExtraProfileResourceEnum,
};
