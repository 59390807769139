import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { CompanySetupListPage } from '.';

export const CompanySetupListRouter: IAppRoute = {
  Page: CompanySetupListPage,
  path: '/empresas',
  name: 'Empresas',
  isPrivate: true,
  Icon: <Icon name="building" />,
  disabled: false,
};
