import { Box, styled } from '@mui/material';

import { COLORS } from '../../../../themes/colors';

export const SelectAllContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  borderTop: `2px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  paddingLeft: '4px',

  '& .MuiTypography-root': {
    fontWeight: 700,
    fontSize: '14px',
    color: COLORS.BLACK,
  },
}));
