import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';

import {
  ErrorLoadingPage,
  FilterSummary,
  Loading,
  NoSearchResults,
  useDrawerFilter,
} from '@hapvida/hapvida-core-components';

import { useAuth, useFilterParams } from '@hooks';
import { EmptyResult } from '@components';

import { PartnerCompanyList } from './components';
import { usePartnerCompanyListState, useSelectPartnerCompany } from './hooks';
import { partnerCompanyDrawerFilterValues } from './constants';

export function PartnerCompanyListLayout() {
  const { handleSelectPartnerCompany } = useSelectPartnerCompany();
  const { hasFilters } = useFilterParams();
  const { partnerCompanyListState, refreshPartnerCompanyStates } =
    usePartnerCompanyListState();
  const { user } = useAuth();

  const userName = user?.name ?? '';
  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const initialRender = useRef(true);

  const { isLoading, data, isError } = partnerCompanyListState;

  useEffect(() => {
    handleSelectPartnerCompany();
    if (initialRender.current) {
      initialRender.current = false;
      const initialUsersFilters = getInitialFilter({
        filtersConfig: partnerCompanyDrawerFilterValues,
      });

      setActiveFilters(initialUsersFilters);
    }
  }, []);

  if (isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você.
          </>
        }
      />
    );
  }

  const totalPartnerCompanyListLength = data?.total ?? 0;
  const isEmptyPartnerCompanies =
    totalPartnerCompanyListLength === 0 && !hasFilters;

  if (isError) {
    return <ErrorLoadingPage onReloadClick={refreshPartnerCompanyStates} />;
  }

  if (isEmptyPartnerCompanies) {
    return (
      <EmptyResult
        message={`${userName}, nenhum contrato está disponível para você.`}
      />
    );
  }

  const isSearchResultEmpty = totalPartnerCompanyListLength === 0 && hasFilters;

  const filterSummaryLabel = `${totalPartnerCompanyListLength} ${
    totalPartnerCompanyListLength === 1 ? 'contrato' : 'contratos'
  }`;

  return (
    <Stack minHeight="100%" gap={3}>
      <Stack py={3} flex={1}>
        <FilterSummary totalLabel={filterSummaryLabel} alwaysShowTitle />

        {isSearchResultEmpty && <NoSearchResults padding={5} />}
        {!isSearchResultEmpty && (
          <PartnerCompanyList partnerCompanyList={data} />
        )}
      </Stack>
    </Stack>
  );
}
