import { Grid } from '@mui/material';
import { Control, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { TextInput } from '@hapvida/hapvida-core-components';

import { AddAccountFormProps } from '@services/UserService';

interface FormValidatorUserProps {
  controlForm: Control<AddAccountFormProps>;
  errorsForm: any;
  watchForm: UseFormWatch<AddAccountFormProps>;
  triggerForm?: UseFormTrigger<AddAccountFormProps>;
}

export const FormValidatorUser = ({
  controlForm,
  errorsForm,
  watchForm,
  triggerForm,
}: FormValidatorUserProps) => {
  const watchEmail = watchForm('email');
  const watchConfirmedEmail = watchForm('confirmedEmail');

  const errorEmail =
    watchEmail?.length > 0 && Boolean(errorsForm?.email?.message);
  const errorConfirmedEmail =
    watchConfirmedEmail?.length > 0 &&
    Boolean(errorsForm?.confirmedEmail?.message);

  const errorEmailMsg = errorEmail ? errorsForm?.email?.message : '';
  const errorConfirmedEmailMsg = errorConfirmedEmail
    ? errorsForm?.confirmedEmail?.message
    : '';

  return (
    <>
      <Grid item xs={12}>
        <TextInput
          control={controlForm}
          name="email"
          fullWidth
          label="E-mail"
          type="email"
          error={errorEmail}
          errorMessage={errorEmailMsg}
          required
          placeholder="Digite"
          onChangeElementInput={() => {
            triggerForm?.('confirmedEmail');
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          control={controlForm}
          name="confirmedEmail"
          fullWidth
          label="Confirmar e-mail"
          type="email"
          required
          placeholder="Digite"
          error={errorConfirmedEmail}
          errorMessage={errorConfirmedEmailMsg}
        />
      </Grid>
    </>
  );
};
