const beneficiaryMock = {
  content: {
    id: 'IDB1',
    dependentsAffiliated: [
      {
        id: 'ID111',
        itHasError: false,
        errorMessage: '',
        status: 'Pendente',
        cnpj: '00000000000002',
        beneficiaryCode: '453265158133542...',
        name: 'Ciclano da Silva 2',
        cpf: '12345678901',
        socialGender: 'Masculino',
        type: 'Dependente',
        company: 'NDI',
        plan: '5252 - Nosso Plano ...',
        contractNumber: '12345678901',
        state: '',
      },
      {
        id: 'ID112',
        itHasError: false,
        errorMessage: '',
        status: 'Pendente',
        cnpj: '00000000000003',
        beneficiaryCode: '453265158133542...',
        name: 'Ciclano da Silva 2',
        cpf: '12345678901',
        socialGender: 'Masculino',
        type: 'Agregado',
        company: 'NDI',
        plan: '5252 - Nosso Plano ...',
        contractNumber: '12345678901',
        state: 'SP',
      },
    ],
    summary: {
      name: 'José da Silva',
      type: 'Titular',
      plan: '5252 - Nosso Plano ...',
      cpf: '12345678901',
      socialGender: 'Homem',
      birthDate: '2022-06-10T10:36:38.666',
      civilStatus: 'Solteiro',
      liveBirthDeclaration: '???',
      nationality: 'Brasileiro',
    },
    registrationData: {
      expirationDay: '16',
      serviceMunicipality: '12345 - São José dos Campos',
      serviceState: '11 - SP',
      name: 'José da Silva',
      socialName: 'José da Silva',
      NameRegistrationBirthCertificate: 'José da Silva',
      socialGender: 'Homem',
      sexo: 'M',
      cpf: '12345678901',
      birthDate: '2022-06-10T10:36:38.666',
      motherName: 'Maria Aparecida da Silva',
      fatherName: 'Francisco da Silva Torres',
      type: 'Titular',
      titularBeneficiaryCode: '123723727395923824782283920',
      beneficiaryCode: '742834234919828312931983192',
      nationality: 'Brasileiro',
      liveBirthDeclaration: '???',
    },
    contacts: {
      email: 'email@email.com',
      sendingEmail: 'sim',
      cellPhone: '35999999999',
      sendingSms: 'sim',
      landline: '12345678901',
    },
    documents: {
      identityNumber: '12345678901',
      emitter: 'SSP',
      dateIssuanceIdentity: '2022-06-10T10:36:38.666',
      country: 'Brasil',
      pisPasepNit: '4234823848282920109',
      cns: '1823747178292',
    },
    professionalInformation: {
      registrationNumber: '6546216348987984765213546549498',
      sequentialRegistration: '1234567890',
      dateAdmissionBeneficiary: '2022-06-10T10:36:38.666',
      startDateLotationTitular: '2022-06-10T10:36:38.666',
      lotationCodeTitular: '12345678901',
      lotationNameTitular: 'Nome da Lotação',
      typePositionTitular: '123',
      typePositionTitularDescription: 'Descrição do tipo',
      codePositionTitular: '1234567890',
      namePositionTitular: 'Nome do Cargo',
      costCenterCodeTitular: '1234567890',
      costCenterNameTitular: 'Nome do centro de custo',
    },
    inactive: {
      inactiveBeneficiaryTitular: 'Aposentado',
      startDateInactivity: '2022-06-10T10:36:38.666',
      startDateContribution: '2022-06-10T10:36:38.666',
    },
    bankData: {
      bankCodeTitular: '123 - Banco do Brasil',
      agencyNumberTitular: '123456789000000',
      agencyDigitTitular: '12345',
      currentAccountNumberTitular: '123456789000000',
      currentAccountDigitTitular: '12345',
    },
    address: {
      zipcode: '37550000',
      street: 'Rua das Flores',
      number: '123',
      complement: 'Casa',
      neighborhood: 'Centro',
      city: 'São José dos Campos',
      state: 'SP',
    },
    additionalInformation: {
      dependencyCode: 'AB',
      weddingDate: '2022-06-10T10:36:38.666',
    },
    complementaryInformation: {
      beneficiaryHealthDeclaration: 'Não',
      weight: '75kg',
      height: '176cm',
      listOfCIDs: '101, 102, 103, 104',
    },
    criticism: [
      {
        id: 'IDC1',
        beneficiaryName: 'LUCAS TARIK S A EL MOUTAQI',
        detailCriticism:
          'MOVIMENTAÇÃO SIB // RECEBIDO DECLARAÇÃO DE SAÚDE E CARTA ANS',
      },
      {
        id: 'IDC2',
        beneficiaryName: 'Maria da Silva',
        detailCriticism:
          'MOVIMENTAÇÃO SIB // RECEBIDO DECLARAÇÃO DE SAÚDE E CARTA ANS',
      },
      {
        id: 'IDC3',
        beneficiaryName: 'Jose da Silva',
        detailCriticism:
          'MOVIMENTAÇÃO SIB // RECEBIDO DECLARAÇÃO DE SAÚDE E CARTA ANS',
      },
    ],
  },
};

export default beneficiaryMock;
