import { PulsarAnimationLoading } from '../../../PulsarAnimation';
import { COLORS } from '../../../../themes/colors';
import { FilterLoadingContainer } from './styles';

export function FilterLoading() {
  return (
    <FilterLoadingContainer>
      <PulsarAnimationLoading
        width="30px"
        height="30px"
        color={COLORS.PRIMARY.ORANGE.MAIN}
      />
    </FilterLoadingContainer>
  );
}
