import {
  FilterKeyEnum,
  FiltersConfigProps,
} from '@hapvida/hapvida-core-components';

import {
  BENEFICIARY_TYPE_NAME,
  BeneficiaryTypeEnum,
  HEALTH_OPERATOR_NAME,
  HealthOperatorEnum,
} from '@constants';

export const beneficiaryDrawerFilterValues: FiltersConfigProps = {
  [FilterKeyEnum.BENEFICIARIES_TYPE]: Object.values(BeneficiaryTypeEnum).map(
    (beneficiaryType: BeneficiaryTypeEnum) => {
      return {
        label: BENEFICIARY_TYPE_NAME[beneficiaryType],
        value: beneficiaryType,
      };
    },
  ),
  status: [
    { label: 'Ativo', value: 'Active' },
    { label: 'Registrado', value: 'Registered' },
    { label: 'Pendente', value: 'Pending' },
    { label: 'Suspenso', value: 'Suspended' },
    { label: 'Cancelado', value: 'Canceled' },
    { label: 'Nenhum', value: 'None' },
  ],
  review: [
    {
      label: 'Crítica',
      value: 'true',
    },
  ],
  [FilterKeyEnum.HEALTH_OPERATOR]: Object.values(HealthOperatorEnum).map(
    healthOperator => {
      return {
        label: HEALTH_OPERATOR_NAME[healthOperator],
        value: healthOperator,
      };
    },
  ),
};
