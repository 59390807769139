import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, Form } from '@hapvida/hapvida-core-components';

import { AddAccountFormProps } from '@services/UserService';

import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

import { schema } from './control/validation';
import {
  EmailValidator,
  FormRegisterUser,
  ModalNotification,
} from './components';
import { useCreateUser, useValidateUser } from './hooks';

export function AddAccountLayout() {
  const { handleAddAccount } = useCreateUser();
  const { editedPartnerCompanies, selectedProfiles } = useAccessManagement();
  const {
    showValidator,
    openModal,
    handleNext,
    handleBack,
    closeModal,
    goToEditUserData,
  } = useValidateUser();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { isValid, errors },
    reset,
  } = useForm<AddAccountFormProps>({
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      confirmedEmail: '',
      phone: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmitForm: SubmitHandler<AddAccountFormProps> = async data => {
    await handleAddAccount(data);
  };

  const hasPartnerCompanyWithAccess = editedPartnerCompanies.some(
    partnerCompany => partnerCompany.hasAccess,
  );

  const profileIsEmpty =
    editedPartnerCompanies.length === 0 ||
    selectedProfiles.length === 0 ||
    !hasPartnerCompanyWithAccess;

  const disableButton = !isValid || profileIsEmpty;

  const preventEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Form title="Novo usuário" subtitle="Campos com * são obrigatórios">
      <Typography variant="text" pt={2} pb={2}>
        Primeiro, informe o e-mail do novo usuário.
      </Typography>
      <Grid
        component={'form'}
        onKeyDown={preventEnter}
        container
        spacing={{ xs: 5, sm: 3 }}
      >
        {showValidator ? (
          <EmailValidator
            control={control}
            watch={watch}
            errors={errors}
            trigger={trigger}
            onNext={handleNext}
          />
        ) : (
          <FormRegisterUser
            controlForm={control}
            watchForm={watch}
            errorsForm={errors}
            triggerForm={trigger}
          />
        )}
        {!showValidator && (
          <Grid item xs={12} container justifyContent="space-between">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleBack(reset)}
            >
              Voltar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={disableButton}
              onClick={handleSubmit(onSubmitForm)}
            >
              Criar usuário
            </Button>
          </Grid>
        )}
      </Grid>
      <ModalNotification
        openModal={openModal}
        handleCloseModal={closeModal}
        goToEditUserData={goToEditUserData}
      />
    </Form>
  );
}
