import { Stack, Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { singularTreatmentShippingReviews } from '@flows/private/movements/utils';

import {
  CustomTableRowAlert,
  LinkRedirect,
  LoadValidationAlert,
} from '../TableRows/styles';
import type { TotalShippingReviewsProps } from './types';

export const TotalShippingReviews = ({
  handleOpenLoadValidation,
  id = '',
  totalShippingReviews = 0,
}: Readonly<TotalShippingReviewsProps>) => {
  if (!totalShippingReviews) {
    return null;
  }

  return (
    <CustomTableRowAlert>
      <LoadValidationAlert colSpan={6}>
        <Stack flexDirection="row" gap={2} alignItems="center">
          <Icon name="alert" size={24} color={COLORS.PRIMARY.BLUE['+1']} />
          <Typography variant="text" fontWeight={700}>
            {singularTreatmentShippingReviews(totalShippingReviews)}
            <LinkRedirect
              variant="text"
              onClick={() => handleOpenLoadValidation(id)}
            >
              mostrar detalhes
            </LinkRedirect>
          </Typography>
        </Stack>
      </LoadValidationAlert>
    </CustomTableRowAlert>
  );
};
