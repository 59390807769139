import { UserPartnerCompanyProps, ProfileProps } from '@services/UserService';

export const getProfiles = (
  filteredPartnerCompaniesWithAccess: UserPartnerCompanyProps[],
  mergedRemovedPartnerCompanies: UserPartnerCompanyProps[],
  availableProfiles: ProfileProps[],
) => {
  return availableProfiles
    .map(profile => {
      const thisIncludedPartnerCompanies = filteredPartnerCompaniesWithAccess
        .filter(item => item.profile?.id === profile.id)
        .map(item => item.partnerCompany);

      const thisExcludedPartnerCompanies = mergedRemovedPartnerCompanies
        .filter(item => item.profile?.id === profile.id)
        .map(item => item.partnerCompany);

      return {
        profileId: profile.id,
        partnerCompaniesIncluded: thisIncludedPartnerCompanies,
        partnerCompaniesExcluded: thisExcludedPartnerCompanies,
      };
    })
    .filter(
      item =>
        item.partnerCompaniesIncluded.length > 0 ||
        item.partnerCompaniesExcluded.length > 0,
    );
};
