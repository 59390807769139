import {
  FilterKeyEnum,
  DrawerFilter,
  Pill,
} from '@hapvida/hapvida-core-components';

import {
  BENEFICIARY_TYPE_COLOR,
  BENEFICIARY_TYPE_NAME,
  BeneficiaryTypeEnum,
} from '@constants';

export function BeneficiaryTypeDrawerFilter() {
  const beneficiaryTypeFilters = Object.values(BeneficiaryTypeEnum).map(
    beneficiaryType => {
      const content = (
        <Pill
          color={BENEFICIARY_TYPE_COLOR[beneficiaryType]}
          text={BENEFICIARY_TYPE_NAME[beneficiaryType]}
          rounded={false}
        />
      );

      return {
        label: BENEFICIARY_TYPE_NAME[beneficiaryType],
        value: beneficiaryType,
        content,
      };
    },
  );

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.BENEFICIARIES_TYPE}
      filters={beneficiaryTypeFilters}
      label="tipos de beneficiários"
      title="Tipo de beneficiário"
    />
  );
}
