import { styled as muiStyled, Box } from '@mui/material';

import { COLORS } from '../../../../themes/colors';

export const FilterButton = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  background: COLORS.PRIMARY.BLUE.MAIN,
  border: `1px solid ${COLORS.PRIMARY.BLUE['-2']}`,
  borderRadius: '4px',
  padding: '6px',
  marginRight: '8px',
  cursor: 'default',

  '& .MuiTypography-root': {
    marginRight: '8px',
    fontWeight: 400,
    fontSize: '12px',
    color: COLORS.WHITE,
  },
}));
