import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FormDataSubmit } from '@services/AuthService/dtos/DataAccessPasswordDefinitionDTO';

import {
  FIRST_STEP,
  FIRST_STEP_LIST_OPTIONS,
  LAST_STEP_LIST_OPTIONS,
  SECOND_STEP_LIST_OPTIONS,
  USER_PASSWORD_DEFINITION_STEPS_KEYS,
  USER_PASSWORD_DEFINITION_STEPS_ORDER,
  USER_PASSWORD_DEFINITION_STEPS_VALUES,
} from '@flows/public/pages/UserFirstAccess/constants';
import {
  DataAccessFormProps,
  UserPasswordFormProps,
  StepProps,
} from '@flows/public/pages/UserFirstAccess/types';

interface StepsContextData {
  dataFormState: FormDataSubmit;
  handleStepChange: (step: number) => void;
  handleStepUpdate: (
    step: number,
    data: UserPasswordFormProps | DataAccessFormProps,
  ) => void;
  token: string | null;
  userId: string | null;
  currentStepList: StepProps[];
  currentStep: number;
}

const StepsContext = createContext<StepsContextData>({} as StepsContextData);

function StepsProvider({ children }: Readonly<PropsWithChildren<{}>>) {
  const [params] = useSearchParams();

  const token = useMemo(() => params.get('token'), [params]);

  const userId = useMemo(() => params.get('userId'), [params]);

  const navigate = useNavigate();
  const routeParams = useParams<{
    userPasswordDefinitionStep: string;
  }>();

  const [dataFormState, setDataFormState] = useState<FormDataSubmit>({
    document: '',
    data: {
      password: '',
      confirmedPassword: '',
    },
  });

  if (!token) {
    const loginPageUrl = process.env.REACT_APP_LOGIN_URL;

    window.location.replace(loginPageUrl);
  }

  const currentStep =
    USER_PASSWORD_DEFINITION_STEPS_VALUES[
      routeParams.userPasswordDefinitionStep ??
        USER_PASSWORD_DEFINITION_STEPS_KEYS.DATA_ACCESS
    ];

  const isDocumentEmpty = dataFormState.document === '';

  const isOnPasswordDefinition =
    currentStep ===
    USER_PASSWORD_DEFINITION_STEPS_VALUES[
      USER_PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION
    ];

  if (isDocumentEmpty && isOnPasswordDefinition) {
    window.location.replace(
      `/definicao-de-senha-usuario/${FIRST_STEP}?token=${token}&userId=${userId}`,
    );
  }

  const stepList = [
    FIRST_STEP_LIST_OPTIONS,
    SECOND_STEP_LIST_OPTIONS,
    LAST_STEP_LIST_OPTIONS,
  ];

  const currentStepList = stepList[currentStep];

  const handleStepChange = useCallback(
    (step: number) => {
      navigate(
        `/definicao-de-senha-usuario/${USER_PASSWORD_DEFINITION_STEPS_ORDER[step]}?token=${token}&userId=${userId}`,
      );
    },
    [navigate],
  );

  const handleStepUpdate = useCallback(
    (step: number, data: UserPasswordFormProps | DataAccessFormProps) => {
      setDataFormState(prev => ({ ...prev, ...data }));

      navigate(
        `/definicao-de-senha-usuario/${
          USER_PASSWORD_DEFINITION_STEPS_ORDER[step + 1]
        }?token=${token}&userId=${userId}`,
      );
    },
    [navigate, token],
  );

  const valueContextProvider = useMemo(
    () => ({
      dataFormState,
      handleStepChange,
      handleStepUpdate,
      token,
      userId,
      currentStepList,
      currentStep,
    }),
    [
      dataFormState,
      handleStepChange,
      handleStepUpdate,
      token,
      userId,
      currentStepList,
      currentStep,
    ],
  );

  return (
    <StepsContext.Provider value={valueContextProvider}>
      {children}
    </StepsContext.Provider>
  );
}

function useSteps() {
  const context = useContext(StepsContext);

  if (!context) {
    throw new Error('useSteps must be used within StepsProvider');
  }

  return context;
}

export { useSteps, StepsProvider };
