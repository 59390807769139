import { COLORS } from '../../themes/colors';
import { StyledStack, StyledTypography } from './styles';

interface PillProps {
  text: string;
  color?: string;
  rounded?: boolean;
  fitContent?: boolean;
}

export const Pill = ({
  text,
  color = COLORS.PRIMARY.BLUE.MAIN,
  rounded = false,
  fitContent = false,
}: PillProps) => {
  return (
    <StyledStack
      color={color}
      justifyContent="center"
      alignItems="center"
      rounded={rounded ? rounded.toString() : 'false'}
      fitcontent={fitContent ? fitContent.toString() : 'false'}
    >
      <StyledTypography
        color={color}
        rounded={rounded ? rounded.toString() : 'false'}
      >
        {text}
      </StyledTypography>
    </StyledStack>
  );
};
