import { Stack } from '@mui/material';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import PrintSvg from '@assets/images/print.svg';

import { DiscountLetterPage } from './components';
import { IconContainer, PrintWrapper } from './styles';
import { useDiscountLetters } from './hooks';

export function PrintDiscountLayout() {
  const componentRef = useRef(null);
  const { letterNumber } = useParams();
  const { discountLettersState, refreshDiscountLetters } = useDiscountLetters();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (discountLettersState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const discountLetters = discountLettersState.data ?? [];
  const selectedLetter = discountLetters.find(
    item => item.letterNumber === letterNumber,
  );

  if (discountLettersState.isError || !selectedLetter) {
    return <ErrorLoadingPage onReloadClick={refreshDiscountLetters} />;
  }

  return (
    <Stack>
      <Stack
        padding={4}
        justifyContent="flex-end"
        flexDirection="row"
        bgcolor={'#323639'}
        alignItems="center"
        gap={2}
      >
        <IconContainer onClick={handlePrint}>
          <img width={16} height={16} src={PrintSvg} alt="print-letter" />
        </IconContainer>
      </Stack>
      <Stack width="60vw" margin="auto">
        <div ref={componentRef}>
          <PrintWrapper>
            <DiscountLetterPage letter={selectedLetter} />
          </PrintWrapper>
        </div>
      </Stack>
    </Stack>
  );
}
