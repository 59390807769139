import { COLORS, SummaryItemProps } from '@hapvida/hapvida-core-components';

import { BeneficiaryMovementTypeEnum } from './beneficiaryMovementType';

interface MovementSummaryItemProps extends SummaryItemProps {
  key: BeneficiaryMovementTypeEnum;
}

export const MOVEMENT_SUMMARY_ITEMS: MovementSummaryItemProps[] = [
  {
    key: BeneficiaryMovementTypeEnum.HOLDER_INCLUSION,
    color: COLORS.PRIMARY.GREEN['+1'],
    label: 'Inclusão de titular',
    iconName: 'account-plus',
  },
  {
    key: BeneficiaryMovementTypeEnum.DEPENDENT_INCLUSION,
    color: COLORS.PRIMARY.GREEN['+1'],
    iconName: 'account-multiple-plus',
    label: 'Inclusão de dependente',
  },
  {
    label: 'Aposentado e/ou demitido',
    color: COLORS.MONOCHROMATIC.GRAY4,
    iconName: 'account-multiple-plus',
    key: BeneficiaryMovementTypeEnum.RETIRED,
  },
  {
    key: BeneficiaryMovementTypeEnum.CADASTRAL_CHANGE,
    color: COLORS.SECONDARY.BLUE['+1'],
    label: 'Alteração cadastral',
    iconName: 'edit',
  },
  {
    iconName: 'file-arrow-left-right-outline',
    key: BeneficiaryMovementTypeEnum.CONTRACT_TRANSFER,
    label: 'Transferência de contrato',
    color: COLORS.SECONDARY.BLUE['+1'],
  },

  {
    key: BeneficiaryMovementTypeEnum.REACTIVATION,
    color: COLORS.PRIMARY.ORANGE['+1'],
    iconName: 'refresh',
    label: 'Reativação',
  },
  {
    color: COLORS.SECONDARY.RED['+1'],
    key: BeneficiaryMovementTypeEnum.EXCLUSION,
    iconName: 'delete-filled',
    label: 'Exclusão',
  },
  {
    key: BeneficiaryMovementTypeEnum.EXCLUSION_CANCELATION,
    color: COLORS.SECONDARY.RED['+1'],
    iconName: 'delete-scratched',
    label: 'Cancelar exclusão',
  },
  {
    color: COLORS.PRIMARY.ORANGE['+1'],
    key: BeneficiaryMovementTypeEnum.PLAN_CHANGE,
    iconName: 'swap-horizontal',
    label: 'Troca de plano',
  },
];
