import { Stack } from '@mui/material';

import {
  PARTNER_COMPANIES_TYPE_NAME,
  PartnerCompaniesTypeEnum,
} from '@constants';
import { PartnerCompanyTypeChip } from '@components';

export const mappedPartner = (partnerCompanyType: PartnerCompaniesTypeEnum) => {
  const isMainPartnerCompany =
    partnerCompanyType === PartnerCompaniesTypeEnum.MOTHER;
  const content = (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <PartnerCompanyTypeChip isMainPartnerCompany={isMainPartnerCompany} />
    </Stack>
  );

  return {
    label: PARTNER_COMPANIES_TYPE_NAME[partnerCompanyType],
    value: partnerCompanyType,
    content,
  };
};
