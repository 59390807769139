import {
  FilterKeyEnum,
  FiltersConfigProps,
} from '@hapvida/hapvida-core-components';

import { USER_STATUS_NAME, UserStatusEnum } from '@constants';

export const userDrawerFilterValues: FiltersConfigProps = {
  [FilterKeyEnum.USER_STATUS]: Object.values(UserStatusEnum).map(
    (userType: UserStatusEnum) => {
      return {
        label: USER_STATUS_NAME[userType],
        value: userType,
      };
    },
  ),
};
