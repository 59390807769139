import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { MovementListPage } from './index';

export const MovementListRouter: IAppRoute = {
  Page: MovementListPage,
  path: '/movimentacoes',
  name: 'Movimentações',
  isPrivate: true,
  Icon: <Icon name="clipboard-text-outline" />,
};
