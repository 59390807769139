import { Typography } from '@mui/material';

import { ModalNew } from '@hapvida/hapvida-core-components';

interface ModalNotificationProps {
  openModal: boolean;
  handleCloseModal: () => void;
  goToEditUserData: () => void;
}

export const ModalNotification = ({
  openModal,
  handleCloseModal,
  goToEditUserData,
}: ModalNotificationProps) => (
  <ModalNew.Container open={openModal} handleCloseModal={handleCloseModal}>
    <ModalNew.Content
      subtitle="Atenção! O e-mail informado já está cadastrado na empresa."
      icon="alert"
    >
      <Typography variant="smallText">
        O e-mail informado já está vinculado a empresa, por isso, não pode ser
        utilizado para criação de um novo usuário. Você ainda pode editar os
        dados deste usuário para adicionar ou remover permissões de contratos
        específicos.
      </Typography>
    </ModalNew.Content>
    <ModalNew.Footer
      secondaryButtonProps={{
        children: 'Fechar',
        onClick: handleCloseModal,
      }}
      mainButtonProps={{
        children: 'Ir para edição de usuário',
        onClick: goToEditUserData,
        color: 'secondary',
      }}
    />
  </ModalNew.Container>
);
