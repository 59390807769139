import { Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Control, FormState } from 'react-hook-form';

import {
  TextInput,
  Mask,
  Icon,
  AvatarPicker,
} from '@hapvida/hapvida-core-components';

import { UserStatusEnum, FEATURE_TOGGLE } from '@constants';
import { useUserDetailsState } from '@flows/private/users/pages/UserDetails/hooks';
import { UserForm } from '@flows/private/users/pages/UserDetails/components/UserEditForm/types';

import { CircularButton, StyledBadge, StyledAvatar } from './styles';
import { ResendConfirmationEmailAlert } from './components';

interface EditFormFieldsProps {
  formState: FormState<UserForm>;
  control: Control<UserForm>;
}

export function EditFormFields({
  control,
  formState,
}: Readonly<EditFormFieldsProps>) {
  const [editingAvatar, setEditingAvatar] = useState<boolean>(false);

  const { userDetailsState } = useUserDetailsState();

  if (!userDetailsState.isSuccess) {
    return null;
  }

  const userData = userDetailsState.data.content;
  const toggleEditingAvatar = () => setEditingAvatar(prev => !prev);

  const pendingUser = userData?.status === UserStatusEnum.PENDING;
  const errorPhone = Boolean(formState.errors?.phone?.message);
  const errorPhoneMessage = formState.errors?.phone?.message;

  return (
    <>
      <AvatarPicker
        handleCloseModal={toggleEditingAvatar}
        open={editingAvatar}
        icon="user"
      />
      <Grid item xs={12}>
        <Stack alignItems="center" direction="row">
          <Typography variant="h5" sx={{ flex: 1 }}>
            Editar usuário
          </Typography>
          <Typography variant="smallText" color="primary.main">
            {' '}
            Campos com * são obrigatórios
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        {pendingUser && <ResendConfirmationEmailAlert formState={formState} />}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="text">Edite as informações do usuário.</Typography>
      </Grid>

      {FEATURE_TOGGLE.EDIT_AVATAR && (
        <Grid item xs={12}>
          <CircularButton onClick={toggleEditingAvatar}>
            <StyledBadge
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={<Icon name="camera" color="white" />}
              overlap="circular"
              color="secondary"
            >
              <StyledAvatar>
                <Icon size={32} name="user" />
              </StyledAvatar>
            </StyledBadge>
          </CircularButton>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextInput
          error={Boolean(formState.errors?.name?.message)}
          errorMessage={formState.errors?.name?.message}
          label="Nome completo"
          placeholder="Digite"
          control={control}
          variant="filled"
          name="name"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          errorMessage={errorPhoneMessage}
          placeholder="Digite"
          error={errorPhone}
          control={control}
          mask={Mask.phone}
          label="Telefone"
          variant="filled"
          name="phone"
          required
          fullWidth
        />
      </Grid>

      {userData?.document && (
        <Grid item xs={12}>
          <TextInput
            value={
              userData?.document?.length === 11
                ? Mask.cpf(userData?.document)
                : Mask.cpfNDITenDigits(userData?.document)
            }
            variant="filled"
            label="CPF"
            fullWidth
            required
            name="cpf"
            disabled
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <TextInput
          error={Boolean(formState.errors?.email?.message)}
          errorMessage={formState.errors?.email?.message}
          placeholder="Digite"
          control={control}
          variant="filled"
          label="E-mail"
          name="email"
          fullWidth
          disabled
          required
        />
      </Grid>
    </>
  );
}
