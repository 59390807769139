import { styled as muiStyled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

import { COLORS } from '@hapvida/hapvida-core-components';

export const Line = muiStyled(Grid)({
  background: COLORS.MONOCHROMATIC.GRAY1,
  padding: '24px, 12px, 24px, 12px',
  borderRadius: '8px',
});

export const StyledGrid = muiStyled(Grid)({
  padding: '24px',
});

export const ActionButton = muiStyled(IconButton)(() => ({
  '&.MuiIconButton-root': {
    border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,
    height: '32px',
    width: '32px',
  },
}));
