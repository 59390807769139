import { Stack } from '@mui/material';

import { Mask } from '@hapvida/hapvida-core-components';

import { Bill } from '@services/FinancialService/dtos/BillsListDTO';

import SimpleText from '../SimpleText';

interface IdentifierProps {
  bill: Bill;
}

const Identifier = ({ bill }: IdentifierProps) => {
  const { companyDocument, number } = bill;

  return (
    <Stack>
      <SimpleText text={`#${number}`} />
      {companyDocument && <SimpleText text={Mask.cnpj(companyDocument)} />}
    </Stack>
  );
};

export default Identifier;
