import { Grid, Stack, Button, Typography } from '@mui/material';

import {
  CircularProgress,
  Icon,
  Mask,
  TextInput,
} from '@hapvida/hapvida-core-components';

import { useUserDataAccess } from './hooks';

export function UserDataAccess() {
  const { control, errors, handleSubmit, onSubmit, isValid, loading } =
    useUserDataAccess();

  return (
    <Stack gap={2}>
      <Typography variant="h5">Bem-vindo ao novo Portal Empresa</Typography>
      <Grid component="form" container spacing={{ xs: 5, sm: 3 }}>
        <Grid item xs={12}>
          <Typography variant="text">
            Para completar o seu cadastro, informe o seu CPF.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            control={control}
            name="document"
            fullWidth
            error={Boolean(errors?.document?.message)}
            helperText={errors?.document?.message}
            label="CPF"
            mask={Mask.cpf}
            required
            placeholder="Digite o seu CPF"
          />
        </Grid>

        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="success"
            endIcon={
              loading ? (
                <CircularProgress size={16} />
              ) : (
                <Icon name="right" size={16} />
              )
            }
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || loading}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}
