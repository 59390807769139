import { IAppRoute } from 'routes/types';

import { SessionExpired } from './index';

export const SessionExpiredRouter: IAppRoute = {
  Page: SessionExpired,
  path: '/sessao-expirada',
  name: 'Sessão expirada',
  Icon: <></>,
  disabled: false,
};
