import { Stack } from '@mui/material';

import { CollapseCard } from '@hapvida/hapvida-core-components';

import { useBeneficiaryDetailsState } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/hooks';

import { CriticismCard } from './components';

export function BeneficiaryReviewsCard() {
  const { beneficiaryDetailsState, beneficiaryReviewsState } =
    useBeneficiaryDetailsState();

  if (
    !beneficiaryDetailsState.isSuccess ||
    !beneficiaryReviewsState.isSuccess
  ) {
    return null;
  }

  const beneficiary = beneficiaryDetailsState.data;
  const beneficiaryReviews = beneficiaryReviewsState.data;

  if (!beneficiaryReviews.reviews || beneficiaryReviews.reviews.length === 0) {
    return null;
  }

  return (
    <CollapseCard
      variant="danger"
      title="Lista de críticas deste beneficiário"
      subtitle="Por aqui, você tem a possibilidade de ver de forma rápida todas as pendências deste beneficiário."
    >
      <Stack direction="column" spacing={2}>
        {beneficiary &&
          beneficiaryReviews.reviews?.map((criticism: string, index) => {
            return (
              <CriticismCard
                key={beneficiaryReviews.movementNumber + String(index)}
                movementNumber={beneficiaryReviews.movementNumber}
                beneficiaryName={beneficiary?.name.full}
                criticism={criticism}
              />
            );
          })}
      </Stack>
    </CollapseCard>
  );
}
