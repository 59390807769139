import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { BackofficeUserListPage } from '.';

export const BackofficeUserListRouter: IAppRoute = {
  Page: BackofficeUserListPage,
  path: '/usuarios-backoffice',
  name: 'Usuários backoffice',
  isPrivate: true,
  Icon: <Icon name="people" />,
  disabled: false,
};
