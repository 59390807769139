import {
  FilterKeyEnum,
  DrawerFilter,
  Gender,
} from '@hapvida/hapvida-core-components';

import { useAccessProfile } from './hooks/useAccessProfile';

export function AccessProfileDrawerFilter() {
  const { loadProfilesFilterList } = useAccessProfile();

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.PROFILE_ID}
      title="Perfis de acesso"
      gender={Gender.Masculine}
      label="perfis"
      filters={loadProfilesFilterList}
      disabledSelectAll
      singularLabel="perfil"
    />
  );
}
