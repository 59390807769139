import { TableHeadParams } from '@hapvida/hapvida-core-components/src/components/BaseTable/types';

enum BillsListSortNameEnum {
  PARTNER_COMPANY = 'PartnerCompany',
  HEALTH_OPERATOR = 'HealthOperator',
  DUE_DATE = 'DueDate',
  AMOUNT = 'Amount',
  STATUS = 'Status',
  NUMBER = 'Number',
  MONTH = 'Month',
  TYPE = 'Type',
}

const BillsListColumns: TableHeadParams[] = [
  {
    id: 1,
    label: '',
    name: '',
    cellWidth: 10,
  },
  {
    id: 2,
    label: 'Nº da fatura',
    name: BillsListSortNameEnum.NUMBER,
  },
  {
    id: 3,
    label: 'Nº Contrato',
    lastLabel: '/Cód. Empresa',
    name: BillsListSortNameEnum.PARTNER_COMPANY,
  },
  {
    id: 4,
    label: 'Vencimento',
    name: BillsListSortNameEnum.DUE_DATE,
  },
  {
    id: 5,
    label: 'Mês',
    name: BillsListSortNameEnum.MONTH,
  },
  {
    id: 6,
    label: 'Valor',
    name: BillsListSortNameEnum.AMOUNT,
  },
  {
    id: 7,
    label: 'Tipo fatura',
    name: BillsListSortNameEnum.TYPE,
  },
  {
    id: 8,
    label: 'Empresa',
    name: BillsListSortNameEnum.HEALTH_OPERATOR,
  },
  {
    id: 9,
    label: 'Status',
    name: BillsListSortNameEnum.STATUS,
  },
  {
    id: 10,
    label: '',
    name: '',
    cellWidth: 80,
  },
];

const BillsSummaryColumns = [
  {
    id: 1,
    label: 'Beneficiário',
    name: 'beneficiary',
  },
  {
    id: 2,
    label: 'Tipo',
    name: 'beneficiaryType',
  },
  {
    id: 3,
    label: 'Paciente',
    name: 'patient',
  },
  {
    id: 4,
    label: 'Descrição/Despesa',
    name: 'descriptionExpenses',
  },
  {
    id: 5,
    label: 'Valor fator',
    name: 'factorValue',
  },
];

export { BillsSummaryColumns, BillsListColumns, BillsListSortNameEnum };
