import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import {
  useConfirmation,
  Typography,
  useDrawerFilter,
} from '@hapvida/hapvida-core-components';

import MovimentationService, {
  MovementBeneficiaryProps,
} from '@services/MovimentationService';

import { useMovementDetailsState } from '@flows/private/movements/pages/MovementDetails/hooks';
import { useScreenLoading, useValidModal } from '@hooks';
import { toastifyApiErrors } from '@utils';
import { movementDetailsFilterConfig } from '@flows/private/movements/pages/MovementDetails/constants/filterValues';

export function useMovementDetails() {
  const { showConfirmation } = useConfirmation();
  const { onScreenLoading } = useScreenLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { movementId } = useParams();
  const { getInitialFilter, setActiveFilters } = useDrawerFilter();

  const {
    isValidResponseServiceRef,
    invalidResponseService,
    validResponseService,
  } = useValidModal();

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const initialFinancialFilters = getInitialFilter({
        filtersConfig: movementDetailsFilterConfig,
      });

      setActiveFilters(initialFinancialFilters);
    }
  }, []);

  const movementService = useMemo(() => new MovimentationService(), []);

  const { refreshMovementDetailsStates } = useMovementDetailsState();

  const handleConfirmationCancelSolicitationMovement = () => {
    showConfirmation({
      title: 'Solicitação de cancelamento realizada com sucesso',
      icon: 'check',
      type: 'success',
      confirmText: 'Voltar para movimentação',
      onConfirm: refreshMovementDetailsStates,
      onCancel: refreshMovementDetailsStates,
      content: (
        <Typography variant="text">
          Em alguns instantes, o status de sua movimentação será atualizado e
          cancelado
        </Typography>
      ),
    });
  };

  const handleErrorCancelSolicitationMovement = () => {
    showConfirmation({
      title:
        'Ocorreu um erro ao tentar cancelar a movimentação desse beneficiário',
      icon: 'alert',
      type: 'alert',
      confirmText: 'Voltar para movimentação',
      onConfirm: refreshMovementDetailsStates,
      content: (
        <Typography variant="text">
          O beneficiário movimentado retornará ao estado “em processamento”.
          Tente novamente caso deseje cancelar.
        </Typography>
      ),
    });
  };

  const handleCancelSolicitationMovement = async (
    lifeSummaryData: MovementBeneficiaryProps,
  ) => {
    try {
      if (movementId) {
        onScreenLoading(true);

        await movementService.cancelMovementSolicitation({
          movementId,
          userId: lifeSummaryData?.user?.id,
        });
      }
      validResponseService();
      refreshMovementDetailsStates();
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 500) {
        handleErrorCancelSolicitationMovement();
      } else {
        toastifyApiErrors(error, enqueueSnackbar);
      }
      invalidResponseService();
    } finally {
      onScreenLoading(false);
      if (isValidResponseServiceRef.current === true) {
        handleConfirmationCancelSolicitationMovement();
      }
    }
  };

  const handleCancelMovementSolicitationModal = (
    lifeSummaryData: MovementBeneficiaryProps,
  ) => {
    showConfirmation({
      title:
        'Atenção! Você deseja mesmo cancelar a movimentação deste beneficiário? Essa ação será irreversível.',
      icon: 'alert',
      type: 'default',
      confirmText: 'Cancelar beneficiário',
      onConfirm: () => handleCancelSolicitationMovement(lifeSummaryData),
      cancelText: 'Fechar',
      content: (
        <Typography variant="text">
          Ao cancelar a movimentação deste beneficiário, ele não será mais
          contabilizado nesta movimentação
        </Typography>
      ),
    });
  };

  return {
    handleCancelMovementSolicitationModal,
  };
}
