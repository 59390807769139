import {
  Control,
  FieldErrors,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { Button, Grid } from '@mui/material';

import { AddAccountFormProps } from '@services/UserService';

import { FormValidatorUser } from '../FormValidatorUser';

interface EmailValidatorProps {
  control: Control<AddAccountFormProps>;
  watch: UseFormWatch<AddAccountFormProps>;
  errors: FieldErrors<AddAccountFormProps>;
  trigger: UseFormTrigger<AddAccountFormProps>;
  onNext: (email: string) => Promise<void>;
}

export const EmailValidator = ({
  control,
  watch,
  errors,
  trigger,
  onNext,
}: EmailValidatorProps) => {
  const email = watch('email');
  const confirmedEmail = watch('confirmedEmail');
  const isEmailInputValid = Boolean(email) && !errors.email;
  const isConfirmedEmailValid =
    Boolean(confirmedEmail) && !errors.confirmedEmail;
  const isEmailValid = isEmailInputValid && isConfirmedEmailValid;

  return (
    <>
      <FormValidatorUser
        controlForm={control}
        watchForm={watch}
        errorsForm={errors}
        triggerForm={trigger}
      />
      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onNext(email)}
          disabled={!isEmailValid}
        >
          Próximo
        </Button>
      </Grid>
    </>
  );
};
