import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { AlertCard, COLORS } from '@hapvida/hapvida-core-components';

import { FinancialRedirectModal } from '@components';

import { StyledTypography } from './styles';

export function RedirectAlert() {
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = useCallback(() => setOpenModal(!openModal), [openModal]);
  return (
    <>
      <AlertCard
        type="info"
        content={
          <Typography variant="text">
            Caso queira acessar os{' '}
            <Typography variant="text" fontWeight={700}>
              relatórios de histórico financeiro
            </Typography>{' '}
            anteriores ao mês de novembro de 2024,{' '}
            <StyledTypography
              onClick={toggleModal}
              variant="link"
              fontWeight={700}
              color={COLORS.PRIMARY.BLUE.MAIN}
            >
              acesse o portal NDI em uma nova guia
            </StyledTypography>{' '}
            e faça o login com sua credencial anterior.
          </Typography>
        }
      />
      <FinancialRedirectModal openModal={openModal} toggleModal={toggleModal} />
    </>
  );
}
