import {
  TableCell,
  TableRow,
  Typography,
  styled as muiStyled,
} from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

interface CustomTableRowProps {
  totalShippingReviews?: number;
}

export const DateText = muiStyled(Typography)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
}));

export const CustomTableRow = muiStyled(TableRow)<CustomTableRowProps>(
  ({ totalShippingReviews = 0 }) => ({
    borderBottom: `1px solid ${
      totalShippingReviews > 0 ? 'none' : COLORS.MONOCHROMATIC.GRAY2
    }`,
  }),
);

export const LinkRedirect = muiStyled(Typography)(() => ({
  minWidth: '60px',
  height: '20px',
  cursor: 'pointer',
  fontWeight: 600,
  textDecoration: 'underline',
  color: `${COLORS.PRIMARY.BLUE.MAIN}`,
}));

export const CustomTableRowAlert = muiStyled(TableRow)<CustomTableRowProps>(
  () => ({
    borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  }),
);

export const CustomTableCell = muiStyled(TableCell)(() => ({
  border: 0,
  padding: '18px',
}));

export const LoadValidationAlert = muiStyled(TableCell)(() => ({
  background: '#E6EDF8',
  padding: '12px 16px',
  gap: '16px',
  borderRadius: '4px',
  width: '100%',
  borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
}));

export const IconDiv = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.25rem',
  cursor: 'pointer',
  color: COLORS.PRIMARY.BLUE.MAIN,
  '& .MuiTypography-root': {
    color: COLORS.PRIMARY.BLUE.MAIN,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    margin: '2px 0 0 0.5rem',
  },
}));

export const NumberMovimentation = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiTypography-root': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    margin: '2px 0 0 0.5rem',
  },
}));

export const EditIcon = muiStyled(Icon)(() => ({
  color: `${COLORS.MONOCHROMATIC.GRAY4} !important`,
  borderColor: `${COLORS.MONOCHROMATIC.GRAY4} !important`,
  ':hover': {
    color: `${COLORS.PRIMARY.ORANGE.MAIN} !important`,
    borderColor: `${COLORS.PRIMARY.ORANGE.MAIN} !important`,
  },
  cursor: 'pointer',
}));
