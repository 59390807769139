import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import { StepLayout } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { useQuery } from '../../../../hooks/useQuery';
import {
  PASSWORD_DEFINITION_STEPS_KEYS,
  PASSWORD_DEFINITION_STEPS_ORDER,
  PASSWORD_DEFINITION_STEPS_VALUES,
} from './constants/keys';
import { steps } from './passwordDefinitionSteps';

function PasswordDefinitionStepsPage() {
  const loginPageUrl = process.env.REACT_APP_LOGIN_URL;

  const query = useQuery();
  const navigateTo = useNavigate();

  const token = query.get('token');
  const userId = query.get('userId');
  const routeParams = useParams<{ passwordDefinitionStep: string }>();

  const [currentStep, setCurrentStep] = useState(
    PASSWORD_DEFINITION_STEPS_VALUES[
      routeParams.passwordDefinitionStep ||
        PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION
    ],
  );

  const [stepList, setStepList] = useState([
    {
      label: 'Definição de senha',
      completed: true,
    },
    {
      label: 'Bem-vindo ao portal corretor',
      completed: false,
    },
  ]);

  const validateToken = useCallback(() => {
    try {
      if (!token) throw new Error('Token inválido');
    } catch (err) {
      window.location.replace(loginPageUrl);
    }
  }, [token, loginPageUrl]);

  useEffect(() => validateToken(), [validateToken]);

  useEffect(() => {
    if (routeParams.passwordDefinitionStep) {
      setCurrentStep(
        PASSWORD_DEFINITION_STEPS_VALUES[routeParams.passwordDefinitionStep],
      );
    }
  }, [routeParams]);

  const handleStepChange = useCallback(
    (step: number) => {
      if (step === 0) {
        return;
      }
      setCurrentStep(step);
      navigateTo(
        `/definicao-de-senha/${PASSWORD_DEFINITION_STEPS_ORDER[step]}`,
      );
    },
    [navigateTo],
  );

  const handleStepUpdate = useCallback(
    (step: number) => {
      const updatedStepList: any = [...stepList];

      updatedStepList[step] = {
        ...stepList[step],
        completed: true,
      };

      setStepList(updatedStepList);
      setCurrentStep(step + 1);
      navigateTo(
        `/definicao-de-senha/${
          PASSWORD_DEFINITION_STEPS_ORDER[step + 1]
        }?token=${token}?userId=${userId}`,
      );
    },
    [navigateTo, stepList, token, userId],
  );

  return (
    <StepLayout
      currentStep={currentStep}
      onStepChange={(step: number) => handleStepChange(step)}
      steps={stepList}
      hasHeader={false}
      hasFirstStep={false}
    >
      <Stack
        gap={1}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <Typography variant="text">Já tem cadastro?</Typography>
        <a href={process.env.REACT_APP_LOGIN_URL}>
          <Typography variant="link" color={COLORS.PRIMARY.BLUE.MAIN}>
            Efetue o login
          </Typography>
        </a>
      </Stack>
      {steps[currentStep].step({
        onStepUpdate: handleStepUpdate,
      })}
    </StepLayout>
  );
}

export default PasswordDefinitionStepsPage;
