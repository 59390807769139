import { Stack, StackProps, styled } from '@mui/material';
import { RouteItem } from '../../styles';
import { Icon } from '@hapvida/hapvida-core-components';

interface IndicationProps {
  selected: boolean;
  disabled: boolean;
}

export const RouteItemGroup = styled(RouteItem)(() => ({
  justifyContent: 'space-between',
}));

export const ExpandIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.action.secondary.contrastText,
}));

export const RouteSubItem = styled(({ ...props }) => (
  <Stack component="li" {...props} />
))<StackProps & IndicationProps>(({ selected, disabled, theme }) => ({
  padding: '8px 48px',
  '.MuiTypography-root': {
    color: theme.palette.action.secondary.contrastText,
  },

  ':hover': {
    cursor: disabled ? 'not-allowed' : 'pointer',
  },

  ...(disabled && {
    opacity: 0.5,
    '.MuiTypography-root': {
      color: theme.palette.action.secondary.disabled,
    },
  }),

  ...(selected && {
    '.MuiTypography-root': {
      fontWeight: 700,
      color: theme.palette.action.secondary.contrastText,
    },
  }),
}));
