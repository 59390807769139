import { HapvidaApi } from '@http';
import { generateUrlParams } from '@utils';

import {
  GetPartnerCompanyRequestDTO,
  GetPartnerCompanyResponseDTO,
} from './dtos';

export class PartnerCompanyService {
  private readonly endpoints = {
    getPartnerCompany: (companyId: string) =>
      `/api/v1/permissions/company/${companyId}/partner-companies`,
  };

  public async getPartnerCompany({
    companyId,
    ...options
  }: GetPartnerCompanyRequestDTO) {
    const params = generateUrlParams(options);

    const { data } = await HapvidaApi.get<GetPartnerCompanyResponseDTO>(
      this.endpoints.getPartnerCompany(companyId),
      {
        params,
      },
    );
    return data.content;
  }
}
