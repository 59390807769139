import { Modal } from '@hapvida/hapvida-core-components';

import { useSidebarModalState } from '@hooks';

export function SidebarModal() {
  const {
    modalData,
    handleCloseModal,
    modalOpen,
    handleMainAction,
    handleRedirectAction,
  } = useSidebarModalState();
  const {
    mainButtonText = '',
    title = '',
    content = null,
    redirectType,
  } = modalData ?? {};

  const isRedirect = Boolean(redirectType);

  const mainModalAction = isRedirect ? handleRedirectAction : handleMainAction;

  return (
    <Modal
      type="default"
      icon="alert"
      open={modalOpen}
      handleCloseModal={handleCloseModal}
      secondaryButtonText="Fechar"
      mainButtonText={mainButtonText}
      mainAction={mainModalAction}
      title={title}
    >
      {content}
    </Modal>
  );
}
