import { AccordionProps, Stack, Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';
import {
  StyledMuiAccordion,
  StyledMuiAccordionDetail,
  StyledMuiAccordionSummary,
} from './styles';
import { PropsWithChildren, ReactNode } from 'react';

interface AccordionNewProps extends AccordionProps {
  title: string;
  subtitle?: ReactNode;
}

export function AccordionNew({
  title,
  children,
  subtitle,
  ...accordionProps
}: PropsWithChildren<AccordionNewProps>) {
  return (
    <StyledMuiAccordion {...accordionProps}>
      <StyledMuiAccordionSummary
        expandIcon={<Icon name="chevron-down" size={24} />}
        aria-controls="accordion-new"
        id="accordion-new"
      >
        <Stack>
          <Typography variant="text">{title}</Typography>
          {subtitle}
        </Stack>
      </StyledMuiAccordionSummary>
      <StyledMuiAccordionDetail>{children}</StyledMuiAccordionDetail>
    </StyledMuiAccordion>
  );
}
