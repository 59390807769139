import { TableBody } from '@mui/material';

import { BaseTable } from '@hapvida/hapvida-core-components';

import { MovementListProps } from '@services/MovimentationService';

import { useFilterParams } from '@hooks';

import { Columns } from '../../constants/columns';
import { TableRows } from './components';

interface MovementListCardProps {
  movementList: MovementListProps;
}

export function MovementListCard({ movementList }: MovementListCardProps) {
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  return (
    <BaseTable
      from={10 * (currentPage - 1) + movementList.movements.length}
      count={Math.ceil(movementList.total / 10)}
      onOrderChange={handleTableOrderChange}
      onPageChange={handleChangePage}
      tableContainerHeight="100%"
      TableHeadColumns={Columns}
      currentPage={currentPage}
      tableOrder={tableOrder}
      to={movementList.total}
      hasCellAction
      width="100%"
    >
      <TableBody>
        <TableRows movimentations={movementList.movements} />
      </TableBody>
    </BaseTable>
  );
}
