import * as Yup from 'yup';

export const schema = Yup.object({
  type: Yup.string().required(),
  status: Yup.string().required(),
  document: Yup.string().required(),
  name: Yup.object({
    trade: Yup.string().required(),
    legal: Yup.string().required(),
  }).required(),
  address: Yup.object({
    zipCode: Yup.string().length(9).required(),
    street: Yup.string().required(),
    number: Yup.string(),
    state: Yup.string().required(),
    city: Yup.string().required(),
    complement: Yup.string(),
  }).required(),
});
