import {
  BaseListRoot,
  BaseListHeader,
  BaseListPagination,
  BaseListItem,
} from './components';

export const BaseList = {
  Root: BaseListRoot,
  Header: BaseListHeader,
  Pagination: BaseListPagination,
  Item: BaseListItem,
};

export * from './components';
