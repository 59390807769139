import { Stack } from '@mui/material';

import { SidebarRouteButton } from '../SidebarRouteButton';
import { useRoutes } from '../../hooks';

export function SidebarRouteList() {
  const { filteredGroupRoutes } = useRoutes();

  return (
    <Stack gap={3}>
      {filteredGroupRoutes.map(({ route }) => (
        <SidebarRouteButton route={route} key={route.path} />
      ))}
    </Stack>
  );
}
