import { HapvidaApiResponseError } from '../infra/http/Hapvida';

export const toastifyApiErrors = (
  error: unknown,
  snackbar: (message: string) => void,
) => {
  if (!(error as HapvidaApiResponseError).response) {
    snackbar('Erro interno, tente novamente mais tarde.');
    return;
  }

  if (
    !(error as HapvidaApiResponseError).response &&
    (error as Error).message
  ) {
    snackbar((error as Error).message);
    return;
  }

  const {
    response: {
      data: { notifications },
      status,
    },
  } = error as HapvidaApiResponseError;

  if (status === 403) {
    snackbar('Você não tem permissão para acessar este recurso.');
    return;
  }

  if (status === 404) {
    if (notifications?.length > 0) {
      notifications.forEach(({ message }) => snackbar(message));
      return;
    }

    snackbar('O recurso não foi encontrado.');
    return;
  }

  if ([500, 504].includes(status) || !notifications?.length) {
    snackbar('Erro interno, tente novamente mais tarde.');
    return;
  }

  notifications.forEach(({ message }) => snackbar(message));
};
