import { Button, Stack, StackProps, Typography } from '@mui/material';

import noSearchResultsImage from '../../assets/images/no-search-result.svg';
import { useDrawerFilter } from '../../hooks';

export function NoSearchResults({ ...stackProps }: StackProps) {
  const { cleanAllFilters } = useDrawerFilter();

  const handleCleanAll = () => {
    cleanAllFilters();
  };

  return (
    <Stack gap={2} alignItems="center" {...stackProps}>
      <img
        alt="Nenhum resultado encontrado"
        src={noSearchResultsImage}
        height={140}
      />
      <Typography variant="h5" textAlign="center">
        Não encontramos resultados <br /> para sua busca
      </Typography>
      <Button onClick={handleCleanAll} variant="outlined">
        Limpar busca
      </Button>
    </Stack>
  );
}
