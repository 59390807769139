import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';

import { useDrawerFilter, Filter } from '../../../../hooks/useDrawerFilter';
import {
  isFilterSelected,
  changeFilters,
  selectFilters,
  updateFilters,
} from '../../utils';
import { FilterContextData, FilterProviderProps } from './types';

const FilterContext = createContext<FilterContextData>({} as FilterContextData);

function FilterProvider({
  isMultiple = true,
  setHasMoreToLoad,
  setSearchFilters,
  setSearchText,
  searchFilters,
  singularLabel,
  hasMoreToLoad,
  loadNextPage,
  setFilters,
  searchText,
  setLoading,
  filterKey,
  children,
  filters,
  gender,
  label,
}: PropsWithChildren<FilterProviderProps>) {
  const {
    setInitialFilters,
    setActiveFilters,
    setProvFilters,
    initialFilters,
    activeFilters,
    provFilters,
  } = useDrawerFilter();

  const [initialHasMoreToLoad, setInitialHasMoreToLoad] = useState(false);

  const [searchSkip, setSearchSkip] = useState(0);
  const [skip, setSkip] = useState(0);

  const currentFilters = useMemo(
    () => provFilters[filterKey]?.filters ?? [],
    [provFilters, filterKey],
  );

  const allSelected = useMemo(() => {
    if (filters.length !== currentFilters.length) {
      return false;
    }

    const filtersValues = new Set(filters.map(item => item.value));

    return currentFilters.every(item => filtersValues.has(item.value));
  }, [filters, currentFilters]);

  const allCurrentSelected = useMemo(() => {
    const filtersValues = new Set(currentFilters.map(item => item.value));

    return searchFilters.every(item => filtersValues.has(item.value));
  }, [searchFilters, currentFilters]);

  const isSelected = (filter: Filter) =>
    isFilterSelected(currentFilters, filter);

  const handleSelectAll = () => {
    setProvFilters(prev => {
      const newFilters = selectFilters(
        prev[filterKey]?.filters ?? [],
        searchFilters,
      );

      return {
        ...prev,
        [filterKey]: {
          ...prev[filterKey],
          filters: newFilters,
        },
      };
    });
  };

  const handleInitialLoad = () => {
    const activeFilterList = activeFilters[filterKey]?.filters;

    setProvFilters(prev => ({
      ...prev,
      [filterKey]: {
        gender,
        singularLabel,
        label,
        filters: activeFilterList ?? [],
      },
    }));

    setActiveFilters(prev => ({
      ...prev,
      [filterKey]: {
        filters: activeFilterList ?? [],
        ...prev[filterKey],
        singularLabel,
        gender,
        label,
      },
    }));

    if (initialFilters[filterKey] === undefined)
      setInitialFilters(prev => ({
        ...prev,
        [filterKey]: {
          gender,
          singularLabel,
          label,
          filters,
          isDynamic: loadNextPage !== undefined,
        },
      }));

    if (loadNextPage) {
      if (initialFilters[filterKey] !== undefined) {
        const loadedFilters = initialFilters[filterKey].filters ?? [];

        const { hasMore } = initialFilters[filterKey];

        if (hasMore !== undefined) {
          setInitialHasMoreToLoad(hasMore);
          setHasMoreToLoad(hasMore);
        }

        if (loadedFilters.length > 0) {
          setSkip(skip + loadedFilters.length);
          setFilters(prev => [...prev, ...loadedFilters]);
          setSearchFilters(prev => [...prev, ...loadedFilters]);
        }
      } else handleNextPage();
    }
  };

  const handleChangeFilter = (filterToChange: Filter) => {
    setProvFilters(prev => {
      const changedFilters = changeFilters({
        currentFilters: prev[filterKey]?.filters ?? [],
        filterToChange,
        isMultiple,
      });

      return {
        ...prev,
        [filterKey]: {
          ...prev[filterKey],
          filters: changedFilters,
        },
      };
    });
  };

  const handleNextPageInitialFilters = async () => {
    const { filters: apiNewFilters, hasMore } = await loadNextPage!({
      offset: skip,
    });

    setActiveFilters(previousFiltersGroup =>
      updateFilters({
        newFilters: apiNewFilters,
        previousFiltersGroup,
        filterKey,
      }),
    );

    setProvFilters(previousFiltersGroup =>
      updateFilters({
        newFilters: apiNewFilters,
        previousFiltersGroup,
        filterKey,
      }),
    );

    setInitialFilters(prev => ({
      ...prev,
      [filterKey]: {
        ...prev[filterKey],
        filters: [...(prev[filterKey]?.filters ?? []), ...apiNewFilters],
        hasMore,
      },
    }));

    setSearchFilters(prev => [...prev, ...apiNewFilters]);
    setFilters(prev => [...prev, ...apiNewFilters]);
    setSkip(prev => prev + apiNewFilters.length);
    setInitialHasMoreToLoad(hasMore);
    setHasMoreToLoad(hasMore);
  };

  const handleNextPageSearchFilters = async () => {
    const { filters: apiNewFilters, hasMore } = await loadNextPage!({
      offset: searchSkip,
      search: searchText,
    });

    setSearchFilters(prev => [...prev, ...apiNewFilters]);
    setSearchSkip(prev => prev + apiNewFilters.length);
    setHasMoreToLoad(hasMore);
  };

  const handleNextPage = async () => {
    setLoading(true);

    if (searchText.length > 0) {
      await handleNextPageSearchFilters();
    } else {
      await handleNextPageInitialFilters();
    }

    setLoading(false);
  };

  const handleSearchFilter = async (search?: string) => {
    setLoading(true);

    if (search === undefined) {
      setHasMoreToLoad(initialHasMoreToLoad);
      setSearchFilters(filters);
      setSearchSkip(skip);
      setSearchText('');
      setLoading(false);
      return;
    }

    const { filters: apiNewFilters, hasMore } = await loadNextPage!({
      offset: 0,
      search,
    });

    setSearchSkip(apiNewFilters.length);
    setSearchFilters(apiNewFilters);
    setHasMoreToLoad(hasMore);
    setSearchText(search);
    setLoading(false);
  };

  useEffect(() => {
    handleInitialLoad();
  }, []);

  const contextValue = useMemo(
    (): FilterContextData => ({
      handleChangeFilter,
      handleSearchFilter,
      allCurrentSelected,
      handleSelectAll,
      currentFilters,
      handleNextPage,
      hasMoreToLoad,
      allSelected,
      isSelected,
      setLoading,
      isMultiple,
      filterKey,
      filters: searchFilters,
    }),
    [
      handleChangeFilter,
      handleSearchFilter,
      allCurrentSelected,
      handleSelectAll,
      currentFilters,
      handleNextPage,
      hasMoreToLoad,
      searchFilters,
      allSelected,
      isSelected,
      isMultiple,
      setLoading,
      filterKey,
      filters,
    ],
  );

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
}

function useFilter() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within FilterProvider');
  }

  return context;
}

export { FilterProvider, useFilter };
