import { TooltipProps } from 'recharts/types/component/Tooltip';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { Pill } from '@hapvida/hapvida-core-components';

import { BeneficiaryTypeEnum } from '@constants';

import { CheckAllCompanyTooltip, TooltipWrapper } from './styles';

interface BeneficiariesTooltipProps extends TooltipProps<ValueType, NameType> {
  summaryValues: Record<BeneficiaryTypeEnum, number>;
}

export function BeneficiariesTooltip({
  summaryValues,
  payload,
  active,
}: BeneficiariesTooltipProps) {
  const keysSummary = Object.keys(summaryValues);
  let sumLifeOfPercentage = 0;

  keysSummary.forEach(key => {
    sumLifeOfPercentage += summaryValues[key as BeneficiaryTypeEnum];
  });

  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <CheckAllCompanyTooltip>
          {payload[0].value} •{' '}
          {(((payload[0].value as number) * 100) / sumLifeOfPercentage).toFixed(
            2,
          )}
          %
        </CheckAllCompanyTooltip>
        <Pill text={payload[0].payload.label} color={payload[0].payload.fill} />
      </TooltipWrapper>
    );
  }

  return null;
}
