import { Checkbox, FormControlLabel, Grid, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Mask, TextInput } from '@hapvida/hapvida-core-components';

import { AddAccountBackofficeFormProps } from '@services/UserService';

import { BackofficeUserCreationFormButtons } from './components';
import { schema } from './validation';

export function BackofficeUserCreationForm() {
  const {
    handleSubmit,
    control,
    trigger,
    formState: { isValid, errors },
  } = useForm<AddAccountBackofficeFormProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      userCreationAllowed: false,
      confirmedEmail: '',
      email: '',
      phone: '',
      name: '',
    },
    mode: 'all',
  });

  return (
    <Grid container component="form" spacing={3}>
      <Grid item xs={12} marginY={1}>
        <TextInput
          error={Boolean(errors?.name?.message)}
          helperText={errors?.name?.message}
          placeholder="Digite o nome"
          label="Nome completo"
          control={control}
          name="name"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6} marginY={1}>
        <TextInput
          onChangeElementInput={() => trigger && trigger('confirmedEmail')}
          error={Boolean(errors?.email?.message)}
          helperText={errors?.email?.message}
          placeholder="Digite o e-mail"
          control={control}
          label="E-mail"
          type="email"
          name="email"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6} marginY={1}>
        <TextInput
          error={Boolean(errors?.confirmedEmail?.message)}
          helperText={errors?.confirmedEmail?.message}
          placeholder="Confirme o e-mail"
          label="Confirmar e-mail"
          name="confirmedEmail"
          control={control}
          type="email"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} marginY={1}>
        <TextInput
          error={Boolean(errors?.phone?.message)}
          helperText={errors?.phone?.message}
          placeholder="Digite o telefone"
          control={control}
          mask={Mask.phone}
          label="Telefone"
          name="phone"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} marginY={1}>
        <TextInput
          label="Tipo de gestão"
          value="Backoffice"
          name="management"
          fullWidth
          disabled
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" spacing={0.1} alignItems="center">
          <Controller
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox {...field} checked={field.value} color="primary" />
                }
                label="Concordo em criar um novo usuário backoffice. Esse usuário terá
              acesso total ao portal."
              />
            )}
            name="userCreationAllowed"
            control={control}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} container justifyContent="flex-end">
        <BackofficeUserCreationFormButtons
          handleSubmit={handleSubmit}
          isValid={isValid}
        />
      </Grid>
    </Grid>
  );
}
