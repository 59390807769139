import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

interface MovementRedirectModalProps {
  modalState: boolean;
  toggleModal: () => void;
}

export function MovementRedirectModal({
  modalState,
  toggleModal,
}: MovementRedirectModalProps) {
  const { redirectUser } = useRedirectUser();

  const redirectToMomentByScreen = () => {
    redirectUser(UserRedirectsEnum.MOVEMENT_BY_SCREEN);
    toggleModal();
  };

  return (
    <Modal
      open={modalState}
      icon="alert"
      type="default"
      handleCloseModal={toggleModal}
      title="Movimentação via tela"
      mainButtonText="Acessar portal"
      secondaryButtonText="Fechar"
      mainAction={redirectToMomentByScreen}
    >
      <Typography variant="text">
        Para acessar a movimentação via tela, clique em “Acessar portal” para
        ser direcionado para uma nova página
      </Typography>
    </Modal>
  );
}
