import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import { EmptyResult } from '@components';
import { useAuth } from 'hooks/useAuth';
import { UserListRouter } from '@flows/private/users/pages/UserList/router';

import { useUserDetailsState } from './hooks';
import { UserEditForm } from './components';

export function UserDetailsLayout() {
  const navigate = useNavigate();
  const { userDetailsState, refreshEditUserDetails } = useUserDetailsState();

  const { user } = useAuth();

  const { isLoading } = userDetailsState;

  const handleUserListNavigate = () => {
    navigate(UserListRouter.path);
  };

  if (isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const hasError = userDetailsState.isError;

  const isUserNotFoundError =
    userDetailsState.failureReason?.response?.status === 404;

  if (isUserNotFoundError) {
    const userName = user?.name ?? '';
    return (
      <EmptyResult
        message={`${userName}, nenhum usuário foi encontrado.`}
        buttonProps={{
          children: 'Voltar para usuários',
          onClick: handleUserListNavigate,
        }}
      />
    );
  }

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={refreshEditUserDetails} />;
  }

  return (
    <Container maxWidth="md">
      <UserEditForm />
    </Container>
  );
}
