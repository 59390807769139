import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useSnackbar } from 'notistack';

import BeneficiaryService, {
  DependentProps,
} from '@services/BeneficiaryService';

import { useAuth, useFilterParams } from '@hooks';
import { toastifyApiErrors } from '@utils';

interface LetterOfStayContextData {
  errorState: boolean;
  loadingState: boolean;
  beneficiaryState: DependentProps | undefined;
  stayLetterFile: string;
  handleSearch: (search: string) => Promise<void>;
}

const LetterOfStayContext = createContext<LetterOfStayContextData>(
  {} as LetterOfStayContextData,
);

export const StayLetterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { searchParam } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errorState, setErrorState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [beneficiaryState, setBeneficiaryState] = useState<DependentProps>();
  const [stayLetterFile, setStayLetterFile] = useState('');

  const { selectedCompany } = useAuth();
  const companyId = selectedCompany?.id ?? '';
  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

  const resetStates = () => {
    setBeneficiaryState(undefined);
    setStayLetterFile('');
  };

  const handleStayLetter = async (beneficiaryCode: string) => {
    try {
      const fileResponse = await beneficiaryService.fetchStayLetterFile(
        beneficiaryCode,
      );
      if (fileResponse) {
        setStayLetterFile(fileResponse);
      }
    } catch (error) {
      setStayLetterFile('');
    }
  };

  const handleSearch = useCallback(
    async (search: string) => {
      try {
        setErrorState(false);
        setLoadingState(true);
        const { beneficiaries } = await beneficiaryService.fetchBeneficiaries({
          companyId,
          offset: 0,
          limit: 1,
          search,
        });
        if (beneficiaries.length === 0) {
          resetStates();
          return;
        }

        const { beneficiary } = beneficiaries[0];

        if (beneficiary) {
          setBeneficiaryState(beneficiary);
          await handleStayLetter(beneficiary.code);
        }
      } catch (error) {
        setErrorState(true);
        resetStates();
        toastifyApiErrors(error, enqueueSnackbar);
      } finally {
        setLoadingState(false);
      }
    },
    [companyId, enqueueSnackbar, beneficiaryService, searchParam],
  );

  const valueContextProvider = useMemo(
    () => ({
      errorState,
      loadingState,
      beneficiaryState,
      stayLetterFile,
      handleSearch,
    }),
    [errorState, loadingState, beneficiaryState, stayLetterFile, handleSearch],
  );

  return (
    <LetterOfStayContext.Provider value={valueContextProvider}>
      {children}
    </LetterOfStayContext.Provider>
  );
};

export function useStayLetter() {
  const context = useContext(LetterOfStayContext);

  if (!context) {
    throw new Error(
      'useLetterOfStay must be used within a LetterOfStayProvider',
    );
  }

  return context;
}
