import { ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';

import { baseTheme } from '@hapvida/hapvida-core-components';

interface BaseThemeProviderProps {
  children: ReactNode;
}

export function BaseThemeProvider({ children }: BaseThemeProviderProps) {
  return <ThemeProvider theme={baseTheme}>{children}</ThemeProvider>;
}
