import { Typography } from '@mui/material';

import { ListGridColumnProps } from 'components/ListGrid/types';

enum CompanySetupListColumnNameEnum {
  COMPANY = 'company',
  CNPJ = 'cnpj',
  PARTNER_COMPANIES = 'partnerCompanies',
  STATUS = 'status',
  ACCESS_ACTION = 'accessAction',
}

const companySetupListColumns: ListGridColumnProps[] = [
  {
    id: CompanySetupListColumnNameEnum.COMPANY,
    item: <Typography variant="text">Empresa matriz</Typography>,
  },
  {
    id: CompanySetupListColumnNameEnum.CNPJ,
    item: <Typography variant="text">CNPJ</Typography>,
  },
  {
    id: CompanySetupListColumnNameEnum.PARTNER_COMPANIES,
    item: <Typography variant="text">Contratos</Typography>,
  },
  {
    id: CompanySetupListColumnNameEnum.ACCESS_ACTION,
    item: <></>,
  },
];

export { companySetupListColumns, CompanySetupListColumnNameEnum };
