import { Typography } from '@mui/material';

import { SelectAllContainer } from './styles';
import Checkbox from '../../../Checkbox';
import { useFilter } from '../../hooks';

export function SelectAllCheckbox() {
  const { allCurrentSelected, handleSelectAll } = useFilter();

  return (
    <SelectAllContainer>
      <Checkbox
        checked={allCurrentSelected}
        onChange={handleSelectAll}
        label="Selecionar todos"
      />
      <Typography>Selecionar todos</Typography>
    </SelectAllContainer>
  );
}
