import { UserProfileEnum } from '@constants';

import { GetEditPartnerCompanyList } from './types';
import { mappedEditedPartnerCompanies } from './mappedEditedPartnerCompanies';

/** Função responsável por inserir ou atualizar contratos editados na lista de editedPartnerCompanies */
export const getEditPartnerCompanyList = ({
  editedPartnerCompanies = [],
  newPartnerCompany,
}: GetEditPartnerCompanyList) => {
  const isMaster = newPartnerCompany.profile?.name === UserProfileEnum.MASTER;
  /** Substituir contrato existente na lista editada */
  const newItemIsMasterMainPartnerCompany =
    newPartnerCompany.isMainPartnerCompany && isMaster;

  const mergedPartnerCompanies = editedPartnerCompanies.map(editedItem =>
    mappedEditedPartnerCompanies({
      editedItem,
      newItemIsMasterMainPartnerCompany,
      newPartnerCompany,
    }),
  );

  return mergedPartnerCompanies;
};
