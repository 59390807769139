import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { UserListLayout } from './layout';
import { UserListSubHeader } from './components';

export function UserListPage() {
  return (
    <DrawerFilterProvider>
      <UserListSubHeader />
      <UserListLayout />
    </DrawerFilterProvider>
  );
}
