import { Box, Typography } from '@mui/material';

import { COLORS } from '../../../../themes/colors';
import { FilterButton } from './styles';
import { useFilter } from '../../hooks';
import Icon from '../../../Icon';

export function AppliedFilters() {
  const { handleChangeFilter, currentFilters, filters, allSelected } =
    useFilter();

  const selectAllText = allSelected
    ? 'Todos selecionados'
    : 'Nenhum selecionado';

  return currentFilters.length > 0 && !allSelected ? (
    <Box flexWrap="wrap" display="flex">
      {currentFilters.map(filter => (
        <FilterButton mt={1} key={filter.value}>
          <Typography>{filter.label}</Typography>
          <Icon
            onClick={e => {
              e.stopPropagation();
              handleChangeFilter(filter);
            }}
            name="close"
            size={16}
            color={COLORS.WHITE}
          />
        </FilterButton>
      ))}
    </Box>
  ) : (
    <Typography variant="smallText">
      {filters.length === 0 ? 'Nenhum filtro disponível' : selectAllText}
    </Typography>
  );
}
