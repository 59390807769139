import { styled } from '@mui/material';

import { Icon, Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '../../../../themes/colors';

export type ModalType = 'info' | 'error' | 'success' | 'warning';

interface TitleProps {
  type: ModalType;
}

const colorMap: Record<ModalType, string> = {
  warning: COLORS.PRIMARY.ORANGE['-1'],
  success: COLORS.PRIMARY.GREEN['+1'],
  info: COLORS.PRIMARY.BLUE['+1'],
  error: COLORS.PRIMARY.RED['+1'],
};

export const Title = styled(Typography)<TitleProps>(({ type }) => ({
  color: colorMap[type],
  lineHeight: '25px',
}));

export const SubTitle = styled(Typography)<TitleProps>(({ type }) => ({
  color: colorMap[type],
  lineHeight: '17px',
}));

export const StyledIcon = styled(Icon)<TitleProps>(({ type, size }) => ({
  color: colorMap[type],
  minWidth: size,
}));
