import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

interface StyledCircularProgressProps {
  progressColor: string;
}

export const StyledCircularProgress = styled(
  CircularProgress,
)<StyledCircularProgressProps>(({ progressColor }) => ({
  outline: 'none',
  color: progressColor,
}));
