import { Typography } from '@mui/material';

import { UserRedirectsEnum } from '@services/UserService';

import { SidebarModalDataProps } from 'hooks/useSidebarModal/types';

export const MORE_SERVICES_REDIRECT: SidebarModalDataProps = {
  title: 'Mais serviços',
  mainButtonText: 'Acessar portal',
  redirectType: UserRedirectsEnum.MORE_SERVICES,
  content: (
    <Typography variant="text">
      Para acessar mais serviços, clique em “Acessar portal” para ser
      direcionado para uma nova página.
    </Typography>
  ),
};
