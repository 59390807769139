import { styled as muiStyled, Stack, Collapse } from '@mui/material';

import { COLORS } from '../../themes/colors';

export const Wrapper = muiStyled(Stack, {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>(({ disabled }) => ({
  border: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  transition: 'background-color 0.3s ease-in-out',
  borderRadius: '8px 8px 8px 8px',
  position: 'relative',

  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.5,
  }),
}));

export const Header = muiStyled(Stack)(() => ({
  cursor: 'pointer',
  padding: '16px',
}));

export const StyledCollapse = muiStyled(Collapse)(() => ({
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  background: COLORS.MONOCHROMATIC.GRAY1,
  borderRadius: '0 0 8px 8px',
}));
