import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import CompanySetupService from '@services/CompanySetupService';
import { GetCompanyDetailResponseDTO } from '@services/CompanySetupService/dtos/GetCompanyDetailDTO';

import { toastifyApiErrors } from '@utils';

export function useCompanySetupDetailsState() {
  const { enqueueSnackbar } = useSnackbar();
  const { companyId = '' } = useParams();

  const companySetupService = useMemo(() => new CompanySetupService(), []);

  const companySetupDetailsState = useQuery<
    GetCompanyDetailResponseDTO,
    AxiosError
  >({
    queryKey: ['companySetupDetailsState', companyId],
    queryFn: () =>
      companySetupService.fetchCompanyDetail({
        id: companyId,
      }),
    enabled: Boolean(companyId),
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshCompanySetupDetailsStates = () => {
    companySetupDetailsState.remove();

    companySetupDetailsState.refetch();
  };

  return {
    companySetupDetailsState,
    refreshCompanySetupDetailsStates,
  };
}
