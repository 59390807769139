import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Typography } from '@mui/material';

import { Button, Icon, Modal, COLORS } from '@hapvida/hapvida-core-components';

import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';

import { UserActionsOptionsEnum } from '../../constants/userActionsOptions';
import { useUserActionsState } from '../../hooks';
import { useDeleteBackofficeUser } from './hooks';

export function DeleteBackofficeUserButton() {
  const [successfulDeletedUserOpen, setSuccessfulDeletedUserOpen] =
    useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const navigate = useNavigate();

  const { getIsLoading, getIsDisabled } = useUserActionsState();

  const isDisabled = getIsDisabled(UserActionsOptionsEnum.DELETE);
  const isLoading = getIsLoading(UserActionsOptionsEnum.DELETE);

  const { handleDeleteUser } = useDeleteBackofficeUser();

  const toggleDeleteUser = () => setDeleteUserOpen(!deleteUserOpen);

  const toggleSuccessfulDeletedUser = () =>
    setSuccessfulDeletedUserOpen(!successfulDeletedUserOpen);

  const handleCloseSuccessfulDeletedUser = useCallback(() => {
    toggleSuccessfulDeletedUser();
    navigate(BackofficeUserListRouter.path);
  }, [navigate, toggleSuccessfulDeletedUser]);

  const handleDeleteUserSuccess = useCallback(() => {
    toggleDeleteUser();
    toggleSuccessfulDeletedUser();
  }, [toggleDeleteUser, toggleSuccessfulDeletedUser]);

  const handleConfirmDeleteUser = useCallback(() => {
    handleDeleteUser().then(isSuccess => {
      if (isSuccess) {
        handleDeleteUserSuccess();
      }
    });
  }, [handleDeleteUserSuccess]);

  return (
    <>
      <Button
        onClick={toggleDeleteUser}
        disabled={isDisabled}
        variant="outlined"
        color="primary"
        type="button"
        endIcon={
          <Icon
            name="delete-filled"
            color={
              isDisabled ? COLORS.MONOCHROMATIC.GRAY3 : COLORS.PRIMARY.BLUE.MAIN
            }
            size={16}
          />
        }
      >
        Excluir usuário
      </Button>
      <Modal
        mainButtonText={isLoading ? 'Excluindo' : 'Excluir agora'}
        title="Atenção! Você está excluindo um usuário."
        mainAction={handleConfirmDeleteUser}
        handleCloseModal={toggleDeleteUser}
        secondaryButtonText="Fechar"
        open={deleteUserOpen}
        loading={isLoading}
        icon="alert"
      >
        <Typography variant="text">
          Tem certeza que deseja continuar com a exclusão do usuário?
        </Typography>
      </Modal>
      <Modal
        handleCloseModal={handleCloseSuccessfulDeletedUser}
        mainAction={handleCloseSuccessfulDeletedUser}
        title="Usuário excluído com sucesso."
        open={successfulDeletedUserOpen}
        mainButtonText="Fechar"
        type="success"
        icon="check"
      />
    </>
  );
}
