import { Stack } from '@mui/material';

import { PartnerCompanyContentProps } from '@services/PartnerCompanyService/dtos';

import { ListGrid } from '@components';
import { useFilterParams } from '@hooks';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

import {
  getPartnerCompanyListRows,
  partnerCompanyListColumns,
} from '../../constants';

interface PartnerCompanyListProps {
  partnerCompanyList: PartnerCompanyContentProps;
}

export function PartnerCompanyList({
  partnerCompanyList,
}: Readonly<PartnerCompanyListProps>) {
  const { currentPage, handlePageChange } = useFilterParams();
  const partnerCompanyListRows = getPartnerCompanyListRows(
    partnerCompanyList.partnerCompanies,
  );

  return (
    <Stack gap={3} paddingY={3} flex={1}>
      <ListGrid
        columns={partnerCompanyListColumns}
        rows={partnerCompanyListRows}
        paginationProps={{
          currentPage,
          handlePageChange,
          totalItems: partnerCompanyList.total,
          totalDescription: 'contratos',
          totalPages: Math.ceil(
            partnerCompanyList.total / ITEMS_LIMIT_PER_PAGE,
          ),
          totalPerPage: partnerCompanyList.totalPerPage,
        }}
        containerProps={{
          justifyContent: 'space-between',
          flex: 1,
        }}
      />
    </Stack>
  );
}
