import { COLORS, IconName } from '@hapvida/hapvida-core-components';

export enum UserStatusEnum {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  ERROR = 'Error',
  PROCESSING = 'Processing',
}

export const USER_STATUS_NAME: Record<UserStatusEnum, string> = {
  [UserStatusEnum.PENDING]: 'Pendente',
  [UserStatusEnum.ACTIVE]: 'Ativo',
  [UserStatusEnum.ERROR]: 'Erro ao processar',
  [UserStatusEnum.PROCESSING]: 'Em processamento',
};

export const USER_STATUS_COLOR: Record<UserStatusEnum, string> = {
  [UserStatusEnum.PENDING]: COLORS.PRIMARY.BLUE['+1'],
  [UserStatusEnum.ACTIVE]: COLORS.PRIMARY.GREEN['+1'],
  [UserStatusEnum.ERROR]: COLORS.SECONDARY.RED.MAIN,
  [UserStatusEnum.PROCESSING]: COLORS.PRIMARY.ORANGE['+1'],
};

export const USER_STATUS_ICON_NAME: Record<UserStatusEnum, IconName> = {
  [UserStatusEnum.ACTIVE]: 'marked-circle-outline',
  [UserStatusEnum.PENDING]: 'alert-circle',
  [UserStatusEnum.ERROR]: 'alert-circle',
  [UserStatusEnum.PROCESSING]: 'progress-clock',
};
