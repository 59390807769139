import { Button } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { CircularProgress } from '@hapvida/hapvida-core-components/src/components/CircularProgress';

import { useExportBeneficiariesFile } from './useExportBeneficiariesFile';

interface BeneficiariesExportButtonProps {
  exportEnabled: boolean;
}

export function BeneficiariesExportButton({
  exportEnabled,
}: BeneficiariesExportButtonProps) {
  const { isExportingBeneficiaries, handleExportBeneficiariesFile } =
    useExportBeneficiariesFile();

  return (
    <Button
      disabled={!exportEnabled || isExportingBeneficiaries}
      onClick={handleExportBeneficiariesFile}
      endIcon={
        isExportingBeneficiaries ? (
          <CircularProgress
            progressColor={COLORS.MONOCHROMATIC.GRAY4}
            size={16}
          />
        ) : (
          <Icon name="clipboard-pulse" size={20} />
        )
      }
      variant="outlined"
      fullWidth
    >
      Exportar beneficiários
    </Button>
  );
}
