import { Avatar, styled as muiStyled } from '@mui/material';
import Stack from '@mui/material/Stack';

import { Icon } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Wrapper = muiStyled(Stack)(() => ({
  borderRadius: '8px',
  border: `1px solid ${COLORS.MONOCHROMATIC.GRAY3}`,
  display: 'flex',
  justifyContent: 'center',

  ':hover': {
    background:
      'linear-gradient(0deg, rgba(244, 158, 0, 0.04), rgba(244, 158, 0, 0.04)), #FFFFFF',
    borderColor: COLORS.PRIMARY.ORANGE.MAIN,
  },
}));

export const EditIcon = muiStyled(Icon)(() => ({
  '&&': {
    cursor: 'pointer',
    color: `${COLORS.MONOCHROMATIC.GRAY4}`,
    borderColor: COLORS.MONOCHROMATIC.GRAY4,

    ':hover': {
      color: COLORS.PRIMARY.ORANGE.MAIN,
      borderColor: COLORS.PRIMARY.ORANGE.MAIN,
    },
  },
}));

export const AvatarStyled = muiStyled(Avatar)(() => ({
  width: '36px',
  height: '36px',
}));
