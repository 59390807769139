export enum BeneficiaryListSortNameEnum {
  PARTNER_COMPANY = 'PartnerCompany',
  COMPANY = 'HealthOperator',
  PLAN = 'CodePlanHealth',
  TYPE = 'Type',
}

export const BeneficiaryListColumns = [
  {
    id: 1,
    label: 'Status e',
    lastLabel: 'Cód. beneficiário',
    name: 'detail',
    sortable: false,
  },
  {
    id: 2,
    label: 'Sexo, Nome e CPF',
    name: 'person',
    sortable: false,
  },
  {
    id: 3,
    label: 'Tipo',
    name: BeneficiaryListSortNameEnum.TYPE,
    sortable: false,
  },
  {
    id: 4,
    label: 'Operadora',
    name: BeneficiaryListSortNameEnum.COMPANY,
    sortable: false,
  },
  {
    id: 5,
    label: 'Plano',
    name: BeneficiaryListSortNameEnum.PLAN,
    sortable: false,
  },
  {
    id: 6,
    label: 'Contrato, CNPJ',
    lastLabel: 'e Cód. unidade',
    name: BeneficiaryListSortNameEnum.PARTNER_COMPANY,
    sortable: false,
  },
];
