import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

import { COLORS } from '../colors';
const MuiTypographyTheme: {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides['MuiTypography'];
  variants?: ComponentsVariants['MuiTypography'];
} = {
  variants: [
    {
      props: { variant: 'text' },
      style: () => ({
        fontSize: 14,
        fontWeight: 'normal',
      }),
    },
    {
      props: { variant: 'smallText' },
      style: () => ({
        fontSize: 12,
        fontWeight: 'normal',
      }),
    },
    {
      props: { variant: 'link' },
      style: () => ({
        fontSize: 14,
        fontWeight: 600,
      }),
    },
  ],
  defaultProps: {
    fontFamily: 'Source Sans Pro',
  },
  styleOverrides: {
    root: {
      color: COLORS.MONOCHROMATIC.GRAY4,
    },
    h1: {
      fontWeight: 900,
      fontSize: 96,
    },
    h2: {
      fontWeight: 900,
      fontSize: 60,
    },
    h3: {
      fontWeight: 900,
      fontSize: 48,
    },
    h4: {
      fontWeight: 900,
      fontSize: 34,
    },
    h5: {
      fontWeight: 900,
      fontSize: 24,
    },
    h6: {
      lineHeight: 'normal',
      fontWeight: 900,
      fontSize: 20,
    },
    subtitle1: {
      lineHeight: 'normal',
      fontWeight: 900,
      fontSize: 16,
    },
    caption: {
      lineHeight: 'normal',
    },
  },
};

export default MuiTypographyTheme;
