import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes/types';

import { CompanyDetailsPage } from './index';

export const CompanyDetailsRouter: IAppDynamicRoute = {
  Page: CompanyDetailsPage,
  path: '/dados-empresa/:id',
  dynamicPath: ({ id }) => `/dados-empresa/${id}`,
  name: '',
  isPrivate: true,
  Icon: <Icon name="home-work" />,
  disabled: false,
};
