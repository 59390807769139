import { styled as muiStyled } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

export const StyledIcon = muiStyled(Icon)(() => ({
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.5,
  },
}));
