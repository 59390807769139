import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes/types';

import { BeneficiaryDetailsPage } from '.';

export const BeneficiaryDetailRouter: IAppDynamicRoute = {
  Page: BeneficiaryDetailsPage,
  path: '/beneficiarios/:beneficiaryId',
  name: 'Resumo do beneficiário',
  isPrivate: true,
  dynamicPath: ({ beneficiaryId }) => `/beneficiarios/${beneficiaryId}`,
  Icon: <Icon name="clipboard-pulse" size={32} />,
};
