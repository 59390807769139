import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import BeneficiaryService, {
  BeneficiariesSummaryProps,
  BeneficiariesProps,
} from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';

import { beneficiaryDrawerFilterValues } from '../../constants';

interface BeneficiariesStateContextData {
  beneficiariesSummaryState: UseQueryResult<BeneficiariesSummaryProps>;
  beneficiariesState: UseQueryResult<BeneficiariesProps>;
  refreshBeneficiariesState: () => void;
}

const BeneficiariesStateContext = createContext<BeneficiariesStateContextData>(
  {} as BeneficiariesStateContextData,
);

function BeneficiariesStateProvider({ children }: PropsWithChildren<{}>) {
  const { currentPage, searchParam, tableOrder, mappedFilters } =
    useFilterParams();
  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const { enqueueSnackbar } = useSnackbar();
  const initialRender = useRef(true);
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const initialFinancialFilters = getInitialFilter({
        filtersConfig: beneficiaryDrawerFilterValues,
      });

      setActiveFilters(initialFinancialFilters);
    }
  }, []);

  const beneficiariesSummaryState = useQuery<BeneficiariesSummaryProps>({
    queryKey: [
      'beneficiariesSummaryState',
      mappedFilters,
      searchParam,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      beneficiaryService.fetchBeneficiariesSummary({
        companyId,
        ...mappedFilters,
        ...searchParam,
      }),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const beneficiariesState = useQuery<BeneficiariesProps>({
    queryKey: [
      'beneficiariesState',
      mappedFilters,
      searchParam,
      currentPage,
      tableOrder,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      beneficiaryService.fetchBeneficiaries({
        companyId,
        ...mappedFilters,
        ...searchParam,
        ...tableOrder,
        offset: 10 * (currentPage - 1),
        limit: 10,
      }),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBeneficiariesState = () => {
    beneficiariesSummaryState.remove();
    beneficiariesState.remove();

    beneficiariesSummaryState.refetch();
    beneficiariesState.refetch();
  };

  const valueContextProvider = useMemo(
    () => ({
      refreshBeneficiariesState,
      beneficiariesSummaryState,
      beneficiariesState,
    }),
    [beneficiariesSummaryState, refreshBeneficiariesState, beneficiariesState],
  );

  return (
    <BeneficiariesStateContext.Provider value={valueContextProvider}>
      {children}
    </BeneficiariesStateContext.Provider>
  );
}

function useBeneficiariesState() {
  const context = useContext(BeneficiariesStateContext);

  if (!context) {
    throw new Error(
      'useBeneficiariesState must be used within BeneficiariesStateProvider',
    );
  }

  return context;
}

export { useBeneficiariesState, BeneficiariesStateProvider };
