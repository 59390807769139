import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes/types';

import { CompanySetupDetailsPage } from '.';

export const CompanySetupDetailsRouter: IAppDynamicRoute = {
  Page: CompanySetupDetailsPage,
  path: '/empresas/:companyId',
  dynamicPath: ({ companyId }: { companyId: string }) =>
    `/empresas/${companyId}`,
  name: '',
  isPrivate: true,
  Icon: <Icon name="home-work" />,
  disabled: false,
};
