import { StackProps } from '@mui/material';
import { ReactNode } from 'react';

import { DrawerContent } from './styles';

interface SidebarContentProps extends StackProps {
  children: ReactNode;
}

export function SidebarContent({
  children,
  ...stackProps
}: Readonly<SidebarContentProps>) {
  return <DrawerContent {...stackProps}>{children}</DrawerContent>;
}
