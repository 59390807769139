import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import HapvidaLogo from '../../../assets/images/hapvida-logo-new.svg';
import HapvidaLogoLight from '../../../assets/images/hapvida-logo-new-rgb-light.svg';
import NdiLogo from '../../../assets/images/ndi-logo-new.svg';
import NdiLogoLight from '../../../assets/images/ndi-logo-new-rgb-light.svg';

import {
  Drawer,
  DrawerContainer,
  MainContent,
  PageContainer,
  RouteGroupName,
  RouteList,
  StyledStack,
} from './styles';
import Icon from '../../Icon';
import { COLORS } from '../../../themes/colors';
import PageHeader from '../../PageHeader';
import RouteLink from './components/RouteLink';
import { ModalLinkButton } from './components/ModalLinkButton';

export interface ICoreGroupRoute<Route = ICoreRoute> {
  id: number;
  name?: string;
  collapsedName?: string;
  routes?: Array<Route>;
  modal?: ICoreModalLink;
}

export interface ModalLinkProps {
  handleCloseModal: () => void;
  open: boolean;
}

export interface ICoreModalLink {
  component: (props: ModalLinkProps) => ReactNode;
  disabledOnRoute?: string;
  collapsedName?: string;
  userTypes: UserType[];
  disabled?: boolean;
  Icon: React.JSX.Element;
  name: string;
}

export interface ICoreRoute {
  Page: React.FC<any>;
  path: string;
  name: string;
  collapsedName?: string;
  Icon: React.JSX.Element;
  userTypes: UserType[];
  disabled?: boolean;
  disabledOnRoute?: string;
}

export interface IAppSearchRoute extends ICoreRoute {
  searchPath: (searchParams?: URLSearchParams) => string;
}

export enum UserType {
  Backoffice = 'Backoffice',
  Agency = 'Agency',
  Company = 'Company',
}

interface DrawerLayoutProps {
  route?: {
    name?: string;
    icon?: React.JSX.Element;
    navigateBackPath?: string;
  };
  subRoute?: string;
  groupRoutes: Array<ICoreGroupRoute>;
  user?: {
    profile: string;
    name: string;
    companyType: UserType;
  };
  selectComponent?: React.JSX.Element;
  subHeader?: React.JSX.Element;
  drawerOpen?: boolean;
  disabledRoutes?: string[];
  setDrawerOpen(state: boolean): void;
  onLogOut?(): void;
  calculateBarSize?(ref: any): void;
  totalQuotationsAndCompany?: number;
}

const DrawerLayout: React.FC<DrawerLayoutProps> = ({
  route,
  subRoute,
  user,
  children,
  groupRoutes,
  subHeader,
  drawerOpen,
  disabledRoutes,
  setDrawerOpen,
  onLogOut,
  calculateBarSize,
  selectComponent,
  totalQuotationsAndCompany,
}) => {
  const appBarRef = useRef(null);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) setDrawerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    const listener = () => {
      if (appBarRef.current && calculateBarSize) {
        calculateBarSize(appBarRef);
      }
    };

    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [calculateBarSize, subHeader]);

  const filteredGroupRoutes = useMemo(
    () =>
      groupRoutes
        .map(({ routes, ...rest }) => ({
          ...rest,
          routes: routes?.filter(
            ({ userTypes }) =>
              user?.companyType && userTypes.includes(user.companyType),
          ),
        }))
        .filter(
          ({ routes, modal }) =>
            (routes && routes?.length > 0) ||
            (user?.companyType && modal?.userTypes.includes(user.companyType)),
        ),
    [groupRoutes, user?.companyType],
  );

  const handleRouteDisabled = (
    route?: ICoreRoute | ICoreModalLink,
  ): boolean => {
    if (
      (route as ICoreRoute)?.path &&
      disabledRoutes?.find(el => el === (route as ICoreRoute)?.path)
    ) {
      return true;
    }

    if (route?.disabled) {
      return true;
    }

    if (route?.disabledOnRoute) {
      const currentRoute = window.location.pathname;

      return currentRoute.includes(route.disabledOnRoute);
    }

    return false;
  };

  return (
    <PageContainer>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={drawerOpen}
      >
        <DrawerContainer
          open={!!drawerOpen}
          alignItems={drawerOpen ? 'start' : 'center'}
          gap={2}
          backofficeVision={user?.companyType === UserType.Backoffice}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent={drawerOpen ? 'start' : 'center'}
            pb={drawerOpen ? 3 : 2}
          >
            <Icon
              name={isMobile ? 'close' : 'drawer'}
              size={32}
              color={
                user?.companyType === UserType.Backoffice
                  ? COLORS.WHITE
                  : COLORS.PRIMARY.ORANGE.MAIN
              }
              onClick={toggleDrawer}
            />
            {drawerOpen && (
              <Stack direction="row" gap={2} order={isMobile ? -1 : 0}>
                <img
                  src={
                    user?.companyType === UserType.Backoffice
                      ? HapvidaLogoLight
                      : HapvidaLogo
                  }
                  alt="Hapvida"
                  width={104}
                />
                <img
                  src={
                    user?.companyType === UserType.Backoffice
                      ? NdiLogoLight
                      : NdiLogo
                  }
                  alt="Ndi"
                  width={104}
                />
              </Stack>
            )}
          </Stack>

          {drawerOpen && user?.companyType === UserType.Backoffice && (
            <Stack direction="row" gap={1} sx={{ mt: 5, mb: 4 }}>
              <Icon name="hapvida-logo" size={24} color={COLORS.WHITE} />
              <Typography color={COLORS.WHITE}>
                Você está na visão colaborador
              </Typography>
            </Stack>
          )}

          {selectComponent}

          <RouteList gap={3}>
            {filteredGroupRoutes.map(group => (
              <Stack
                gap={2}
                key={group.id}
                alignItems={drawerOpen ? 'normal' : 'center'}
              >
                {group.name && (
                  <RouteGroupName open={drawerOpen} variant="smallText">
                    {drawerOpen ? group.name : group.collapsedName}
                  </RouteGroupName>
                )}
                {group?.routes?.map(route => (
                  <RouteLink
                    key={route.path}
                    route={route}
                    drawerOpen={!!drawerOpen}
                    backofficeVision={user?.companyType === UserType.Backoffice}
                    disabled={handleRouteDisabled(route)}
                  >
                    <Icon name={route.Icon.props.name} size={24} />

                    {drawerOpen ? (
                      <Typography>{route.name}</Typography>
                    ) : (
                      <Typography variant="smallText">
                        {route.collapsedName}
                      </Typography>
                    )}
                  </RouteLink>
                ))}

                {group?.modal && (
                  <ModalLinkButton
                    backofficeVision={user?.companyType === UserType.Backoffice}
                    disabled={handleRouteDisabled(group?.modal)}
                    drawerOpen={Boolean(drawerOpen)}
                    modal={group.modal.component}
                  >
                    <Icon name={group.modal.Icon.props.name} size={24} />

                    {drawerOpen ? (
                      <Typography>{group.modal.name}</Typography>
                    ) : (
                      <Typography variant="smallText">
                        {group.modal.collapsedName}
                      </Typography>
                    )}
                  </ModalLinkButton>
                )}
              </Stack>
            ))}
          </RouteList>
        </DrawerContainer>
      </Drawer>
      <StyledStack>
        <PageHeader
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          innerRef={appBarRef}
          user={user}
          route={route}
          subHeader={subHeader}
          totalQuotationsAndCompany={totalQuotationsAndCompany}
          onLogOut={onLogOut}
          subRoute={subRoute}
        />
        <MainContent component="main" paddingX={3}>
          {children}
        </MainContent>
      </StyledStack>
    </PageContainer>
  );
};

export default DrawerLayout;
