/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ScreenLoading } from '@hapvida/hapvida-core-components';

import {
  ScreenLoadingContext,
  IScreenLoadingContextData,
} from '../contexts/ScreenLoadingContext';

const ScreenLoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleScreenLoading = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const valueScreenLoadingProvider = useMemo(() => {
    return {
      isLoading,
      onScreenLoading: handleScreenLoading,
    };
  }, [isLoading, handleScreenLoading]);

  return (
    <ScreenLoadingContext.Provider value={valueScreenLoadingProvider}>
      <ScreenLoading open={isLoading} />
      {children}
    </ScreenLoadingContext.Provider>
  );
};

function useScreenLoading(): IScreenLoadingContextData {
  const context = useContext(ScreenLoadingContext);

  if (!context) {
    throw new Error(
      'useScreenLoading must be used within ScreenLoadingProvider',
    );
  }

  return context;
}

export { ScreenLoadingProvider, useScreenLoading };
