import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSnackbar } from 'notistack';

import { CompanyListProps } from '@services/CompanySetupService/dtos/ListCompanyDTO';
import CompanySetupService from '@services/CompanySetupService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams } from '@hooks';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

export function useCompanySetupListState() {
  const { searchParam, currentPage, mappedFilters } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();

  const companySetupService = useMemo(() => new CompanySetupService(), []);

  const companySetupListState = useQuery<CompanyListProps>({
    queryKey: [
      'companySetupListState',
      currentPage,
      mappedFilters,
      searchParam,
    ],
    queryFn: () =>
      companySetupService.getCompanies({
        ...searchParam,
        ...mappedFilters,
        offset: ITEMS_LIMIT_PER_PAGE * (currentPage - 1),
        limit: ITEMS_LIMIT_PER_PAGE,
      }),
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshCompanySetupListStates = () => {
    companySetupListState.remove();

    companySetupListState.refetch();
  };

  return {
    companySetupListState,
    refreshCompanySetupListStates,
  };
}
