import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '../Icon';
import { FilterButton } from './styles';
import { COLORS } from '../../themes/colors';
import { useMemo } from 'react';
import {
  Filter,
  FiltersGroup,
  useDrawerFilter,
} from '../../hooks/useDrawerFilter';
import { format } from 'date-fns';
import { Gender } from '../../constants/enums';
import { FilterKeyEnum, SearchParamEnum } from '../../constants';
import { useSearchParams } from 'react-router-dom';

interface CustomFilter extends Filter {
  filterKey: string;
  isGroup?: boolean;
}

interface FiltersListProps {
  onApplyFilters?: (filters: FiltersGroup) => void;
}

const FiltersList = ({ onApplyFilters }: FiltersListProps) => {
  const { activeFilters, handleFilterChange, ignoreFilters } =
    useDrawerFilter();

  const [params, setParams] = useSearchParams();

  const searchParam = useMemo(
    () => params.get(SearchParamEnum.SEARCH),
    [params],
  );

  const currentFiltersGroups: CustomFilter[] = useMemo(() => {
    const filters: CustomFilter[] = [];

    Object.keys(activeFilters)?.forEach(key => {
      if (ignoreFilters.includes(key)) return;
      const currentGroup = activeFilters[key];

      if (key !== 'date') {
        if (currentGroup.filters!.length > 1) {
          const filterCount = currentGroup.filters!.length;

          const labelOrganizational =
            key === FilterKeyEnum.ORGANIZATIONAL_GROUP
              ? 'grupos organizacionais'
              : 'filtros';

          const labelFilterUndefined =
            currentGroup.label ?? labelOrganizational;

          const filterNounGender =
            currentGroup.gender === Gender.Feminine
              ? 'selecionadas'
              : 'selecionados';

          filters.push({
            isGroup: true,
            filterKey: key,
            content: `${currentGroup.label}-group`,
            label: `${filterCount} ${labelFilterUndefined} ${filterNounGender}`,
            value: currentGroup.label!,
          });
        } else if (currentGroup.filters!.length === 1) {
          const undefinedFilterLabel = currentGroup.singularLabel ?? 'filtro';

          const filterNounGender =
            currentGroup.gender === Gender.Feminine
              ? 'selecionada'
              : 'selecionado';

          const undefinedLabel = `01 ${undefinedFilterLabel} ${filterNounGender}`;

          const label =
            currentGroup.filters![0].label !== ''
              ? currentGroup.filters![0].label
              : undefinedLabel;

          filters.push({
            ...currentGroup.filters![0],
            filterKey: key,
            label,
          });
        }
      } else {
        const dateGroup = activeFilters[key];

        if (dateGroup.startDate && dateGroup.endDate) {
          filters.push({
            filterKey: key,
            content: 'date',
            label: `Início: ${format(
              activeFilters[key].startDate!,
              'dd/MM/yyyy',
            )} • Término: ${format(activeFilters[key].endDate!, 'dd/MM/yyyy')}`,
            value: 'date',
          });
        }
      }
    });

    if (searchParam) {
      filters.push({
        filterKey: SearchParamEnum.SEARCH,
        content: SearchParamEnum.SEARCH,
        label: `${searchParam}`,
        value: SearchParamEnum.SEARCH,
      });
    }

    return filters;
  }, [activeFilters, ignoreFilters, searchParam]);

  const handleChange = (filter: CustomFilter) => {
    let newActiveFilters: FiltersGroup;

    if (filter.filterKey === SearchParamEnum.SEARCH) {
      params.delete(SearchParamEnum.SEARCH);
      setParams(params);
      return;
    }

    if (filter.filterKey !== 'date') {
      newActiveFilters = Object.fromEntries(
        Object.entries(activeFilters).filter(
          ([key]) => key !== filter.filterKey,
        ),
      ) as FiltersGroup;
    } else {
      const { date, ...otherFilters } = activeFilters;
      newActiveFilters = otherFilters as FiltersGroup;
    }

    handleFilterChange(newActiveFilters);
    onApplyFilters?.(newActiveFilters);
  };

  return (
    <Box gap={1} flexWrap="wrap" display="flex">
      {currentFiltersGroups.map(fullFilter => (
        <FilterButton key={fullFilter.value}>
          <Typography>{fullFilter.label}</Typography>
          <Icon
            onClick={e => {
              e.stopPropagation();
              handleChange(fullFilter);
            }}
            name="close"
            size={16}
            color={COLORS.WHITE}
          />
        </FilterButton>
      ))}
    </Box>
  );
};

export default FiltersList;
