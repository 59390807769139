import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FiltersGroup, useDrawerFilter } from '../../hooks/useDrawerFilter';
import { COLORS } from '../../themes/colors';
import FiltersList from '../FiltersList';
import { OverflowStrip } from '../OverflowStrip';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { SearchParamEnum } from '../../constants';

interface FilterSummaryProps extends StackProps {
  totalLabel?: string;
  alwaysShowTitle?: boolean;
  onApplyFilters?: (filter: FiltersGroup) => void;
}

const FilterSummary = ({
  totalLabel,
  alwaysShowTitle = false,
  onApplyFilters,
}: FilterSummaryProps) => {
  const { filterCount } = useDrawerFilter();

  const [params] = useSearchParams();

  const hasSearchParam = useMemo(
    () => Boolean(params.get(SearchParamEnum.SEARCH)),
    [params],
  );

  const allFiltersCount = filterCount + (hasSearchParam ? 1 : 0);

  const hasFilters = allFiltersCount > 0;

  const hasMoreThanOneFilter = allFiltersCount > 1;

  const filterTitle = hasMoreThanOneFilter
    ? 'Filtros aplicados:'
    : 'Filtro aplicado:';

  const getTitle = () => {
    if (totalLabel)
      return (
        <Typography component="div" variant="subtitle1">
          Total de{' '}
          <Typography
            variant="subtitle1"
            display="inline"
            color={COLORS.PRIMARY.ORANGE.MAIN}
          >
            {totalLabel}
          </Typography>
        </Typography>
      );

    return null;
  };

  return (
    <>
      {hasFilters && (
        <OverflowStrip>
          <Stack padding={3} gap={2}>
            {totalLabel && getTitle()}

            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography variant="text">
                <b>{filterTitle}</b>
              </Typography>

              <FiltersList onApplyFilters={onApplyFilters} />
            </Stack>
          </Stack>
        </OverflowStrip>
      )}

      {!hasFilters && alwaysShowTitle && <Stack>{getTitle()}</Stack>}
    </>
  );
};

export default FilterSummary;
