import { UpdateEditedPartnerCompanies } from './types';

export const getFilterPartnerCompany = ({
  newPartnerCompanies = [],
  editedPartnerCompanies = [],
}: UpdateEditedPartnerCompanies) =>
  newPartnerCompanies.filter(
    newItem =>
      !editedPartnerCompanies.some(
        ({ partnerCompany }) => partnerCompany === newItem.partnerCompany,
      ),
  );
