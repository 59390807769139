import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { UserService } from '@services/UserService';

import { BackofficeUserQueryKeysEnum } from '@constants';
import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { UserActionsOptionsEnum } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/constants/userActionsOptions';
import { useUserActionsState } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/hooks/index';

export function useDeleteBackofficeUser() {
  const { changeCurrentAction } = useUserActionsState();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { id } = useParams();

  const handleDeleteUser = useCallback(async () => {
    changeCurrentAction(UserActionsOptionsEnum.DELETE);
    try {
      const backofficeCompanyId = user?.companyId ?? '';
      if (!backofficeCompanyId || !id) {
        return false;
      }

      const userService = new UserService();
      await userService.backofficeDeleteUser({
        companyId: backofficeCompanyId,
        userId: id,
      });

      const queryKey = [BackofficeUserQueryKeysEnum.USERS_STATE];
      queryClient.removeQueries({ queryKey, exact: true });
      await queryClient.refetchQueries({
        queryKey,
      });
      return true;
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return false;
    } finally {
      changeCurrentAction(undefined);
    }
  }, [enqueueSnackbar, id, changeCurrentAction, user]);

  return {
    handleDeleteUser,
  };
}
