import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { Stack, Typography } from '@mui/material';

import { COLORS, SummaryItemProps } from '@hapvida/hapvida-core-components';

import { BENEFICIARIES_SUMMARY_ITEMS, BeneficiaryTypeEnum } from '@constants';

import { BeneficiariesTooltip } from '../BeneficiariesTooltip';

interface BeneficiariesChartProps {
  summaryValues: Record<BeneficiaryTypeEnum, number>;
  totalBeneficiaries: number;
}

export function BeneficiariesChart({
  totalBeneficiaries,
  summaryValues,
}: BeneficiariesChartProps) {
  const dataChart = ({ key }: SummaryItemProps) => {
    return summaryValues[key as BeneficiaryTypeEnum];
  };

  return (
    <Stack alignItems="center" justifyContent="center" position="relative">
      <Stack alignItems="center" justifyContent="center" position="absolute">
        <Typography variant="h5" color={COLORS.DARK.MAIN}>
          {totalBeneficiaries}
        </Typography>
        <Typography variant="body2">Total de beneficiários</Typography>
      </Stack>

      <PieChart width={240} height={240}>
        <Pie
          data={BENEFICIARIES_SUMMARY_ITEMS}
          dataKey={dataChart}
          outerRadius={120}
          paddingAngle={0}
          innerRadius={86}
          startAngle={150}
          endAngle={600}
        >
          {BENEFICIARIES_SUMMARY_ITEMS.map((summaryItem, index) => (
            <Cell key={`cell-${String(index)}`} fill={summaryItem.color} />
          ))}
        </Pie>
        <Tooltip
          content={<BeneficiariesTooltip summaryValues={summaryValues} />}
        />
      </PieChart>
    </Stack>
  );
}
