import { ButtonBase, Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const ButtonContainer = styled(ButtonBase)`
  outline: 1px solid ${COLORS.MONOCHROMATIC.GRAY3};
  color: ${COLORS.PRIMARY.ORANGE['-2']};
  background-color: ${COLORS.WHITE};
  transition: all 120ms linear;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 24px 32px;
  text-align: start;
  cursor: pointer;
  gap: 24px;
  flex: 1;

  &:hover {
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
    outline-color: ${COLORS.PRIMARY.ORANGE.MAIN};
    background-color: #fffaf4;
    outline-width: 2px;
  }
`;

export const IconContainer = styled(Stack)`
  background-color: ${COLORS.WHITE};
  justify-content: center;
  outline-color: inherit;
  outline-style: solid;
  align-items: center;
  position: relative;
  outline-width: 2px;
  border-radius: 7px;
  min-width: 64px;
  height: 64px;
`;

export const BallsIcon = styled('img')`
  position: absolute;
  height: 54px;
  width: 54px;
`;
