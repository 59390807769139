import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { HomeStateProvider } from './hooks';
import { HomeLayout } from './layout';

export function HomePage() {
  return (
    <DrawerFilterProvider>
      <HomeStateProvider>
        <HomeLayout />
      </HomeStateProvider>
    </DrawerFilterProvider>
  );
}
