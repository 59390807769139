import { ProfileProps } from '@services/UserService';

import { BaseProfileResourceEnum } from '@constants';

const resourcesSorterDict = {
  [BaseProfileResourceEnum.BENEFICIARIES]: 1,
  [BaseProfileResourceEnum.FINANCIAL]: 2,
  [BaseProfileResourceEnum.MOVEMENT]: 3,
  [BaseProfileResourceEnum.USERS]: 4,
  [BaseProfileResourceEnum.COMPANIES]: 5,
  [BaseProfileResourceEnum.MORE_SERVICES]: 6,
};

export function formatResourcesInfo(profile: ProfileProps) {
  const resources = profile.resources
    ? (Object.keys(profile.resources) as BaseProfileResourceEnum[])
    : [];

  resources.sort((a, b) => resourcesSorterDict[a] - resourcesSorterDict[b]);
  return resources;
}
