import { Radio, Typography } from '@mui/material';

import Checkbox from '../../../Checkbox';
import { useFilter } from '../../hooks';
import { FilterItem } from './styles';

export function FilterList() {
  const { handleChangeFilter, isSelected, isMultiple, filters } = useFilter();

  return (
    <>
      {filters.map(filter => (
        <FilterItem
          onClick={() => handleChangeFilter(filter)}
          key={filter.value}
        >
          {isMultiple ? (
            <Checkbox label={filter.label} checked={isSelected(filter)} />
          ) : (
            <Radio checked={isSelected(filter)} />
          )}
          {typeof filter.content === 'string' ? (
            <Typography>{filter.content}</Typography>
          ) : (
            filter.content
          )}
        </FilterItem>
      ))}
    </>
  );
}
