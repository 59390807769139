import { FC, Fragment } from 'react';
import { Stack, TableRow, TableBody } from '@mui/material';

import { TableHeadCell, TableBodyCell } from '@hapvida/hapvida-core-components';

import CustomPagination from './components/Pagination';

import {
  Wrapper,
  StyledTable,
  StyledTableRow,
  TotalItemsLabel,
  StyledTableHead,
  StyledTableContainer,
} from './styles';
import { TableViewProps } from './types';

const BaseTable: FC<TableViewProps> = ({
  data,
  tableOrder,
  TableHeadColumns,
  onPageChange,
  onOrderChange,
  children,
  count,
  from = 0,
  to = 0,
  currentPage = 1,
  width = '2000px',
  minWidth = '100%',
  tableLayout = 'fixed',
  hasCellAction = false,
  showFooter = true,
  tableContainerWidth = 'auto',
  tableContainerHeight = 540,
  rowColor,
  footerLabel = 'solicitações',
  ...paperProps
}) => {
  const dynamicRow = (item: any) =>
    item ? (
      <StyledTableRow key={item.id} hover sx={{ background: rowColor }}>
        {TableHeadColumns.map(({ name, lastLabel }, columnIndex) =>
          item[name] ? (
            <TableBodyCell key={`${item.id}-${columnIndex}-${name}`}>
              {item[name]}
              {lastLabel && item[lastLabel] ? (
                <span>{item[lastLabel]}</span>
              ) : null}
            </TableBodyCell>
          ) : null,
        )}
      </StyledTableRow>
    ) : null;

  return (
    <Wrapper
      tablecontainerwidth={tableContainerWidth}
      elevation={0}
      {...paperProps}
    >
      <StyledTableContainer tablecontainerheight={tableContainerHeight}>
        <StyledTable
          width={width}
          minwidth={minWidth}
          tablelayout={tableLayout}
        >
          <StyledTableHead>
            <TableRow>
              {TableHeadColumns.map(column => (
                <TableHeadCell
                  key={column.id}
                  label={column.label}
                  lastLabel={column.lastLabel}
                  name={column.name}
                  order={tableOrder}
                  hide={column.sortable === false}
                  onOrderChange={onOrderChange}
                  width={column.cellWidth}
                />
              ))}

              {hasCellAction && <TableHeadCell width={100} />}
            </TableRow>
          </StyledTableHead>
          {children || (
            <TableBody>
              {data?.map((item, index) => (
                <Fragment key={Math.random() * (10 - index) + Math.random()}>
                  {dynamicRow(item)}
                </Fragment>
              ))}
            </TableBody>
          )}
        </StyledTable>
      </StyledTableContainer>

      {showFooter && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginTop: '20px' }}
          p={2}
        >
          <TotalItemsLabel>
            Visualizando {from} de {to} {footerLabel}
          </TotalItemsLabel>

          <CustomPagination
            count={count}
            currentPage={currentPage}
            onChange={(evt, page) => onPageChange?.(evt, page)}
          />
        </Stack>
      )}
    </Wrapper>
  );
};

export default BaseTable;
