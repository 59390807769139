import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export enum FinancialStatusEnum {
  // Adimplente
  Defaulting = 'Defaulting',

  // Inadimplente
  NonDefaulting = 'NonDefaulting',
}

export const FinancialStatusNames: Record<FinancialStatusEnum, string> = {
  [FinancialStatusEnum.Defaulting]: 'Adimplente',
  [FinancialStatusEnum.NonDefaulting]: 'Inadimplente',
};

export const FinancialStatusTranslate: Record<string, string> = {
  Adimplente: 'Defaulting',
  Inadimplente: 'NonDefaulting',
};

export const FinancialStatusIcons: Record<FinancialStatusEnum, IconName> = {
  [FinancialStatusEnum.Defaulting]: 'coins',
  [FinancialStatusEnum.NonDefaulting]: 'close',
};

export const FinancialStatusColor: Record<FinancialStatusEnum, string> = {
  [FinancialStatusEnum.Defaulting]: COLORS.PRIMARY.BLUE['+1'],
  [FinancialStatusEnum.NonDefaulting]: COLORS.SECONDARY.RED.MAIN,
};
