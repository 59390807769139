import { ButtonBaseProps, Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';

import { ButtonContainer } from './styles';

interface ButtonLinkProps extends ButtonBaseProps {
  iconName?: IconName;
  text: string;
}

export function ButtonLink({
  iconName = 'file-check',
  text,
  ...buttonBaseProps
}: Readonly<ButtonLinkProps>) {
  return (
    <ButtonContainer {...buttonBaseProps} disableRipple>
      <Icon name={iconName} size={16} color={COLORS.PRIMARY.BLUE.MAIN} />
      <Typography
        color={COLORS.PRIMARY.BLUE['-1']}
        variant="smallText"
        fontWeight={700}
      >
        {text}
      </Typography>
    </ButtonContainer>
  );
}
