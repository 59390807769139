import React from 'react';
import Header from '../../HeaderMinimal';
import { Children, Wrapper } from './styles';

interface HeaderMinimalLayoutProps {
  children: React.ReactNode;
  user?: {
    profile: string;
    name: string;
  };
  logOut?: () => void;
  loginRoute: string;
}

const HeaderMinimalLayout: React.FC<HeaderMinimalLayoutProps> = ({
  children,
  user,
  logOut,
  loginRoute,
}) => {
  return (
    <Wrapper>
      <Header user={user} logOut={logOut} loginRoute={loginRoute} />
      <Children>{children}</Children>
    </Wrapper>
  );
};

export default HeaderMinimalLayout;
