import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { UserRedirectsEnum, UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';

export function useRedirectUser() {
  const { enqueueSnackbar } = useSnackbar();

  const userService = useMemo(() => new UserService(), []);

  const redirectUser = async (redirectType: UserRedirectsEnum) => {
    try {
      const redirectUrl = await userService.getRedirect(redirectType);

      window.open(redirectUrl, '_blank');
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  };

  return { redirectUser };
}
