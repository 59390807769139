import { Stack } from '@mui/material';

import { Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import { useAuth } from '@hooks';
import { UserType } from '@contexts';

import { BackofficeManagement, SidebarContent } from './components';

export function Sidebar() {
  const { user } = useAuth();

  const isBackoffice = user?.companyType === UserType.Backoffice;

  return (
    <SidebarCore.Root>
      <SidebarCore.Header isColorful={!isBackoffice} />
      <SidebarCore.Content flex={1} gap={5}>
        <Stack flex={1} gap={3}>
          <SidebarContent />
        </Stack>
        {isBackoffice && <BackofficeManagement />}
      </SidebarCore.Content>
    </SidebarCore.Root>
  );
}
