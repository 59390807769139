import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { useUserActionsState } from '@flows/private/users/pages/UserDetails/components/UserEditForm/hooks';
import { UserActionsOptionsEnum } from '@flows/private/users/pages/UserDetails/components/UserEditForm/constants/userActionsOptions';
import { UserQueryKeysEnum } from '@constants';
import { UserType } from '@contexts';

export function useDeleteUser() {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { selectedCompany } = useAuth();
  const { changeCurrentAction } = useUserActionsState();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const isBackoffice = useMemo(
    () => user?.companyType === UserType.Backoffice,
    [user?.companyType],
  );

  const handleDeleteUser = useCallback(async () => {
    changeCurrentAction(UserActionsOptionsEnum.DELETE);
    try {
      const companyId = selectedCompany?.id;
      if (!companyId || !id) {
        return false;
      }

      const userService = new UserService();

      if (isBackoffice) {
        await userService.backofficeDeleteUser({ userId: id, companyId });
      } else {
        await userService.deleteUser({ userId: id, companyId });
      }

      const queryKey = [UserQueryKeysEnum.USERS_STATE];
      queryClient.removeQueries({ queryKey, exact: true });
      await queryClient.refetchQueries({
        queryKey,
      });
      return true;
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return false;
    } finally {
      changeCurrentAction(undefined);
    }
  }, [enqueueSnackbar, id, changeCurrentAction, selectedCompany]);

  return {
    handleDeleteUser,
  };
}
