import { UserPartnerCompanyProps } from '@services/UserService';

export function mapOriginalProfile(
  partnerCompany: UserPartnerCompanyProps,
  originalPartnerCompanies: UserPartnerCompanyProps[],
) {
  const originalPartnerCompany = originalPartnerCompanies.find(
    item => item.partnerCompany === partnerCompany.partnerCompany,
  );
  return {
    ...partnerCompany,
    hasAccess: false,
    profile: originalPartnerCompany?.profile,
  };
}
