const companiesMock = {
  content: [
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'PendingRegistration',
      id: 1,
      fantasyName: 'Nome fantasia 2',
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'Active',
      id: 2,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'Active',
      id: 3,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'IntegrationPending',
      id: 4,
      fantasyName: 'Nome fantasia 2',
      file: {
        path: 'http://localhost:3000/fakePath/IDXXXXX.pdf',
        fileName: 'IDXXXXXX.pdf',
        size: '12345',
        date: '2022-05-09T10:36:38.666',
      },
      responsible: {
        name: 'LUCAS T S A EL MOUTAQI',
        email: 'landrade@wefit.com.br',
        phone: '5511990000000',
      },
      isReviewing: false,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'Processing',
      id: 5,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'Error',
      id: 6,
      file: {
        path: 'http://localhost:3000/fakePath/IDXXXXX.pdf',
        fileName: 'IDXXXXXX.pdf',
        size: '12345',
        date: '2022-05-09T10:36:38.666',
      },
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'Error',
      id: 7,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'PartialProcessing',
      id: 8,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'PendingRegistration',
      id: 9,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'PendingRegistration',
      id: 10,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'PendingIntegration',
      id: 11,
    },
    {
      legalName: 'NOME DA EMPRESA COM ATÉ 40 CARACTERES',
      document: '93638376000107',
      status: 'Processing',
      id: 12,
    },
  ],
};

export default companiesMock;
