const movementsMock = {
  content: {
    id: 'IDM1',
    totalPages: 10,
    movements: [
      {
        id: 'IDM11',
        operationCode: '2904141',
        operationCodeType: 'inclusionOfDependent',
        company: 'NDI',
        date: '2022-05-09T10:36:38.666',
        plan: '5252 - Nosso Plano(...)',
        newContractNumber: '2904141123123123...',
      },
      {
        id: 'IDM12',
        operationCode: '2904141',
        operationCodeType: 'changePlan',
        company: 'Hapvida',
        date: '2022-06-10T10:36:38.666',
        plan: '5252 - Nosso Plano(...)',
        newContractNumber: '2904141123123123...',
      },
    ],
  },
};

export default movementsMock;
