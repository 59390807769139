import { UserPartnerCompanyProps } from '@services/UserService';

import { UserProfileEnum } from '@constants';

export const getIsMainPartnerCompanyRemovedAsMaster = (
  editedPartnerCompanies: UserPartnerCompanyProps[],
  originalPartnerCompanies: UserPartnerCompanyProps[],
  isMainPartnerCompany: boolean,
  mainPartnerCompany: string,
) => {
  return isMainPartnerCompany
    ? false
    : editedPartnerCompanies.some(item => {
        const originalItem = originalPartnerCompanies.find(
          oldItem => oldItem.partnerCompany === item.partnerCompany,
        );

        const itemWasMaster =
          originalItem?.profile?.name === UserProfileEnum.MASTER;

        const itemWasRemovedAsMaster =
          itemWasMaster && originalItem?.profile?.name !== item.profile?.name;

        if (
          itemWasRemovedAsMaster &&
          item.partnerCompany === mainPartnerCompany
        ) {
          return true;
        }

        return (
          item.partnerCompany === mainPartnerCompany &&
          !item.hasAccess &&
          item.profile?.name === UserProfileEnum.MASTER
        );
      });
};
