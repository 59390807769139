import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const StyledMuiAccordion = styled(Accordion)(() => ({
  border: `1px solid ${COLORS.DISABLED.BORDER}`,
  padding: '0px',
  background: `${COLORS.WHITE}`,
  boxShadow: 'none',
  '&.MuiAccordion-root:first-of-type': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '&.MuiAccordion-root:last-of-type': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  '&.Mui-expanded': {
    margin: '0',
  },
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}));

export const StyledMuiAccordionSummary = styled(AccordionSummary)(() => ({
  borderBottom: 'none',
  '&.Mui-expanded': {
    borderBottom: `1px solid ${COLORS.DISABLED.BORDER}`,
  },
  '&#accordion-new': {
    minHeight: '48px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
}));

export const StyledMuiAccordionDetail = styled(AccordionDetails)(() => ({
  padding: 0,
}));
