import { useSnackbar } from 'notistack';

import { Filter } from '@hapvida/hapvida-core-components/src/hooks/useDrawerFilter';

import { toastifyApiErrors } from '@utils';

import { CompaniesListRequestDTO } from '../../infra/services/CompanyService/dtos';
import CompanyService from '../../infra/services/CompanyService';
import { CompanyFilterItem } from './components';
import { useAuth } from '../useAuth';

type LoadCompanyFilterListProps = Omit<CompaniesListRequestDTO, 'id'> & {
  id?: string;
};

export function useCompany() {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();

  const loadCompanyFilterList = async ({
    id = selectedCompany?.id ?? '',
    offset = 0,
    limit = 10,
    ...companiesListRequest
  }: LoadCompanyFilterListProps) => {
    try {
      const service = new CompanyService();

      const { companies, total, totalPerPage } =
        await service.fetchCompaniesList({
          offset,
          limit,
          id,
          ...companiesListRequest,
        });

      const filters: Filter[] =
        companies?.map(company => ({
          content: <CompanyFilterItem company={company} />,
          label: company.name.trade || company.name.legal,
          value: company.id,
        })) ?? [];

      return {
        hasMore: total > offset + totalPerPage,
        totalPerPage,
        filters,
        total,
      };
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return { filters: [] as Filter[], hasMore: false };
    }
  };

  return { loadCompanyFilterList };
}
