import { ButtonBase, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const StyledButton = styled(ButtonBase)`
  background-color: ${COLORS.PRIMARY.BLUE.MAIN}14;
  :hover {
    background-color: ${COLORS.PRIMARY.BLUE.MAIN}14;
  }
  color: ${COLORS.PRIMARY.BLUE.MAIN};
  font-size: 12px;
  font-weight: 600;
  gap: 4px;
  padding: 8px 16px;
  min-width: 128px;
  border-radius: 4px;
`;
