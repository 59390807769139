import { UserPartnerCompanyProps } from '@services/UserService';

import { UserProfileEnum } from '@constants';

export const getWithAccess = (
  editedPartnerCompanies: UserPartnerCompanyProps[],
  originalPartnerCompanies: UserPartnerCompanyProps[],
) => {
  return editedPartnerCompanies.filter(partnerCompany => {
    const isIdenticalInOriginalPartnerCompanies = originalPartnerCompanies.some(
      item =>
        item.partnerCompany === partnerCompany.partnerCompany &&
        item.profile?.id === partnerCompany.profile?.id &&
        item.hasAccess === partnerCompany.hasAccess,
    );

    if (isIdenticalInOriginalPartnerCompanies) return false;

    const { hasAccess, isMainPartnerCompany, mainPartnerCompany } =
      partnerCompany;

    const isMainPartnerCompanySelectedAsMaster = isMainPartnerCompany
      ? false
      : editedPartnerCompanies.some(
          item =>
            item.partnerCompany === mainPartnerCompany &&
            item.hasAccess &&
            item.profile?.name === UserProfileEnum.MASTER,
        );

    return hasAccess && !isMainPartnerCompanySelectedAsMaster;
  });
};
