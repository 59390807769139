import { Stack, css, styled } from '@mui/material';
import { COLORS } from '../../../../themes/colors';

interface SummaryCardContainerProps {
  success?: boolean;
}

export const SummaryCardContainer = styled(Stack)<SummaryCardContainerProps>`
  justify-content: space-between;
  border-radius: 8px;
  padding: 24px;

  ${({ gap }) =>
    !gap &&
    css`
      gap: 24px;
    `}

  ${({ success }) =>
    success
      ? css`
          background: linear-gradient(
              0deg,
              rgba(82, 135, 44, 0.04),
              rgba(82, 135, 44, 0.04)
            ),
            #ffffff;
          border: 1px solid ${COLORS.SECONDARY.GREEN['+1']};
        `
      : css`
          background: ${COLORS.WHITE};
        `}
`;
