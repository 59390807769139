import styled from 'styled-components';

const PrintWrapper = styled.div`
  @media print {
    @page {
      size: A4 portrait;
      margin: 0;
    }
    width: 210mm;
    height: 297mm;
    page-break-after: always;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

export { PrintWrapper, IconContainer };
