import { Typography } from '@mui/material';

interface SimpleTextProps {
  text: string;
  fontWeight?: number;
}

const SimpleText = ({ text, fontWeight }: SimpleTextProps) => {
  return (
    <Typography fontSize={14} fontWeight={fontWeight}>
      {text === '' ? '-' : text}
    </Typography>
  );
};

export default SimpleText;
