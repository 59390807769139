import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';
import { UserDetailsRouter } from '@flows/private/users/pages/UserDetails/router';

export const useValidateUser = () => {
  const [userId, setUserId] = useState<string>('');
  const [showValidator, setShowValidator] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { selectedCompany } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const companyId = selectedCompany?.id;

  const handleValidateUser = async (email: string): Promise<boolean> => {
    try {
      if (companyId) {
        const validadeUser = new UserService();
        const response = await validadeUser.validateExistUser({
          companyId,
          email,
        });
        if (response.content.id) {
          setUserId(response.content.id);
          return true;
        }
        setUserId('');
        return false;
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        setUserId('');
        return false;
      }
      toastifyApiErrors(error, enqueueSnackbar);
      return false;
    }
    return false;
  };

  const handleNext = async (email: string) => {
    const isValidUser = await handleValidateUser(email);
    if (isValidUser) {
      setOpenModal(true);
    } else {
      setShowValidator(false);
    }
  };

  const handleBack = (reset: Function) => {
    reset({ name: '', email: '', confirmedEmail: '', phone: '' });
    setShowValidator(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const goToEditUserData = () => {
    navigate(UserDetailsRouter.dynamicPath({ id: userId }));
  };

  return {
    userId,
    showValidator,
    openModal,
    handleNext,
    handleBack,
    closeModal,
    goToEditUserData,
  };
};
