import { StepsProvider } from './hooks';
import { UserFirstAccessLayout } from './layout';

export function UserFirstAccessPage() {
  return (
    <StepsProvider>
      <UserFirstAccessLayout />
    </StepsProvider>
  );
}
