import React from 'react';

import StartRegisterStep from './steps/StartRegister/step';
import CompanyManagerStep from './steps/CompanyManager/step';
import EmailConfirmationStep from './steps/EmailConfirmation/step';

export interface IRegisterStep {
  name?: string;
  step(props: any): React.ReactElement;
}

export const steps: Array<IRegisterStep> = [
  StartRegisterStep,
  CompanyManagerStep,
  EmailConfirmationStep,
];
