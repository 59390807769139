import affiliateDataMock from './affiliateDataMock';
import beneficiariesMock from './beneficiariesMock';
import beneficiaryMock from './beneficiaryMock';
import companyListSetupMock from './comanyListSetupMock';
import companiesDataMock from './companiesDataMock';
import companiesListMock from './companiesListMock';
import companiesMock from './companiesMock';
import companyDetailsListMock from './companyDetailsListMock';
import invoiceMock from './invoiceMock';
import invoicesMock from './invoicesMock';
import movementsMock from './movementsMock';
import movimentationsMock from './movimentationsMock';

const mockData = {
  beneficiaries: beneficiariesMock,
  beneficiary: beneficiaryMock,
  companiesData: companiesDataMock,
  companiesList: companiesListMock,
  companies: companiesMock,
  invoice: invoiceMock,
  invoices: invoicesMock,
  movements: movementsMock,
  movimentations: movimentationsMock,
  companyListSetup: companyListSetupMock,
  companyDetailSetup: companyDetailsListMock,
  affiliateList: affiliateDataMock,
};

export default mockData;

export * from './partnerCompanyListMock';
