enum PartnerCompaniesStatusEnum {
  REGISTER_ACCESS = 'RegisterAccess',
  PENDING_ACCESS = 'PendingAccess',
  CANCELED = 'Canceled',
  ACTIVE = 'Active',
}

const PARTNER_COMPANIES_STATUS_NAME: Record<
  PartnerCompaniesStatusEnum,
  string
> = {
  [PartnerCompaniesStatusEnum.REGISTER_ACCESS]: 'Cadastro pendente',
  [PartnerCompaniesStatusEnum.PENDING_ACCESS]: 'Acesso pendente',
  [PartnerCompaniesStatusEnum.CANCELED]: 'Cancelada',
  [PartnerCompaniesStatusEnum.ACTIVE]: 'Ativa',
} as const;

export { PartnerCompaniesStatusEnum, PARTNER_COMPANIES_STATUS_NAME };
