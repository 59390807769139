import { TableCell } from '@mui/material';

import ErrorLine from '../../ErrorLine';

import { DependentRow, EmptyRow } from './styles';
import ActionCellChild from '../../ActionCellChild';

type TableHeadParams = {
  id: number;
  name: string;
};

type Dependent = {
  id: number | string;
  itHasError?: boolean;
  errorMessage?: string;
  components: object;
};

interface DependentLineProps {
  dependents: Dependent[];
  TableHeadColumns: TableHeadParams[];
  onEditChild?: (id: string) => void;
}

const DependentLine = ({
  dependents,
  TableHeadColumns,
  onEditChild,
}: DependentLineProps) => {
  const createCells = () => {
    const rows: any = [];
    let dependentsRows;

    if (dependents.length > 0) {
      let cell: React.JSX.Element;

      dependents.forEach((dependent: Dependent, index: number) => {
        let cells: any = [];
        TableHeadColumns.forEach((column: TableHeadParams, index) => {
          if (dependent.components.hasOwnProperty(column.name)) {
            cell = (
              <TableCell key={Math.random() * (10 - index) + Math.random()}>
                {
                  dependent.components[
                    column.name as keyof typeof dependent.components
                  ]
                }
              </TableCell>
            );
            cells.push(cell);
          }
        });

        if (!onEditChild) {
          cells.push(
            <TableCell
              width={100}
              key={Math.random() * (11 - index) + Math.random()}
            />,
          );
        } else {
          cells.push(
            <ActionCellChild
              onEdit={() => {
                onEditChild(dependent.id.toString());
              }}
            />,
          );
        }

        dependentsRows = (
          <DependentRow
            key={Math.random() * (12 - index) + Math.random()}
            ithaserror={
              dependent.itHasError ? dependent.itHasError.toString() : 'false'
            }
            ithaserrormessage={dependent.errorMessage ? 'true' : 'false'}
          >
            {cells}
          </DependentRow>
        );
        rows.push(dependentsRows);

        if (dependent.errorMessage && dependent.errorMessage.length > 0) {
          rows.push(
            <ErrorLine
              key={Math.random() * (13 - index) + Math.random()}
              colspan={TableHeadColumns.length}
              errorMessage={dependent.errorMessage}
            />,
          );
        }

        if (index === dependents.length - 1) {
          rows.push(
            <EmptyRow
              key={Math.random() * (10 - (index + 2)) + Math.random()}
            />,
          );
        }
      });
    }

    return rows;
  };

  return <>{createCells()}</>;
};

export default DependentLine;
