import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { PartnerCompanyListSubHeader } from './components';
import { PartnerCompanyListLayout } from './layout';

export function PartnerCompanyListPage() {
  return (
    <DrawerFilterProvider>
      <PartnerCompanyListSubHeader />
      <PartnerCompanyListLayout />
    </DrawerFilterProvider>
  );
}
