import {
  FiltersConfigProps,
  FiltersLabelProps,
  FilterKeyEnum,
  Gender,
} from '@hapvida/hapvida-core-components';

import { statuses } from '../../../constants/common';

export const movementListFilterValues: FiltersConfigProps = {
  status: statuses,
};

export const movementListFilterLabels: FiltersLabelProps = {
  status: 'status',
  [FilterKeyEnum.ORGANIZATIONAL_GROUP]: {
    gender: Gender.Feminine,
    singular: 'empresa',
    plural: 'empresas',
  },
};
