import { useNavigate } from 'react-router-dom';

import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { CreateUserRouter } from '../../../CreateUser/router';
import { UserListCardTable } from './components';

export function UserListCard() {
  const navigate = useNavigate();

  return (
    <SummaryCardNew.Container>
      <SummaryCardNew.Header
        subtitle="Lista dos usuários que podem acessar a plataforma."
        title="Usuários"
        buttonProps={{
          onClick: () => navigate(CreateUserRouter.path),
          content: 'Novo usuário',
          variant: 'contained',
          color: 'success',
        }}
      />
      <UserListCardTable />
    </SummaryCardNew.Container>
  );
}
