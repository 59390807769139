import { Stack, StackProps } from '@mui/material';

import { IconName } from '@hapvida/hapvida-core-components';

import { ModalType, StyledIcon, SubTitle, Title } from './styles';
import { PropsWithChildren } from 'react';

export interface ModalContentProps {
  contentProps?: StackProps;
  subtitle?: string;
  type?: ModalType;
  icon?: IconName;
  title?: string;
  loading?: boolean;
}

export function ModalContent({
  type = 'info',
  contentProps,
  children,
  subtitle,
  title,
  icon,
}: PropsWithChildren<ModalContentProps>) {
  return (
    <Stack gap={2} padding={3} flexDirection="row">
      {icon && <StyledIcon name={icon} size={32} type={type} />}

      <Stack gap={2} flex={1}>
        {title && (
          <Title type={type} id="dialog-title" variant="h6">
            {title}
          </Title>
        )}
        {subtitle && (
          <SubTitle type={type} id="dialog-subtitle" variant="subtitle1">
            {subtitle}
          </SubTitle>
        )}
        <Stack {...contentProps}>{children}</Stack>
      </Stack>
    </Stack>
  );
}
