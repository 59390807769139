import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes/types';

import { UserDetailsPage } from '.';

export const UserDetailsRouter: IAppDynamicRoute = {
  Page: UserDetailsPage,
  path: '/usuarios/:id',
  name: 'Editar usuário',
  dynamicPath: ({ id }) => `/usuarios/${id}`,
  Icon: <Icon name="account-multiple" />,
  isPrivate: true,
  disabled: false,
};
