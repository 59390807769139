import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';
import { COLORS } from '../colors';

const MuiTableRowTheme: {
  defaultProps?: ComponentsProps['MuiTableRow'];
  styleOverrides?: ComponentsOverrides['MuiTableRow'];
  variants?: ComponentsVariants['MuiTableRow'];
} = {
  variants: [
    {
      props: { hover: true },
      style: {
        ':hover': {
          background:
            'linear-gradient(0deg, rgba(244, 158, 0, 0.04), rgba(244, 158, 0, 0.04)), #FFFFFF !important',
          '.table-action': {
            color: COLORS.PRIMARY.ORANGE.MAIN,
            borderColor: COLORS.PRIMARY.ORANGE.MAIN,
          },
        },
      },
    },
  ],
  styleOverrides: {
    root: {},
  },
};

export default MuiTableRowTheme;
