import { createGlobalStyle, keyframes } from 'styled-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

const gearSpin = keyframes`
 0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const pulse = keyframes`
  0% {
    scale: 0.8;
  }

  50% {
    scale: 1;
  }

  100% {
    scale: 0.8;
  }
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    width: 100%;

    -webkit-overflow-scrolling: touch;
    position: fixed;
  }

  #root {
    height: 100%;
    overflow: auto;
  }

  body {
    margin: 0;
    background: ${COLORS.MONOCHROMATIC.GRAY1};
    font-family: Source Sans Pro;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .gear-animated {
    animation: 8s ${gearSpin} linear infinite;
    transform-origin: 50% 50%;
    transform-box: fill-box;

  }

  .pulse-animated {
    animation: 1s ${pulse} linear infinite;
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }

`;

export default GlobalStyle;
