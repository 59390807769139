import React from 'react';

import StartPasswordRecoveryLayout, { PasswordRecoveryProps } from './layout';

const StartPasswordRecoveryStep: React.FC<PasswordRecoveryProps> = ({
  ...props
}) => {
  return <StartPasswordRecoveryLayout {...props} />;
};

export default StartPasswordRecoveryStep;
