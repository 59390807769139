import { Stack, Typography } from '@mui/material';

import { COLORS, Icon, Loading } from '@hapvida/hapvida-core-components';

import { PartnerCompanyTypeChip } from 'components/PartnerCompanyTypeChip';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

import { RelatedPartnerCompaniesModalLayoutProps } from './types';

export function RelatedPartnerCompaniesModalLayout({
  mainPartnerCompany,
  relatedPartnerCompanyListState,
}: Readonly<RelatedPartnerCompaniesModalLayoutProps>) {
  const { hasNextPage, fetchNextPage, data, isLoading, isError, isFetching } =
    relatedPartnerCompanyListState;
  if (isLoading) {
    return (
      <Loading
        padding={0}
        message={
          <Typography variant="subtitle1">
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </Typography>
        }
      />
    );
  }

  if (isError) {
    return null;
  }

  const handleNextPage = () => {
    if (hasNextPage) fetchNextPage();
  };

  const partnerCompanies =
    data?.pages.map(item => item.partnerCompanies).flat() ?? [];

  return (
    <>
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Typography variant="subtitle1" fontWeight={700}>
          Contratos vinculados ao:
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={700}
          color={COLORS.PRIMARY.BLUE.MAIN}
        >
          {mainPartnerCompany}
        </Typography>
        <PartnerCompanyTypeChip isMainPartnerCompany />
      </Stack>
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Icon name="alert-circle" color={COLORS.PRIMARY.BLUE.MAIN} />
        <Typography variant="text">
          A permissão de acesso ao contrato filho depende da seleção da mãe
        </Typography>
      </Stack>
      <Stack gap={1} flexWrap="wrap" flexDirection="row">
        {partnerCompanies.map(partnerCompany => (
          <PartnerCompanyTypeChip
            key={partnerCompany.partnerCompany}
            isMainPartnerCompany={false}
            text={partnerCompany.partnerCompany}
          />
        ))}
      </Stack>
      {hasNextPage && (
        <Typography
          onClick={isFetching ? undefined : handleNextPage}
          variant="text"
          textAlign="center"
          color={COLORS.PRIMARY.BLUE.MAIN}
          sx={{ cursor: 'pointer' }}
        >
          Mostrar mais {ITEMS_LIMIT_PER_PAGE} contratos
        </Typography>
      )}
    </>
  );
}
