import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { PartnerCompanyContentProps } from '@services/PartnerCompanyService/dtos';
import { PartnerCompanyService } from '@services/PartnerCompanyService';

import { toastifyApiErrors } from '@utils';
import { useAuth, useFilterParams } from '@hooks';
import {
  ITEMS_LIMIT_PER_PAGE,
  PartnerCompaniesQueryKeysEnum,
} from '@constants';

export function usePartnerCompanyListState() {
  const { currentPage, searchParam, mappedFilters } = useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();

  const partnerCompanyService = useMemo(() => new PartnerCompanyService(), []);

  const companyId = selectedCompany?.id ?? '';

  const partnerCompanyListState = useQuery<PartnerCompanyContentProps>({
    queryKey: [
      PartnerCompaniesQueryKeysEnum.PARTNER_COMPANIES_STATE,
      currentPage,
      companyId,
      searchParam,
      mappedFilters,
    ],
    queryFn: () =>
      partnerCompanyService.getPartnerCompany({
        ...searchParam,
        ...mappedFilters,
        companyId,
        offset: ITEMS_LIMIT_PER_PAGE * (currentPage - 1),
        limit: ITEMS_LIMIT_PER_PAGE,
      }),
    onError: (error: unknown) => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshPartnerCompanyStates = () => {
    partnerCompanyListState.remove();
    partnerCompanyListState.refetch();
  };

  return {
    refreshPartnerCompanyStates,
    partnerCompanyListState,
  };
}
