import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const TableContainer = muiStyled(Stack)(() => ({
  background: COLORS.WHITE,

  marginTop: '1.5rem',
  padding: '1.5rem',

  borderRadius: '8px',
}));

export const TableTitle = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '1rem',
  lineHeight: '1.25rem',

  color: COLORS.MONOCHROMATIC.GRAY4,

  marginBottom: '1.5rem',
}));
