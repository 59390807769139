import { Stack, Typography } from '@mui/material';

import { ProfileProps } from '@services/UserService';

import { SelectedProfileStack } from './styles';
import { ResourcesModalButton } from '../ResourcesModalButton';

interface SelectedProfileProps {
  profile: ProfileProps;
}

export function SelectedProfile({ profile }: SelectedProfileProps) {
  return (
    <SelectedProfileStack flexDirection="row" justifyContent="space-between">
      <Stack flexDirection="row" gap="4px">
        <Typography variant="text">Perfil selecionado: </Typography>
        <Typography variant="text" fontWeight={700} color="orange">
          {profile.name}
        </Typography>
      </Stack>
      <ResourcesModalButton profile={profile} />
    </SelectedProfileStack>
  );
}
