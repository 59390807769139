import { useNavigate } from 'react-router-dom';

import { DataGrid } from '@hapvida/hapvida-core-components';

import { CompanyListRouter } from '@flows/private/companies/pages/CompanyList/router';

import { useHomeState } from '../../hooks';
import { getRowList } from './utils';

export function CompanySummary() {
  const { companySummaryDetailsState } = useHomeState();
  const navigateTo = useNavigate();

  if (!companySummaryDetailsState?.isSuccess) return null;

  const company = companySummaryDetailsState.data;

  const handleCompanyListRedirect = () => {
    navigateTo(CompanyListRouter.path);
  };

  const rows = getRowList({ company, handleCompanyListRedirect });

  return (
    <DataGrid
      containerStyles={{ margin: 0 }}
      title="Resumo do contrato"
      customSpacing={3}
      rows={rows}
    />
  );
}
