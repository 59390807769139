import { Mask } from '@hapvida/hapvida-core-components';

export const beneficiaryDocument = (document: string) => {
  if (!document) {
    return '-';
  }
  if (document.length === 11) {
    return Mask.cpf(document);
  }
  return Mask.cpfNDITenDigits(document);
};
