import { Grid, Stack, Typography } from '@mui/material';

import { Mask, Pill } from '@hapvida/hapvida-core-components';

import { CompanyGroupDetail } from '@services/CompanyService/dtos';

import { CompanyTypeEnumColor, CompanyTypeEnumName } from '@constants';

interface CompanyFilterItemProps {
  company: CompanyGroupDetail;
}

export function CompanyFilterItem({ company }: CompanyFilterItemProps) {
  return (
    <Grid container alignItems="center" paddingRight={2.5} marginY={2}>
      <Grid item xs={6}>
        <Stack gap={0.5}>
          <Typography variant="link">
            {company.name.trade || company.name.legal}
          </Typography>
          <Typography variant="smallText">
            {Mask.cnpj(company.document)}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack gap={0.5}>
          <Typography variant="smallText">{company?.partnerCompany}</Typography>
          <Typography variant="smallText">
            <strong>Unidade:</strong> {company?.unitCode}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={3} justifyContent="flex-end" display="flex">
        <Pill
          color={CompanyTypeEnumColor[company.type]}
          text={CompanyTypeEnumName[company.type]}
          fitContent
        />
      </Grid>
    </Grid>
  );
}
