import { Grid } from '@mui/material';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import {
  FiltersDrawerButton,
  ReviewDrawerFilter,
  SearchField,
} from '@components';

import { ExportButton } from './components';
import type { MovementBeneficiariesHeaderProps } from './types';

export function MovementBeneficiariesHeader({
  movementInfo,
  beneficiaries,
}: Readonly<MovementBeneficiariesHeaderProps>) {
  const { filterCount } = useDrawerFilter();

  const { reviewLives, totalLives } = movementInfo.summaryLives;

  const showReviewFilter = reviewLives > 0 && reviewLives !== totalLives;
  /**
   * REMINDER: quando exportação estiver pronta no backd, descomentar esse codigo e usa-lo em vez de false
   * const showExportButton =
   *  status === MovementStatusEnum.FILE_PROCESSED ||
   *  status === MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW ||
   *  status === MovementStatusEnum.ERROR;
   */

  const showExportButton = false;

  const isBeneficiariesListEmpty = beneficiaries.length === 0;

  const disableFilterButton = !filterCount && isBeneficiariesListEmpty;

  return (
    <Grid container spacing={3}>
      <Grid item display="flex" gap={3} flexDirection="row" flex={1}>
        <Grid item xs={12}>
          <SearchField placeholder="Cód. Beneficiário ou CNPJ da empresa" />
        </Grid>
      </Grid>

      {showReviewFilter && (
        <Grid item md={2}>
          <FiltersDrawerButton
            subtitle="Selecione como deseja filtrar os detalhes da movimentação"
            title="Filtrar detalhes da movimentação"
            disabled={disableFilterButton}
          >
            <ReviewDrawerFilter />
          </FiltersDrawerButton>
        </Grid>
      )}

      {showExportButton && (
        <Grid item md={2}>
          <ExportButton movementInfo={movementInfo} />
        </Grid>
      )}
    </Grid>
  );
}
