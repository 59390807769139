import { PropsWithChildren } from 'react';
import TableCell from '@mui/material/TableCell';
import { COLORS } from '../../../../../themes/colors';

import Icon from '../../../../Icon';

import { ErrorRow, ErrorMessageBox, Message } from './styles';

interface ErrorLineProps {
  colspan: number;
  errorMessage?: string;
}

const ErrorLine = ({
  colspan,
  errorMessage,
  children,
}: PropsWithChildren<ErrorLineProps>) => {
  const hasErrorMessage = Boolean(errorMessage && !children);
  return (
    <ErrorRow>
      <TableCell colSpan={colspan + 1}>
        <ErrorMessageBox>
          <Icon name="alert-circle" color={COLORS.ERROR.MAIN} size={24} />
          {hasErrorMessage && <Message>{errorMessage}</Message>}
          {children}
        </ErrorMessageBox>
      </TableCell>
    </ErrorRow>
  );
};

export default ErrorLine;
