import Grid from '@mui/material/Grid';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import {
  AccessProfileDrawerFilter,
  FiltersDrawerButton,
  SearchField,
  SubHeader,
  TypeOfPartnerCompaniesDrawerFilter,
} from '@components';

import { usePartnerCompanyListState } from '../../hooks';

export function PartnerCompanyListSubHeader() {
  const { hasFilters } = useFilterParams();
  const { filterCount } = useDrawerFilter();
  const { partnerCompanyListState } = usePartnerCompanyListState();

  const { isError, isLoading, data } = partnerCompanyListState;

  const totalPartnerCompaniesListLength = data?.total ?? 0;

  const isResultEmpty = totalPartnerCompaniesListLength === 0;

  const isEmpty = isResultEmpty && !hasFilters;

  const disableButtonSearch = isLoading && hasFilters;

  const filterDisabled = !filterCount && (isLoading || isResultEmpty);

  const isLoadingWithoutFilters = !hasFilters && isLoading;

  if (isLoadingWithoutFilters || isError || isEmpty) {
    return null;
  }

  return (
    <SubHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={10}>
          <SearchField
            currentResultLength={totalPartnerCompaniesListLength}
            placeholder="Buscar contrato mãe ou contrato filho"
            disableSearch={disableButtonSearch}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={2}>
          <FiltersDrawerButton
            subtitle="Selecione como deseja filtrar os contratos"
            title="Filtrar contratos"
            disabled={filterDisabled}
          >
            <AccessProfileDrawerFilter />
            <TypeOfPartnerCompaniesDrawerFilter />
          </FiltersDrawerButton>
        </Grid>
      </Grid>
    </SubHeader>
  );
}
