import { Typography } from '@mui/material';
import { useCallback } from 'react';

import { Modal } from '@hapvida/hapvida-core-components';

import { ExternalLinksEnum } from '@constants';

import type { FinancialRedirectModalProps } from './types';

export function FinancialRedirectModal({
  openModal,
  toggleModal,
}: Readonly<FinancialRedirectModalProps>) {
  const handleRedirect = useCallback(() => {
    window.open(ExternalLinksEnum.NDI, '_blank');
    toggleModal();
  }, [toggleModal]);

  return (
    <Modal
      type="default"
      icon="alert"
      open={openModal}
      handleCloseModal={toggleModal}
      secondaryButtonText="Fechar"
      mainButtonText="Acessar portal"
      mainAction={handleRedirect}
      title="Relatórios de histórico financeiro"
    >
      <Typography variant="text">
        Caso queira ter acesso aos{' '}
        <Typography variant="text" fontWeight={700}>
          relatórios de histórico financeiro
        </Typography>{' '}
        anteriores ao mês de novembro de 2024, acesse o portal NDI, clicando no
        botão abaixo e faça o login com sua credencial anterior.
      </Typography>
    </Modal>
  );
}
