import { Box, styled } from '@mui/material';

export const Layout = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2rem',
  justifyContent: 'flex-start',
}));

export const BoxImg = styled(Box)(() => ({
  img: {
    height: '98px',
    width: '108px',
    marginBottom: '24px',
  },
}));
