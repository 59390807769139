import { SummaryCardNew, COLORS } from '@hapvida/hapvida-core-components';

import { BeneficiariesSummaryProps } from './types';
import { TOTAL_BENEFICIARIES_SUMMARY_ITEMS } from './constants';

export function BeneficiariesSummary({
  summaryValues,
}: Readonly<BeneficiariesSummaryProps>) {
  return (
    <SummaryCardNew.Container success>
      <SummaryCardNew.Content
        perLine={TOTAL_BENEFICIARIES_SUMMARY_ITEMS.length}
        startIconColor={COLORS.SECONDARY.GREEN['+1']}
        summary={TOTAL_BENEFICIARIES_SUMMARY_ITEMS}
        summaryValues={summaryValues}
        startIconName="heart"
        pillColor
      />
    </SummaryCardNew.Container>
  );
}
