import { IAppDynamicRoute } from 'routes/types';

import { PrintDiscount } from '.';

export const PrintDiscountRouter: IAppDynamicRoute = {
  Page: PrintDiscount,
  name: 'Carta de desconto',
  path: '/financeiro/detalhes/:id/:invoiceNumber/carta-desconto/:letterNumber',
  dynamicPath: ({ billNumber, letterNumber, invoiceNumber }) =>
    `/financeiro/detalhes/${billNumber}/${invoiceNumber}/carta-desconto/${letterNumber}`,
  isPrivate: true,
  hideSidebar: true,
  hideHeader: true,
};
