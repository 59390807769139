import { ReactNode } from 'react';
import { TableCell } from '@mui/material';

import ErrorLine from '../../ErrorLine';
import ActionCell from '../../ActionCell';

import { MainRow } from './styles';

type TableHeadParams = {
  id: number;
  name: string;
};

type Dependent = {
  id: number | string;
  itHasError?: boolean;
  errorMessage?: string;
  components: object;
};

type Item = {
  id: number | string;
  main: object;
  itHasError?: boolean;
  errorMessage?: string;
  errorComponent?: ReactNode;
  dependents: Dependent[];
};

interface MainLineProps {
  item: Item;
  collapsed: boolean;
  tableHeadColumns: TableHeadParams[];
  onCollapse: () => void;
  onEdit?: (id: string) => void;
  hasCellAction?: boolean;
  rowColor?: string;
}

const MainLine = ({
  item,
  collapsed,
  tableHeadColumns,
  onCollapse,
  onEdit,
  hasCellAction,
  rowColor,
}: MainLineProps) => {
  const errorMessage =
    item.errorMessage && item.errorMessage.length > 0 ? true : false;

  const colSpan = hasCellAction
    ? tableHeadColumns.length
    : tableHeadColumns.length - 1;

  const { errorComponent } = item;

  const createCells = () => {
    const cells: any = [];

    tableHeadColumns.forEach((column: TableHeadParams, index: number) => {
      if (item.main.hasOwnProperty(column.name)) {
        const cell = (
          <TableCell key={Math.random() * (10 - index) + index}>
            {item.main[column.name as keyof typeof item.main]}
          </TableCell>
        );

        cells.push(cell);
      }
    });

    if (onEdit) {
      cells.push(
        <ActionCell
          itHasError={item.itHasError}
          itHasErrorMessage={errorMessage}
          key={
            Math.random() * (10 - tableHeadColumns.length) +
            tableHeadColumns.length
          }
          collapsed={collapsed}
          showCollapseButton={item.dependents.length > 0}
          onEdit={() => {
            onEdit(item.id.toString());
          }}
          onCollapse={onCollapse}
        />,
      );
    }

    return cells;
  };

  return (
    <>
      <MainRow
        key={item.id}
        collapsed={collapsed ? collapsed.toString() : 'false'}
        ithaserror={item.itHasError ? item.itHasError.toString() : 'false'}
        ithaserrormessage={errorMessage ? errorMessage.toString() : 'false'}
        sx={{ background: rowColor && rowColor }}
      >
        {createCells()}
      </MainRow>
      {errorMessage && (
        <ErrorLine colspan={colSpan} errorMessage={item.errorMessage || ''} />
      )}
      {errorComponent && (
        <ErrorLine colspan={colSpan}>{errorComponent}</ErrorLine>
      )}
    </>
  );
};

export default MainLine;
