import { TableCellProps } from '@mui/material';
import React from 'react';

import { SearchParamEnum, TableOrderParams } from '../../../constants';
import { ColumnNameStack, StyledTableCell } from './styles';
import OrderColumn from '../../OrderColumn';
import Typography from '../../Typography';

interface TableHeadCellProps extends TableCellProps {
  onOrderChange?: (params: TableOrderParams) => void;
  order?: TableOrderParams;
  lastLabel?: string;
  label?: string;
  hide?: boolean;
  name?: string;
}

const TableHeadCell: React.FC<TableHeadCellProps> = ({
  onOrderChange,
  hide = false,
  lastLabel,
  label,
  order,
  name,
  ...props
}) => {
  const selectedOrder =
    order && name === order[SearchParamEnum.SORT_BY]
      ? order[SearchParamEnum.ORDER_BY]
      : null;

  const handleOrderChange = () => {
    if (onOrderChange && name) {
      if (order && order[SearchParamEnum.SORT_BY] === name) {
        if (order[SearchParamEnum.ORDER_BY] === 'asc') {
          onOrderChange({
            [SearchParamEnum.ORDER_BY]: 'desc',
            [SearchParamEnum.SORT_BY]: name,
          });
        }

        if (order[SearchParamEnum.ORDER_BY] === 'desc') {
          onOrderChange({});
        }
      } else {
        onOrderChange({
          [SearchParamEnum.ORDER_BY]: 'asc',
          [SearchParamEnum.SORT_BY]: name,
        });
      }
    }
  };

  return (
    <StyledTableCell {...props}>
      {label && (
        <ColumnNameStack
          direction="row"
          alignItems="center"
          gap={1}
          onClick={!hide ? handleOrderChange : undefined}
        >
          <Typography noWrap>
            {label} <br /> {lastLabel}
          </Typography>

          {!hide && <OrderColumn order={selectedOrder} />}
        </ColumnNameStack>
      )}
    </StyledTableCell>
  );
};

export default TableHeadCell;
