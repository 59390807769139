export enum UserCompanyTypeEnum {
  BACKOFFICE = 'Backoffice',
  AGENCY = 'Agency',
  COMPANY = 'Company',
}

export const USER_COMPANY_TYPE_NAME: Record<UserCompanyTypeEnum, string> = {
  [UserCompanyTypeEnum.BACKOFFICE]: 'Backoffice',
  [UserCompanyTypeEnum.AGENCY]: 'Agencia',
  [UserCompanyTypeEnum.COMPANY]: 'Empresa',
};
