import { COLORS } from '@hapvida/hapvida-core-components';

import { BENEFICIARIES_SUMMARY_ITEMS } from '@constants';

export const TOTAL_BENEFICIARIES_SUMMARY_ITEMS = [
  {
    color: COLORS.MONOCHROMATIC.GRAY1,
    key: 'totalBeneficiaries',
    label: 'Total de beneficiários',
  },
  ...BENEFICIARIES_SUMMARY_ITEMS,
];
