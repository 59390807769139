import { KeyboardEvent } from 'react';

interface SearchPartnerCompanyProps {
  placeholder: string;
  setSearchParam: React.Dispatch<React.SetStateAction<string>>;
}

interface SearchPartnerCompanyLayoutProps {
  placeholder: string;
  inputRef: React.RefObject<HTMLInputElement>;
  handlePressEnter: ({ key }: KeyboardEvent) => void;
  handleClickMagnifier: () => void;
  handleClickClose: () => void;
  showCloseButton: boolean;
  handleSearch: () => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

enum KeyboardEvents {
  ENTER = 'Enter',
}

enum SearchLength {
  MIN = 5,
}

export {
  SearchPartnerCompanyProps,
  SearchPartnerCompanyLayoutProps,
  KeyboardEvents,
  SearchLength,
};
