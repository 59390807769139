import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import {
  TableOrderParams,
  SearchParamEnum,
  useDrawerFilter,
} from '@hapvida/hapvida-core-components';

import { filtersToMap, getStringLengthWithoutSpaces } from '../../utils';

export interface SearchParamProps {
  search: string | undefined;
}

export type FilterParamsProps = {
  paramsId?: string;
};

export function useFilterParams(filterParamsProps?: FilterParamsProps) {
  const [params, setParams] = useSearchParams();
  const { activeFilters } = useDrawerFilter();

  const { paramsId } = filterParamsProps || {};

  const generateSearchParam = (searchParam: string) => {
    return paramsId ? paramsId.concat('-', searchParam) : searchParam;
  };

  const orderByKey = generateSearchParam(SearchParamEnum.ORDER_BY);
  const sortByKey = generateSearchParam(SearchParamEnum.SORT_BY);
  const searchKey = generateSearchParam(SearchParamEnum.SEARCH);
  const pageKey = generateSearchParam(SearchParamEnum.PAGE);

  const handleTableOrderChange = useCallback(
    (newTableOrder?: TableOrderParams) => {
      if (newTableOrder) {
        const newOrderBy = newTableOrder[SearchParamEnum.ORDER_BY];
        const newSortBy = newTableOrder[SearchParamEnum.SORT_BY];

        if (newOrderBy && newSortBy) {
          params.set(orderByKey, newOrderBy);
          params.set(sortByKey, newSortBy);
        } else {
          params.delete(orderByKey);
          params.delete(sortByKey);
        }
        setParams(params);
      }
    },
    [orderByKey, sortByKey, params],
  );

  const tableOrder: TableOrderParams = useMemo(() => {
    const orderBy = params.get(orderByKey) ?? undefined;
    const sortBy = params.get(sortByKey) ?? undefined;

    if (orderBy && sortBy) {
      return {
        [SearchParamEnum.ORDER_BY]:
          orderBy as TableOrderParams[SearchParamEnum.ORDER_BY],
        [SearchParamEnum.SORT_BY]: sortBy,
      };
    }

    return {};
  }, [params]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage === 1) {
        params.delete(pageKey);
      } else {
        params.set(pageKey, String(newPage));
      }
      setParams(params);
    },
    [params],
  );

  const handleSearchChange = useCallback(
    (newSearch?: string) => {
      const newSearchLength = getStringLengthWithoutSpaces(newSearch);

      if (newSearchLength > 4 || newSearchLength === 0) {
        if (newSearch && newSearchLength > 4) {
          params.set(searchKey, newSearch);
        } else {
          params.delete(searchKey);
        }
        params.delete(orderByKey);
        params.delete(sortByKey);
        params.delete(pageKey);
        setParams(params);
      }
    },
    [params],
  );

  const currentPage = useMemo(() => Number(params.get(pageKey) ?? 1), [params]);

  const hasFilters = useMemo(() => params.size > 0, [params]);

  const searchParam: SearchParamProps = useMemo(() => {
    const search = params.get(searchKey) ?? undefined;

    return { [SearchParamEnum.SEARCH]: search };
  }, [params]);

  const mappedFilters = useMemo(
    () => filtersToMap(activeFilters),
    [activeFilters],
  );

  return {
    handleTableOrderChange,
    handleSearchChange,
    handlePageChange,
    mappedFilters,
    searchParam,
    currentPage,
    tableOrder,
    hasFilters,
  };
}
