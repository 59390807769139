import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack } from '@mui/material';
import React, { useState } from 'react';

import {
  Typography,
  CountDown,
  TextInput,
  Button,
  Form,
} from '@hapvida/hapvida-core-components';

import EmailLogo from './assets/email.svg';
import { schema } from './validation';
import { Wrapper } from './styles';

interface PasswordRecoveryForm {
  email: string;
}

export interface PasswordRecoveryProps {
  onSendRecovery: (email: string) => void;
}

const StartPasswordRecoveryLayout: React.FC<PasswordRecoveryProps> = ({
  onSendRecovery,
}) => {
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<PasswordRecoveryForm>({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<PasswordRecoveryForm> = async data => {
    await Promise.resolve(onSendRecovery(data.email));

    setShowSubmitSuccess(true);
  };

  if (showSubmitSuccess)
    return (
      <Stack margin={5} marginTop="52px" paddingX="100px">
        <Wrapper>
          <img
            src={EmailLogo}
            alt="Desenho de uma prancheta com uma lista de itens com 'checks' indicando que o cadastro foi finalizado"
          />
        </Wrapper>
        <Form title="Enviamos as instruções para você!">
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography variant="text">
                Cheque seu e-mail com as informações de como recuperar sua
                senha.
              </Typography>
            </Grid>

            <Grid item sm={12}>
              <CountDown
                unavaible={{
                  loadingText: 'Aguarde',
                  loadingInfo: 'para reenviar o e-mail de confirmação',
                }}
                timer={60}
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </Form>
      </Stack>
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: '52px', padding: '0 100px' }}
    >
      <Stack margin={5} gap={3}>
        <Typography variant="h5">Digite seu e-mail cadastrado</Typography>

        <Typography variant="body1">
          E receba informações de como recuperar sua senha.
        </Typography>

        <TextInput
          required
          type="email"
          name="email"
          label="Email cadastrado"
          placeholder="seuemail@mail.com"
          control={control}
          fullWidth
        />

        <Stack alignItems="flex-end">
          <Button
            disabled={!isValid}
            variant="contained"
            type="submit"
            color="success"
          >
            Recuperar senha
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default StartPasswordRecoveryLayout;
