import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Mask, TextInput } from '@hapvida/hapvida-core-components';

import { CompanyDetails } from '@services/CompanyService/dtos';

import {
  CompanyTypeEnumName,
  CompanyTypeEnum,
  COMPANY_STATUS_NAME,
  CompanyStatusEnum,
} from '@constants';

import { CompanyListRouter } from '../../../CompanyList/router';
import { schema } from './validation';
import { StyledPaper } from './styles';

interface CompanyDetailsCardProps {
  onSubmitDataToTable?: (dataForm: CompanyDetails) => void;
  companyDetailsData: CompanyDetails;
}

export function CompanyDetailsCard({
  companyDetailsData,
  onSubmitDataToTable,
}: Readonly<CompanyDetailsCardProps>) {
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<CompanyDetails>({
    mode: 'all',
    defaultValues: {
      ...companyDetailsData,
      type: (CompanyTypeEnumName[companyDetailsData.type as CompanyTypeEnum] ??
        '') as CompanyTypeEnum,
      status: COMPANY_STATUS_NAME[
        companyDetailsData.status
      ] as CompanyStatusEnum,
    },
    resolver: yupResolver(schema),
  });

  const onSubmitForm: SubmitHandler<CompanyDetails> = async dataForm => {
    await Promise.resolve(onSubmitDataToTable?.(dataForm));
    navigate(CompanyListRouter.path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <StyledPaper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Dados Cadastrados</Typography>
          </Grid>

          <>
            <Grid item xs={12} sm={5} md={6}>
              <TextInput
                control={control}
                name="status"
                label="Status da empresa"
                disabled
                variant="filled"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextInput
                control={control}
                name="type"
                label="Tipo"
                disabled
                variant="filled"
                fullWidth
              />
            </Grid>
          </>

          <Grid item xs={12}>
            <TextInput
              control={control}
              name="document"
              label="CNPJ"
              disabled
              mask={Mask.cnpj}
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              control={control}
              name="name.legal"
              label="Razão Social"
              disabled
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              control={control}
              name="name.trade"
              label="Nome fantasia"
              disabled
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">Endereço cadastrado</Typography>
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextInput
              control={control}
              name="address.zipCode"
              placeholder="Digite"
              disabled
              label="CEP"
              mask={Mask.cep}
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10}>
            <TextInput
              control={control}
              name="address.street"
              placeholder="Digite"
              disabled
              label="Endereço"
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextInput
              control={control}
              name="address.number"
              placeholder="Digite"
              disabled
              label="Número"
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextInput
              control={control}
              name="address.stateCode"
              disabled
              label="UF"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextInput
              control={control}
              name="address.city"
              label="Cidade"
              disabled
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextInput
              control={control}
              name="address.complement"
              disabled
              label="Complemento"
              variant="filled"
              fullWidth
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </form>
  );
}
