import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { BeneficiaryDetailsLayout } from './layout';

export function BeneficiaryDetailsPage() {
  return (
    <DrawerFilterProvider>
      <BeneficiaryDetailsLayout />
    </DrawerFilterProvider>
  );
}
