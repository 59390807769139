import { Stack } from '@mui/material';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import { MovementStatusEnum } from '@constants';

import { useMovementDetailsState } from './hooks';
import {
  MovementSummaryCard,
  MovementDetailsCard,
  MovementAlertsCard,
  MovementInfoCard,
} from './components';

export function MovementDetailsLayout() {
  const {
    refreshMovementDetailsStates,
    movementSummaryState,
    movementLivesState,
    movementInfoState,
  } = useMovementDetailsState();

  const hasError =
    movementSummaryState.isError ||
    movementLivesState.isError ||
    movementInfoState.isError;

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={refreshMovementDetailsStates} />;
  }

  const isLoading =
    movementInfoState.isLoading ||
    movementLivesState.isFetching ||
    movementSummaryState.isFetching;

  if (isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const {
    movementDetails: { status },
    summaryLives,
  } = movementInfoState.data.content;

  const movementProcessed =
    status === MovementStatusEnum.FILE_PROCESSED ||
    status === MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW;

  const movementUnableToStart = status === MovementStatusEnum.UNABLE_TO_START;

  const hasSummaryLives = Boolean(summaryLives?.totalLives);

  return (
    <Stack paddingTop={3} paddingBottom={10} gap={3}>
      <MovementAlertsCard />

      <MovementInfoCard />

      {movementProcessed && hasSummaryLives && <MovementSummaryCard />}

      {!movementUnableToStart && hasSummaryLives && <MovementDetailsCard />}
    </Stack>
  );
}
