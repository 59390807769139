export enum UserProfileEnum {
  ADM = 'Administrador',
  FINANCIAL = 'Financeiro',
  MOVEMENT = 'Movimentação',
  MASTER = 'Master',
}

export const USER_PROFILE_NAME: Record<UserProfileEnum, string> = {
  [UserProfileEnum.ADM]: 'Administrador',
  [UserProfileEnum.FINANCIAL]: 'Financeiro',
  [UserProfileEnum.MOVEMENT]: 'Movimentação',
  [UserProfileEnum.MASTER]: 'Master',
};
