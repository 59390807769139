import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import movementImage from '@assets/images/movement.svg';
import { useAuth } from '@hooks';
import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';
import { MOVIMENTATION_STEPS_KEYS } from '@flows/private/movements/pages/MovimentationSteps/constants/keys';

export function EmptyMovements() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const userName = user?.name ?? '';

  const handleNewMovement = () => {
    navigate(
      MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.NEW_MOVIMENTATION,
      }),
    );
  };

  return (
    <Stack alignItems="center" padding={5} gap={3}>
      <img
        alt="Sem movimentações recentes"
        src={movementImage}
        draggable={false}
        height={112}
      />
      <Typography variant="h6" textAlign="center" maxWidth={472}>
        {userName} parece que você ainda não efetuou nenhuma movimentação de
        beneficiários.
      </Typography>
      <Button
        onClick={handleNewMovement}
        variant="contained"
        color="success"
        disableRipple
      >
        Movimentar beneficiários agora
      </Button>
    </Stack>
  );
}
