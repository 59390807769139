import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes/types';

import { MovementDetailsPage } from './index';

export const MovimentationDetailRouter: IAppDynamicRoute = {
  Page: MovementDetailsPage,
  path: '/movimentacoes/:movementId',
  dynamicPath: ({ movementId }) => `/movimentacoes/${movementId}`,
  Icon: <Icon name="clipboard-text-outline" size={32} />,
  name: 'Movimentações',
  isPrivate: true,
};
