import { Stack } from '@mui/material';

import {
  ErrorLoadingPage,
  NoSearchResults,
  FilterSummary,
  Loading,
} from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';
import { EmptyResult } from '@components';

import { CompanyListCard } from './components';
import { useCompaniesListState } from './hooks';

export function CompanyListLayout() {
  const { companyListState, refreshCompanyListStates } =
    useCompaniesListState();
  const { hasFilters } = useFilterParams();
  const { user } = useAuth();

  const userName = user?.name ?? '';

  if (companyListState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (companyListState.isError) {
    return <ErrorLoadingPage onReloadClick={refreshCompanyListStates} />;
  }

  const totalCompaniesLength = companyListState.data.total ?? 0;

  const isEmpty = totalCompaniesLength === 0 && !hasFilters;

  if (isEmpty) {
    return (
      <EmptyResult
        message={`${userName}, nenhuma empresa está disponível para você.`}
      />
    );
  }

  const isSearchResultEmpty = totalCompaniesLength === 0 && hasFilters;

  const filterSummaryLabel = `${totalCompaniesLength} ${
    totalCompaniesLength === 1 ? 'empresa' : 'empresas'
  }`;

  return (
    <Stack paddingTop={3} gap={3} paddingBottom={8}>
      <FilterSummary totalLabel={filterSummaryLabel} />

      {isSearchResultEmpty && <NoSearchResults padding={5} />}

      {!isSearchResultEmpty && (
        <CompanyListCard companyListData={companyListState.data} />
      )}
    </Stack>
  );
}
