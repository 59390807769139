import {
  SidebarContent,
  SidebarHeader,
  SidebarButton,
  SidebarRoot,
  SidebarItem,
  SidebarItemGroup,
} from './components';

export * from './hooks';

export const Sidebar = {
  Content: SidebarContent,
  Header: SidebarHeader,
  Button: SidebarButton,
  Root: SidebarRoot,
  Item: SidebarItem,
  ItemGroup: SidebarItemGroup,
};
