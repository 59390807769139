/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

interface PromptProps {
  disabled?: boolean;
}

export const usePrompt = ({ disabled }: PromptProps = { disabled: false }) => {
  const listener = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = true;
  };

  useEffect(() => {
    if (!disabled) {
      window.addEventListener('beforeunload', listener, { capture: true });
    }

    return () =>
      window.removeEventListener('beforeunload', listener, { capture: true });
  }, [disabled]);
};
