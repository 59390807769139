import { useNavigate } from 'react-router-dom';

import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { BackofficeUserListCardTable } from './components';
import { CreateBackofficeUserRouter } from '../../../BackofficeUserCreation/router';

export function BackofficeUserListCard() {
  const navigate = useNavigate();
  return (
    <SummaryCardNew.Container>
      <SummaryCardNew.Header
        subtitle="Lista dos usuários que podem acessar a plataforma na visão backoffice."
        title="Lista de usuários backoffice"
        buttonProps={{
          content: 'Novo usuário backoffice',
          variant: 'contained',
          color: 'success',
          onClick: () => navigate(CreateBackofficeUserRouter.path),
        }}
      />
      <BackofficeUserListCardTable />
    </SummaryCardNew.Container>
  );
}
