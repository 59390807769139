import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import AuthService from '@services/AuthService';

import { useAuth } from 'hooks/useAuth';
import { UserType } from '@contexts';
import { ThemesEnum, useCurrentThemeState } from 'hooks/useCurrentThemeState';

interface UseCurrentUserStateProps {
  newRefreshToken?: string;
  newToken?: string;
}

export function useCurrentUserState({
  newToken,
  newRefreshToken,
}: UseCurrentUserStateProps) {
  const { changeCurrentTheme } = useCurrentThemeState();
  const { updateAccount, token = '', refreshToken = '' } = useAuth();

  const authService = useMemo(() => new AuthService(), []);

  const currentToken = newToken ?? token;
  const currentRefreshToken = newRefreshToken ?? refreshToken;

  const currentUserState = useQuery({
    queryKey: ['currentUserState', currentToken],
    queryFn: () => authService.authenticate(currentToken),
    enabled: Boolean(currentToken),
    onSuccess: currentUser => {
      updateAccount({
        token: currentToken,
        user: currentUser,
        refreshToken: currentRefreshToken,
      });

      const isBackoffice = currentUser.companyType === UserType.Backoffice;

      changeCurrentTheme(
        isBackoffice ? ThemesEnum.DARK_THEME : ThemesEnum.LIGHT_THEME,
      );
    },
    onError: () => {
      window.location.replace(process.env.REACT_APP_LOGIN_URL);
    },
  });

  const refreshCurrentUserState = () => {
    currentUserState.remove();
    currentUserState.refetch();
  };

  return {
    refreshCurrentUserState,
    currentUserState,
  };
}
