import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { HomePage } from './index';

export const HomeRouter: IAppRoute = {
  Page: HomePage,
  path: '/home',
  name: 'Início',
  isPrivate: true,
  Icon: <Icon name="home" />,
};
