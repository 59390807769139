import { Filter } from '../../../hooks/useDrawerFilter';
import { isFilterSelected } from './is-filter-selected';

export function selectFilters(
  currentFilters: Filter[],
  filtersToSelect: Filter[],
) {
  const allFiltersSelected = filtersToSelect.every(filter =>
    isFilterSelected(currentFilters, filter),
  );

  if (allFiltersSelected) {
    return currentFilters.filter(
      currentFilter => !isFilterSelected(filtersToSelect, currentFilter),
    );
  }

  const newFilters = filtersToSelect.filter(
    filter => !isFilterSelected(currentFilters, filter),
  );

  return [...currentFilters, ...newFilters];
}
