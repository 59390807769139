import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

interface OperationalCostModalModalProps {
  openModal: boolean;
  toggleModal: () => void;
}

export function OperationalCostModal({
  openModal,
  toggleModal,
}: Readonly<OperationalCostModalModalProps>) {
  const { redirectUser } = useRedirectUser();

  const handleRedirectLegacyPortal = () => {
    redirectUser(UserRedirectsEnum.OPERATING_COST_REPORT);
  };
  return (
    <Modal
      type="default"
      icon="alert"
      open={openModal}
      handleCloseModal={toggleModal}
      secondaryButtonText="Fechar"
      mainButtonText="Acessar portal"
      mainAction={handleRedirectLegacyPortal}
      title="Relatório custo operacional"
    >
      <Typography variant="text">
        A funcionalidade de{' '}
        <Typography variant="text" fontWeight={700}>
          relatório de custo operacional
        </Typography>{' '}
        estará disponível em breve. No momento, você pode visualizar os dados do
        relatório em uma nova guia.
      </Typography>
    </Modal>
  );
}
