import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import {
  BENEFICIARY_MOVEMENT_STATUS_NAME,
  BeneficiaryMovementStatusEnum,
} from '@constants';

import { Container, IconStyled, Label } from './style';

export const MovementEnumValues: {
  [statusType: string]: {
    icon?: IconName;
    name: string;
    color: string;
    background: string;
    size?: number;
  };
} = {
  [BeneficiaryMovementStatusEnum.PROCESSED]: {
    icon: 'hap-b2b-check-filled',
    name: BENEFICIARY_MOVEMENT_STATUS_NAME[
      BeneficiaryMovementStatusEnum.PROCESSED
    ],
    color: COLORS.SECONDARY.GREEN['+1'],
    background: COLORS.SECONDARY.GREEN['-1'],
  },
  [BeneficiaryMovementStatusEnum.PROCESSING]: {
    icon: 'timer',
    name: BENEFICIARY_MOVEMENT_STATUS_NAME[
      BeneficiaryMovementStatusEnum.PROCESSING
    ],
    color: COLORS.PRIMARY.BLUE['+1'],
    background: COLORS.SECONDARY.BLUE['-1'],
    size: 22,
  },
  [BeneficiaryMovementStatusEnum.UNPROCESSED]: {
    icon: 'hap-b2b-subtitles-outlined',
    name: BENEFICIARY_MOVEMENT_STATUS_NAME[
      BeneficiaryMovementStatusEnum.UNPROCESSED
    ],
    color: COLORS.BLACK,
    background: COLORS.MONOCHROMATIC.GRAY5,
  },
  [BeneficiaryMovementStatusEnum.REVIEWED]: {
    icon: 'hap-b2b-warning',
    name: BENEFICIARY_MOVEMENT_STATUS_NAME[
      BeneficiaryMovementStatusEnum.REVIEWED
    ],
    color: COLORS.SECONDARY.RED.MAIN,
    background: COLORS.SECONDARY.RED['-2'],
  },
  [BeneficiaryMovementStatusEnum.CANCELED]: {
    icon: 'close',
    name: BENEFICIARY_MOVEMENT_STATUS_NAME[
      BeneficiaryMovementStatusEnum.CANCELED
    ],
    color: COLORS.SECONDARY.RED['+1'],
    background: COLORS.ERROR.LIGHT,
  },
  [BeneficiaryMovementStatusEnum.CANCELING]: {
    name: BENEFICIARY_MOVEMENT_STATUS_NAME[
      BeneficiaryMovementStatusEnum.CANCELING
    ],
    color: COLORS.SECONDARY.RED['+1'],
    background: COLORS.ERROR.LIGHT,
  },
};

interface StatusTypeProps {
  statusType: string;
}

const StatusMovementLife = ({ statusType }: StatusTypeProps) => {
  return (
    <Container
      color={MovementEnumValues[statusType]?.color}
      background={MovementEnumValues[statusType]?.background}
    >
      <IconStyled
        name={MovementEnumValues[statusType]?.icon as IconName}
        color={MovementEnumValues[statusType]?.color}
        size={MovementEnumValues[statusType]?.size ?? 18}
      />
      <Label color={MovementEnumValues[statusType]?.color}>
        {MovementEnumValues[statusType]?.name ?? '-'}
      </Label>
    </Container>
  );
};

export default StatusMovementLife;
