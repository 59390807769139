import { Key, ReactNode } from 'react';
import { Stack, StackProps } from '@mui/material';

import {
  BaseList,
  BaseListPaginationProps,
} from '@hapvida/hapvida-core-components';

interface ListProps<T> {
  data: T[];
  keyExtractor: (item: T) => Key;
  renderItem: (item: T) => ReactNode;
  header?: ReactNode;
  headerProps?: StackProps;
  listProps?: StackProps;
  containerProps?: StackProps;
  paginationProps?: BaseListPaginationProps;
}

export function List<T>({
  data,
  renderItem,
  keyExtractor,
  header,
  headerProps,
  listProps,
  containerProps,
  paginationProps,
}: Readonly<ListProps<T>>) {
  const hasMultiPages =
    paginationProps &&
    paginationProps?.totalItems > paginationProps?.totalPerPage;

  return (
    <BaseList.Root {...containerProps}>
      <Stack gap={2}>
        {header && <BaseList.Header {...headerProps}>{header}</BaseList.Header>}

        {data.map(item => {
          return (
            <BaseList.Item {...listProps} key={keyExtractor(item)}>
              {renderItem(item)}
            </BaseList.Item>
          );
        })}
      </Stack>
      {hasMultiPages && <BaseList.Pagination {...paginationProps} />}
    </BaseList.Root>
  );
}
