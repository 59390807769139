import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ProfileProps, UserPartnerCompanyProps } from '@services/UserService';

import { useAccessManagement } from 'components/ProfileSelectionCard/hooks/index';

interface ProfileCardContextData {
  selectedProfile?: ProfileProps;
  handleSelectProfile: (profile: ProfileProps) => void;
  currentSelectedPartnerCompanies: UserPartnerCompanyProps[];
}

const ProfileCardContext = createContext<ProfileCardContextData>(
  {} as ProfileCardContextData,
);

interface ProfileCardProviderProps {
  initialSelectedProfile?: ProfileProps;
}

function ProfileCardProvider({
  children,
  initialSelectedProfile,
}: PropsWithChildren<ProfileCardProviderProps>) {
  const [selectedProfile, setSelectedProfile] = useState<
    ProfileProps | undefined
  >(initialSelectedProfile);

  const { updateProfileSelection, selectedPartnerCompanies } =
    useAccessManagement();

  const handleSelectProfile = (profile: ProfileProps) => {
    updateProfileSelection({
      prevSelectedProfile: selectedProfile,
      newSelectedProfile: profile,
    });
    setSelectedProfile(profile);
  };

  const currentSelectedPartnerCompanies = selectedPartnerCompanies.filter(
    item => (selectedProfile ? item.profile?.id === selectedProfile.id : true),
  );

  const valueContextProvider = useMemo(
    () => ({
      handleSelectProfile,
      selectedProfile,
      currentSelectedPartnerCompanies,
    }),
    [handleSelectProfile, selectedProfile, currentSelectedPartnerCompanies],
  );

  return (
    <ProfileCardContext.Provider value={valueContextProvider}>
      {children}
    </ProfileCardContext.Provider>
  );
}

function useProfileCard() {
  const context = useContext(ProfileCardContext);

  if (!context) {
    throw new Error('useProfileCard must be used within ProfileCardProvider');
  }

  return context;
}

export { useProfileCard, ProfileCardProvider };
