const companyListSetupMock = {
  companies: [
    {
      id: 'c4dcc76a-6f94-4403-9b8c-d19701c761cd',
      status: 'PendingAccess',
      type: 'Company',
      legal: 'Teste mock',
      document: '92817613000134',
    },
    {
      id: 'b95125f8-cef8-4e71-b99a-acc9a659bbb3',
      status: 'RegisterAccess',
      type: 'Company',
      legal: 'Teste 2',
      document: '56128106000120',
    },
    {
      id: '1dad0f02-b486-4e0a-aaea-6981505cc09f',
      status: 'Active',
      type: 'Company',
      legal: 'razão 1',
      document: '50593072000168',
    },
    {
      id: '52669c12-a291-475a-976e-5e914a6075a8',
      status: 'RegisterAccess',
      type: 'Company',
      legal: 'razao social',
      document: '76829028000106',
    },
    {
      id: 'c5c15a9c-1cc2-4290-b9f7-d4c18c2bdbf6',
      status: 'Canceled',
      type: 'Company',
      legal: 'nome',
      document: '55833286000188',
    },
    {
      id: '40becdad-723e-4de7-a0a7-8866391927a4',
      status: 'Canceled',
      type: 'Company',
      legal: 'empresa de homologação 5',
      document: '49670780000176',
    },
    {
      id: '8b2189f5-407e-4b5c-bb63-8f5fa048a13e',
      status: 'PendingAccess',
      type: 'Company',
      legal: 'boticario a',
      document: '92763554000169',
    },
    {
      id: '302ec8ae-5aec-40d6-bd14-f1c4fb18049a',
      status: 'Active',
      type: 'Company',
      legal: 'boticario 23',
      document: '97987022000129',
    },
    {
      id: '52a86800-543e-4914-8975-1c1f58501cf3',
      status: 'PendingAccess',
      type: 'Company',
      legal: 'boticario 23',
      document: '99203440000103',
    },
  ],
  total: 9,
  totalPerPage: 9,
};

export default companyListSetupMock;
