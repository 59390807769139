import {
  FilterKeyEnum,
  SearchParamEnum,
} from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { HealthOperatorEnum } from '@constants';

import {
  CoparticipationReportProps,
  InvoiceProps,
  FileData,
} from './BillDetailsDTO';

export interface Bill {
  coparticipationAnalytical: CoparticipationReportProps[];
  healthOperator: HealthOperatorEnum;
  analyticalStatements: FileData[];
  verificationCode?: string;
  companyDocument?: string;
  partnerCompany: string;
  invoice?: InvoiceProps;
  billingNumber: string;
  personNumber: number;
  companyId: string;
  billType: string;
  dueDate: string;
  amount: number;
  status: string;
  number: string;
  month: string;
  year: string;
}

export interface GetMonthlyAnalyticalReport {
  createdAt?: string;
  path: string;
  fileName: string;
  size?: string;
  uploadName?: string;
}

export interface GetBillListRequestDTO {
  companyId: string;
  limit: number;
  offset: number;
  [SearchParamEnum.ORDER_BY]?: string;
  [SearchParamEnum.SORT_BY]?: string;
  search?: string;
  [FilterKeyEnum.START_DATE]?: string;
  [FilterKeyEnum.END_DATE]?: string;
  [FilterKeyEnum.HEALTH_OPERATOR]?: string;
  [FilterKeyEnum.ORGANIZATIONAL_GROUP]?: string;
}

export interface BillListProps {
  totalPerPage: number;
  bills: Bill[];
  total: number;
}

export interface GetBillListResponseDTO {
  content: BillListProps;
  timestamp: number;
  traceId: string;
  notifications: any[];
}

export const BillType: { [x: string]: string } = {
  OperationalCost: 'Custo Operacional',
  SingleOperatingCost: 'Custo Operacional Avulso',
  MonthlyPayment: 'Mensalidade',
  SingleMonthlyFee: 'Mensalidade Avulso',
  Participation: 'Participação',
  SingleParticipation: 'Participação Avulso',
  Prepayment: 'Pré-Pagamento',
  SinglePrepayment: 'Pré-Pagamento Avulso',
  RetroactiveReadjustment: 'Reajuste Retroativo',
  ClaimRecovery: 'Recuperação de Sinistro',
  SingleClaimRecovery: 'Recuperação de Sinistro Avulso',
  OccupationalHealth: 'Saúde Ocupacional',
  ValuesCooperatedAct: 'Valores Ato Cooperado',
  CarnetComplement: 'Carnet Complementar-coparticipação',
  HealthPlanMonthlyFee: 'Mensalidade Plano Saúde',
  ExtraInvoice: 'Fatura extra',
  FirstMonthlyHealthPlan: 'Primeira Mensalidade Plano Saúde',
  SpecialCommission: 'Comissão Especial',
  NfCommissionAdvance: 'Nf Adiantamento De Comissão',
  CltCommission: 'Comissão Clt',
  SalesCommission: 'Comissão De Vendas',
  CommissionAdvance: 'Adiantamento De Comissão',
  CustomerRefunc: 'Reembolso Cliente',
  SpecialPendingAdvance: 'Adiantamento De Pendencia Especial',
  PaymentOfMedicalPayroll: 'Pagamento De Folha Medica',
  DuplicateDentalService: 'Duplicata Serviço Odontologia',
  TradeDuplicate: 'Duplicata Mercantil',
  PromissoryNotes: 'Notas Promissória',
  InvoiceNote: 'Nota De Seguro',
  Receipt: 'Recibo',
  BillOfExchange: 'Letra De Cambio',
  Leasing: 'Leasing',
  Cheeck: 'Cheque',
  Contract: 'Contrato',
  ServiceDuplicate: 'Duplicata De Serviço',
  DebitNote: 'Nota De Debito',
  BankingTitle: 'Titulo Bancário',
  Request: 'Pedido',
  Invoice: 'Nota Fiscal',
  HealthPlanMembershipFee: 'Taxa De Adesão Plano Saúde',
  PlanChange: 'Mudança De Plano',
  DependeentInclusion: 'Inclusão De Dependente',
  DentalAdhesionFeee: 'Taxa Adesão Odontologia',
  PlanCancellation: 'Cancelamento Do Plano',
  Others: 'Outros',
  ProceedureCapitalization: 'Capitalização De Procedimento',
  FriendSellerCommission: 'Comissão Vendedor Amigo',
  PersonnelExpenses: 'Despesas De Pessoal',
  Tax: 'Impostos',
  Advancee: 'Adiantamento',
  Rpa: 'Rpa',
  ExpensesRefund: 'Reembolso De Despesas',
  JuridicalProcesses: 'Processos Jurídicos',
  OnlineSales: 'Vendas Online',
  SupplementaryInvoice: 'Fatura Complementar',
};

export const BillStatus: {
  [x: string]: {
    color: string;
    label: string;
  };
} = {
  Open: { label: 'Em Aberto', color: COLORS.PRIMARY.ORANGE.MAIN },
  Paid: { label: 'Paga', color: COLORS.SECONDARY.GREEN.MAIN },
  Canceled: { label: 'Cancelada', color: COLORS.SECONDARY.RED.MAIN },
  Sent: { label: 'Enviada', color: COLORS.SECONDARY.GREEN.MAIN },
  Renegotiated: { label: 'Renegociada', color: COLORS.MONOCHROMATIC.GRAY4 },
  CanceledIssue: {
    label: 'Emissão Cancelada',
    color: COLORS.SECONDARY.RED.MAIN,
  },
  PartialWriteOff: { label: 'Baixa Parcial', color: COLORS.SECONDARY.RED.MAIN },
  ChargeSent: { label: 'Enviado Cobrança', color: COLORS.PRIMARY.ORANGE.MAIN },
  OpenAndCharged: {
    label: 'Em Aberto/Cobrança',
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
  Settled: { label: 'Liquidado', color: COLORS.SECONDARY.GREEN.MAIN },
  SettledProtest: {
    label: 'Liquidado Protesto',
    color: COLORS.SECONDARY.GREEN.MAIN,
  },
  InformedSettlement: {
    label: 'Liquidação Informada',
    color: COLORS.SECONDARY.GREEN.MAIN,
  },

  SettledNotCharged: {
    label: 'Liquidado N/COB',
    color: COLORS.SECONDARY.GREEN.MAIN,
  },
  BankDeposit: {
    label: 'Depósito em Conta',
    color: COLORS.SECONDARY.GREEN.MAIN,
  },
  SettledPartialy: {
    label: 'Liquidado Parcialmente',
    color: COLORS.SECONDARY.GREEN.MAIN,
  },
  Protest: { label: 'Protesto', color: COLORS.SECONDARY.RED.MAIN },
  ProtestSettledPartialy: {
    label: 'Protesto Parcial Liquidado',
    color: COLORS.SECONDARY.ORANGE.MAIN,
  },
  OpenCharged: {
    label: 'Em Aberto/Cobrança',
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
  PartialWriteOffRequest: {
    label: 'Baixa Parcial Pedido',
    color: COLORS.SECONDARY.RED.MAIN,
  },
  PartiallySettled: {
    label: 'Liquidado Parcial',
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
  CanceledBalance: {
    label: 'Saldo Cancelado',
    color: COLORS.PRIMARY.RED.MAIN,
  },
  RequestDownBilling: {
    label: 'Solic Baixa Cobrança',
    color: COLORS.PRIMARY.RED.MAIN,
  },
  RefusedBilling: {
    label: 'Recusado Cobrança',
    color: COLORS.PRIMARY.RED.MAIN,
  },
  ProtestBilling: {
    label: 'Protesto P/cob',
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
  OrderDown: {
    label: 'Baixa Pedido',
    color: COLORS.PRIMARY.GREEN.MAIN,
  },
  OrderCanceled: {
    label: 'Pedido Cancelado',
    color: COLORS.PRIMARY.RED.MAIN,
  },
  ProtestSettledPartially: {
    label: 'Liquidado parc/protesto',
    color: COLORS.PRIMARY.GREEN.MAIN,
  },
};
