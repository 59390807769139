import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { BeneficiaryListPage } from './index';

export const BeneficiaryListRouter: IAppRoute = {
  Page: BeneficiaryListPage,
  path: '/beneficiarios',
  name: 'Lista de beneficiários',
  isPrivate: true,
  Icon: <Icon name="clipboard-pulse" />,
};
