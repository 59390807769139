import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { PartnerCompanyListPage } from '.';

export const PartnerCompanyListRouter: IAppRoute = {
  Page: PartnerCompanyListPage,
  path: '/contratos',
  name: 'Contratos',
  isPrivate: true,
  Icon: <Icon name="building" />,
  disabled: false,
};
