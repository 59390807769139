import React from 'react';

import EmailConfirmationLayout from './layout';

type EmailConfirmationProps = {
  registerData: void;
};

const EmailConfirmationStep: React.FC<EmailConfirmationProps> = ({
  registerData,
}) => {
  return <EmailConfirmationLayout registerData={registerData} />;
};

export default EmailConfirmationStep;
