import { Stack, styled } from '@mui/material';

export const RadioWrapper = styled(Stack)(() => ({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',

  '& .MuiTypography-root': {
    marginBottom: '-4px',
  },

  '& .MuiTypography-root:first-child': {
    fontSize: '14px',
    fontWeight: 600,
  },
}));
