import { User } from '@services/BeneficiaryService/dtos/ListBeneficiariesDTO';

interface PersonProps {
  data: User;
}

const Genders: {
  [x: string]: string;
} = {
  M: 'MASCULINO',
  F: 'FEMININO',
};

export { Genders, PersonProps };
