import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { BillList } from '.';

export const BillListRouter: IAppRoute = {
  Page: BillList,
  path: '/financeiro',
  name: 'Lista de faturas',
  isPrivate: true,
  Icon: <Icon name="money" />,
};
