import { Stack, Typography } from '@mui/material';
import type { UseQueryResult } from '@tanstack/react-query';

import { CircularProgress, Icon } from '@hapvida/hapvida-core-components';

import type { ReportMonthlyProps } from '@services/FinancialService';

import { ButtonLink } from '../components';
import {
  REPORT_MONTHLY_MESSAGE,
  REPORT_MONTHLY_MESSAGE_COLOR,
  REPORT_MONTHLY_TYPE,
  ReportMonthlyMessageEnum,
} from '../constants';

export const getReportFileRows = (
  fileType: string,
  fetchState: UseQueryResult<ReportMonthlyProps>,
) => {
  if (fetchState.isLoading) {
    return <CircularProgress size={16} />;
  }

  const isError =
    fetchState.data?.content?.message ===
    REPORT_MONTHLY_MESSAGE[ReportMonthlyMessageEnum.ERROR];
  if (isError && fetchState.isError) {
    return <Typography variant="body2">-</Typography>;
  }

  const notifications = fetchState.data?.notifications || [];

  const isProcessing =
    notifications.length > 0 &&
    notifications[0]?.type ===
      REPORT_MONTHLY_TYPE[ReportMonthlyMessageEnum.PROCESSING];

  if (isProcessing) {
    const color =
      REPORT_MONTHLY_MESSAGE_COLOR[ReportMonthlyMessageEnum.PROCESSING];

    return (
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <Icon color={color} name="progress-clock" size={18} />
        <Typography variant="body2" color={color} fontWeight={600}>
          Em processamento
        </Typography>
      </Stack>
    );
  }

  if (fetchState.data?.content?.link) {
    return (
      <ButtonLink
        onClick={() => window.open(fetchState.data.content.link, '_blank')}
        text={`Relatório.${fileType.toLowerCase()}`}
      />
    );
  }

  return <Typography variant="body2">-</Typography>;
};
