export function generateUrlParams(paramsObject: Object) {
  const filteredParams = Object.fromEntries(
    Object.entries(paramsObject).filter(
      ([, value]) => value !== undefined && value !== null && value !== '',
    ),
  );

  const params = new URLSearchParams();

  Object.entries(filteredParams).forEach(([key, value]) => {
    if (typeof value === 'string' && value.includes(',')) {
      const valuesArray = value.split(',');
      valuesArray.forEach(val => {
        params.append(key, val);
      });
    } else {
      params.append(key, String(value));
    }
  });

  return params;
}
