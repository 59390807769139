import { Fragment, ReactNode, useMemo } from 'react';
import { Divider, Grid, Stack } from '@mui/material';

import { SummaryItemProps, SummaryRowItem } from '../SummaryRowItem';
import { IconName } from '../../../Icon/types';
import Icon from '../../../Icon';

interface SummaryCardContentProps {
  summaryValues: Record<string, any>;
  summary: SummaryItemProps[];
  startIconName?: IconName;
  defaultValue?: ReactNode;
  startIconColor?: string;
  pillColor?: boolean;
  perLine: number;
}

export function SummaryCardContent({
  pillColor = false,
  startIconColor,
  summaryValues,
  startIconName,
  defaultValue,
  perLine,
  summary,
}: Readonly<SummaryCardContentProps>) {
  const summaryRows = useMemo(
    () =>
      summary.reduce(
        (summaryRows: SummaryItemProps[][], summaryItem, index) => {
          const rowIndex = Math.floor(index / perLine);
          summaryRows[rowIndex] = [
            ...(summaryRows[rowIndex] || []),
            summaryItem,
          ];
          return summaryRows;
        },
        [],
      ),
    [summary, perLine],
  );

  return (
    <Stack gap={3}>
      {summaryRows.map((summaryRow, rowIndex) => (
        <Fragment key={`summary-row-${String(rowIndex)}`}>
          <Grid alignItems="center" flexWrap="nowrap" container>
            {summaryRow.map((item, summaryIndex) => {
              return (
                <Fragment key={`summary-item-${String(summaryIndex)}`}>
                  {summaryIndex % perLine !== 0 && (
                    <Divider
                      style={{ marginRight: -1 }}
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />
                  )}

                  <Grid
                    xs={12 / summaryRow.length}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                    paddingX={1.5}
                    display="flex"
                    gap={1}
                    item
                  >
                    {summaryIndex === 0 && startIconName && (
                      <Icon
                        color={startIconColor}
                        name={startIconName}
                        size={24}
                      />
                    )}
                    <SummaryRowItem
                      value={summaryValues[item.key] ?? defaultValue}
                      pillColor={pillColor}
                      summary={item}
                    />
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>

          {rowIndex !== summaryRows.length - 1 && (
            <Divider orientation="horizontal" flexItem />
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
