import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { UserListProps } from '@services/UserService';
import { UserService } from '@services/index';

import { toastifyApiErrors } from '@utils';
import { useFilterParams, useAuth } from '@hooks';
import { ITEMS_LIMIT_PER_PAGE, UserQueryKeysEnum } from '@constants';

export function useUsersState() {
  const { currentPage, tableOrder, searchParam, mappedFilters } =
    useFilterParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const userService = useMemo(() => new UserService(), []);

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled = Boolean(companyId) && Boolean(partnerCompany);

  const usersState = useQuery<UserListProps>({
    queryKey: [
      UserQueryKeysEnum.USERS_STATE,
      currentPage,
      tableOrder,
      companyId,
      partnerCompany,
      searchParam,
      mappedFilters,
    ],
    queryFn: () =>
      userService.getCompanyAccounts({
        ...tableOrder,
        ...searchParam,
        ...mappedFilters,
        companyId,
        partnerCompany,
        offset: ITEMS_LIMIT_PER_PAGE * (currentPage - 1),
        limit: ITEMS_LIMIT_PER_PAGE,
      }),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshUsersStates = () => {
    usersState.remove();
    usersState.refetch();
  };

  return {
    refreshUsersStates,
    usersState,
  };
}
