import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { COLORS } from '@hapvida/hapvida-core-components';

import HapvidaLogo from '@assets/images/hapvida-logo-white.svg';
import NdiLogo from '@assets/images/ndi-logo-white.svg';

import { Container } from './styles';

interface SelectionSidebarProps {
  subtitle: string | ReactNode;
  title: string;
}

export function SelectionSidebar({ title, subtitle }: SelectionSidebarProps) {
  return (
    <Container square>
      <Stack flexDirection="row" alignItems="center" gap={4}>
        <img src={HapvidaLogo} alt="Hapvida" height={28} />
        <img src={NdiLogo} alt="Ndi" height={32} />
      </Stack>
      <Stack gap={2}>
        <Typography variant="h4" color={COLORS.WHITE}>
          {title}
        </Typography>
        <Typography variant="body2" color={COLORS.WHITE}>
          {subtitle}
        </Typography>
      </Stack>
    </Container>
  );
}
