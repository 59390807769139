import { CompanyDetailMock } from '@services/CompanySetupService/dtos/GetCompanyDetailDTO';

import { AccessManagementProvider } from 'components/ProfileSelectionCard/hooks';

import { RegisterUserCompany } from './components/RegisterUserCompany';

export interface RegisterAccessProps {
  companyDetails?: CompanyDetailMock;
}

export const RegisterAccess = ({ companyDetails }: RegisterAccessProps) => {
  return (
    <AccessManagementProvider>
      <RegisterUserCompany companyDetails={companyDetails} />
    </AccessManagementProvider>
  );
};
