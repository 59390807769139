import { Stack, StackProps, Dialog, DialogProps } from '@mui/material';
import { FC } from 'react';

import { Button } from '@hapvida/hapvida-core-components';

import { Title, StyledIcon, Footer, ModalType } from './styles';
import { IconName } from '../Icon/types';
import { CircularProgress } from '../CircularProgress';

type ButtonColor =
  | 'error'
  | 'info'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning';

export interface ModalProps extends DialogProps {
  secondaryButtonText?: string;
  handleCloseModal: () => void;
  disableMainAction?: boolean;
  colorButton?: ButtonColor;
  contentProps?: StackProps;
  mainButtonText?: string;
  mainAction?: () => void;
  subtitle?: string;
  type?: ModalType;
  icon?: IconName;
  title: string;
  open: boolean;
  loading?: boolean;
}

const Modal: FC<ModalProps> = ({
  colorButton = 'secondary',
  secondaryButtonText,
  disableMainAction,
  handleCloseModal,
  type = 'default',
  mainButtonText,
  contentProps,
  mainAction,
  children,
  subtitle,
  title,
  open,
  icon,
  loading,
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : handleCloseModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...rest}
    >
      <Stack gap={2} padding={3} flexDirection="row">
        {icon && <StyledIcon name={icon} size={32} type={type} />}

        <Stack gap={1.5}>
          <Title type={type} id="dialog-title" variant="subtitle1">
            {title}
          </Title>
          <Stack {...contentProps}>{children}</Stack>
        </Stack>
      </Stack>

      <Footer disableSpacing>
        {secondaryButtonText && (
          <Button
            variant="outlined"
            size="small"
            onClick={handleCloseModal}
            disabled={loading}
          >
            {secondaryButtonText}
          </Button>
        )}
        {mainAction && mainButtonText && (
          <Button
            disabled={disableMainAction || loading}
            onClick={mainAction}
            variant="contained"
            endIcon={loading ? <CircularProgress size={16} /> : undefined}
            color={colorButton}
            size="small"
          >
            {mainButtonText}
          </Button>
        )}
      </Footer>
    </Dialog>
  );
};

export default Modal;
