import { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';

import {
  ErrorLoadingPage,
  useDrawerFilter,
  NoSearchResults,
  FilterSummary,
  Loading,
} from '@hapvida/hapvida-core-components';

import { NoInvoices } from '@flows/private/financial/components/NoInvoices';
import { useFilterParams } from '@hooks';
import { useAuth } from 'hooks/useAuth';

import { financialFilterLabels, financialFilterValues } from './constants';
import { BillsListCard, BillsSummaryCard } from './components';
import { useBillsState } from './hooks';
import { RedirectAlert } from '../../components';

export function FinancialListLayout() {
  const { getInitialFilter, setActiveFilters } = useDrawerFilter();
  const { hasFilters } = useFilterParams();
  const initialRender = useRef(true);
  const { user } = useAuth();
  const { handleRefreshBillsStates, billSummariesState, billListState } =
    useBillsState();

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const initialFinancialFilters = getInitialFilter({
        filtersConfig: financialFilterValues,
        filtersLabel: financialFilterLabels,
      });

      setActiveFilters(initialFinancialFilters);
    }
  }, []);

  if (billListState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const hasError = billListState.isError || billSummariesState.isError;

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={handleRefreshBillsStates} />;
  }

  const isEmpty = billListState.data.bills.length === 0 && !hasFilters;
  const isSearchResultEmpty = billListState.data.bills.length === 0;

  if (isEmpty) {
    return <NoInvoices userName={user?.name ?? ''} />;
  }

  const totalBillsListLength = billListState.data.total ?? 0;

  const filterSummaryLabel = `${totalBillsListLength} ${
    totalBillsListLength === 1 ? 'fatura' : 'faturas'
  }`;

  return (
    <Stack paddingTop={3} gap={3}>
      <RedirectAlert />

      <FilterSummary totalLabel={filterSummaryLabel} />

      {isSearchResultEmpty && <NoSearchResults padding={5} />}

      {!isSearchResultEmpty && (
        <Stack gap={3} paddingBottom={8}>
          <BillsSummaryCard />

          <BillsListCard billsList={billListState.data} />
        </Stack>
      )}
    </Stack>
  );
}
