import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import noInvoicesImage from '@assets/images/no-invoices.svg';
import { useAuth } from 'hooks/useAuth';

export function EmptyBills() {
  const { user } = useAuth();

  const userName = user?.name ?? '';

  return (
    <Stack alignItems="center" gap={3}>
      <img src={noInvoicesImage} alt="Sem faturas recentes" height={112} />
      <Typography variant="h6" textAlign="center" maxWidth={472}>
        {userName}, parece que você não possui faturas recentes.
      </Typography>
    </Stack>
  );
}
