import React from 'react';

import PasswordRecoveryStep from './steps/PasswordRecovery/step';

export interface IPasswordRecoveryStep {
  name?: string;
  step(props: any): React.ReactElement;
}

export const steps: Array<IPasswordRecoveryStep> = [PasswordRecoveryStep];
