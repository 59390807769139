import { Stack, Typography } from '@mui/material';

import { CustomTableCell, NumberMovimentation } from '../TableRows/styles';
import type { MovimentationNumberProps } from './types';
import { UploadName } from '../UploadName';

export const MovimentationNumber = ({
  uploadName,
  number,
  selectedCompany,
}: Readonly<MovimentationNumberProps>) => {
  return (
    <CustomTableCell align="left">
      <Stack>
        <NumberMovimentation>
          <Typography>{number}</Typography>
        </NumberMovimentation>

        {uploadName && (
          <UploadName
            uploadName={uploadName}
            selectedCompany={selectedCompany}
          />
        )}
      </Stack>
    </CustomTableCell>
  );
};
