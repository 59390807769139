const companiesDataMock = {
  content: {
    companies: [
      {
        id: '62cceafb-1918-4683-bf7e-2400eb4bbd28',
        name: {
          trade: 'Carrefour',
          legal: 'Carrefour 3 S.A',
        },
        document: '26252799000105',
        unitCode: '0001',
        partnerCompany: 'ABC23',
        healthOperator: 'Hapvida',
        backOffice: {
          name: 'Carrefour backoffice',
          document: '59050880045',
          profile: 'Adm',
          isCompany: false,
          isCancelled: false,
        },
        type: 'Company',
        totalBeneficiaries: 2040,
        effectiveDate: '2023-03-02T15:45:06.824',
      },
      {
        id: 'c889d2b2-48e4-4741-8ae6-c0dfe3aa0867',
        name: {
          trade: 'Carrefour 4',
          legal: 'Carrefour 4 S.A',
        },
        document: '26252799000105',
        unitCode: '0002',
        partnerCompany: 'ABC239',
        healthOperator: 'Hapvida',
        backOffice: {
          name: 'Carrefour 4 backoffice',
          document: '59050880045',
          profile: 'Adm',
          isCompany: false,
          isCancelled: false,
        },
        type: 'Company',
        totalBeneficiaries: 2040,
        effectiveDate: '2023-03-02T15:45:06.824',
      },
      {
        id: 'c889d2b2-48e4-4741-8ae6-c0dfe3aa0867',
        name: {
          trade: 'Carrefour 2',
          legal: 'Carrefour 2 S.A',
        },
        document: '98877435003',
        unitCode: '0003',
        partnerCompany: 'BCD029',
        healthOperator: 'Hapvida',
        backOffice: {
          name: 'Carrefour 2 backoffice',
          document: '59050880045',
          profile: 'Adm',
          isCompany: false,
          isCancelled: false,
        },
        type: 'Affiliate',
        totalBeneficiaries: 0,
        effectiveDate: '2023-04-19T18:50:10.374',
      },
    ],
    total: 2,
    totalPerPage: 2,
  },
};

export default companiesDataMock;
