import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { Typography } from '@hapvida/hapvida-core-components';

import NDILogo from '@hapvida/hapvida-core-components/src/assets/images/ndi-logo-new-light.svg';
import HapVidaLogo from '@hapvida/hapvida-core-components/src/assets/images/hapvida-white.svg';

import {
  Drawer,
  FirstStepContent,
  LogoContainer,
  PageContainer,
} from './styles';
import SplashImage from '../../assets/splash-image.png';

interface SplashScreenProps {
  title: string;
  subtitle: string;
  duration?: number;
  onDone: () => void;
}

const SplashScreen: React.FC<SplashScreenProps> = ({
  title,
  subtitle,
  duration = 3000,
  onDone,
}) => {
  const [loadedImage, setLoadedImage] = useState<string>();

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setLoadedImage(image.src);
    };
    image.src = SplashImage;
  }, []);

  return (
    <PageContainer
      animate={loadedImage !== undefined}
      duration={duration}
      onAnimationEnd={onDone}
    >
      <Drawer
        open
        variant="permanent"
        transitionDuration={400}
        startImage={loadedImage}
      >
        <Stack
          alignItems="center"
          sx={{ padding: '16px 80px', maxWidth: 500 }}
          gap={6}
        >
          <LogoContainer direction="row" justifyContent="space-evenly">
            <img src={HapVidaLogo} alt="Hapvida" />
            <img src={NDILogo} alt="NDI" />
          </LogoContainer>
          <FirstStepContent gap={2}>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="text">{subtitle}</Typography>
          </FirstStepContent>
        </Stack>
      </Drawer>
    </PageContainer>
  );
};

export default SplashScreen;
