import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { Button } from '@hapvida/hapvida-core-components';

import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';

import info from './assets/info.svg';
import { BoxImg, Layout } from './styles';

export const ConfirmationLayout: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleBackMovementsList = async () => {
    const movementsQueryKey = ['movementsState'];

    queryClient.removeQueries({
      queryKey: movementsQueryKey,
      exact: true,
    });

    await queryClient.refetchQueries({
      queryKey: movementsQueryKey,
    });
    navigate(MovementListRouter.path);
  };

  return (
    <Layout>
      <BoxImg>
        <img src={info} alt="info" />
      </BoxImg>
      <Typography variant="h5">
        Parabéns! Sua movimentação foi enviada para processamento
      </Typography>
      <Typography variant="text" mt={2} mb={3}>
        Fique ligado! Em alguns instantes você já terá a resposta do
        processamento.
      </Typography>

      <Button
        type="submit"
        color="success"
        variant="contained"
        onClick={handleBackMovementsList}
      >
        Ir para movimentações
      </Button>
    </Layout>
  );
};
