import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, InputAdornment } from '@mui/material';
import { ValidationError } from 'yup';
import { useSnackbar } from 'notistack';

import {
  Icon,
  Form,
  Button,
  FormGroup,
  TextInput,
  Typography,
} from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';

import { toastifyApiErrors } from '@utils';

import AuthService from '../../../../../../infra/services/AuthService';
import { useQuery } from '../../../../../../hooks/useQuery';
import { useScreenLoading } from '../../../../../../hooks/useScreenLoading';
import {
  PASSWORD_DEFINITION_STEPS_VALUES,
  PASSWORD_DEFINITION_STEPS_KEYS,
} from '../../constants/keys';
import { schema } from './validation';
import {
  Container,
  ErrorWrapper,
  List,
  StyledForm,
  StyledIcon,
  StyledTypography,
} from './styles';

type PasswordDefinitionForm = {
  password: string;
  password_confirmation: string;
};

interface PasswordDefinitionProps {
  onStepUpdate(step: number, data: any): void;
}

const PasswordDefinition: FC<PasswordDefinitionProps> = ({ onStepUpdate }) => {
  const possibleErrors = [
    'Mínimo 7 caracteres',
    '1 caracter especial',
    '1 número',
    '1 letra maiúscula',
    '1 letra minúscula',
  ];

  const [disableButton, setDisableButton] = useState(true);
  const [currentErrors, setCurrentErrors] = useState<string[]>([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<PasswordDefinitionForm>({
    mode: 'all',
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(schema, { abortEarly: false }),
  });

  const password = watch('password');
  const passwordConfirmation = watch('password_confirmation');

  const query = useQuery();
  const token = query.get('token');
  const userId = query.get('userId');

  const { enqueueSnackbar } = useSnackbar();
  const { onScreenLoading } = useScreenLoading();

  useEffect(() => {
    schema
      .validate({ password, passwordConfirmation }, { abortEarly: false })
      .then(() => setCurrentErrors([]))
      .catch((err: ValidationError) => {
        const errors = err.inner.map(e => e.message);
        setCurrentErrors(errors);
      });
  }, [password, passwordConfirmation]);

  const handleErrors = (error: string) => {
    let errConfig: {
      icon: IconName;
      color: string;
      error: string;
    } = {
      icon: 'check',
      color: COLORS.SECONDARY.GREEN.MAIN,
      error: 'false',
    };

    if (currentErrors.includes(error))
      errConfig = {
        icon: 'close',
        color: COLORS.SECONDARY.RED.MAIN,
        error: 'true',
      };

    return (
      <ErrorWrapper>
        <Icon name={errConfig.icon} color={errConfig.color} size={12} />
        <StyledTypography error={errConfig.error}>{error}</StyledTypography>
      </ErrorWrapper>
    );
  };

  useEffect(() => {
    if (isValid) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [isValid]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmationPassword = () => {
    setShowConfirmationPassword(!showConfirmationPassword);
  };

  const onSubmit = useCallback(
    async (data: PasswordDefinitionForm) => {
      try {
        onScreenLoading(true);

        if (token && userId) {
          const authService = new AuthService();

          const step = {
            userId,
            token,
            password: data.password,
          };

          await authService.passwordDefinition(step);

          onStepUpdate(
            PASSWORD_DEFINITION_STEPS_VALUES[
              PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION
            ],
            data,
          );
        }
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      } finally {
        onScreenLoading(false);
      }
    },
    [enqueueSnackbar, onScreenLoading, onStepUpdate, token, userId],
  );

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Form title="Definição de senha">
        <List>
          {possibleErrors.map(error => (
            <li key={error}>{handleErrors(error)}</li>
          ))}
        </List>

        <Typography>Insira uma nova senha abaixo:</Typography>

        <Container>
          <FormGroup>
            <TextInput
              type={showPassword ? 'text' : 'password'}
              control={control}
              margin="normal"
              name="password"
              label="Digite sua senha"
              placeholder="Digite"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={handleShowPassword}>
                    <StyledIcon
                      name={showPassword ? 'eye' : 'eye-outline'}
                      size={16}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextInput
              type={showConfirmationPassword ? 'text' : 'password'}
              control={control}
              margin="normal"
              name="password_confirmation"
              label="Confirme sua senha"
              placeholder="Digite"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleShowConfirmationPassword}
                  >
                    <StyledIcon
                      name={showConfirmationPassword ? 'eye' : 'eye-outline'}
                      size={16}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </FormGroup>
        </Container>

        <Grid container justifyContent="flex-end">
          <Button
            disabled={disableButton}
            color="success"
            variant="contained"
            endIcon={<Icon name="chevron-right" size={16} />}
            type="submit"
          >
            Confirmar
          </Button>
        </Grid>
      </Form>
    </StyledForm>
  );
};

export default PasswordDefinition;
