import {
  FilterKeyEnum,
  FiltersConfigProps,
} from '@hapvida/hapvida-core-components';

import {
  PartnerCompaniesTypeEnum,
  PARTNER_COMPANIES_TYPE_NAME,
} from '@constants';

export const partnerCompanyDrawerFilterValues: FiltersConfigProps = {
  [FilterKeyEnum.CONTRACT_TYPE]: Object.values(PartnerCompaniesTypeEnum).map(
    (partnerCompanyType: PartnerCompaniesTypeEnum) => {
      return {
        label: PARTNER_COMPANIES_TYPE_NAME[partnerCompanyType],
        value: partnerCompanyType,
      };
    },
  ),
};
