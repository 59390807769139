import Typography from '@mui/material/Typography';

import { COLORS } from '@hapvida/hapvida-core-components';

import { CardWrapper } from './styles';

const CARD_DATA = {
  error: {
    titleColor: COLORS.SECONDARY.RED['-1'],
    backgroundColor: 'rgba(201, 54, 0, 0.04)',
  },
  success: {
    titleColor: COLORS.SECONDARY.GREEN.MAIN,
    backgroundColor: 'rgba(82, 135, 44, 0.04)',
  },
  normal: {
    titleColor: COLORS.BLACK,
    backgroundColor: COLORS.MONOCHROMATIC.GRAY1,
  },
  process: {
    titleColor: COLORS.PRIMARY.BLUE.MAIN,
    backgroundColor: COLORS.SECONDARY.BLUE['-1'],
  },
  canceled: {
    titleColor: COLORS.SECONDARY.RED['+1'],
    backgroundColor: COLORS.SECONDARY.RED['-3'],
  },
};

interface LifeCardProps {
  value: string | number;
  type?: 'normal' | 'error' | 'success' | 'process' | 'canceled';
  label: string;
}

export function LifeCard({
  value: title,
  type = 'normal',
  label: subtitle,
}: LifeCardProps) {
  return (
    <CardWrapper
      sx={{
        background: CARD_DATA[type]?.backgroundColor,
      }}
    >
      <Typography
        variant="h6"
        color={CARD_DATA[type]?.titleColor}
        marginBottom={1}
      >
        {title}
      </Typography>

      <Typography variant="text">{subtitle}</Typography>
    </CardWrapper>
  );
}
