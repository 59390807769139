import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { COLORS } from '@hapvida/hapvida-core-components';

import { ProfileProps, UserPartnerCompanyProps } from '@services/UserService';

import { useAccessManagement } from 'components/ProfileSelectionCard/hooks/index';
import { PartnerCompanyTypeChip } from 'components/PartnerCompanyTypeChip';
import { UserProfileEnum } from '@constants';

import { StyledStack } from './style';
import { RelatedPartnerCompaniesModal } from './components';

interface PartnerCompanyItemProps {
  partnerCompany: UserPartnerCompanyProps;
  selectedProfile?: ProfileProps;
}

export function PartnerCompanyItem({
  selectedProfile,
  partnerCompany,
}: Readonly<PartnerCompanyItemProps>) {
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  const { editPartnerCompanyList, selectedPartnerCompanies } =
    useAccessManagement();

  const selectedPartnerCompany = selectedPartnerCompanies.find(
    item => item.partnerCompany === partnerCompany.partnerCompany,
  );

  const isChecked = Boolean(selectedPartnerCompany);

  const hasSelectedProfile = Boolean(
    selectedPartnerCompany?.profile && selectedProfile,
  );

  const selectedPartnerCompanyProfileIsDistinct =
    selectedPartnerCompany?.profile?.id !== selectedProfile?.id;

  const isDisabled = Boolean(
    hasSelectedProfile && selectedPartnerCompanyProfileIsDistinct,
  );

  const isMainPartnerCompany =
    Boolean(partnerCompany?.isMainPartnerCompany) &&
    selectedProfile?.name === UserProfileEnum.MASTER;

  const handleOnChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const updatedPartnerCompany = {
      ...partnerCompany,
      hasAccess: checked,
      profile: selectedProfile,
    };
    editPartnerCompanyList(updatedPartnerCompany);
  };

  return (
    <StyledStack checked={isChecked} disabled={isDisabled}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleOnChange}
          />
        }
        sx={{ flex: 1 }}
        label={
          <Stack flexDirection="row" gap={2} alignItems="center">
            <Typography>{partnerCompany.partnerCompany}</Typography>
            {isMainPartnerCompany && (
              <PartnerCompanyTypeChip isMainPartnerCompany />
            )}
          </Stack>
        }
      />
      {(isMainPartnerCompany || selectedPartnerCompany?.profile) && (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          {selectedPartnerCompany?.profile && (
            <Stack flexDirection="row" gap="4px">
              <Typography variant="text">Perfil: </Typography>
              <Typography variant="text" fontWeight={600}>
                {selectedPartnerCompany.profile.name}
              </Typography>
            </Stack>
          )}
          {isMainPartnerCompany && (
            <Stack justifyContent="flex-end" flexDirection="row">
              <Typography
                onClick={toggleModal}
                variant="text"
                color={COLORS.PRIMARY.BLUE.MAIN}
                sx={{ cursor: 'pointer' }}
              >
                Mostrar contratos relacionados
              </Typography>
              {open && (
                <RelatedPartnerCompaniesModal
                  open={open}
                  toggleModal={toggleModal}
                  mainPartnerCompany={partnerCompany.partnerCompany}
                />
              )}
            </Stack>
          )}
        </Stack>
      )}
    </StyledStack>
  );
}
