import { Stack, Typography } from '@mui/material';
import { FormState } from 'react-hook-form';

import { AlertCard, COLORS, CountDown } from '@hapvida/hapvida-core-components';

import { UserActionsOptionsEnum } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/constants/userActionsOptions';
import { BackofficeUserDetailsFormProps } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/types';
import { useUserActionsState } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/components/BackofficeUserEditForm/hooks';

import { useResendConfirmationEmail } from './hooks';

interface ResendConfirmationEmailProps {
  formState: FormState<BackofficeUserDetailsFormProps>;
}

export function ResendConfirmationEmail({
  formState,
}: Readonly<ResendConfirmationEmailProps>) {
  const { dirtyFields } = formState;

  const { handleResendConfirmationEmail } = useResendConfirmationEmail();

  const { getIsDisabled, getIsLoading } = useUserActionsState();

  const isDisabled =
    getIsDisabled(UserActionsOptionsEnum.RESEND_CONFIRMATION_EMAIL) ||
    dirtyFields.email;

  const isLoading = getIsLoading(
    UserActionsOptionsEnum.RESEND_CONFIRMATION_EMAIL,
  );

  return (
    <Stack>
      <AlertCard
        type="info"
        title="Usuário com acesso pendente"
        content={
          <Stack>
            <Typography
              color={COLORS.MONOCHROMATIC.GRAY4}
              mb={1}
              variant="text"
            >
              Vimos que este usuário ainda não acessou o Portal. Deseja reenviar
              o e-mail de primeiro acesso?
            </Typography>
            <CountDown
              unavaible={{
                loadingText: 'Aguarde',
                loadingInfo: 'para reenviar.',
              }}
              onClick={isDisabled ? undefined : handleResendConfirmationEmail}
              disabled={isDisabled}
              loading={isLoading}
              timer={60}
              successMessage="E-mail enviado"
            />
          </Stack>
        }
      />
    </Stack>
  );
}
