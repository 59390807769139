import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { LetterOfStayLayout } from './layout';
import { StayLetterProvider } from './hooks';
import { LetterOfStaySubHeader } from './components';

export function LetterOfStayPage() {
  return (
    <DrawerFilterProvider>
      <StayLetterProvider>
        <LetterOfStaySubHeader />
        <LetterOfStayLayout />
      </StayLetterProvider>
    </DrawerFilterProvider>
  );
}
