import { createContext, Dispatch, SetStateAction } from 'react';
import { Gender } from '../constants/enums';
import { FiltersGroup } from '../hooks/useDrawerFilter';

export type FiltersConfigProps = Record<
  string,
  {
    label: string;
    value: string;
  }[]
>;

export type FiltersLabelProps = Record<
  string,
  string | { singular?: string; plural?: string; gender?: Gender }
>;

export interface GetInitialFiltersProps {
  filtersConfig?: FiltersConfigProps;
  filtersLabel?: FiltersLabelProps;
}

export interface DrawerFilterContextData {
  getInitialFilter: (params?: GetInitialFiltersProps) => FiltersGroup;
  activeFilters: FiltersGroup;
  setActiveFilters: Dispatch<SetStateAction<FiltersGroup>>;
  provFilters: FiltersGroup;
  setProvFilters: Dispatch<SetStateAction<FiltersGroup>>;
  initialFilters: FiltersGroup;
  setInitialFilters: Dispatch<SetStateAction<FiltersGroup>>;
  cleanAllFilters: () => void;
  cleanFilters: () => void;
  applyFilters: (callback?: (filters: FiltersGroup) => void) => void;
  filterCount: number;
  handleFilterChange: (filters: FiltersGroup) => void;
  ignoreFilters: string[];
  register: (filterKey: string | string[]) => void;
  unregister: (filterKey: string | string[]) => void;
}

export const DrawerFilterContext = createContext<DrawerFilterContextData>(
  {} as DrawerFilterContextData,
);
