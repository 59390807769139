import React from 'react';

import StartRegisterLayout from './layout';

type StartRegisterProps = {
  onStepUpdate(): void;
  setCompanyId: Function;
};

const StartRegisterStep: React.FC<StartRegisterProps> = ({
  onStepUpdate,
  setCompanyId,
}) => {
  return (
    <StartRegisterLayout
      onStepUpdate={onStepUpdate}
      setCompanyId={setCompanyId}
    />
  );
};

export default StartRegisterStep;
