import { Stack, Typography } from '@mui/material';

import {
  ErrorLoadingPage,
  NoSearchResults,
  useDrawerFilter,
  FilterSummary,
  Loading,
} from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';

import { BeneficiariesSummaryCard, BeneficiaryListCard } from './components';
import { EmptyBeneficiaries } from './components/EmptyBeneficiaries';
import { useBeneficiariesState } from './hooks';

export function BeneficiaryListLayout() {
  const { filterCount } = useDrawerFilter();
  const { hasFilters } = useFilterParams();
  const {
    refreshBeneficiariesState,
    beneficiariesSummaryState,
    beneficiariesState,
  } = useBeneficiariesState();

  if (beneficiariesState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (beneficiariesState.isError || beneficiariesSummaryState.isError) {
    return <ErrorLoadingPage onReloadClick={refreshBeneficiariesState} />;
  }

  const totalBeneficiariesLength = beneficiariesState.data.total ?? 0;

  const isResultEmpty = totalBeneficiariesLength === 0;

  if (isResultEmpty && !hasFilters) {
    return <EmptyBeneficiaries />;
  }

  const filterSummaryLabel = `${totalBeneficiariesLength} ${
    totalBeneficiariesLength === 1 ? 'beneficiário' : 'beneficiários'
  }`;

  return (
    <Stack paddingTop={3} gap={3}>
      <FilterSummary totalLabel={filterSummaryLabel} />

      {isResultEmpty && <NoSearchResults padding={5} />}

      {!isResultEmpty && (
        <Stack gap={3} paddingBottom={8}>
          {filterCount === 0 && (
            <Typography variant="subtitle1">Lista de beneficiários</Typography>
          )}

          <BeneficiariesSummaryCard />

          <BeneficiaryListCard beneficiaryList={beneficiariesState.data} />
        </Stack>
      )}
    </Stack>
  );
}
