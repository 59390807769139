import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

import { Loading } from '@hapvida/hapvida-core-components';

import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';
import { useCurrentUserState } from '@hooks';

export const AuthenticatingPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { hash = '' } = location;

  const hashList = hash.split('&');

  const token = hashList[1]?.split('=')[1];
  const refreshToken = hashList[0]?.split('=')[1];

  const { currentUserState } = useCurrentUserState({
    newToken: token,
    newRefreshToken: refreshToken,
  });

  if (currentUserState.isSuccess) {
    navigate(CompanySetupListRouter.path, { replace: true });
  }

  if (!refreshToken) {
    window.location.href = process.env.REACT_APP_LOGIN_URL;

    return null;
  }

  if (currentUserState.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  return null;
};
