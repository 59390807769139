import { Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

export const SelectedProfileStack = styled(Stack)(() => ({
  padding: '16px',
  background: `${COLORS.PRIMARY.ORANGE.MAIN}14`,
  border: `1px solid ${COLORS.DISABLED.BORDER} `,
  borderTop: 'none',
}));
