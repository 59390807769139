import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { PartnerCompanyTypeChip } from '@components';
import { UserProfileEnum } from '@constants';

import { useProfileCard } from '../../hooks';

export function SummarySelectedPartnerCompanies() {
  const [showAll, setShowAll] = useState(false);

  const { selectedProfile, currentSelectedPartnerCompanies } = useProfileCard();

  const isMaster = selectedProfile?.name === UserProfileEnum.MASTER;

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const filteredContracts = isMaster
    ? currentSelectedPartnerCompanies.filter(
        partnerCompany => partnerCompany.isMainPartnerCompany,
      )
    : currentSelectedPartnerCompanies;

  const contractsLength = filteredContracts.length > 19;

  const displayedContracts = showAll
    ? filteredContracts
    : filteredContracts.slice(0, 20);

  if (filteredContracts.length === 0 || !selectedProfile) {
    return null;
  }
  return (
    <Stack gap={1}>
      <Typography variant="text">Resumo dos contratos selecionados:</Typography>
      <Stack flexDirection="row" gap="10px" flexWrap="wrap">
        {displayedContracts.map(item => (
          <PartnerCompanyTypeChip
            text={item.partnerCompany}
            isMainPartnerCompany={item.isMainPartnerCompany}
            key={item.partnerCompany}
          />
        ))}
      </Stack>
      {contractsLength && (
        <Button variant="text" onClick={handleShowMore}>
          {!showAll ? 'Ver todos' : 'Ver menos'}
        </Button>
      )}
    </Stack>
  );
}
