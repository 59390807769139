import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';

import { UserActionsOptionsEnum } from '../../../../../../constants/userActionsOptions';
import { useUserActionsState } from '../../../../../../hooks';

export function useResendConfirmationEmail() {
  const { changeCurrentAction } = useUserActionsState();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const handleResendConfirmationEmail = useCallback(async () => {
    try {
      changeCurrentAction(UserActionsOptionsEnum.RESEND_CONFIRMATION_EMAIL);

      if (id) {
        const userService = new UserService();

        await userService.resendConfirmation(id);
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      changeCurrentAction(undefined);
    }
  }, [enqueueSnackbar, id, changeCurrentAction]);

  return {
    handleResendConfirmationEmail,
  };
}
