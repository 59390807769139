import { Typography } from '@mui/material';

import { SelectionCard } from '@flows/others/selection/components';
import { useAuth } from 'hooks/useAuth';
import { SalesChannelEnum } from '@constants';

import { salesChannelCard } from '../../constants';

interface SalesChannelListProps {
  handleSelectSalesChannel: (salesChannelName: SalesChannelEnum) => void;
}

export function SalesChannelList({
  handleSelectSalesChannel,
}: SalesChannelListProps) {
  const { user } = useAuth();

  const salesChannel = user?.salesChannel;

  if (!salesChannel) {
    return null;
  }

  const corporateItems = salesChannel.filter(
    item => item === SalesChannelEnum.CORPORATE,
  );
  const otherItems = salesChannel.filter(
    item => item !== SalesChannelEnum.CORPORATE,
  );

  return (
    <>
      {corporateItems.length > 0 && (
        <Typography variant="h6">Corporativos</Typography>
      )}
      {corporateItems.map(salesChannelName => (
        <SelectionCard
          key={salesChannelName}
          {...salesChannelCard[salesChannelName]}
          onClick={() => handleSelectSalesChannel(salesChannelName)}
        />
      ))}
      {corporateItems.length > 0 && otherItems.length > 0 && (
        <Typography variant="h6">Outros</Typography>
      )}
      {otherItems.map(salesChannelName => (
        <SelectionCard
          key={salesChannelName}
          {...salesChannelCard[salesChannelName]}
          onClick={() => handleSelectSalesChannel(salesChannelName)}
        />
      ))}
    </>
  );
}
