import { Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Icon, Modal } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { UserListRouter } from '@flows/private/users/pages/UserList/router';
import { useUserActionsState } from '@flows/private/users/pages/UserDetails/components/UserEditForm/hooks';
import { UserActionsOptionsEnum } from '@flows/private/users/pages/UserDetails/components/UserEditForm/constants/userActionsOptions';

import { useDeleteUser } from './hooks';

export function DeleteUserButton() {
  const navigate = useNavigate();
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [successfulDeletedUserOpen, setSuccessfulDeletedUserOpen] =
    useState(false);

  const { getIsLoading, getIsDisabled } = useUserActionsState();

  const isDisabled = getIsDisabled(UserActionsOptionsEnum.DELETE);
  const isLoading = getIsLoading(UserActionsOptionsEnum.DELETE);

  const { handleDeleteUser } = useDeleteUser();

  const toggleDeleteUser = () => setDeleteUserOpen(!deleteUserOpen);

  const toggleSuccessfulDeletedUser = () =>
    setSuccessfulDeletedUserOpen(!successfulDeletedUserOpen);

  const handleCloseSuccessfulDeletedUser = useCallback(() => {
    toggleSuccessfulDeletedUser();
    navigate(UserListRouter.path);
  }, [navigate, toggleSuccessfulDeletedUser]);

  const handleDeleteUserSuccess = useCallback(() => {
    toggleDeleteUser();
    toggleSuccessfulDeletedUser();
  }, [toggleDeleteUser, toggleSuccessfulDeletedUser]);

  const handleConfirmDeleteUser = useCallback(() => {
    handleDeleteUser().then(isSuccess => {
      if (isSuccess) {
        handleDeleteUserSuccess();
      }
    });
  }, [handleDeleteUserSuccess]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        disabled={isDisabled}
        onClick={toggleDeleteUser}
        endIcon={
          <Icon
            name="delete-filled"
            color={
              isDisabled ? COLORS.MONOCHROMATIC.GRAY3 : COLORS.PRIMARY.BLUE.MAIN
            }
            size={16}
          />
        }
      >
        Excluir usuário
      </Button>
      <Modal
        title="Atenção! Você está excluindo um usuário."
        handleCloseModal={toggleDeleteUser}
        mainAction={handleConfirmDeleteUser}
        open={deleteUserOpen}
        secondaryButtonText="Fechar"
        mainButtonText={isLoading ? 'Excluindo' : 'Excluir agora'}
        icon="alert"
        loading={isLoading}
      >
        <Stack gap={1.5}>
          <Typography variant="text">
            Ao confirmar a exclusão, o usuário perderá acesso a todos os
            contratos da empresa. Se preferir, você pode gerenciar o acesso de
            contratos específicos direto no perfil do usuário.
          </Typography>
          <Typography variant="text">
            Tem certeza que deseja continuar com a exclusão do usuário?
          </Typography>
        </Stack>
      </Modal>
      <Modal
        title="Usuário excluído com sucesso."
        type="success"
        handleCloseModal={handleCloseSuccessfulDeletedUser}
        mainAction={handleCloseSuccessfulDeletedUser}
        open={successfulDeletedUserOpen}
        mainButtonText="Fechar"
        icon="check"
      />
    </>
  );
}
