import { useCallback, useState } from 'react';

import {
  Typography,
  TableCell,
  TableBody,
  Checkbox,
  Stack,
} from '@mui/material';

import Button from '../../Button';
import BaseTable from '../../BaseTable';

import {
  Title,
  StyledRow,
  StyledActionCell,
  ActionBox,
  EditButton,
} from './styles';

import Icon from '../../Icon';
import { TableHeadParams } from '../../BaseTable/types';
import { CheckboxBodyProps, Component } from './types';

const CheckboxBody = ({
  components,
  columns,
  tableOrder,
  count,
  from,
  to,
  checkAllLabel = 'Selecionar todos',
  buttonLabel = 'Baixar arquivos selecionados',
  onOrderChange,
  onPageChange,
  allSelectedAction,
  onDownload,
  onDetail,
  baseTableProps,
  onRowAction,
  footerLabel,
  width,
  currentPage,
}: CheckboxBodyProps) => {
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleClick = (_event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = components.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onCells = (invoice: Component) => {
    const cells: any = [];

    columns.forEach((column: TableHeadParams, index: number) => {
      if (column.name) {
        if (invoice.main.hasOwnProperty(column.name)) {
          const cell = (
            <TableCell
              key={`cell-${String(index)}`}
              onClick={
                onRowAction
                  ? event => onRowAction(event, invoice.id)
                  : undefined
              }
            >
              {invoice.main[column.name as keyof typeof invoice.main]}
            </TableCell>
          );

          cells.push(cell);
        }
      }
    });

    return cells;
  };

  const doAction = useCallback(() => {
    allSelectedAction([...selected]);
  }, [allSelectedAction, selected]);

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Title>Lista de faturas</Title>
        <Stack direction="row" spacing={4}>
          <Stack direction="row" spacing={0.1} alignItems="center">
            <Checkbox
              color="primary"
              onChange={handleSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
            <Typography>{checkAllLabel}</Typography>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            onClick={doAction}
            disabled={selected.length === 0}
          >
            {buttonLabel}
          </Button>
        </Stack>
      </Stack>
      <BaseTable
        tableOrder={tableOrder}
        onOrderChange={onOrderChange}
        onPageChange={onPageChange}
        footerLabel={footerLabel}
        width={width}
        currentPage={currentPage}
        {...baseTableProps}
        TableHeadColumns={columns}
        count={count ?? 0}
        from={from ?? 0}
        to={to ?? 0}
      >
        <TableBody>
          {components.map((c, index) => {
            const isItemSelected = isSelected(c.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <StyledRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                isselected={
                  isItemSelected ? isItemSelected.toString() : 'false'
                }
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    onClick={event => handleClick(event, c.id)}
                  />
                </TableCell>
                {onCells(c)}

                <StyledActionCell>
                  <ActionBox>
                    <EditButton
                      onClick={() => {
                        onDownload(c.id);
                      }}
                    >
                      <Icon
                        name="download"
                        size={24}
                        style={{
                          minWidth: '24px',
                        }}
                      />
                    </EditButton>
                    <EditButton
                      onClick={() => {
                        onDetail(c.id);
                      }}
                    >
                      <Icon
                        name="chevron-right"
                        size={24}
                        style={{
                          minWidth: '24px',
                        }}
                      />
                    </EditButton>
                  </ActionBox>
                </StyledActionCell>
              </StyledRow>
            );
          })}
        </TableBody>
      </BaseTable>
    </Stack>
  );
};

export default CheckboxBody;
