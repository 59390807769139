import Grid from '@mui/material/Grid';
import { Control, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Mask, TextInput } from '@hapvida/hapvida-core-components';

import { AddAccountFormProps } from '@services/UserService';

import { ProfileSelectionCard } from '@components';

interface FormRegisterUserProps {
  controlForm: Control<AddAccountFormProps>;
  errorsForm: any;
  watchForm: UseFormWatch<AddAccountFormProps>;
  triggerForm?: UseFormTrigger<AddAccountFormProps>;
}

export const FormRegisterUser = ({
  controlForm,
  errorsForm,
  watchForm,
  triggerForm,
}: FormRegisterUserProps) => {
  const watchEmail = watchForm('email');
  const watchPhone = watchForm('phone');
  const watchConfirmedEmail = watchForm('confirmedEmail');

  const errorPhone =
    watchPhone?.length > 0 && Boolean(errorsForm?.phone?.message);
  const errorEmail =
    watchEmail?.length > 0 && Boolean(errorsForm?.email?.message);
  const errorConfirmedEmail =
    watchConfirmedEmail?.length > 0 &&
    Boolean(errorsForm?.confirmedEmail?.message);

  const errorEmailMsg = errorEmail ? errorsForm?.email?.message : '';
  const errorPhoneMsg = errorPhone ? errorsForm?.phone?.message : '';
  const errorConfirmedEmailMsg = errorConfirmedEmail
    ? errorsForm?.confirmedEmail?.message
    : '';

  return (
    <>
      <Grid item xs={12}>
        <TextInput
          control={controlForm}
          name="email"
          fullWidth
          label="E-mail"
          type="email"
          error={errorEmail}
          errorMessage={errorEmailMsg}
          required
          placeholder="Digite"
          disabled
          onChangeElementInput={() => {
            triggerForm?.('confirmedEmail');
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          control={controlForm}
          name="confirmedEmail"
          fullWidth
          label="Confirmar e-mail"
          type="email"
          required
          disabled
          placeholder="Digite"
          error={errorConfirmedEmail}
          errorMessage={errorConfirmedEmailMsg}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="text">
          Agora, finalize o cadastro com as informações pessoais e permissões do
          usuário.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextInput
          error={Boolean(errorsForm?.name?.message)}
          errorMessage={errorsForm?.name?.message}
          control={controlForm}
          name="name"
          fullWidth
          label="Nome completo"
          required
          placeholder="Digite"
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          control={controlForm}
          name="phone"
          fullWidth
          label="Número de telefone"
          error={errorPhone}
          errorMessage={errorPhoneMsg}
          required
          placeholder="Digite"
          mask={Mask.phone}
        />
      </Grid>

      <Grid item xs={12}>
        <ProfileSelectionCard />
      </Grid>
    </>
  );
};
