import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import noInvoicesImage from '@assets/images/no-invoices.svg';

interface NoInvoicesProps {
  userName: string;
}

export function NoInvoices({ userName }: NoInvoicesProps) {
  return (
    <Stack alignItems="center" padding={5} gap={3}>
      <img src={noInvoicesImage} alt="Sem pagamentos" height={120} />
      <Typography variant="h5" textAlign="center">
        {userName}, parece que nenhuma fatura <br /> está disponível para você.
      </Typography>
    </Stack>
  );
}
