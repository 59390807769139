import mockData from '../mock';

class MockApi {
  public fetchMock<T extends keyof typeof mockData>(
    key: T,
    forceError?: boolean,
  ): Promise<(typeof mockData)[T]> {
    return this.simulateRequest(mockData[key], forceError);
  }

  private async simulateRequest<T>(
    response: T,
    forceError?: boolean,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      setTimeout(() => {
        if (forceError) {
          reject(
            new Error('Erro ao carregar os dados. Tente novamente mais tarde.'),
          );
        }
        resolve(response);
      }, 1500);
    });
  }
}
export default new MockApi();
