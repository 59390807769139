import { Stack, StackProps, Typography } from '@mui/material';

import { IconName } from '@hapvida/hapvida-core-components';
import { useSidebar } from '../../hooks';
import { RouteItemGroup, ExpandIcon } from './styles';
import { ReactNode } from 'react';
import { RouteIcon } from '../../styles';
import { SidebarSubItemProps } from './components/SidebarSubItem/types';
import { SidebarSubItem } from './components';

interface SidebarItemGroupProps extends StackProps {
  isSelected?: boolean;
  disabled?: boolean;
  iconName?: IconName;
  label: string;
  iconComponent?: ReactNode;
  items: SidebarSubItemProps[];
}

export function SidebarItemGroup({
  isSelected = false,
  disabled = false,
  label,
  iconName,
  iconComponent,
  items,
  ...stackProps
}: Readonly<SidebarItemGroupProps>) {
  const { drawerOpen } = useSidebar();

  const hasIconComponent = !iconName && iconComponent;

  const chevronIcon = isSelected ? 'chevron-up' : 'chevron-down';

  return (
    <Stack gap={0.5}>
      <RouteItemGroup
        justifyContent={drawerOpen ? 'flex-start' : 'center'}
        direction={drawerOpen ? 'row' : 'column'}
        selected={isSelected}
        disabled={disabled}
        {...stackProps}
      >
        <Stack gap={1} alignItems="center" flexDirection="row">
          {iconName && (
            <RouteIcon
              selected={isSelected}
              disabled={disabled}
              name={iconName}
              size={24}
            />
          )}
          {hasIconComponent && iconComponent}

          {drawerOpen && <Typography variant="body2">{label}</Typography>}
        </Stack>

        <ExpandIcon name={chevronIcon} size={24} />
      </RouteItemGroup>
      {isSelected && (
        <Stack gap={1}>
          {items.map(item => (
            <SidebarSubItem key={item.label} {...item} />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
