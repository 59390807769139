import { Loading, SummaryCardNew } from '@hapvida/hapvida-core-components';

import { BeneficiariesSummary } from '@components';
import { calculateSummaryValues, calculateTotalBeneficiaries } from '@utils';

import { useCompanyDetailsState } from '../../hooks';
import { initialSummaryValues } from './constants';

export function CompanyBeneficiariesSummaryCard() {
  const { companyDetailsSummaryState } = useCompanyDetailsState();

  if (companyDetailsSummaryState?.isLoading) {
    return (
      <SummaryCardNew.Container success>
        <Loading minHeight={64} alignItems="center" justifyContent="center" />
      </SummaryCardNew.Container>
    );
  }

  if (companyDetailsSummaryState.isError) {
    return null;
  }

  const summaryValues = calculateSummaryValues(
    companyDetailsSummaryState.data.beneficiaries,
    initialSummaryValues,
  );

  summaryValues.totalBeneficiaries = calculateTotalBeneficiaries(summaryValues);

  return <BeneficiariesSummary summaryValues={summaryValues} />;
}
