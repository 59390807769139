import { GenderEnum } from '../infra/services/BeneficiaryService/dtos/BeneficiaryDetailDTO';

const handleGender = (gender?: GenderEnum) => {
  const genders: Record<GenderEnum, string> = {
    F: 'Feminino',
    M: 'Masculino',
  };

  return gender ? genders[gender] : '';
};

export default handleGender;
