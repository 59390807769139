export enum DependentsAggregatedListTableNameEnum {
  PARTNER_COMPANY = 'PartnerCompany',
  COMPANY = 'HealthOperator',
  PLAN = 'CodePlanHealth',
  PERSON = 'Name',
  DETAIL = 'Code',
  TYPE = 'Type',
}

export const dependentsAggregatedListTableHeadColumns = [
  {
    id: 1,
    label: 'Status e',
    lastLabel: 'Cód. beneficiário',
    name: DependentsAggregatedListTableNameEnum.DETAIL,
  },
  {
    id: 2,
    label: 'Sexo, Nome e CPF',
    name: DependentsAggregatedListTableNameEnum.PERSON,
  },
  { id: 3, label: 'Tipo', name: DependentsAggregatedListTableNameEnum.TYPE },
  {
    id: 4,
    label: 'Operadora',
    name: DependentsAggregatedListTableNameEnum.COMPANY,
  },
  { id: 5, label: 'Plano', name: DependentsAggregatedListTableNameEnum.PLAN },
  {
    id: 6,
    label: 'Contrato, CNPJ',
    lastLabel: 'e Cód. unidade',
    name: DependentsAggregatedListTableNameEnum.PARTNER_COMPANY,
  },
];
