import { FileData } from '@services/FinancialService';

export interface FileGroupProps {
  csvFiles: FileData[];
  pdfFiles: FileData[];
  txtFiles: FileData[];
}

export function organizeFilesByType(files?: FileData[]) {
  const fileGroup = {
    csvFiles: [],
    pdfFiles: [],
    txtFiles: [],
  } as FileGroupProps;

  files?.forEach(file => {
    const { fileName } = file;

    const lowerCaseFileName = fileName.toLowerCase();

    if (lowerCaseFileName.includes('csv')) {
      fileGroup.csvFiles.push(file);
    } else if (lowerCaseFileName.includes('pdf')) {
      fileGroup.pdfFiles.push(file);
    } else if (lowerCaseFileName.includes('txt')) {
      fileGroup.txtFiles.push(file);
    }
  });

  return fileGroup;
}
