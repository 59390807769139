import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { BeneficiaryListRouter } from '@flows/private/beneficiaries/pages/BeneficiaryList/router';
import { MOVIMENTATION_STEPS_KEYS } from '@flows/private/movements/pages/MovimentationSteps/constants/keys';
import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';
import { BillListRouter } from '@flows/private/financial/pages/List/router';

import QuickActionButton from './components/QuickActionButton';

export function HomeQuickActions() {
  const navigate = useNavigate();

  const handleGoToBeneficiaries = () => {
    navigate(BeneficiaryListRouter.path);
  };

  const handleNewLayoutMovement = () => {
    navigate(
      MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE,
      }),
    );
  };

  const handleGoToBillList = () => {
    navigate(BillListRouter.path);
  };

  return (
    <SummaryCardNew.Container>
      <Typography variant="subtitle1">Ações rápidas</Typography>

      <Stack gap={3} flexDirection="row" flexWrap="wrap">
        <QuickActionButton
          onClick={handleGoToBeneficiaries}
          text="Lista de beneficiários"
          iconName="clipboard-pulse"
        />

        <QuickActionButton
          onClick={handleNewLayoutMovement}
          text="Movimentação via layout"
          iconName="file-excel"
        />

        <QuickActionButton
          onClick={handleGoToBillList}
          text="Extrato financeiro"
          iconName="money"
        />
      </Stack>
    </SummaryCardNew.Container>
  );
}
