import { ChangeEvent, useState, KeyboardEvent } from 'react';

import TextInput from '../../../TextInput';
import { useFilter } from '../../hooks';

export interface SearchFieldProps {
  placeholder?: string;
}

export function SearchField({ placeholder }: Readonly<SearchFieldProps>) {
  const [prevSearchState, setPrevSearchState] = useState<string>('');
  const [searchState, setSearchState] = useState<string>('');
  const { handleSearchFilter } = useFilter();

  const handleFieldChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchState(value);
  };

  const handleApplySearch = () => {
    const purePrevSearchState = prevSearchState.trim().toLowerCase();
    const pureSearchState = searchState.trim().toLowerCase();

    if (pureSearchState === purePrevSearchState) return;

    setPrevSearchState(pureSearchState);

    if (pureSearchState.length > 4 || pureSearchState.length === 0) {
      handleSearchFilter(
        pureSearchState.length === 0 ? undefined : pureSearchState,
      );
    }
  };

  const handleEnter = ({ key }: KeyboardEvent) => {
    if (key === 'Enter') {
      (document.activeElement as HTMLElement)?.blur();
    }
  };

  return (
    <TextInput
      onChange={handleFieldChange}
      onBlur={handleApplySearch}
      placeholder={placeholder}
      onKeyDown={handleEnter}
      iconStart="magnifier"
      value={searchState}
      name="search"
      fullWidth
    />
  );
}
