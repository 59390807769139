import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { useMovementDetailsState } from '../../hooks';
import { SUMMARY_DATA } from './constants/summary';

export function MovementSummaryCard() {
  const { movementSummaryState } = useMovementDetailsState();

  if (!movementSummaryState.isSuccess) {
    return null;
  }

  return (
    <SummaryCardNew.Container marginTop={3}>
      <SummaryCardNew.Header title="Resumo da movimentação" />
      <SummaryCardNew.Content
        summaryValues={movementSummaryState.data}
        summary={SUMMARY_DATA}
        defaultValue="-"
        perLine={5}
      />
    </SummaryCardNew.Container>
  );
}
