import * as Yup from 'yup';

import { getStringLengthWithoutSpaces } from '@utils';

import { SearchLength } from './types';

export const schema = Yup.object({
  search: Yup.string().test(
    'empty-or-5-characters-check',
    'Digite no mínimo 5 caracteres para fazer a busca.',
    search =>
      !search || getStringLengthWithoutSpaces(search) >= SearchLength.MIN,
  ),
});
