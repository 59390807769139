import { Icon } from '@hapvida/hapvida-core-components';

import { IAppDynamicRoute } from 'routes/types';

import { BillDetail } from '.';

export const BillDetailRouter: IAppDynamicRoute = {
  Page: BillDetail,
  path: '/financeiro/detalhes/:id',
  dynamicPath: ({ billNumber }) => `/financeiro/detalhes/${billNumber}`,
  name: 'Financeiro',
  isPrivate: true,
  Icon: <Icon name="money" />,
};
