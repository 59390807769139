import { PropsWithChildren, JSX } from 'react';

import { MainContent, PageContainer, StyledStack, AppBar } from './styles';
import { Toolbar } from '@mui/material';

interface HeaderProps {
  mainContent?: JSX.Element;
}

export function Header({
  mainContent,
  children,
}: PropsWithChildren<HeaderProps>) {
  return (
    <PageContainer>
      <StyledStack>
        <AppBar position="sticky">
          <Toolbar>{children}</Toolbar>
        </AppBar>
        <MainContent component="main">{mainContent}</MainContent>
      </StyledStack>
    </PageContainer>
  );
}
