import { Grid, Typography } from '@mui/material';
import React from 'react';

import { DisclaimerCard, Icon } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import Authenticate from '@assets/images/authenticate.svg';

const AuthenticateOptions: React.FC = () => {
  const benefits = [
    {
      benefit: (
        <p>
          O maior sistema de saúde suplementar do Brasil{' '}
          <strong>e uma das maiores empresas verticalizadas do mundo.</strong>
        </p>
      ),
    },
    {
      benefit: (
        <p>
          <strong>Atendemos mais de 15 milhões de beneficiários,</strong> entre
          saúde e odontologia, com rede própria, em todas as regiões do país.
        </p>
      ),
    },
    {
      benefit: (
        <p>
          <strong>Toda a rede própria de ambas as operadoras</strong> estará
          disponível para atender os clientes da Solução Nacional.
        </p>
      ),
    },
    {
      benefit: (
        <p>
          Todos os clientes com três mil beneficiários ou mais, que estejam
          cobertos por uma das operadoras, estão{' '}
          <strong>elegíveis para o programa.</strong>
        </p>
      ),
    },
  ];

  return (
    <Grid display="flex" gap={4}>
      <DisclaimerCard
        image={Authenticate}
        imageAlt="Cadastro na plataforma"
        imageWidth={100}
        imageHeight={108}
        description="Faça cadastro na plataforma e comece agora a companhar todo processo de venda, contratação e implantação da sua empresa ou dos seus clientes."
        button={{
          text: 'Quero me cadastrar',
          redirectTo: '/login',
        }}
        customStyles={{ maxWidth: 368 }}
      />
      <Grid item container direction="column" maxWidth={464}>
        {benefits.map(({ benefit }) => (
          <Grid item key={benefit.key} display="flex" alignItems="center">
            <Icon
              name="marked-circle-outline"
              color={COLORS.PRIMARY.ORANGE.MAIN}
              size={24}
            />
            <Typography marginLeft={2} fontSize={14}>
              {benefit}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AuthenticateOptions;
