import { StackProps, Typography } from '@mui/material';

import { useSidebar } from '../../hooks';
import { RouteIcon, RouteItem } from '../../styles';
import { ReactNode } from 'react';
import { IconName } from '@hapvida/hapvida-core-components';

interface SidebarItemProps extends StackProps {
  isSelected?: boolean;
  disabled?: boolean;
  noInteraction?: boolean;
  iconName?: IconName;
  label: string;
  iconComponent?: ReactNode;
}

export function SidebarItem({
  isSelected = false,
  disabled = false,
  label,
  iconName,
  iconComponent,
  noInteraction,
  ...stackProps
}: Readonly<SidebarItemProps>) {
  const { drawerOpen } = useSidebar();

  const hasIconComponent = !iconName && iconComponent;

  return (
    <RouteItem
      justifyContent={drawerOpen ? 'flex-start' : 'center'}
      direction={drawerOpen ? 'row' : 'column'}
      selected={isSelected}
      disabled={disabled}
      noInteraction={noInteraction}
      {...stackProps}
    >
      {iconName && (
        <RouteIcon
          selected={isSelected}
          disabled={disabled}
          name={iconName}
          size={24}
        />
      )}
      {hasIconComponent && iconComponent}

      {drawerOpen && <Typography variant="body2">{label}</Typography>}
    </RouteItem>
  );
}
