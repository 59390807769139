import React from 'react';

import { ErrorPage } from '@hapvida/hapvida-core-components';

import Error500Image from '@assets/images/error-500.svg';
import { useAuth } from 'hooks/useAuth';

import AuthenticateOptions from '../../components/AuthenticateOptions';

export const ServerError: React.FC = () => {
  const { user, logOut } = useAuth();

  return (
    <ErrorPage
      image={Error500Image}
      title="Sem resposta do servidor."
      imageAlt="Erro 500, servidor indisponível (erro interno)"
      description="Parece que nosso servidor não está respondendo. Em breve, tudo estará pronto para você."
      user={user}
      logOut={logOut}
      showBottomContent={!user}
      loginRoute={process.env.REACT_APP_LOGIN_URL}
      authenticateOptions={AuthenticateOptions}
    />
  );
};
