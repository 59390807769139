import { Grid } from '@mui/material';

import { AccessManagementProvider } from 'components/ProfileSelectionCard/hooks';

import { AddAccountLayout } from './layout';
import { Container } from './styles';

export function AddAccountPage() {
  return (
    <Container container spacing={3}>
      <Grid xs={6}>
        <AccessManagementProvider>
          <AddAccountLayout />
        </AccessManagementProvider>
      </Grid>
    </Container>
  );
}
