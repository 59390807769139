import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import {
  Icon,
  Mask,
  Pill,
  COLORS,
  Company,
} from '@hapvida/hapvida-core-components';

import {
  BillStatus,
  BillType,
  Bill,
} from '@services/FinancialService/dtos/BillsListDTO';
import { FileData, InvoiceProps } from '@services/FinancialService';

import { BillDetailRouter } from '@flows/private/financial/pages/Detail/router';
import { useBill } from '@flows/private/financial/hooks';
import { useScreenLoading } from '@hooks';

import { Line, StyledGrid, ActionButton } from './styles';

interface BillRowProps {
  bill: Bill;
}

export function BillRow({ bill }: Readonly<BillRowProps>) {
  const { getBillFile, getCoparticipationReportFiles } = useBill();
  const { onScreenLoading } = useScreenLoading();
  const navigate = useNavigate();

  const handleOpenFile = (file?: FileData | InvoiceProps) => {
    if (file?.path) window.open(file.path, '_blank');
  };

  const handleOpenFiles = (files?: FileData[]) => {
    files?.forEach(file => handleOpenFile(file));
  };

  const downloadFiles = async () => {
    onScreenLoading(true);
    const billFile = await getBillFile(bill);

    handleOpenFile(bill.invoice);

    if (bill.coparticipationAnalytical.length > 0) {
      const coparticipationReportFiles = await getCoparticipationReportFiles(
        bill,
      );
      handleOpenFiles(coparticipationReportFiles);
    }
    handleOpenFile(billFile);
    onScreenLoading(false);
  };

  const { number, status, healthOperator, amount, billType, companyDocument } =
    bill;

  return (
    <Line container alignItems="center">
      <StyledGrid item xs={3}>
        <Stack>
          <Typography variant="body2">{number}</Typography>
          {companyDocument && (
            <Typography variant="body2">
              {Mask.cnpj(companyDocument)}
            </Typography>
          )}
        </Stack>
      </StyledGrid>
      <StyledGrid item xs={2}>
        <Stack>
          <Typography variant="body2" fontWeight={700}>
            {Mask.currency(amount)}
          </Typography>
          <Typography variant="body2">{BillType[billType]}</Typography>
        </Stack>
      </StyledGrid>
      <StyledGrid item xs={3}>
        <Company company={healthOperator} />
      </StyledGrid>
      <StyledGrid item xs={2}>
        <Pill
          color={BillStatus[status]?.color}
          text={BillStatus[status]?.label}
          fitContent
        />
      </StyledGrid>
      <StyledGrid item xs={2}>
        <Stack
          justifyContent="end"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <ActionButton onClick={downloadFiles}>
            <Icon
              name="download"
              color={COLORS.MONOCHROMATIC.GRAY4}
              size={24}
            />
          </ActionButton>

          <ActionButton
            onClick={() => {
              navigate(
                `${BillDetailRouter.dynamicPath({
                  billNumber: bill.number,
                })}?company=${bill.companyId}&year=${bill.year}`,
              );
            }}
          >
            <Icon
              color={COLORS.MONOCHROMATIC.GRAY4}
              name="chevron-right"
              size={24}
            />
          </ActionButton>
        </Stack>
      </StyledGrid>
    </Line>
  );
}
