import { FC } from 'react';
import { Stack } from '@mui/material';

import { Icon, Button, Typography } from '@hapvida/hapvida-core-components';

import PasswordRecoveryCompletedLogo from './assets/registration-completed-logo.svg';
import { Container, Wrapper } from './styles';

const PasswordRecoveryCompleted: FC = () => {
  const loginPageUrl = process.env.REACT_APP_LOGIN_URL;

  return (
    <Container>
      <img
        width={'112px'}
        height={'98px'}
        src={PasswordRecoveryCompletedLogo}
        alt="Desenho de uma prancheta com uma lista de itens com 'checks' indicando que o cadastro foi finalizado"
      />

      <Stack gap={2}>
        <Typography variant="h5">Senha criada com sucesso</Typography>
        <Typography variant="text">
          Sua área está te esperando, desejamos ótimos negócios
        </Typography>
      </Stack>

      <Stack pt={2}>
        <Button
          color="success"
          variant="contained"
          endIcon={<Icon name="chevron-right" size={16} />}
          href={loginPageUrl}
        >
          Efetuar login
        </Button>
      </Stack>
      <Wrapper />
    </Container>
  );
};

export default PasswordRecoveryCompleted;
