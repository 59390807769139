import { Stack } from '@mui/material';

import { Icon, Mask, Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { Wrapper, Value, Label, IconWrapper, Image } from './styles';

interface ValuesProps {
  title: string;
  image?: string;
  imageAlt?: string;
  totalPaid: number;
  totalRemaining: number;
}

export const Values = ({
  title,
  image,
  imageAlt,
  totalPaid,
  totalRemaining,
}: ValuesProps) => {
  return (
    <Wrapper
      gap={3}
      direction="column"
      alignItems="flex-start"
      justifyContent="space-around"
    >
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        {image && <Image src={image} alt={imageAlt} />}

        <Typography fontWeight={900} fontSize={16}>
          {title}
        </Typography>
      </Stack>
      <Stack width="100%" direction="row">
        <Stack gap={2} direction="row" width="100%">
          <IconWrapper>
            <Icon
              size={24}
              name="marked-circle-outline"
              color={COLORS.SECONDARY.GREEN['+1']}
            />
          </IconWrapper>
          <Stack spacing={0.5} direction="column" width="100%">
            <Value color={COLORS.SECONDARY.GREEN['+1']}>
              {Mask.currency(totalPaid)}
            </Value>
            <Label>Total pago</Label>
          </Stack>
        </Stack>
        <Stack gap={2} direction="row" width="100%">
          <IconWrapper color="red">
            <Icon
              size={24}
              name="alert-circle"
              color={COLORS.SECONDARY.RED.MAIN}
            />
          </IconWrapper>
          <Stack spacing={0.5} direction="column" width="100%">
            <Value color={COLORS.SECONDARY.RED.MAIN}>
              {Mask.currency(totalRemaining)}
            </Value>
            <Label>Total em aberto</Label>
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  );
};
