import { Typography } from '@mui/material';

import { AlertCard, COLORS } from '@hapvida/hapvida-core-components';

import { MovementStatusEnum } from '@constants';

import { INTEGRATION_STATUS_ERROR } from './constants';
import { useMovementDetailsState } from '../../hooks';
import { CallOut } from './components';

export function MovementAlertsCard() {
  const { movementInfoState } = useMovementDetailsState();

  if (!movementInfoState.isSuccess) {
    return null;
  }

  const { movementDetails } = movementInfoState.data.content;

  if (
    movementDetails?.status === MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW
  ) {
    return (
      <AlertCard
        title="Arquivo processado parcialmente."
        type="alert"
        content={
          <>
            {movementDetails.integrationStatus ? (
              <>
                {
                  INTEGRATION_STATUS_ERROR[movementDetails.integrationStatus]
                    .label
                }
              </>
            ) : (
              <Typography color={COLORS.MONOCHROMATIC.GRAY4} variant="text">
                A movimentação foi processada,{' '}
                <b>porém nem todas os beneficiários foram efetivados.</b> Fique
                a vontade para ver o resultado da sua movimentação.
              </Typography>
            )}
          </>
        }
      />
    );
  }

  return <CallOut status={movementDetails.status} />;
}
