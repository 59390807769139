import React from 'react';

import CompanyManagerLayout from './layout';

type CompanyManagerProps = {
  onStepUpdate(): void;
  companyId: string;
};

const CompanyManagerStep: React.FC<CompanyManagerProps> = ({
  onStepUpdate,
  companyId,
}) => {
  return (
    <CompanyManagerLayout onStepUpdate={onStepUpdate} companyId={companyId} />
  );
};

export default CompanyManagerStep;
