import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

import { COLORS } from '../colors';
const MuiRadioTheme: {
  defaultProps?: ComponentsProps['MuiRadio'];
  styleOverrides?: ComponentsOverrides['MuiRadio'];
  variants?: ComponentsVariants['MuiRadio'];
} = {
  variants: [],
  styleOverrides: {
    root: {
      transition: 'color 0.75s',
      '&.Mui-checked': {
        color: COLORS.PRIMARY.ORANGE.MAIN,
        transition: 'color 0s',
      },
    },
  },
};

export default MuiRadioTheme;
