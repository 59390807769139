import React from 'react';

import PasswordRecoveryStep from './steps/PasswordDefinition/step';
import PasswordRecoveryCompletedStep from './steps/PasswordRecoveryCompleted/step';

export interface IPasswordRecoveryStep {
  name?: string;
  step(props: any): React.ReactElement;
}

export const steps: Array<IPasswordRecoveryStep> = [
  PasswordRecoveryStep,
  PasswordRecoveryCompletedStep,
];
