import { useState } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { Button, Stack, Typography } from '@mui/material';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import Icon from '../Icon';

import { StyledTypography, TimerContent } from './styles';
import { CircularProgress } from '../CircularProgress';

interface CountDownProps {
  available?: {
    info?: string;
    clickText?: string;
  };
  unavaible?: {
    loadingText?: string;
    loadingInfo?: string;
  };

  timer?: number;
  onClick?: () => void | Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  successMessage?: string;
}

export const CountDown: React.FC<CountDownProps> = ({
  available = {
    info: '',
    clickText: 'Reenviar agora',
  },
  unavaible = {
    loadingText: 'Aguarde',
    loadingInfo: '',
  },
  disabled,
  timer = 60,
  onClick,
  loading = false,
  successMessage,
}: CountDownProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const countDownDateCalc = Date.now() + timer * 1000;

  const renderDecimal = (time: number): string => {
    return time.toString().padStart(2, '0');
  };

  const rendererConditional = ({
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      setIsClicked(false);
      return null;
    }

    return (
      <span>
        {renderDecimal(minutes)}:{renderDecimal(seconds)}
      </span>
    );
  };

  const handleTimerClick = async () => {
    if (disabled || isLoading) {
      return;
    }

    setIsLoading(true);

    if (onClick) {
      await onClick();
    }

    setIsClicked(true);
    setIsLoading(false);
  };

  return (
    <Stack direction="row">
      {!isClicked ? (
        <>
          <Typography variant="text">{available.info}</Typography>
          <Button
            variant="contained"
            disabled={loading || isLoading}
            onClick={handleTimerClick}
            color="secondary"
            endIcon={isLoading && <CircularProgress size={16} />}
          >
            {available.clickText}
          </Button>
        </>
      ) : (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <StyledTypography variant="text" color="secondary" fontWeight={700}>
            <Icon name="check" size={20} color={COLORS.SECONDARY.GREEN.MAIN} />
            {successMessage}
          </StyledTypography>

          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <StyledTypography variant="text">
              {unavaible.loadingText}
            </StyledTypography>
            <TimerContent>
              <Typography
                variant="text"
                color={COLORS.MONOCHROMATIC.GRAY4}
                sx={{ fontWeight: 700 }}
              >
                <Countdown
                  date={countDownDateCalc}
                  renderer={rendererConditional}
                />
              </Typography>
            </TimerContent>
            <Typography variant="text">{unavaible.loadingInfo}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
