enum HealthOperatorEnum {
  CLINIPAM = 'Clinipam',
  HAPVIDA = 'Hapvida',
  NDI = 'NDI',
  CCG = 'CCG',
}

const HEALTH_OPERATOR_NAME: Record<HealthOperatorEnum, string> = {
  [HealthOperatorEnum.CLINIPAM]: 'Clinipam',
  [HealthOperatorEnum.HAPVIDA]: 'Hapvida',
  [HealthOperatorEnum.NDI]: 'NDI',
  [HealthOperatorEnum.CCG]: 'CCG',
};

export { HealthOperatorEnum, HEALTH_OPERATOR_NAME };
