import { ButtonProps, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Button from '../../../Button';

interface SummaryCardHeaderProps {
  subtitle?: string | ReactNode;
  subtitleUpperCase?: boolean;
  buttonProps?: ButtonProps;
  titleUpperCase?: boolean;
  title: string;
}

export function SummaryCardHeader({
  subtitleUpperCase,
  titleUpperCase,
  buttonProps,
  subtitle,
  title,
}: Readonly<SummaryCardHeaderProps>) {
  return (
    <Stack justifyContent="space-between" direction="row" alignItems="center">
      <Stack gap={0.5}>
        <Typography
          textTransform={titleUpperCase ? 'uppercase' : 'none'}
          variant="subtitle1"
        >
          {title}
        </Typography>
        {subtitle &&
          (typeof subtitle === 'string' ? (
            <Typography
              textTransform={subtitleUpperCase ? 'uppercase' : 'none'}
              variant="body2"
            >
              {subtitle}
            </Typography>
          ) : (
            subtitle
          ))}
      </Stack>

      {buttonProps?.onClick && (
        <Button
          variant={buttonProps.variant || 'outlined'}
          color="secondary"
          {...buttonProps}
        >
          {buttonProps.content}
        </Button>
      )}
    </Stack>
  );
}
