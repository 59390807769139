import { styled as muiStyled } from '@mui/material/styles';

import { Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Label = muiStyled(Typography)(() => ({
  '&.MuiTypography-root': {
    color: COLORS.MONOCHROMATIC.GRAY4,
  },
}));
