import { Stack, StackProps, Typography } from '@mui/material';
import CustomPagination from '@hapvida/hapvida-core-components/src/components/BaseTable/components/Pagination';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes';

export interface BaseListPaginationProps {
  totalDescription: string;
  totalPerPage: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  stackProps?: StackProps;
}

export function BaseListPagination({
  totalDescription,
  totalPerPage,
  currentPage,
  totalPages,
  handlePageChange,
  totalItems,
  stackProps,
}: Readonly<BaseListPaginationProps>) {
  return (
    <Stack
      bgcolor={COLORS.WHITE}
      borderRadius={1}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      minHeight={40}
      gap={3}
      paddingX={3}
      {...stackProps}
    >
      <Typography variant="text" color="#767C81">
        Visualizando {totalPerPage} de {totalItems} {totalDescription}
      </Typography>
      <CustomPagination
        count={totalPages}
        currentPage={currentPage}
        onChange={(_, page) => handlePageChange(page)}
        height={40}
      />
    </Stack>
  );
}
