import { Typography } from '@mui/material';
import { RouteSubItem } from '../../styles';
import { SidebarSubItemProps } from './types';

export function SidebarSubItem({
  isSelected = false,
  disabled = false,
  label,
  ...stackProps
}: Readonly<SidebarSubItemProps>) {
  return (
    <RouteSubItem {...stackProps} selected={isSelected} disabled={disabled}>
      <Typography variant="body2">{label}</Typography>
    </RouteSubItem>
  );
}
