import React from 'react';

import { UserDataAccessStep } from './steps/UserDataAccess/step';
import { UserPasswordStep } from './steps/UserPassword/step';
import { UserRegistrationCompletedStep } from './steps/UserRegistrationCompleted/step';

export interface IUserFirstAccessStep {
  name: string;
  step(): React.ReactElement;
}

export const stepsUserFirstAccess: Array<IUserFirstAccessStep> = [
  UserDataAccessStep,
  UserPasswordStep,
  UserRegistrationCompletedStep,
];
