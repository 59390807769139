import { styled as muiStyled } from '@mui/material/styles';

interface IconDivProps {
  color: string;
}

export const IconDiv = muiStyled('div')<IconDivProps>(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.25rem',
  color,
  '& .MuiTypography-root': {
    color,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '2px 0 0 0.5rem',
  },
}));
