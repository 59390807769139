import styled from 'styled-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Description = styled.p`
  margin: 0px;
  color: ${COLORS.MONOCHROMATIC.GRAY4};
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
`;
