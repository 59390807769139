import Typography from '@mui/material/Typography';

import {
  SummaryCardNew,
  FilterSummary,
} from '@hapvida/hapvida-core-components';

import { useMovementDetailsState } from '../../hooks';
import {
  MovementBeneficiariesSummary,
  MovementBeneficiariesHeader,
  MovementBeneficiariesList,
} from './components';

export function MovementDetailsCard() {
  const { movementLivesState, movementInfoState } = useMovementDetailsState();

  if (!movementInfoState.isSuccess || !movementLivesState.isSuccess) {
    return null;
  }

  const { beneficiaries, summaryLives } = movementLivesState.data;
  const { status } = movementInfoState.data.content.movementDetails;
  const totalMovementLivesLength = movementLivesState.data.total ?? 0;

  const filterSummaryLabel = `${totalMovementLivesLength} ${
    totalMovementLivesLength === 1 ? 'beneficiário' : 'beneficiários'
  }`;

  return (
    <SummaryCardNew.Container>
      <Typography variant="subtitle1">Detalhes da movimentação</Typography>

      {summaryLives && (
        <MovementBeneficiariesSummary summaryLives={summaryLives} />
      )}

      <MovementBeneficiariesHeader
        beneficiaries={beneficiaries}
        movementInfo={movementInfoState.data.content}
      />

      <FilterSummary totalLabel={filterSummaryLabel} />

      <MovementBeneficiariesList
        total={totalMovementLivesLength}
        movementStatus={status}
      />
    </SummaryCardNew.Container>
  );
}
