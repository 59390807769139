import { UserPartnerCompanyProps } from '@services/UserService';

interface ExtraConfigProps {
  queryParams?: {
    'contract-type'?: string;
  };
  enabled?: boolean;
}

interface UsePartnerCompanyListProps {
  searchParam: string;
}

enum UserPartnerCompanyQueryKeysEnum {
  INITIAL_PARTNER_COMPANIES_STATE = 'initialPartnerCompaniesState',
  INITIAL_MAIN_PARTNER_COMPANIES_STATE = 'initialMainPartnerCompaniesState',
}

interface ExtraUserPartnerCompanyProps extends UserPartnerCompanyProps {
  isOldOnList?: boolean;
}

export {
  ExtraConfigProps,
  UsePartnerCompanyListProps,
  UserPartnerCompanyQueryKeysEnum,
  ExtraUserPartnerCompanyProps,
};
