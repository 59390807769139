import { Grid, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, Form } from '@hapvida/hapvida-core-components';

import { AddAccountFormProps } from '@services/UserService';
import { CompanyDetailMock } from '@services/CompanySetupService/dtos/GetCompanyDetailDTO';

import { FormRegisterUser } from '@flows/private/users/pages/CreateUser/components/FormRegisterUser';
import { useValidateUser } from '@flows/private/users/pages/CreateUser/hooks';
import {
  EmailValidator,
  ModalNotification,
} from '@flows/private/users/pages/CreateUser/components';
import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

import { schema } from './control/validation';
import { Container } from './styles';
import { useRegisterUserCompany } from '../../hooks';

interface RegisterUserCompanyProps {
  companyDetails?: CompanyDetailMock;
}

export const RegisterUserCompany = ({
  companyDetails,
}: RegisterUserCompanyProps) => {
  const { onRegisterUserCompany } = useRegisterUserCompany({ companyDetails });
  const { editedPartnerCompanies, selectedProfiles } = useAccessManagement();
  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { isValid, errors },
    reset,
  } = useForm<AddAccountFormProps>({
    mode: 'all',
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });
  const {
    showValidator,
    openModal,
    handleNext,
    handleBack,
    closeModal,
    goToEditUserData,
  } = useValidateUser();

  const onSubmitForm: SubmitHandler<AddAccountFormProps> = async data => {
    await onRegisterUserCompany(data);
  };

  const profileIsEmpty =
    editedPartnerCompanies.length === 0 || selectedProfiles.length === 0;

  const disableButton = !isValid || profileIsEmpty;

  return (
    <Stack gap={3}>
      <Container container spacing={3}>
        <Grid xs={6}>
          <Form title="Novo usuário" subtitle="Campos com * são obrigatórios">
            <Typography variant="text" pt={2} pb={2}>
              Primeiro, informe o e-mail do novo usuário.
            </Typography>
            <form>
              <Grid container spacing={{ xs: 5, sm: 3 }}>
                {showValidator ? (
                  <EmailValidator
                    control={control}
                    watch={watch}
                    errors={errors}
                    trigger={trigger}
                    onNext={handleNext}
                  />
                ) : (
                  <FormRegisterUser
                    controlForm={control}
                    watchForm={watch}
                    errorsForm={errors}
                    triggerForm={trigger}
                  />
                )}
                {!showValidator && (
                  <Grid item xs={12} container justifyContent="space-between">
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleBack(reset)}
                    >
                      Voltar
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={disableButton}
                      onClick={handleSubmit(onSubmitForm)}
                    >
                      Criar usuário
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
            <ModalNotification
              openModal={openModal}
              handleCloseModal={closeModal}
              goToEditUserData={goToEditUserData}
            />
          </Form>
        </Grid>
      </Container>
    </Stack>
  );
};
