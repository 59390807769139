import { css, styled, TextField } from '@mui/material';

interface StyledTextFieldProps {
  disableGutters?: boolean;
}

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  ${({ disableGutters }) =>
    disableGutters &&
    css`
      .MuiOutlinedInput-root {
        fieldset {
          border-color: transparent;
        }
        &:hover fieldset {
          border-color: transparent;
        }
        &.Mui-focused fieldset {
          border-color: transparent;
        }
      }
    `}
`;
