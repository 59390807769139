import {
  Button,
  ButtonProps,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';

import emptyStateImage from '@assets/images/empty-state.svg';

interface EmptyResultProps extends StackProps {
  buttonProps?: ButtonProps;
  message: string;
}

export function EmptyResult({ message, buttonProps }: EmptyResultProps) {
  return (
    <Stack alignItems="center" padding={4} gap={3}>
      <img
        src={emptyStateImage}
        alt="Sem resultados"
        draggable={false}
        height={124}
      />
      <Typography variant="h5" textAlign="center" maxWidth={472}>
        {message}
      </Typography>
      {buttonProps?.children && buttonProps?.onClick && (
        <Button variant="outlined" {...buttonProps} />
      )}
    </Stack>
  );
}
