import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';
import { Control, UseFormTrigger } from 'react-hook-form';

import TextInput from '@hapvida/hapvida-core-components/src/components/TextInput';

import { StyledIcon } from './styles';
import CombinedAdornmentPassword from '../CombinedAdornmentPassword';
import usePasswordValidateRender from '../../../hooks/usePasswordValidateRender';
import { PasswordDefinitionForm } from '../../PasswordRecovery/PasswordRecovery/steps/PasswordDefinition';

interface FormPasswordProps {
  passwordWatch: string;
  passwordConfirmWatch: string;
  errorsForm: any;
  triggerForm: UseFormTrigger<PasswordDefinitionForm>;
  controlForm: Control<PasswordDefinitionForm, any>;
}

const FormPassword = ({
  passwordWatch,
  passwordConfirmWatch,
  errorsForm,
  triggerForm,
  controlForm,
}: FormPasswordProps) => {
  const [showPasswordState, setShowPasswordState] = useState<boolean>(false);
  const [showConfirmationPasswordState, setShowConfirmationPasswordState] =
    useState<boolean>(false);

  const handleShowPassword = () => setShowPasswordState(!showPasswordState);
  const handleShowConfirmationPassword = () =>
    setShowConfirmationPasswordState(!showConfirmationPasswordState);

  const {
    errorPassword,
    errorPasswordConfirmation,
    errorPasswordMsg,
    errorPasswordConfirmationMsg,
    handleMessageSuccess,
    hasIconSuccessConfirmInput,
  } = usePasswordValidateRender({
    passwordContent: passwordWatch,
    passwordContentConfirmation: passwordConfirmWatch,
    errors: errorsForm,
  });

  return (
    <>
      <Grid item xs={12}>
        <TextInput
          type={showPasswordState ? 'text' : 'password'}
          control={controlForm}
          margin="normal"
          name="password"
          label="Nova senha"
          placeholder="Pelo menos 8 caracteres"
          required
          fullWidth
          errorPassword={Boolean(errorPassword)}
          errorMessage={errorPasswordMsg}
          success={handleMessageSuccess()}
          successMessage="Sua senha é forte!"
          onChangeElementInput={() => {
            triggerForm('passwordConfirmation');
          }}
          InputProps={{
            endAdornment: (
              <>
                {passwordWatch && (
                  <CombinedAdornmentPassword
                    colorField={!errorPassword ? 'success' : 'error'}
                  />
                )}

                <InputAdornment position="end" onClick={handleShowPassword}>
                  <StyledIcon
                    name={showPasswordState ? 'eye' : 'eye-outline'}
                  />
                </InputAdornment>
              </>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          type={showConfirmationPasswordState ? 'text' : 'password'}
          control={controlForm}
          margin="normal"
          name="passwordConfirmation"
          label="Confirmar nova senha"
          placeholder="Digite novamente"
          errorPassword={Boolean(errorPasswordConfirmation)}
          errorMessage={errorPasswordConfirmationMsg}
          successMessage="Senhas correspondentes!"
          success={hasIconSuccessConfirmInput && !errorPasswordConfirmation}
          required
          fullWidth
          InputProps={{
            endAdornment: (
              <>
                {passwordConfirmWatch && (
                  <CombinedAdornmentPassword
                    colorField={
                      !errorPasswordConfirmation ? 'success' : 'error'
                    }
                  />
                )}

                <InputAdornment
                  position="end"
                  onClick={handleShowConfirmationPassword}
                >
                  <StyledIcon
                    name={showConfirmationPasswordState ? 'eye' : 'eye-outline'}
                  />
                </InputAdornment>
              </>
            ),
          }}
        />
      </Grid>
    </>
  );
};

export default FormPassword;
