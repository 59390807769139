import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { BeneficiariesSubHeader } from './components';
import { BeneficiariesStateProvider } from './hooks';
import { BeneficiaryListLayout } from './layout';

export function BeneficiaryListPage() {
  return (
    <DrawerFilterProvider>
      <BeneficiariesStateProvider>
        <BeneficiariesSubHeader />
        <BeneficiaryListLayout />
      </BeneficiariesStateProvider>
    </DrawerFilterProvider>
  );
}
