import { Divider, FormGroup, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  COLORS,
  AccordionNew,
  Loading,
  Icon,
} from '@hapvida/hapvida-core-components';

import { UserProfileEnum } from '@constants';
import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

import { PartnerCompaniesStack } from './styles';
import {
  MainPartnerCompanyAlert,
  PartnerCompanyItem,
  SearchPartnerCompany,
} from './components';
import { usePartnerCompanyList, useProfileCard } from '../../hooks';

export function PartnerCompaniesSelection() {
  const [searchParam, setSearchParam] = useState('');

  const { selectedProfile, currentSelectedPartnerCompanies } = useProfileCard();
  const { hasError } = useAccessManagement();

  const isMaster = selectedProfile?.name === UserProfileEnum.MASTER;

  const { initialPartnerCompaniesState, initialMainPartnerCompaniesState } =
    usePartnerCompanyList({
      searchParam,
    });

  const partnerCompaniesState = isMaster
    ? initialMainPartnerCompaniesState
    : initialPartnerCompaniesState;

  if (partnerCompaniesState.isError) {
    return null;
  }

  const initialPartnerCompanies =
    partnerCompaniesState?.data?.pages
      .map(item => item.partnerCompanies.itens)
      .flat() ?? [];

  const selectedPartnerCompaniesLength = currentSelectedPartnerCompanies.length;

  const isEmpty = initialPartnerCompanies.length === 0;

  const isFetching =
    partnerCompaniesState.isFetching || partnerCompaniesState.isLoading;

  const showCircularProgress = isEmpty && isFetching && !hasError;

  const showDivider = (index: number) => {
    return index < initialPartnerCompanies.length - 1 || isFetching;
  };

  const animationProps = { height: 20, width: 20 };

  if (!selectedProfile) {
    return null;
  }

  return (
    <AccordionNew
      title="Lista de contratos"
      subtitle={
        <Stack flexDirection="row" gap="4px" mt={0.5}>
          <Typography
            variant="smallText"
            color={COLORS.PRIMARY.ORANGE.MAIN}
            fontWeight={700}
          >
            {selectedPartnerCompaniesLength}
          </Typography>
          <Typography variant="smallText" fontWeight={700}>
            contratos selecionados
          </Typography>
        </Stack>
      }
    >
      {isMaster && (
        <>
          <MainPartnerCompanyAlert />
          <Divider />
        </>
      )}
      <FormGroup>
        {!hasError && (
          <SearchPartnerCompany
            placeholder="Buscar contrato"
            setSearchParam={search => setSearchParam(search)}
          />
        )}
        <Divider />
        <PartnerCompaniesStack id={selectedProfile.name}>
          {showCircularProgress && (
            <Stack my={2} justifyContent="center" alignItems="center">
              <Loading animationProps={animationProps} />
            </Stack>
          )}
          {hasError && (
            <Stack
              my={2}
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              gap={0.5}
            >
              <Icon
                name="alert-circle"
                size={20}
                color={COLORS.SECONDARY.RED['+1']}
              />
              <Typography variant="text">
                Não foi possível carregar os contratos.
              </Typography>
            </Stack>
          )}
          {isEmpty && !isFetching && (
            <Stack my={2} justifyContent="center" alignItems="center">
              <Typography variant="text">
                Não foram encontrados contratos para essa busca.
              </Typography>
            </Stack>
          )}
          {!hasError && !showCircularProgress && (
            <InfiniteScroll
              scrollableTarget={selectedProfile.name}
              dataLength={initialPartnerCompanies.length}
              hasMore={Boolean(partnerCompaniesState.hasNextPage)}
              next={partnerCompaniesState.fetchNextPage}
              loader={
                <Stack my={2} justifyContent="center" alignItems="center">
                  <Loading animationProps={animationProps} />
                </Stack>
              }
            >
              {initialPartnerCompanies.map((item, index) => (
                <div key={item.partnerCompany}>
                  <PartnerCompanyItem
                    partnerCompany={item}
                    selectedProfile={selectedProfile}
                  />
                  {showDivider(index) && <Divider />}
                </div>
              ))}
            </InfiniteScroll>
          )}
        </PartnerCompaniesStack>
      </FormGroup>
    </AccordionNew>
  );
}
