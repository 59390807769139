import { Button, Stack, Typography, Box } from '@mui/material';

import { COLORS, Icon, Mask, Pill } from '@hapvida/hapvida-core-components';

import { CompanyProps } from '@services/AuthService/dtos';
import { COMPANY_USER_REGISTER_STATUS_DATA } from '@services/CompanySetupService/dtos/ListCompanyDTO';

import { PartnerCompanyTypeChip } from '@components';
import { ListGridRowProps } from 'components/ListGrid/types';
import NewCompanyIcon from '@assets/images/new-company-icon.svg';
import { useAuth } from '@hooks';
import { UserType } from '@contexts';

import { CompanySetupListColumnNameEnum } from './index';
import { useSelectCompany } from '../hooks';
import { AvatarStyled } from '../components/CompanyLineItem/styles';

export function getCompanySetupListRows(
  companySetupList: CompanyProps[],
): ListGridRowProps[] {
  const { handleSelectCompany } = useSelectCompany();

  return companySetupList.map(companySetupListData => {
    const {
      id,
      name,
      document,
      totalMainPartnerCompanies,
      totalPartnerCompanies,
      isNewCompany,
      status,
    } = companySetupListData;
    const { user } = useAuth();
    const isBackoffice = user?.companyType === UserType.Backoffice;

    const companyUserRegisterStatus = COMPANY_USER_REGISTER_STATUS_DATA[status];

    const hasCompanyStatus =
      status && companyUserRegisterStatus && isBackoffice;

    return {
      id,
      items: {
        [CompanySetupListColumnNameEnum.COMPANY]: (
          <Stack alignItems="center" flexDirection="row" gap={1}>
            <Box ml={2} display="flex" alignItems="center" gap={2}>
              {isNewCompany ? (
                <img
                  width={36}
                  height={36}
                  src={NewCompanyIcon}
                  alt="new-company"
                />
              ) : (
                <AvatarStyled alt={name}>
                  <Icon
                    name="building"
                    color={COLORS.PRIMARY.BLUE['-1']}
                    size={23}
                  />
                </AvatarStyled>
              )}

              <Typography
                variant="text"
                fontWeight={700}
                title={name}
                color={COLORS.MONOCHROMATIC.GRAY4}
              >
                {name}
              </Typography>
            </Box>
          </Stack>
        ),
        [CompanySetupListColumnNameEnum.CNPJ]: (
          <Typography fontWeight={400}>{Mask.cnpj(document)}</Typography>
        ),
        [CompanySetupListColumnNameEnum.PARTNER_COMPANIES]: (
          <Stack gap={1} flexDirection="row">
            {totalMainPartnerCompanies !== 0 && (
              <PartnerCompanyTypeChip
                isMainPartnerCompany
                text={totalMainPartnerCompanies}
                title="Contrato mãe"
              />
            )}

            {totalPartnerCompanies !== 0 && (
              <PartnerCompanyTypeChip
                isMainPartnerCompany={false}
                text={totalPartnerCompanies}
                title="Contrato filho"
              />
            )}
            <></>
          </Stack>
        ),
        [CompanySetupListColumnNameEnum.STATUS]: (
          <Stack alignItems="center" flexDirection="row" gap={1}>
            {hasCompanyStatus && (
              <Pill
                fitContent
                text={companyUserRegisterStatus.text}
                color={companyUserRegisterStatus.color}
              />
            )}
          </Stack>
        ),
        [CompanySetupListColumnNameEnum.ACCESS_ACTION]: (
          <Stack alignSelf="flex-end">
            <Button
              variant="outlined"
              endIcon={<Icon name="right" size={20} />}
              onClick={() =>
                handleSelectCompany({
                  ...companySetupListData,
                })
              }
            >
              Acessar
            </Button>
          </Stack>
        ),
      },
    };
  });
}
