import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CompanySetupListRouter } from '@flows/private/companySetup/pages/CompanySetupList/router';

import { SelectionSidebar } from '../../components';
import { SalesChannelList } from './components';

export * from './constants';

export function SalesChannelPage() {
  const navigate = useNavigate();

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const handleSelectSalesChannel = () => {
    navigate(CompanySetupListRouter.path);
  };

  return (
    <Stack flexDirection="row" minHeight="100vh">
      {!isTablet && (
        <SelectionSidebar
          title="Selecione o canal de venda"
          subtitle={
            <>
              Selecione o <strong>Canal de Venda</strong> que deseja visualizar
              e administrar.
            </>
          }
        />
      )}
      <Stack
        paddingX={isMobile ? 2 : 4}
        alignItems="center"
        paddingY={5}
        flex={1}
      >
        <Stack maxWidth={704} width="100%" gap={2}>
          <Typography variant="h5">Tipos de canal de venda</Typography>
          <SalesChannelList
            handleSelectSalesChannel={handleSelectSalesChannel}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
