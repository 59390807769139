import { styled as muiStyled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

export const Container = muiStyled(TableCell)(({}) => ({
  position: 'sticky',
  right: 0,
  padding: 0,
}));

export const ActionBox = muiStyled(Box)(() => ({
  '&.MuiBox-root': {
    paddingRight: '20px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));
