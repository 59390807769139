import { Grid, Typography } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

import { ProfileCardList } from './components';

export function ProfileSelectionCard() {
  return (
    <Grid item xs={12} gap={2} display="flex" flexDirection="column">
      <Typography variant="text" color={COLORS.PRIMARY.BLUE['+1']}>
        Perfil de gestão e contratos *
      </Typography>
      <ProfileCardList />
    </Grid>
  );
}
