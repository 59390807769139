import { Stack, Typography } from '@mui/material';

import heartbrokenImage from '@assets/images/heartbroken.svg';
import { useAuth } from 'hooks/useAuth';

export function EmptyBeneficiaries() {
  const { user } = useAuth();

  const userName = user?.name ?? '';

  return (
    <Stack alignItems="center" gap={3}>
      <img src={heartbrokenImage} alt="Sem beneficiários" height={112} />
      <Typography variant="h6" textAlign="center" maxWidth={472}>
        {userName}, parece que não conseguimos carregar seus beneficiários.
      </Typography>
    </Stack>
  );
}
