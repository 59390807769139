import { Typography } from '@mui/material';

import { ButtonLink } from '../components';
import type { CarnetComplementRowProps } from '../constants';

export const getReportFilesCarnetComplementRow = ({
  pdfFiles,
  csvFiles,
  txtFiles,
  handleOpenFiles,
}: CarnetComplementRowProps) => [
  {
    label: 'Relatório analítico (PDF)',
    xs: 3,
    value:
      pdfFiles && pdfFiles.length > 0 ? (
        <ButtonLink
          onClick={() => handleOpenFiles(pdfFiles)}
          text="Relatório.pdf"
        />
      ) : (
        <Typography variant="body2">-</Typography>
      ),
  },
  {
    label: 'Relatório analítico (CSV)',
    xs: 3,
    value:
      csvFiles && csvFiles.length > 0 ? (
        <ButtonLink
          onClick={() => handleOpenFiles(csvFiles)}
          text="Relatório.csv"
        />
      ) : (
        <Typography variant="body2">-</Typography>
      ),
  },
  {
    label: 'Relatório analítico (TXT)',
    xs: 3,
    value:
      txtFiles && txtFiles.length > 0 ? (
        <ButtonLink
          onClick={() => handleOpenFiles(txtFiles)}
          text="Relatório.txt"
        />
      ) : (
        <Typography variant="body2">-</Typography>
      ),
  },
];
