import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';

import { Mask } from '@hapvida/hapvida-core-components';

import AuthService from '@services/AuthService';

import { useSteps } from '@flows/public/pages/UserFirstAccess/hooks';
import { UserPasswordFormProps } from '@flows/public/pages/UserFirstAccess/types';
import { toastifyApiErrors } from '@utils';
import {
  USER_PASSWORD_DEFINITION_STEPS_KEYS,
  USER_PASSWORD_DEFINITION_STEPS_VALUES,
} from '@flows/public/pages/UserFirstAccess/constants';

import { schema } from './control/validation';

export function useUserPassword() {
  const { token, userId, dataFormState, handleStepUpdate } = useSteps();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(schema, { abortEarly: false }),
  });

  const passwordWatch = watch('password');
  const passwordConfirmWatch = watch('passwordConfirmation');

  const handleRegisterDataAccessUser = async (
    dataForm: UserPasswordFormProps,
  ) => {
    try {
      if (token && userId) {
        setLoading(true);
        const serviceAuth = new AuthService();
        await serviceAuth.dataAccessPasswordDefinition({
          document: Mask.numbers(dataFormState.document),
          token,
          userId,
          password: dataForm?.password,
        });
        handleStepUpdate(
          USER_PASSWORD_DEFINITION_STEPS_VALUES[
            USER_PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION
          ],
          dataForm,
        );
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  };

  return {
    control,
    passwordWatch,
    passwordConfirmWatch,
    handleSubmit,
    trigger,
    isValid,
    errors,
    handleRegisterDataAccessUser,
    loading,
  };
}
