import {
  SummaryCardContainer,
  SummaryCardContent,
  SummaryCardHeader,
} from './components';

export type { SummaryItemProps } from './components';

export const SummaryCardNew = {
  Container: SummaryCardContainer,
  Content: SummaryCardContent,
  Header: SummaryCardHeader,
};
