import { useMediaQuery, useTheme } from '@mui/material';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';

interface SidebarContextData {
  toggleDrawer: () => void;
  drawerOpen: boolean;
  isMobile: boolean;
}

const SidebarContext = createContext<SidebarContextData>(
  {} as SidebarContextData,
);

function SidebarProvider({ children }: PropsWithChildren<{}>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const valueContextProvider = useMemo(
    () => ({
      drawerOpen,
      isMobile,
      toggleDrawer,
    }),
    [drawerOpen, isMobile, toggleDrawer],
  );

  return (
    <SidebarContext.Provider value={valueContextProvider}>
      {children}
    </SidebarContext.Provider>
  );
}

function useSidebar() {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error('useSidebar must be used within SidebarProvider');
  }

  return context;
}

export { useSidebar, SidebarProvider };
