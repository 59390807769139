import { Stack, Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { Container } from './styles';

interface InvoiceInfoProps {
  title: string;
  value: string;
}

export function InvoiceInfo({ title, value }: Readonly<InvoiceInfoProps>) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Container onClick={copyToClipboard}>
      <Stack gap={0.5} alignItems="flex-start">
        <Typography
          color={COLORS.PRIMARY.BLUE['-1']}
          fontWeight={600}
          variant="body2"
        >
          {title}
        </Typography>
        <Typography variant="body2">{value}</Typography>
      </Stack>
      <Stack gap={0.5} flexDirection="row" alignItems="center">
        <Icon name="copy" size={16} color={COLORS.PRIMARY.BLUE.MAIN} />
        <Typography
          color={COLORS.PRIMARY.BLUE['-1']}
          variant="smallText"
          fontWeight={700}
        >
          Copiar
        </Typography>
      </Stack>
    </Container>
  );
}
