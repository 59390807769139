import { useNavigate } from 'react-router-dom';

import { MovimentationDetailRouter } from '@flows/private/movements/pages/MovementDetails/router';

import { CustomTableCell, EditIcon } from '../TableRows/styles';
import type { EditButtonProps } from './types';

export const EditButton = ({ id = '' }: Readonly<EditButtonProps>) => {
  const navigateTo = useNavigate();
  return (
    <CustomTableCell align="center">
      <EditIcon
        size={24}
        name="edit"
        variant="outlined"
        className="action-edit"
        key={id}
        onClick={() => {
          navigateTo(
            MovimentationDetailRouter.dynamicPath({
              movementId: id,
            }),
          );
        }}
      />
    </CustomTableCell>
  );
};
