const beneficiariesMock = {
  content: [
    {
      id: 'ID1',
      itHasError: false,
      errorMessage: '',
      status: 'Pendente',
      cnpj: '00000000000001',
      beneficiaryCode: '453265158133542...',
      name: 'José da Silva',
      cpf: '12345678901',
      socialGender: 'Masculino',
      type: 'Titular',
      company: 'NDI',
      plan: '5252 - Nosso Plano ...',
      contractNumber: '12345678901',
      contractDate: '01/01/2020',
      aggregateCount: 1,
      dependentCount: 1,
      state: 'MG',
      dependentsAffiliated: [
        {
          id: 'ID11',
          itHasError: false,
          errorMessage: '',
          status: 'Pendente',
          cnpj: '00000000000002',
          beneficiaryCode: '453265158133542...',
          name: 'Ciclano da Silva 2',
          cpf: '12345678901',
          socialGender: 'Masculino',
          type: 'Dependente',
          company: 'NDI',
          plan: '5252 - Nosso Plano ...',
          contractNumber: '12345678901',
          state: '',
        },
        {
          id: 'ID2',
          itHasError: false,
          errorMessage: '',
          status: 'Pendente',
          cnpj: '00000000000003',
          beneficiaryCode: '453265158133542...',
          name: 'José da Silva',
          cpf: '12345678901',
          socialGender: 'Masculino',
          type: 'Titular',
          company: 'NDI',
          plan: '5252 - Nosso Plano ...',
          contractNumber: '12345678901',
          contractDate: '01/01/2020',
          aggregateCount: 0,
          dependentCount: 0,
          state: 'RJ',
          dependentsAffiliated: [],
        },
        {
          id: 'ID3',
          itHasError: true,
          errorMessage: 'dependente com erro',
          status: 'Pendente',
          cnpj: '00000000000004',
          beneficiaryCode: '453265158133542...',
          name: 'José da Silva',
          cpf: '12345678901',
          socialGender: 'Masculino',
          type: 'Titular',
          company: 'NDI',
          plan: '',
          contractNumber: '12345678901',
          contractDate: '01/01/2020',
          aggregateCount: 0,
          dependentCount: 0,
          state: '',
          dependentsAffiliated: [],
        },
      ],
    },
  ],
};

export default beneficiariesMock;
