import { IAppDynamicRoute } from 'routes/types';

import PasswordDefinitionStepsPage from '.';

export const PasswordDefinitionRouter: IAppDynamicRoute = {
  Page: PasswordDefinitionStepsPage,
  path: '/definicao-de-senha/:passwordDefinitionStep',
  name: 'Definição de senha',
  dynamicPath: ({ passwordDefinitionStep }) =>
    `/definicao-de-senha/${passwordDefinitionStep}`,
  Icon: <></>,
  disabled: false,
};
