import { UserPartnerCompanyProps } from '@services/UserService';

import { getIsMainPartnerCompanyRemovedAsMaster } from './getIsMainPartnerCompanyRemovedAsMaster';
import { mapOriginalProfile } from './mapOriginalProfile';

export const getRemovedAndRelocated = (
  editedPartnerCompanies: UserPartnerCompanyProps[],
  originalPartnerCompanies: UserPartnerCompanyProps[],
) => {
  return editedPartnerCompanies
    .filter(partnerCompany => {
      const { isMainPartnerCompany, mainPartnerCompany } = partnerCompany;

      const isMainPartnerCompanyRemovedAsMaster =
        getIsMainPartnerCompanyRemovedAsMaster(
          editedPartnerCompanies,
          originalPartnerCompanies,
          isMainPartnerCompany,
          mainPartnerCompany,
        );

      return (
        partnerCompany.hasAccess &&
        !isMainPartnerCompanyRemovedAsMaster &&
        originalPartnerCompanies.some(
          item =>
            item.partnerCompany === partnerCompany.partnerCompany &&
            item.hasAccess &&
            item.profile?.id !== partnerCompany.profile?.id,
        )
      );
    })
    .map(partnerCompany =>
      mapOriginalProfile(partnerCompany, originalPartnerCompanies),
    );
};
