import type { ComponentProps } from 'react';

import { BaseTable } from '@hapvida/hapvida-core-components';

export const Columns: ComponentProps<typeof BaseTable>['TableHeadColumns'] = [
  {
    id: 1,
    label: 'Movimentação /Arquivo',
    name: 'id',
    sortable: false,
  },
  { id: 2, label: 'Tipo de movimentação', name: 'type' },
  { id: 3, label: 'Dt. carga', name: 'createdAt' },
  { id: 4, label: 'Dt. efetivação', name: 'updatedAt' },
  { id: 5, label: 'Status', name: 'status' },
];
