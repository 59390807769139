import axios from 'axios';

import { LocalStorageKeyEnum } from '@constants';
import { AccountProps } from '@contexts';

export interface HapvidaApiResponseError {
  response: {
    data: {
      notifications: Array<{
        message: string;
        type?: string;
        propertyName?: string;
      }>;
    };
    status: number;
  };
}

const HapvidaApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY || '',
  },
});

HapvidaApi.interceptors.request.use(config => {
  const data = localStorage.getItem(LocalStorageKeyEnum.ACCOUNT_DATA);

  if (data) {
    const userData = JSON.parse(data) as AccountProps;

    if (userData.token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${userData.token}`,
        },
      };
    }
  }

  return config;
});

HapvidaApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      // unauthorized
      localStorage.removeItem(LocalStorageKeyEnum.ACCOUNT_DATA);
      window.location.assign('/sessao-expirada');
    }

    if (error.response.status === 302) {
      return error.response;
    }

    return Promise.reject(error);
  },
);

export { HapvidaApi };
