import React from 'react';
import { StyledIcon } from './styles';
import { IconName } from './types';
import iconSet from './selection.json';

export * from './types';

export interface IconProps extends React.HTMLAttributes<any> {
  name: IconName;
  variant?: 'outlined' | 'standart' | 'filled';
  className?: string;
  size?: number;
  mirrored?: boolean;
}

const Icon: React.FC<IconProps> = ({
  name,
  className,
  size = 24,
  variant = 'standart',
  mirrored,
  ...props
}) => {
  return (
    <StyledIcon
      icon={name}
      iconSet={iconSet}
      variant={variant}
      size={size}
      className={`icon-${name} ${className || ''}`}
      {...props}
    />
  );
};

export default Icon;
