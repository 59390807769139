import { UseFormHandleSubmit } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  CircularProgress,
  useConfirmation,
} from '@hapvida/hapvida-core-components';

import {
  AddAccountBackofficeFormProps,
  UserService,
} from '@services/UserService';

import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';
import { toastifyApiErrors } from '@utils';
import { BackofficeUserQueryKeysEnum } from '@constants';

interface BackofficeUserCreationFormButtonsProps {
  handleSubmit: UseFormHandleSubmit<AddAccountBackofficeFormProps>;
  isValid: boolean;
}

export function BackofficeUserCreationFormButtons({
  handleSubmit,
  isValid,
}: BackofficeUserCreationFormButtonsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleNavigateToBackofficeUserList = () => {
    navigate(BackofficeUserListRouter.path);
  };

  const handleSuccessfullyCreatedUser = () => {
    showConfirmation({
      onConfirm: handleNavigateToBackofficeUserList,
      onCancel: handleNavigateToBackofficeUserList,
      title: 'Acesso atrelado com sucesso',
      confirmText: 'Prosseguir',
      type: 'success',
      icon: 'check',
      content: (
        <Typography variant="body2">
          Enviamos um e-mail de confirmação para o usuário.
        </Typography>
      ),
    });
  };

  const handleCreateBackofficeUser = async (
    data: AddAccountBackofficeFormProps,
  ) => {
    try {
      setIsLoading(true);
      const userService = new UserService();
      await userService.createBackofficeUser(data);

      const usersQueryKey = [BackofficeUserQueryKeysEnum.USERS_STATE];
      queryClient.removeQueries({ queryKey: usersQueryKey, exact: true });
      await queryClient.refetchQueries({
        queryKey: usersQueryKey,
      });

      handleSuccessfullyCreatedUser();
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <Button
        onClick={() => navigate(BackofficeUserListRouter.path)}
        variant="outlined"
        color="primary"
      >
        Cancelar
      </Button>
      <Button
        endIcon={isLoading ? <CircularProgress size={16} /> : undefined}
        onClick={handleSubmit(handleCreateBackofficeUser)}
        disabled={!isValid || isLoading}
        variant="contained"
        color="secondary"
        type="submit"
      >
        Criar usuário
      </Button>
    </Stack>
  );
}
