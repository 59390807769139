import { BeneficiaryListRouter } from '@flows/private/beneficiaries/pages/BeneficiaryList/router';
import { CompanyListRouter } from '@flows/private/companies/pages/CompanyList/router';
import { BillListRouter } from '@flows/private/financial/pages/List/router';
import { HomeRouter } from '@flows/private/home/router';
import { MoreServicesRouter } from '@flows/private/moreServices/router';
import { MovementListRouter } from '@flows/private/movements/pages/MovementList/router';
import { UserListRouter } from '@flows/private/users/pages/UserList/router';

export const ROUTES = {
  companyPendingAccessDisabled: [
    BillListRouter.path,
    MovementListRouter.path,
    BeneficiaryListRouter.path,
  ],
  allMenu: [
    BillListRouter.path,
    MovementListRouter.path,
    BeneficiaryListRouter.path,
    HomeRouter.path,
    UserListRouter.path,
    MoreServicesRouter.path,
    CompanyListRouter.path,
  ],
};
