import { styled as muiStyled } from '@mui/material';

import Box from '@mui/material/Box';
import { COLORS } from '../../themes/colors';

export const FilterButton = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  background: COLORS.PRIMARY.BLUE.MAIN,
  border: `1px solid ${COLORS.PRIMARY.BLUE['-2']}`,
  borderRadius: '4px',
  padding: '6px',
  cursor: 'default',
  maxWidth: 250,

  '& .MuiTypography-root': {
    marginRight: '8px',
    fontWeight: 400,
    fontSize: '12px',
    color: COLORS.WHITE,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
