import { useCallback } from 'react';

import { Stack } from '@mui/material';

import Icon from '../Icon';

import { COLORS } from '../../themes/colors';

import { Filename } from './styles';
import { IconName } from '../Icon/types';

interface SimpleFileProps {
  filename: string;
  maxLabelLength?: number;
  onDownload: () => void;
  iconName?: IconName;
}

const SimpleFile = ({
  filename,
  maxLabelLength = 15,
  onDownload,
  iconName = 'file-check',
}: SimpleFileProps) => {
  const substringFilename = useCallback(
    (filename: string) => {
      if (filename.length <= maxLabelLength) return filename;

      const aux = filename.split('.');
      const ext = aux.length > 1 ? `.${aux[aux.length - 1]}` : '';
      const extPos = filename.indexOf('.');

      const subname =
        filename.substring(
          0,
          extPos !== -1
            ? Math.min(maxLabelLength - ext.length, extPos - 1)
            : maxLabelLength,
        ) + '(...)';

      return subname + ext;
    },
    [maxLabelLength],
  );

  return (
    <Stack direction="row" alignItems="center">
      <Icon name={iconName} size={20} color={COLORS.PRIMARY.BLUE.MAIN} />

      <Filename onClick={onDownload}>
        {filename.length > 0 ? substringFilename(filename) : ' - '}
      </Filename>
    </Stack>
  );
};

export default SimpleFile;
