import { Wrapper, Text } from './styles';

interface CriticismProps {
  beneficiaryName: string;
  movementNumber: string;
  criticism: string;
}

export function CriticismCard({
  criticism,
  beneficiaryName,
  movementNumber,
}: CriticismProps) {
  return (
    <Wrapper>
      <Text>
        Beneficiário <strong>{beneficiaryName}</strong> está com crítica do tipo{' '}
        <strong>{criticism}</strong> na movimentação{' '}
        <strong>cód. {movementNumber}</strong>
        <br />
      </Text>
    </Wrapper>
  );
}
