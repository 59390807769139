import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';

export function useProfileDetails() {
  const { enqueueSnackbar } = useSnackbar();

  const userService = useMemo(() => new UserService(), []);

  const getUserProfileDetails = async (profileId: string) => {
    try {
      return await userService.getProfileDetails(profileId);
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return undefined;
    }
  };

  return {
    getUserProfileDetails,
  };
}
