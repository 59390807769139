import { Typography, styled, Box } from '@mui/material';

export const TimerContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiTypography-root:first-of-type': {
    marginBottom: 0,
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  '&.MuiTypography-root:first-of-type': {
    marginBottom: 0,
  },
}));
