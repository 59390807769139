import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { FinancialListSubHeader } from './components';
import { FinancialListLayout } from './layout';
import { BillsStateProvider } from './hooks';

export function BillList() {
  return (
    <DrawerFilterProvider>
      <BillsStateProvider>
        <FinancialListSubHeader />
        <FinancialListLayout />
      </BillsStateProvider>
    </DrawerFilterProvider>
  );
}
