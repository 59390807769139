import { DrawerFilter, Pill } from '@hapvida/hapvida-core-components';

import { COMPANY_USER_REGISTER_STATUS_DATA } from '@services/CompanySetupService/dtos/ListCompanyDTO';

export function CompanyStatusDrawerFilter() {
  const companyStatusFilters = Object.entries(
    COMPANY_USER_REGISTER_STATUS_DATA,
  ).map(([key, value]) => {
    return {
      label: value.text,
      value: key,
      content: <Pill fitContent text={value.text} color={value.color} />,
    };
  });

  return (
    <DrawerFilter
      filterKey="status"
      label="status"
      title="Status da empresa"
      filters={companyStatusFilters}
      isMultiple={false}
    />
  );
}
