import { Stack, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import { Icon } from '@hapvida/hapvida-core-components';

interface StyledProps {
  background?: string;
  color?: string;
}

export const Container = muiStyled(Stack)(
  ({ background, color }: StyledProps) => ({
    background,
    color,
    padding: '3px 15px 3px 10px',
    borderRadius: '100px',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);
export const Label = muiStyled(Typography)(({ color }: StyledProps) => ({
  color,
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '13px',
  fontWeight: 400,
}));

export const IconStyled = muiStyled(Icon)(() => ({
  paddingRight: '6px',
}));
