import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { AddAccountPage } from '.';

export const CreateUserRouter: IAppRoute = {
  Page: AddAccountPage,
  path: '/usuarios/novo',
  name: 'Novo usuário',
  Icon: <Icon name="account-multiple" />,
  isPrivate: true,
  disabled: false,
};
