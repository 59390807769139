import { UserProfileEnum } from '@constants';

import { UpdatedItems } from './types';

export const getUpdatedItems = ({
  updatedItem,
  editedMainPartnerCompanies = [],
}: UpdatedItems) => {
  const { isMainPartnerCompany, isOldOnList, profile } = updatedItem;

  const mainPartnerCompany = editedMainPartnerCompanies.find(
    ({ partnerCompany }) => partnerCompany === updatedItem.mainPartnerCompany,
  );
  const updatedItemIsMaster = profile?.name === UserProfileEnum.MASTER;
  const mainPartnerCompanyIsMaster =
    mainPartnerCompany?.profile?.name === UserProfileEnum.MASTER;

  const isUpdatePermittedForNonMasterPartner =
    !isMainPartnerCompany && !mainPartnerCompanyIsMaster && updatedItemIsMaster;
  if (isUpdatePermittedForNonMasterPartner) {
    return { ...updatedItem, hasAccess: false, profile: undefined };
  }

  const isNewMainPartner = mainPartnerCompanyIsMaster && !isOldOnList;
  const mainPartnerOptional = !mainPartnerCompany || isMainPartnerCompany;
  if (mainPartnerOptional || !isNewMainPartner) {
    return updatedItem;
  }

  const newProfile = mainPartnerCompany?.hasAccess
    ? mainPartnerCompany.profile
    : undefined;
  return {
    ...updatedItem,
    hasAccess: mainPartnerCompany?.hasAccess,
    profile: newProfile,
  };
};
