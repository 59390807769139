enum MovementDetailsSortNameEnum {
  HEALTH_OPERATOR = 'healthOperator',
  HEALTH_PLAN = 'CodePlanHealth',
  NUMBER = 'MovementType',
  UNIT_CODE = 'unitCode',
  STATUS = 'Status',
  DOCUMENT = 'Code',
  USER = 'Name',
  TYPE = 'Type',
}

type TableHeadParams = {
  name: MovementDetailsSortNameEnum | '';
  cellWidth?: number | string;
  sortable?: boolean;
  label: string;
  id: number;
};

export const movementDetailsTableHead: TableHeadParams[] = [
  {
    id: 0,
    label: 'Status solicitação',
    name: MovementDetailsSortNameEnum.STATUS,
    sortable: false,
  },
  {
    id: 1,
    label: 'Cód./Tipo movimentação',
    name: MovementDetailsSortNameEnum.NUMBER,
  },
  {
    id: 2,
    label: 'Cód. Beneficiário / CNPJ',
    name: MovementDetailsSortNameEnum.DOCUMENT,
  },
  {
    id: 3,
    label: 'Sexo, Nome e CPF',
    name: MovementDetailsSortNameEnum.USER,
  },
  {
    id: 4,
    label: 'Tipo',
    name: MovementDetailsSortNameEnum.TYPE,
  },
  {
    id: 5,
    label: 'Operadora',
    name: MovementDetailsSortNameEnum.HEALTH_OPERATOR,
    sortable: false,
  },
  {
    id: 6,
    label: 'Plano',
    name: MovementDetailsSortNameEnum.HEALTH_PLAN,
  },
  {
    id: 7,
    label: 'Unidade',
    name: MovementDetailsSortNameEnum.UNIT_CODE,
    sortable: false,
  },
  {
    id: 8,
    label: '',
    name: '',
    cellWidth: 32,
  },
];
