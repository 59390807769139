import { Stack } from '@mui/material';

import { BackofficeUserListCard } from './components';

export function BackofficeUserListLayout() {
  return (
    <Stack paddingY={3} gap={3}>
      <BackofficeUserListCard />
    </Stack>
  );
}
