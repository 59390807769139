import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { UserActionsOptionsEnum } from '../../constants/userActionsOptions';

interface UserActionsStateContextData {
  changeCurrentAction: (action?: UserActionsOptionsEnum) => void;
  getIsDisabled: (action: UserActionsOptionsEnum) => boolean;
  getIsLoading: (action: UserActionsOptionsEnum) => boolean;
}

export const UserActionsStateContext =
  createContext<UserActionsStateContextData>({} as UserActionsStateContextData);

function UserActionsStateProvider({
  children,
}: Readonly<PropsWithChildren<{}>>) {
  const [currentAction, setCurrentAction] = useState<UserActionsOptionsEnum>();

  const changeCurrentAction = (action?: UserActionsOptionsEnum) => {
    setCurrentAction(action);
  };

  const getIsLoading = (action: UserActionsOptionsEnum) => {
    return action === currentAction;
  };

  const getIsDisabled = (action: UserActionsOptionsEnum) => {
    if (action === currentAction) {
      return true;
    }
    if (currentAction) {
      return true;
    }

    return false;
  };

  const valueUserActionsStateContextProvider = useMemo(
    () => ({
      changeCurrentAction,
      getIsDisabled,
      getIsLoading,
    }),
    [getIsDisabled, getIsLoading, changeCurrentAction],
  );

  return (
    <UserActionsStateContext.Provider
      value={valueUserActionsStateContextProvider}
    >
      {children}
    </UserActionsStateContext.Provider>
  );
}

function useUserActionsState() {
  const context = useContext(UserActionsStateContext);

  if (!context) {
    throw new Error(
      'useUserActionsState must be used within UserActionsStateProvider',
    );
  }

  return context;
}

export { useUserActionsState, UserActionsStateProvider };
