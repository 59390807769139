import React from 'react';

import { ErrorPage } from '@hapvida/hapvida-core-components';

import Error404Image from '@assets/images/error-404.png';
import { useAuth } from 'hooks/useAuth';

import AuthenticateOptions from '../../components/AuthenticateOptions';

export const PageNotFound: React.FC = () => {
  const { logOut, user } = useAuth();

  return (
    <ErrorPage
      image={Error404Image}
      title="Oops! Página não encontrada."
      imageAlt="Erro 404, página não encontrada"
      description="Parece que essa página está fora da nossa área de cobertura."
      user={user}
      logOut={logOut}
      showBottomContent={!user}
      loginRoute={process.env.REACT_APP_LOGIN_URL}
      authenticateOptions={AuthenticateOptions}
    />
  );
};
