import React from "react";

function EmptyState() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="121"
      fill="none"
      viewBox="0 0 128 121"
    >
      <path
        fill="#ECF3FE"
        d="M79.183 93.22c25.7 0 46.534-20.757 46.534-46.36C125.717 21.256 104.883.5 79.183.5S32.65 21.256 32.65 46.86c0 25.603 20.834 46.36 46.533 46.36z"
      ></path>
      <path
        fill="#3B82F4"
        d="M115.496 81.568H57.741a2.619 2.619 0 01-2.622-2.616v-45.01a2.619 2.619 0 012.622-2.617h57.755a2.618 2.618 0 012.621 2.617v45.005a2.622 2.622 0 01-2.621 2.621z"
      ></path>
      <path
        fill="#3A7EC1"
        d="M70.392 101.572H12.637a2.619 2.619 0 01-2.622-2.617V53.95a2.619 2.619 0 012.622-2.617h57.755a2.619 2.619 0 012.622 2.617v45.005a2.613 2.613 0 01-2.622 2.617z"
        opacity="0.25"
      ></path>
      <path
        fill="#FFCB48"
        d="M73.546 98.828H15.79a2.619 2.619 0 01-2.622-2.617V51.205a2.619 2.619 0 012.622-2.616h57.755a2.619 2.619 0 012.621 2.616v45.006a2.616 2.616 0 01-2.621 2.617z"
      ></path>
      <path
        fill="#fff"
        d="M37.941 68.496l-.02 18.215 13.462.015.02-18.215-13.462-.015z"
      ></path>
      <path
        fill="#fff"
        d="M48.51 87.46l-3.888-3.494-3.896 3.485 2.585 2.452-.88 5.288h2.178l2.177.004-.871-5.287 2.594-2.448z"
      ></path>
      <path
        fill="#EAA244"
        d="M38.723 72.819h-.788a2.166 2.166 0 01-2.164-2.164c0-1.19.977-2.163 2.168-2.159h.789c1.191 0 2.168.974 2.163 2.164a2.17 2.17 0 01-2.168 2.159zM51.397 72.832h-.788a2.166 2.166 0 01-2.164-2.163c0-1.19.977-2.164 2.168-2.16h.789c1.191 0 2.168.975 2.163 2.164a2.17 2.17 0 01-2.168 2.16z"
      ></path>
      <path
        fill="#EAA244"
        d="M51.397 72.265l-.005 4.826c-1.178 2.428-4.748 7.735-13.471 7.89l.014-12.73 13.462.014z"
        opacity="0.3"
      ></path>
      <path
        fill="#EAA244"
        d="M50.004 57.66s6.284-.462 1.98 11.504H49.99l.014-11.504z"
      ></path>
      <path
        fill="#EAA244"
        d="M50.82 61.53s2.534-5.38-3.704-6.437c-6.239-1.056-11.519 4.227-11.336 6.19.184 1.966 1.577 7.862 1.577 7.862L50.82 61.53z"
      ></path>
      <path
        fill="#fff"
        d="M52.163 62.416l-.01 9.698c-.004 4.313-3.717 7.794-8.104 7.437-3.914-.32-6.87-3.691-6.866-7.607l.01-9.546c0-.723.59-1.308 1.315-1.308l12.344.014c.362 0 .692.146.93.384.234.238.38.567.38.928z"
      ></path>
      <path
        fill="#EAA244"
        d="M40.965 87.603l-4.483-.005.683-3.641 7.453.01a3.64 3.64 0 01-3.653 3.636zM48.267 87.611l4.482.005-.674-3.646-7.453-.01a3.64 3.64 0 003.645 3.651z"
      ></path>
      <path
        fill="#EAA244"
        d="M46.855 61.099c-3.159.69-8.16 2.836-9.672 9.524l.01-8.225c0-.723.59-1.308 1.315-1.308l8.347.009z"
        opacity="0.3"
      ></path>
      <path
        fill="#F9C067"
        d="M44.742 72.915c-1.595 0-2.81-.66-2.884-.7a.656.656 0 01.633-1.148c.096.05 2.218 1.175 4.286.009a.655.655 0 11.646 1.139c-.93.526-1.856.7-2.681.7z"
      ></path>
      <path
        fill="#3A7EC1"
        d="M105.379 74.982H47.625a2.619 2.619 0 01-2.622-2.617V27.36a2.619 2.619 0 012.622-2.617h57.754a2.618 2.618 0 012.622 2.617v45.005a2.618 2.618 0 01-2.622 2.617z"
        opacity="0.25"
      ></path>
      <path
        fill="#fff"
        d="M108.061 71.98H50.306a2.619 2.619 0 01-2.622-2.616V24.36a2.619 2.619 0 012.622-2.617h57.755a2.619 2.619 0 012.622 2.617v45.005a2.613 2.613 0 01-2.622 2.617z"
      ></path>
      <path
        fill="#3B82F4"
        d="M90.038 67.673H68.325a10.363 10.363 0 016.183-4.88c0-.005.005-.005.005-.005.463-.133.94-.23 1.425-.293.019-.005.037-.005.06-.01a9.651 9.651 0 011.27-.082h3.854c.418 0 .83.032 1.233.083.019 0 .037.004.06.009.49.06.963.165 1.43.293h.005a10.396 10.396 0 016.188 4.885z"
        opacity="0.21"
      ></path>
      <path
        fill="#3B82F4"
        d="M89.662 45.56V57.6a4.807 4.807 0 01-4.813 4.803h-11.34A4.806 4.806 0 0168.7 57.6V45.56c0-10.694 10.483-10.209 10.483-10.209s2.727-.123 5.4 1.176c2.392 1.157 4.739 3.462 5.041 8.018.028.325.037.663.037 1.016z"
      ></path>
      <path
        fill="#fff"
        d="M82.42 60.633v3.54c0 1.5-1.458 2.731-3.241 2.731-1.783 0-3.236-1.23-3.236-2.73v-3.54c0-.618.247-1.195.664-1.652.097-.11.202-.21.317-.302.22-.178.467-.334.738-.458.453-.205.967-.32 1.517-.32.545 0 1.063.115 1.517.32.27.124.518.275.738.453l.032.028c.101.087.197.178.284.274.422.462.67 1.039.67 1.656z"
      ></path>
      <path
        fill="#3B82F4"
        d="M87.975 48.287v5.155c0 3.623-2.324 6.728-5.555 7.913v1.359a8.329 8.329 0 01-2.893.512h-.692a8.418 8.418 0 01-2.892-.512v-1.359c-3.232-1.185-5.551-4.295-5.551-7.913v-5.155a8.39 8.39 0 012.718-6.139 8.41 8.41 0 015.725-2.25h.692c1.98 0 3.805.69 5.249 1.838 1.939 1.542 3.185 3.907 3.2 6.55z"
        opacity="0.21"
      ></path>
      <path
        fill="#fff"
        d="M87.975 46.965v6.477c0 1.77-.555 3.416-1.499 4.775l-.004.005c-.22.315-.459.613-.72.896a8.406 8.406 0 01-1.71 1.432c-.509.32-1.05.59-1.622.805a8.327 8.327 0 01-2.893.512h-.692a8.327 8.327 0 01-2.892-.512c-3.232-1.185-5.556-4.29-5.556-7.918v-6.44c0-.092.005-.179.005-.27a8.411 8.411 0 012.952-6.157 8.399 8.399 0 015.491-2.035h.692c1.912 0 3.681.644 5.102 1.724a8.56 8.56 0 011.939 2.063c.215.324.408.663.577 1.015a8.264 8.264 0 01.83 3.627z"
      ></path>
      <path
        fill="#B3CEF6"
        d="M91.358 50.378a3.38 3.38 0 01-3.383 3.376v-6.752a3.338 3.338 0 011.325.274 3.37 3.37 0 012.058 3.102z"
      ></path>
      <path
        fill="#fff"
        d="M90.07 50.377c0 1.153-.94 2.09-2.095 2.09v-4.18c1.155 0 2.095.938 2.095 2.09z"
      ></path>
      <path
        fill="#3B82F4"
        d="M83.854 62.792l-1.43 4.88h-6.486l-1.43-4.88h1.788l.064.064 2.823 2.914 2.82-2.91.004-.004.064-.064h1.783z"
      ></path>
      <path
        fill="#B3CEF6"
        d="M70.392 47.002v6.752a3.38 3.38 0 01-3.383-3.376 3.372 3.372 0 012.622-3.29c.05-.013.105-.022.16-.031.028-.005.056-.01.088-.014.164-.027.339-.041.513-.041z"
      ></path>
      <path
        fill="#fff"
        d="M68.297 50.377c0 1.153.94 2.09 2.095 2.09v-4.18a2.092 2.092 0 00-2.095 2.09z"
      ></path>
      <path
        fill="#B3CEF6"
        d="M83.854 62.792L81.65 67.38l-2.471-1.61 2.888-2.978.38-.389 1.407.39z"
      ></path>
      <path
        fill="#B3CEF6"
        d="M79.179 65.77l-2.466 1.61-2.205-4.588 1.412-.389.376.39 2.883 2.977z"
      ></path>
      <path
        fill="#3B82F4"
        d="M89.662 45.337c-6.303.453-5.702-5.238-5.042-8.019 2.392 1.162 4.74 3.463 5.042 8.019z"
      ></path>
      <path
        fill="#3B82F4"
        d="M86.444 40.392c-.215.348-2.388 4.25-6.298 4.25s-10.116 2.341-10.309 6.23c-.192 3.883-4.895-11.866 6.981-13.092 11.872-1.23 9.626 2.612 9.626 2.612zM105.595 30.324h-2.572a.311.311 0 01-.311-.311v-2.854c0-.174.142-.311.311-.311h2.572c.174 0 .312.142.312.31v2.855c0 .17-.138.311-.312.311z"
      ></path>
      <path
        fill="#3B82F4"
        d="M104.224 31.326H98.38a.655.655 0 01-.655-.654v-4.186c0-.361.293-.654.655-.654h5.844c.362 0 .656.293.656.654v4.186a.661.661 0 01-.656.654z"
      ></path>
      <path
        fill="#fff"
        d="M102.299 28.823a1.022 1.022 0 00-1.99-.469 1.023 1.023 0 001.99.469z"
      ></path>
      <path
        fill="#3A7EC1"
        d="M37.921 20.878l-12.715-9.766-.656 4.918c-4.684.489-8.956 2.108-12.72 4.825C1.197 28.535.523 40.593.495 42.931l6.188.073c.014-1.116.367-11.074 8.773-17.144a19.828 19.828 0 018.246-3.481l-.614 4.606 14.833-6.107z"
        opacity="0.25"
      ></path>
      <path
        fill="#3B82F4"
        d="M.023 42.62c-.115-2.338-.179-14.414 9.965-22.725 4.061-3.325 8.851-5.292 14.241-5.841l.628 6.143c-4.157.425-7.842 1.93-10.945 4.469-8.017 6.573-7.765 16.536-7.71 17.647l-6.18.307z"
      ></path>
      <path
        fill="#3B82F4"
        d="M21.598 25.329l14.434-6.994L22.75 9.36l-1.15 15.969z"
      ></path>
      <path
        fill="#3A7EC1"
        d="M111.7 91.555c-.169 1.102-1.939 10.909-11.12 15.73a19.84 19.84 0 01-8.654 2.278l1.26-4.473-15.547 3.943L88.837 120.5l1.347-4.771c4.708.179 9.168-.823 13.279-2.977 11.615-6.098 13.994-17.94 14.357-20.25l-6.12-.947z"
        opacity="0.25"
      ></path>
      <path
        fill="#FFCB48"
        d="M117.522 88.074c-.179 2.332-1.632 14.322-12.743 21.297-4.446 2.79-9.447 4.144-14.865 4.012l.147-6.175c4.18.1 8.021-.934 11.422-3.065 8.783-5.517 9.782-15.429 9.869-16.54l6.17.47z"
      ></path>
      <path
        fill="#FFCB48"
        d="M93.943 102.528l-15.195 5.137 12.05 10.567 3.145-15.704z"
      ></path>
      <path
        fill="#52D1BD"
        d="M62.829 8.135a3.572 3.572 0 003.575-3.568c0-1.97-1.6-3.568-3.575-3.568a3.572 3.572 0 00-3.575 3.568c0 1.97 1.6 3.568 3.575 3.568zM30.84 109.481a2.278 2.278 0 00-4.497-.728 2.274 2.274 0 001.883 2.608 2.277 2.277 0 002.614-1.88zM125.722 77.256A2.276 2.276 0 00128 74.982a2.276 2.276 0 00-2.278-2.273 2.276 2.276 0 00-2.278 2.273 2.276 2.276 0 002.278 2.274z"
      ></path>
    </svg>
  );
}

export default EmptyState;