import { Stack, Typography } from '@mui/material';

import { COLORS, Icon, Modal } from '@hapvida/hapvida-core-components';

import { useBillState } from '@flows/private/financial/pages/Detail/hooks/useBillState';

import { IconContainer } from './styles';
import { OperationalCostModalModalProps } from './types';

export function PrintDiscountModal({
  openModal,
  toggleModal,
  onPrint,
}: Readonly<OperationalCostModalModalProps>) {
  const { discountLettersState } = useBillState();
  const discountLetters = discountLettersState.data ?? [];
  const lettersLength = discountLetters?.length ?? 0;

  return (
    <Modal
      title="Carta de desconto"
      type="default"
      open={openModal}
      handleCloseModal={toggleModal}
      secondaryButtonText="Fechar"
      mainButtonText="Visualizar todos"
      mainAction={() => onPrint()}
      contentProps={{ gap: '24px' }}
    >
      <Typography variant="text">
        Existem{' '}
        <Typography variant="text" fontWeight={700}>
          {lettersLength} arquivos
        </Typography>{' '}
        de Carta de Desconto anexados à essa nota, disponíveis para download.
      </Typography>
      <Stack gap="12px">
        {discountLetters.map((letter, idx) => (
          <Stack
            key={letter.letterNumber}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="text" fontWeight={700}>
              Carta de Desconto ({idx + 1})
            </Typography>
            <IconContainer onClick={() => onPrint(letter.letterNumber)}>
              <Icon
                name="open-new-window"
                color={COLORS.PRIMARY.BLUE.MAIN}
                size={22}
              />
            </IconContainer>
          </Stack>
        ))}
      </Stack>
    </Modal>
  );
}
