import { IAppRoute } from 'routes/types';

import { ServerError } from './index';

export const ServerErrorRouter: IAppRoute = {
  Page: ServerError,
  path: '/servico-indisponivel',
  name: 'Erro de Servidor',
  Icon: <></>,
  disabled: false,
};
