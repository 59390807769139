import { styled, css, Stack } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

interface StyledStackProps {
  checked: boolean;
  disabled: boolean;
}

export const StyledStack = styled(Stack)<StyledStackProps>`
  padding: 10px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${COLORS.DISABLED.BACKGROUND};
    `}

  ${({ checked, disabled }) =>
    checked &&
    !disabled &&
    css`
      background-color: ${COLORS.PRIMARY.ORANGE.MAIN}14;
    `}
`;
