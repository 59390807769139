import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import { CompanyProps } from '@services/AuthService/dtos';

import { CompanyStatusEnum } from '@constants';

export interface CompanyListProps {
  companies: CompanyProps[];
  totalPerPage: number;
  total: number;
  userIsBackoffice?: boolean;
}

export interface ListCompaniesResponseDTO {
  content: CompanyListProps;
}

export const COMPANY_USER_REGISTER_STATUS_DATA: Record<
  CompanyStatusEnum,
  { color: string; text: string }
> = {
  PendingAccess: {
    color: COLORS.PRIMARY.ORANGE.MAIN,
    text: 'Acesso pendente',
  },
  RegisterAccess: {
    color: COLORS.MONOCHROMATIC.GRAY4,
    text: 'Cadastro pendente',
  },
  Canceled: {
    color: COLORS.SECONDARY.RED.MAIN,
    text: 'Empresa cancelada',
  },
  Active: {
    color: COLORS.SECONDARY.GREEN.MAIN,
    text: 'Empresa ativa',
  },
};

export interface CompanyUserRegister {
  type: string;
  legal: string;
  document: string;
  status: CompanyStatusEnum;
  id: string;
  thumbnail: string;
}
export interface ListCompaniesUserRegisterResponseDTO {
  content: {
    companies: CompanyUserRegister[];
    total: number;
    totalPerPage: number;
  };
}

export type CombinedStatusData = Record<
  CompanyStatusEnum,
  { color: string; text: string }
>;
