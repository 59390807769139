import {
  hapvidaNdiLogoLightNew,
  hapvidaNdiLogoNew,
} from '@hapvida/hapvida-core-components/src/assets/images';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import Icon from '../../Icon';
import PageHeader, { HeaderRouteProps } from '../../PageHeader';
import Typography from '../../Typography';
import { Steps } from './components/Steps';
import {
  Drawer,
  FirstStepContent,
  LogoContainer,
  PageContainer,
  StyledStack,
} from './styles';

interface StepLayoutProps {
  user?: {
    profile: string;
    name: string;
  };
  steps?: Array<{
    label: string;
    createdAt?: string;
    completed: boolean;
    blocked?: boolean;
  }>;
  route?: HeaderRouteProps;
  subRoute?: string | null;
  firstStepTitle?: {
    name?: string;
    icon?: React.JSX.Element;
  };
  currentStep?: number;
  sideBarTitle?: string;
  sideBarSubtitle?: React.ReactNode;
  startImage?: string;
  hasAnimation?: boolean;
  onLogOut?(): void;
  onStepChange?(step: number): void;
  hasHeader?: boolean;
  hasFirstStep?: boolean;
  enableOnClickLogo?: boolean;
  customRedirectRoute?: string;
  firstStepColor?: string;
  textContentColor?: string;
  drawerFirstStep?: boolean;
  showDrawer?: boolean;
}

export const StepLayout: React.FC<StepLayoutProps> = ({
  user,
  children,
  steps,
  currentStep,
  subRoute,
  route,
  firstStepTitle,
  sideBarTitle,
  sideBarSubtitle,
  startImage,
  hasAnimation,
  onLogOut,
  onStepChange,
  hasHeader = true,
  hasFirstStep = true,
  enableOnClickLogo = false,
  firstStepColor,
  customRedirectRoute,
  textContentColor,
  drawerFirstStep = false,
  showDrawer = true,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(Boolean(hasAnimation));
  const [drawerModal, setDrawerModal] = useState(true);
  const contentPageAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: Boolean(hasAnimation) ? 2400 : 0,
    config: {
      duration: 800,
    },
  });

  const isFirstStep = () => {
    if (drawerFirstStep) {
      return true;
    }
    if (currentStep === 0 && hasFirstStep) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      setDrawerOpen(false);
    }, 2000);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const handleClick = () =>
    navigate(customRedirectRoute ? customRedirectRoute : '/cotacoes');

  return (
    <PageContainer>
      {showDrawer && (
        <Drawer
          isFirstStep={isFirstStep()}
          variant={isMobile ? 'temporary' : 'permanent'}
          anchor="left"
          showImage={drawerOpen}
          open={drawerModal}
          transitionDuration={400}
          startImage={startImage}
          firstStepColor={firstStepColor}
        >
          <Stack
            alignItems="center"
            sx={
              isMobile
                ? { padding: '16px 16px' }
                : { padding: '16px 80px', maxWidth: 500 }
            }
            gap={6}
          >
            <LogoContainer
              direction="row"
              justifyContent="space-between"
              pt="2px"
            >
              {drawerFirstStep ? (
                <img
                  src={hapvidaNdiLogoNew}
                  alt="Hapvida"
                  onClick={enableOnClickLogo ? handleClick : undefined}
                  width={295}
                />
              ) : (
                <img
                  src={
                    isFirstStep() ? hapvidaNdiLogoLightNew : hapvidaNdiLogoNew
                  }
                  alt="Hapvida"
                  onClick={enableOnClickLogo ? handleClick : undefined}
                  width={295}
                />
              )}

              {isMobile && (
                <Icon
                  name="close"
                  size={32}
                  color={COLORS.BLACK}
                  onClick={() => setDrawerModal(!drawerModal)}
                />
              )}
            </LogoContainer>
          </Stack>
          {isFirstStep() ? (
            <FirstStepContent gap={2} textContentColor={textContentColor}>
              <Typography variant="h4">{sideBarTitle}</Typography>
              <Typography variant="text">{sideBarSubtitle}</Typography>
            </FirstStepContent>
          ) : (
            <Stack
              sx={
                isMobile
                  ? { padding: '16px 16px' }
                  : { padding: '16px 80px', maxWidth: 500 }
              }
            >
              <Steps
                activeStep={currentStep}
                steps={steps}
                onStepChange={onStepChange}
              />
            </Stack>
          )}
        </Drawer>
      )}
      <StyledStack>
        {showDrawer ? (
          <animated.div style={contentPageAnimation}>
            {hasHeader && (
              <PageHeader
                user={user}
                onLogOut={onLogOut}
                route={isFirstStep() && firstStepTitle ? firstStepTitle : route}
                subRoute={subRoute}
                drawerOpen={drawerModal}
                toggleDrawer={() => setDrawerModal(!drawerModal)}
                drawerCloseWidth={500}
                drawerOpenWidth={500}
              />
            )}
            <Box paddingX={4} paddingY={5}>
              {children}
            </Box>
          </animated.div>
        ) : (
          <Box paddingX={4} paddingY={5}>
            {children}
          </Box>
        )}
      </StyledStack>
    </PageContainer>
  );
};
