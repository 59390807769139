import React from 'react';

import { Modal } from '@mui/material';
import { LoadingContainer } from './styles';
import { CircularProgress } from '../CircularProgress';

interface ScreenLoadingProps {
  open: boolean;
  customLoading?: React.JSX.Element;
}

const ScreenLoading: React.FC<ScreenLoadingProps> = ({
  open,
  customLoading,
  children,
}) => {
  return (
    <>
      <Modal open={open}>
        <LoadingContainer>
          {customLoading ? (
            customLoading
          ) : (
            <CircularProgress progressColor="white" size={40} />
          )}
        </LoadingContainer>
      </Modal>
      {children}
    </>
  );
};

export default ScreenLoading;
