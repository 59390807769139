import Stack from '@mui/material/Stack';

import NDILogo from '../../assets/images/ndi-mini-logo.svg';
import Clinipam from './assets/clinipam.svg';
import Hapvida from './assets/hapvida.svg';
import CCG from './assets/ccg.svg';

import { Image, CompanyLabel } from './styles';

interface CompanyProps {
  company: string;
}

export enum CompanyEnum {
  Clinipam = 'Clinipam',
  Hapvida = 'Hapvida',
  NDI = 'NDI',
  CCG = 'CCG',
}

export const CompanyValues: {
  [x: string]: {
    image: string;
    name: string;
  };
} = {
  [CompanyEnum.Clinipam]: {
    image: Clinipam,
    name: '',
  },
  [CompanyEnum.Hapvida]: {
    image: Hapvida,
    name: 'Hapvida',
  },
  [CompanyEnum.NDI]: {
    image: NDILogo,
    name: 'NDI',
  },
  [CompanyEnum.CCG]: {
    image: CCG,
    name: 'CCG',
  },
};

const Company = ({ company }: CompanyProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Image
        src={CompanyValues[company]?.image}
        alt={CompanyValues[company]?.name}
      />

      <CompanyLabel>{CompanyValues[company]?.name}</CompanyLabel>
    </Stack>
  );
};

export default Company;
