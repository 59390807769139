import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { UserService, GetUserResponse } from '@services/UserService';

import { toastifyApiErrors } from '@utils';
import { UserQueryKeysEnum } from '@constants';
import { useAuth } from '@hooks';

export function useUserDetailsState() {
  const { enqueueSnackbar } = useSnackbar();
  const { id = '' } = useParams();
  const { selectedCompany, selectedPartnerCompany } = useAuth();

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const userService = useMemo(() => new UserService(), []);

  const userDetailsState = useQuery<GetUserResponse, AxiosError>({
    queryKey: [
      UserQueryKeysEnum.USER_DETAILS_STATE,
      id,
      companyId,
      partnerCompany,
    ],
    queryFn: () => userService.getUserDetail(id, companyId),
    enabled: Boolean(id),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshEditUserDetails = () => {
    userDetailsState.remove();

    userDetailsState.refetch();
  };

  return {
    userDetailsState,
    refreshEditUserDetails,
  };
}
