import * as yup from 'yup';

export const schema = yup.object({
  password: yup
    .string()
    .required()
    .min(7, 'Mínimo 7 caracteres')
    .matches(/(?=\W)/, '1 caracter especial')
    .matches(/(?=\d)/, '1 número')
    .matches(/(?=[a-z])/, '1 letra minúscula')
    .matches(/(?=[A-Z])/, '1 letra maiúscula'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null]),
});
