import { Stack, StackProps } from '@mui/material';

export function BaseListRoot({
  children,
  ...stackProps
}: Readonly<StackProps>) {
  return (
    <Stack gap={2} {...stackProps}>
      {children}
    </Stack>
  );
}
