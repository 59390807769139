import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { CompanySetupListSubHeader } from './components';
import { CompanySetupListLayout } from './layout';

export function CompanySetupListPage() {
  return (
    <DrawerFilterProvider>
      <CompanySetupListSubHeader />
      <CompanySetupListLayout />
    </DrawerFilterProvider>
  );
}
