import { Typography } from '@mui/material';

import { SimpleFile } from '@hapvida/hapvida-core-components';

import type { DataGridItem } from '@hapvida/hapvida-core-components/src/components/DataGrid';

import { BillDetails, FileData } from '@services/FinancialService';

import { InvoiceButton, DiscountLink } from '../components';

export const getGridRows = (
  billingFile: FileData | undefined,
  bill: BillDetails,
  reportFilesRows: DataGridItem[],
): DataGridItem[] => {
  return [
    {
      label: 'Boleto',
      xs: 3,
      value: billingFile ? (
        <SimpleFile
          onDownload={() => window.open(billingFile.path, '_blank')}
          filename={billingFile.fileName}
        />
      ) : (
        <Typography variant="body2">-</Typography>
      ),
    },
    {
      label: 'Nota Fiscal',
      xs: 3,
      value: <InvoiceButton bill={bill} />,
    },
    {
      label: 'Carta de desconto',
      xs: 6,
      value: <DiscountLink />,
    },
    ...reportFilesRows,
  ];
};
