import { styled, Stack } from '@mui/material';

export const DrawerContent = styled(Stack)`
  padding: 20px 4px 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
