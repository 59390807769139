import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import BeneficiaryService from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import { useFilterParams, useAuth } from '@hooks';
import { DEPENDENTS_PARAMS_ID } from '@flows/private/beneficiaries/pages/BeneficiaryDetails/constants';

export function useBeneficiaryDetailsState() {
  const { enqueueSnackbar } = useSnackbar();
  const { beneficiaryId = '' } = useParams();
  const { selectedCompany, selectedPartnerCompany } = useAuth();
  const { tableOrder } = useFilterParams({
    paramsId: DEPENDENTS_PARAMS_ID,
  });

  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

  const companyId = selectedCompany?.id ?? '';
  const partnerCompany = selectedPartnerCompany?.partnerCompany ?? '';

  const isEnabled =
    Boolean(beneficiaryId) && Boolean(companyId) && Boolean(partnerCompany);

  const beneficiaryDetailsState = useQuery({
    queryKey: ['beneficiaryDetailsState', beneficiaryId],
    queryFn: () => beneficiaryService.fetchBeneficiaryById(beneficiaryId),
    enabled: Boolean(beneficiaryId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const beneficiaryReviewsState = useQuery({
    queryKey: [
      'beneficiaryReviewsState',
      beneficiaryId,
      companyId,
      partnerCompany,
    ],
    queryFn: () =>
      beneficiaryService.fetchBeneficiaryReviews(beneficiaryId, companyId),
    enabled: isEnabled,
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const beneficiaryDependentsState = useQuery({
    queryKey: ['beneficiaryDependentsState', beneficiaryId, tableOrder],
    queryFn: () =>
      beneficiaryService.fetchBeneficiaryDependents({
        beneficiaryId,
        filters: { ...tableOrder },
      }),
    enabled: Boolean(beneficiaryId),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshBeneficiaryDetailsStates = () => {
    beneficiaryDetailsState.remove();
    beneficiaryReviewsState.remove();
    beneficiaryDependentsState.remove();

    beneficiaryDetailsState.refetch();
    beneficiaryReviewsState.refetch();
    beneficiaryDependentsState.refetch();
  };

  return {
    beneficiaryDetailsState,
    beneficiaryReviewsState,
    beneficiaryDependentsState,
    refreshBeneficiaryDetailsStates,
  };
}
