import { createContext } from 'react';

export interface IScreenLoadingContextData {
  isLoading: boolean;
  onScreenLoading(value: boolean): void;
}

export const ScreenLoadingContext = createContext<IScreenLoadingContextData>(
  {} as IScreenLoadingContextData,
);
