import { Stack, StepLabel } from '@mui/material';
import React, { useCallback } from 'react';
import { StyledStep, StyledStepper } from './styles';
import { Typography } from '@hapvida/hapvida-core-components';
import { formatDateISO } from '@hapvida/hapvida-core-components/src/utils';

interface StepsProps {
  activeStep?: number;

  steps?: Array<{
    label: string;
    createdAt?: string;
    blocked?: boolean;
    completed: boolean;
  }>;

  onStepChange?(step: number): void;
}

export const Steps: React.FC<StepsProps> = ({
  steps,
  activeStep,
  onStepChange,
}) => {
  const onStepSelected = useCallback(
    (step: number, completed: boolean, previous: boolean) => {
      if (completed) {
        if (activeStep !== step) {
          onStepChange?.(step);
          return;
        }
      }
      if (previous) {
        onStepChange?.(step);
      }
    },
    [onStepChange, activeStep],
  );

  return (
    <StyledStepper
      nonLinear
      activeStep={activeStep}
      connector={<></>}
      orientation="vertical"
    >
      {steps?.map((step, index) => (
        <StyledStep
          key={step.label}
          completed={activeStep === index ? false : step.completed}
          active={index > 0 ? steps[index - 1].completed : step.completed}
          lastStep={index > 0 ? steps[index - 1].completed : false}
          onClick={() =>
            !step.blocked &&
            onStepSelected(
              index,
              step.completed,
              index > 0 && steps[index - 1].completed,
            )
          }
          selected={index === activeStep}
        >
          <StepLabel>
            <Stack>
              <Typography variant="text">{step.label}</Typography>
              {step.createdAt && (
                <Typography variant="text">
                  Realizado em <strong>{formatDateISO(step.createdAt)}</strong>
                </Typography>
              )}
            </Stack>
          </StepLabel>
        </StyledStep>
      ))}
    </StyledStepper>
  );
};
