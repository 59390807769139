import { createContext } from 'react';

import { CompanyProps, CurrentUserProps } from '@services/AuthService/dtos';
import { PartnerCompanyProps } from '@services/PartnerCompanyService/dtos';

enum UserType {
  Backoffice = 'Backoffice',
  Company = 'Company',
  Agency = 'Agency',
}

interface AccountProps {
  selectedCompany?: CompanyProps;
  selectedPartnerCompany?: PartnerCompanyProps;
  user?: CurrentUserProps;
  token?: string;
  refreshToken?: string;
}

interface AuthContextData extends AccountProps {
  logIn: (account: AccountProps) => void;
  logOut(): void;
  updateAccount: (account: AccountProps) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export { AccountProps, AuthContextData, AuthContext, UserType };
