/* eslint-disable no-return-assign */
import { useRef } from 'react';

export const useValidModal = () => {
  const isValidResponseServiceRef = useRef<boolean>(false);

  const validResponseService = () => {
    isValidResponseServiceRef.current = true;
  };

  const invalidResponseService = () => {
    isValidResponseServiceRef.current = false;
  };

  return {
    isValidResponseServiceRef,
    validResponseService,
    invalidResponseService,
  };
};
