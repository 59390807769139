import {
  Box,
  CSSObject,
  styled as muiStyled,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  Stack,
} from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

const drawerWidth = '100%';

interface PageContainerProps {
  animate: boolean;
  duration: number;
}

export const PageContainer = muiStyled(Box, {
  shouldForwardProp: prop => !['duration', 'animate'].includes(prop as string),
})<PageContainerProps>(({ animate, duration }) => ({
  display: 'flex',
  animation: animate ? `fadeOut ${duration}ms` : undefined,
  '@keyframes fadeOut': {
    '0%': { opacity: 1 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
}));

const openedMixin = (): CSSObject => ({
  width: drawerWidth,
  overflowX: 'hidden',
});

interface DrawerProps {
  startImage?: string;
}

export const Drawer = muiStyled(
  ({ ...props }: DrawerProps & MuiDrawerProps) => <MuiDrawer {...props} />,
  {
    shouldForwardProp: prop => !['open', 'startImage'].includes(prop as string),
  },
)<DrawerProps>(({ theme, startImage }) => ({
  width: 500,
  zIndex: theme.zIndex.drawer,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
  '.MuiPaper-root': {
    borderRight: 'none',
  },
  '.MuiDrawer-paper': {
    background: `url(${startImage}) no-repeat`,
    backgroundPosition: '100% 0px',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    width: 500,
  },
  '& .MuiDrawer-paper': {
    ...openedMixin(),
    backgroundColor: '#4E555C',
  },
}));

export const LogoContainer = muiStyled(Stack)(() => ({
  width: '100%',

  img: {
    width: 132,
  },
}));

export const FirstStepContent = muiStyled(Stack)(() => ({
  marginTop: 120,
  overflowWrap: 'break-word',
  whiteSpace: 'break-spaces',
  color: COLORS.WHITE,
  h4: {
    width: '100%',
    maxWidth: 264,
    wordWrap: 'break-word !important',
    overflowWrap: 'break-word !important',
    wordBreak: 'break-word !important',
    color: COLORS.WHITE,
  },
  span: {
    color: COLORS.WHITE,
  },
}));

export const StartImage = muiStyled('img')(() => ({}));
