import { COLORS, IconName } from '@hapvida/hapvida-core-components';

export enum BeneficiaryStatusEnum {
  ACTIVE = 'Active',
  REGISTERED = 'Registered',
  PENDING = 'Pending',
  SUSPENDED = 'Suspended',
  CANCELED = 'Canceled',
  NONE = 'None',
}

export const BENEFICIARY_STATUS_NAME: Record<BeneficiaryStatusEnum, string> = {
  [BeneficiaryStatusEnum.REGISTERED]: 'Registrado',
  [BeneficiaryStatusEnum.SUSPENDED]: 'Suspenso',
  [BeneficiaryStatusEnum.CANCELED]: 'Cancelado',
  [BeneficiaryStatusEnum.PENDING]: 'Pendente',
  [BeneficiaryStatusEnum.ACTIVE]: 'Ativo',
  [BeneficiaryStatusEnum.NONE]: 'Nenhum',
};

export const BENEFICIARY_STATUS_ICON_NAME: Record<
  BeneficiaryStatusEnum,
  IconName
> = {
  [BeneficiaryStatusEnum.REGISTERED]: 'marked-circle-outline',
  [BeneficiaryStatusEnum.ACTIVE]: 'marked-circle-outline',
  [BeneficiaryStatusEnum.SUSPENDED]: 'progress-clock',
  [BeneficiaryStatusEnum.CANCELED]: 'progress-alert',
  [BeneficiaryStatusEnum.PENDING]: 'progress-clock',
  [BeneficiaryStatusEnum.NONE]: 'progress-alert',
};

export const BENEFICIARY_STATUS_COLOR: Record<BeneficiaryStatusEnum, string> = {
  [BeneficiaryStatusEnum.REGISTERED]: COLORS.SECONDARY.GREEN['+1'],
  [BeneficiaryStatusEnum.SUSPENDED]: COLORS.SECONDARY.RED['+1'],
  [BeneficiaryStatusEnum.CANCELED]: COLORS.MONOCHROMATIC.GRAY4,
  [BeneficiaryStatusEnum.ACTIVE]: COLORS.SECONDARY.GREEN['+1'],
  [BeneficiaryStatusEnum.PENDING]: COLORS.PRIMARY.BLUE.MAIN,
  [BeneficiaryStatusEnum.NONE]: COLORS.MONOCHROMATIC.GRAY3,
};
