import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import {
  useDrawerFilter,
  Button,
  Icon,
} from '@hapvida/hapvida-core-components';

import { MOVIMENTATION_STEPS_KEYS } from '@flows/private/movements/pages/MovimentationSteps/constants/keys';
import { useFilterParams } from '@hooks';
import { SearchField, SubHeader } from '@components';
import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';

import { MovementsFiltersDrawer } from './components';
import { useMovementsState } from '../../hooks';

export function MovementsSubHeader() {
  const { hasFilters } = useFilterParams();
  const { movementsState, movementsSummaryState } = useMovementsState();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { filterCount } = useDrawerFilter();
  const navigate = useNavigate();

  const resultLength = movementsState.data?.movements?.length;
  const isLoading = movementsState.isLoading || movementsSummaryState.isLoading;
  const isEmpty = resultLength === 0 && !hasFilters;
  const isEmptyAndNoFilter = isEmpty && !hasFilters;
  const hasError = movementsState.isError || movementsSummaryState.isError;
  const isInitialLoading = isLoading && !hasFilters;

  if (isInitialLoading || hasError || isEmptyAndNoFilter) return null;

  const isDataUnavailable = isEmpty || movementsState.isLoading;
  const filterDisabled = !filterCount && isDataUnavailable;

  const handleToggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer);
  };

  const handleNewMovement = () => {
    navigate(
      MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.NEW_MOVIMENTATION,
      }),
    );
  };

  return (
    <SubHeader>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={7} md={8}>
          <SearchField
            currentResultLength={resultLength}
            placeholder="ID Solicitação"
          />
        </Grid>
        <Grid item xs={3} sm={3} md={2}>
          <Button
            endIcon={!filterCount && <Icon name="filter" size={20} />}
            variant={!filterCount ? 'outlined' : 'contained'}
            onClick={handleToggleDrawer}
            disabled={filterDisabled}
            color="secondary"
            disableRipple
            fullWidth
          >
            {!filterCount ? 'Aplicar filtro' : 'Ver filtros aplicados'}
          </Button>
        </Grid>
        <Grid item xs={3} sm={12} md={2}>
          <Button
            onClick={handleNewMovement}
            variant="contained"
            color="success"
            disableRipple
            fullWidth
          >
            Nova movimentação
          </Button>
        </Grid>
      </Grid>
      <MovementsFiltersDrawer
        toggleDrawer={handleToggleDrawer}
        visibleDrawer={visibleDrawer}
      />
    </SubHeader>
  );
}
