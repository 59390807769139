import { ReactNode } from 'react';

import { CompanySetupDetails } from '@services/CompanySetupService/dtos/GetCompanyDetailDTO';

import { CompanyStatusEnum } from '@constants';

import { RegisterAccess } from './components/RegisterAccess';

interface StatusComponentProps {
  companySetupDetailsData: CompanySetupDetails;
}

const STATUS_COMPONENT: Record<
  CompanyStatusEnum,
  (props: StatusComponentProps) => ReactNode
> = {
  PendingAccess: () => <></>,
  RegisterAccess: ({ companySetupDetailsData }: StatusComponentProps) => (
    <RegisterAccess companyDetails={companySetupDetailsData} />
  ),
  Active: () => <></>,
  Canceled: () => <></>,
};

export function CompanySetupDetailsCard({
  companySetupDetailsData,
}: StatusComponentProps) {
  return (
    <>
      {STATUS_COMPONENT[companySetupDetailsData.status]({
        companySetupDetailsData,
      })}
    </>
  );
}
