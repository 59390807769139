import { Control, FormState } from 'react-hook-form';
import { Grid } from '@mui/material';

import { TextInput, Mask } from '@hapvida/hapvida-core-components';

import { UserStatusEnum } from '@constants';
import { useBackofficeUserDetailsState } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/hooks/index';

import { BackofficeUserDetailsFormProps } from '../../types';
import { ResendConfirmationEmail } from './components';

interface BackofficeUserEditFormFieldsProps {
  formState: FormState<BackofficeUserDetailsFormProps>;
  control: Control<BackofficeUserDetailsFormProps>;
}

export function BackofficeUserEditFormFields({
  formState,
  control,
}: Readonly<BackofficeUserEditFormFieldsProps>) {
  const { backofficeUserDetailsState } = useBackofficeUserDetailsState();

  if (!backofficeUserDetailsState.isSuccess) {
    return null;
  }

  const userData = backofficeUserDetailsState.data.content;

  const pendingUser = userData?.status === UserStatusEnum.PENDING;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {pendingUser && <ResendConfirmationEmail formState={formState} />}
      </Grid>
      <Grid item xs={12} marginY={1}>
        <TextInput
          error={Boolean(formState.errors?.name?.message)}
          helperText={formState.errors?.name?.message}
          placeholder="Digite o nome"
          label="Nome completo"
          control={control}
          name="name"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} marginY={1}>
        <TextInput
          error={Boolean(formState.errors?.email?.message)}
          errorMessage={formState.errors?.email?.message}
          placeholder="Digite o e-mail"
          control={control}
          label="E-mail"
          name="email"
          fullWidth
          disabled
          required
        />
      </Grid>

      {userData?.document && (
        <Grid item xs={6} marginY={1}>
          <TextInput
            value={
              userData?.document?.length === 11
                ? Mask.cpf(userData?.document)
                : Mask.cpfNDITenDigits(userData?.document)
            }
            label="CPF"
            name="cpf"
            fullWidth
            required
            disabled
          />
        </Grid>
      )}

      <Grid item xs={12} sm={userData?.document ? 6 : 12} marginY={1}>
        <TextInput
          error={Boolean(formState.errors?.phone?.message)}
          errorMessage={formState.errors?.phone?.message}
          placeholder="Digite o telefone"
          control={control}
          mask={Mask.phone}
          label="Telefone"
          name="phone"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12} marginY={1}>
        <TextInput
          label="Tipo de gestão"
          value="Backoffice"
          name="management"
          fullWidth
          required
          disabled
        />
      </Grid>
    </Grid>
  );
}
