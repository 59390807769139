import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import {
  COLORS,
  SUB_HEADER_PAGE_ELEMENT_ID,
} from '@hapvida/hapvida-core-components';

export function SubHeader({ children }: Readonly<PropsWithChildren<{}>>) {
  return (
    <Box bgcolor={COLORS.WHITE} id={SUB_HEADER_PAGE_ELEMENT_ID}>
      {children}
    </Box>
  );
}
