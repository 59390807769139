import { UseQueryResult } from '@tanstack/react-query';

import {
  CreateUserProfileProps,
  ProfileProps,
  UserPartnerCompanyProps,
} from '@services/UserService';

import { UserProfileEnum } from '@constants';

export const handleProfiles = (
  editedPartnerCompanies: UserPartnerCompanyProps[],
  availableProfilesState: UseQueryResult<ProfileProps[]>,
) => {
  const filteredPartnerCompanies = editedPartnerCompanies.filter(
    partnerCompany => {
      const { hasAccess, isMainPartnerCompany } = partnerCompany;

      const isMainPartnerCompanySelectedAsMaster = isMainPartnerCompany
        ? false
        : editedPartnerCompanies.some(
            item =>
              item.partnerCompany === partnerCompany.mainPartnerCompany &&
              item.hasAccess &&
              item.profile?.name === UserProfileEnum.MASTER,
          );

      return hasAccess && !isMainPartnerCompanySelectedAsMaster;
    },
  );

  const availableProfiles = availableProfilesState?.data ?? [];

  const profiles: CreateUserProfileProps[] = availableProfiles
    .map(profile => {
      const thisPartnerCompanies = filteredPartnerCompanies
        .filter(item => item.profile?.id === profile.id)
        .map(item => item.partnerCompany);
      return {
        profileId: profile.id,
        partnerCompaniesIncluded: thisPartnerCompanies,
      };
    })
    .filter(item => item.partnerCompaniesIncluded.length > 0);

  return profiles;
};
