import axios from 'axios';

import { Mask } from '@hapvida/hapvida-core-components';

import { REGISTER_STEPS_KEYS } from '@flows/public/pages/RegisterSteps/constants/keys';
import { HapvidaApi } from '@http';

import {
  CompanyManager,
  CreateCompanyManagerDTO,
} from './dtos/CreateCompanyManagerDTO';
import {
  CompanyAddress,
  UpdateCompanyAddressDTO,
} from './dtos/UpdateCompanyAddressDTO';
import { GetCompanyResponse } from './dtos/GetCompanyDetailDTO';
import { PasswordRecoveryRequestDTO } from './dtos/PasswordRecoveryDTO';
import {
  PasswordRequestDefinitionDTO,
  PasswordResponseDefinitionDTO,
} from './dtos/PasswordDefinitionDTO';
import { RecoveryPasswordRequestDTO } from './dtos/RecoveryPasswordDTO';
import { DataAccessDefinitionRequestDTO } from './dtos/DataAccessPasswordDefinitionDTO';
import {
  CreateSessionRequestDTO,
  CurrentUserResponseDTO,
  SalesChannelRequestDTO,
  SalesChannelResponseDTO,
  ValidateCpfExistsRequestDTO,
} from './dtos';

export default class AuthService {
  public async authenticate(token: string) {
    const { data } = await axios.get<CurrentUserResponseDTO>(
      `${process.env.REACT_APP_API_URL}/api/v1/users/current`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY,
        },
      },
    );

    return data.content;
  }

  public async fetchSalesChannel({ salesChannelName }: SalesChannelRequestDTO) {
    const { data } = await HapvidaApi.get<SalesChannelResponseDTO>(
      `/api/v1/users/sales-channel/${salesChannelName}`,
    );

    return data.content;
  }

  public async getCompanyDetail(token: string): Promise<GetCompanyResponse> {
    const { data } = await HapvidaApi.get<GetCompanyResponse>(
      `/api/v1/request-user-quotations/${token}/access-link`,
    );

    return data;
  }

  public async registerCompany(data: CompanyAddress) {
    const { quotationId, ...rest } = data;

    await HapvidaApi.patch<UpdateCompanyAddressDTO>(
      `/api/v1/quotations/${quotationId}/active`,
      rest,
    );
  }

  public async createCompanyManager({
    companyId,
    companyManager,
  }: CompanyManager) {
    const step = {
      companyId,
      name: companyManager.name,
      document: Mask.numbers(Mask.cpf(companyManager.document)),
      phone: Mask.numbers(companyManager.phone),
      email: companyManager.email,
      confirmedEmail: companyManager.confirmEmail,
    };

    await HapvidaApi.post<CreateCompanyManagerDTO>(
      '/api/v1/users/company',
      step,
    );
  }

  public async requestPasswordRecovery(email: PasswordRecoveryRequestDTO) {
    const { data } = await HapvidaApi.post(
      `/api/v1/users/recover-password`,
      email,
    );

    return data;
  }

  public async passwordDefinition({
    userId,
    token,
    password,
  }: PasswordRequestDefinitionDTO): Promise<void> {
    await HapvidaApi.post<PasswordResponseDefinitionDTO>(
      `api/v1/users/${userId}/company`,
      {
        token,
        password,
      },
    );
  }

  public async recoveryPassword({
    password,
    token,
    userId,
  }: RecoveryPasswordRequestDTO) {
    const { data } = await HapvidaApi.put(
      `/api/v1/users/${userId}/reset-password`,
      { password, token },
    );

    return data;
  }

  public stepsMap = {
    [REGISTER_STEPS_KEYS.COMPANY_INFO]: this.registerCompany,
    [REGISTER_STEPS_KEYS.COMPANY_MANAGER]: this.createCompanyManager,
  };

  public async registerSteps(data: any, step: string) {
    await this.stepsMap[step](data);
  }

  public async dataAccessPasswordDefinition({
    document,
    token,
    password,
    userId,
  }: DataAccessDefinitionRequestDTO): Promise<void> {
    await HapvidaApi.post<PasswordResponseDefinitionDTO>(
      `api/v1/users/${userId}/first-access`,
      {
        document,
        token,
        password,
      },
    );
  }

  public async createSession({
    partnerCompany,
    companyId,
  }: CreateSessionRequestDTO) {
    await HapvidaApi.post(`/api/v1/users/create-session`, {
      partnerCompany,
      companyId,
    });
  }

  public async validateCpfExists({ document }: ValidateCpfExistsRequestDTO) {
    const { data } = await HapvidaApi.get(
      `/api/v1/user/exists?document=${document}`,
    );
    return data.notifications[0]?.type === 'Success';
  }
}
