import { Button, Stack, StackProps, Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import alertSource from '@assets/images/alert.svg';

interface ErrorLoadingProps extends StackProps {
  onReloadClick?: () => void;
}

export function ErrorLoading({
  onReloadClick,
  ...stackProps
}: ErrorLoadingProps) {
  return (
    <Stack gap={3} alignItems="center" padding={4} {...stackProps}>
      <img src={alertSource} alt="Falha ao carregar informações" height={90} />
      <Typography variant="h5" textAlign="center">
        Atenção! Não conseguimos carregar os usuários.
      </Typography>
      <Button
        endIcon={<Icon name="refresh" size={16} />}
        onClick={onReloadClick}
        variant="outlined"
      >
        Recarregar usuários
      </Button>
    </Stack>
  );
}
