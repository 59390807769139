import React from 'react';

import { Card, Header, Content, CardsContainer } from './styles';

interface StepCardProps {
  steps: {
    id: number;
    img: string;
    content: string | React.JSX.Element;
    alt?: string;
  }[];
  cardStyle?: React.CSSProperties;
}

export const StepCard: React.FC<StepCardProps> = ({ steps, cardStyle }) => {
  return (
    <CardsContainer gap={2}>
      {steps.map(step => (
        <React.Fragment key={step.id}>
          <Card sx={cardStyle}>
            <Header>
              <img src={step.img} alt={step.alt} />
            </Header>
            <Content>{step.content}</Content>
          </Card>
        </React.Fragment>
      ))}
    </CardsContainer>
  );
};
