import { styled as muiStyled } from '@mui/material';
import Stack from '@mui/material/Stack';

interface ContainerProps {
  borderColor: string;
  backgroundColor: string;
  alignItems: string;
}

export const Container = muiStyled('div')<ContainerProps>(
  ({ backgroundColor, borderColor, alignItems }) => ({
    display: 'flex',
    borderRadius: '8px',
    background: backgroundColor,
    border: `1px solid ${borderColor}`,
    padding: '1.5rem',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
    alignItems: alignItems,
  }),
);

interface StackProps {
  marginBottom: number;
}

export const TextWrapper = muiStyled(Stack)<StackProps>(({ marginBottom }) => ({
  '& .MuiTypography-root:first-of-type': {
    marginBottom: `${marginBottom}rem`,
  },
  margin: '-2px 0 0 1rem',
}));
