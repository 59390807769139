import { Box, Stack, styled as muiStyled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const FooterDrawer = muiStyled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '16px',
  background: COLORS.WHITE,
  boxShadow: '0px -20px 10px 0px rgba(0, 0, 0, 0.05)',
}));

export const WrapperFilter = muiStyled(Stack)(() => ({
  height: 'calc(100% - 105px)',
  padding: '16px',
  gap: '16px',
  overflow: 'auto',
}));
