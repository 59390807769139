import { AlertCard } from '@hapvida/hapvida-core-components';

import { MovementStatusEnum } from '@constants';

import { SubtitleCallout } from './styles';

const MOV_STATUS_DATA = {
  [MovementStatusEnum.NOT_STARTED]: {
    type: 'info',
    title: 'Não iniciado',
    subtitle: '',
  },

  [MovementStatusEnum.FILE_PROCESSED]: {
    type: 'success',
    title: 'Arquivo processado com sucesso!',
    subtitle: (
      <SubtitleCallout>
        A movimentação foi processada e os beneficiários abaixo já foram
        efetivados.
        <br />
        Fique a vontade para ver o resultado da sua movimentação.
      </SubtitleCallout>
    ),
  },
  [MovementStatusEnum.UNABLE_TO_START]: {
    type: 'error',
    title: 'Não foi possível iniciar o processamento da sua movimentação!',
    subtitle: (
      <SubtitleCallout>
        Parece que seu arquivo não corresponde com o arquivo de movimentação.
        <br />
        Inicie uma nova movimentação e{' '}
        <b>
          certifique-se de utilizar o modelo de movimentação e criar o arquivo
          com as diretrizes corretas.
        </b>
      </SubtitleCallout>
    ),
  },
  [MovementStatusEnum.ERROR]: {
    type: 'error',
    title: 'Nenhum beneficiário foi processado.',
    subtitle: (
      <SubtitleCallout>
        Seu arquivo está correto, porém, foram encontradas críticas que
        inviabilizaram a movimentação inteira. Faça o reenvio da planilha
        <b> através de uma nova movimentação.</b>
      </SubtitleCallout>
    ),
  },
  [MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW]: {
    type: 'alert',
    title: 'Arquivo processado parcialmente.',
    subtitle: (
      <SubtitleCallout>
        A movimentação foi processada,{' '}
        <b>porém nem todos os beneficiários foram efetivados.</b> Fique a
        vontade para ver o resultado da sua movimentação.
      </SubtitleCallout>
    ),
  },
  [MovementStatusEnum.PROCESS_FILE_WITH_PENDENCY]: {
    type: 'error',
    title: 'Processamento com pendência',
    subtitle: (
      <SubtitleCallout>
        Seu arquivo está correto, porém, foram encontradas pendência que
        <b> pausaram </b>a movimentação. Para continuar com o processamento,
        verifique e resolva as pendências.
      </SubtitleCallout>
    ),
  },
};

interface CallOutProps {
  status: MovementStatusEnum;
}

export function CallOut({ status }: CallOutProps) {
  return (
    <>
      {status !== MovementStatusEnum.PROCESS_FILE && (
        <AlertCard
          type={MOV_STATUS_DATA[status].type as any}
          title={MOV_STATUS_DATA[status].title}
          content={MOV_STATUS_DATA[status].subtitle}
        />
      )}
    </>
  );
}
