import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { DataAccessFormProps } from '@flows/public/pages/UserFirstAccess/types';
import {
  USER_PASSWORD_DEFINITION_STEPS_KEYS,
  USER_PASSWORD_DEFINITION_STEPS_VALUES,
} from '@flows/public/pages/UserFirstAccess/constants';
import { useSteps } from '@flows/public/pages/UserFirstAccess/hooks';
import { toastifyApiErrors } from '@utils';

import { schema } from './control/validation';

export function useUserDataAccess() {
  const { handleStepUpdate, dataFormState } = useSteps();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      document: dataFormState.document,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<DataAccessFormProps> = data => {
    try {
      setLoading(true);
      handleStepUpdate(
        USER_PASSWORD_DEFINITION_STEPS_VALUES[
          USER_PASSWORD_DEFINITION_STEPS_KEYS.DATA_ACCESS
        ],
        data,
      );
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      setLoading(false);
    }
  };

  return {
    control,
    handleSubmit,
    errors,
    isValid,
    onSubmit,
    loading,
  };
}
