import { Dialog, DialogProps } from '@mui/material';

export interface ModalContainerProps extends DialogProps {
  loading?: boolean;
  handleCloseModal: () => void;
}

export function ModalContainer({
  loading,
  handleCloseModal,
  ...dialogProps
}: Readonly<ModalContainerProps>) {
  return (
    <Dialog
      {...dialogProps}
      onClose={loading ? undefined : handleCloseModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    />
  );
}
