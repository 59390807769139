import { StepProps } from '@flows/public/pages/UserFirstAccess/types';

export const USER_PASSWORD_DEFINITION_STEPS_KEYS = {
  DATA_ACCESS: 'dados-de-acesso',
  PASSWORD_DEFINITION: 'criar-senha',
  REGISTRATION_COMPLETE: 'cadastro-concluido',
};

export const USER_PASSWORD_DEFINITION_STEPS_VALUES = {
  [USER_PASSWORD_DEFINITION_STEPS_KEYS.DATA_ACCESS]: 0,
  [USER_PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION]: 1,
  [USER_PASSWORD_DEFINITION_STEPS_KEYS.REGISTRATION_COMPLETE]: 2,
};

export const USER_PASSWORD_DEFINITION_STEPS_ORDER = [
  USER_PASSWORD_DEFINITION_STEPS_KEYS.DATA_ACCESS,
  USER_PASSWORD_DEFINITION_STEPS_KEYS.PASSWORD_DEFINITION,
  USER_PASSWORD_DEFINITION_STEPS_KEYS.REGISTRATION_COMPLETE,
];

export const FIRST_STEP =
  USER_PASSWORD_DEFINITION_STEPS_ORDER[
    USER_PASSWORD_DEFINITION_STEPS_VALUES[
      USER_PASSWORD_DEFINITION_STEPS_KEYS.DATA_ACCESS
    ]
  ];

enum StepLabelEnum {
  FIRST = 'Bem-vindo',
  SECOND = 'Definição de senha',
  LAST = 'Acesse o Portal Empresa',
}

export const FIRST_STEP_LIST_OPTIONS: StepProps[] = [
  {
    label: StepLabelEnum.FIRST,
    completed: false,
    blocked: false,
  },
  {
    label: StepLabelEnum.SECOND,
    completed: false,
    blocked: true,
  },
  {
    label: StepLabelEnum.LAST,
    completed: false,
    blocked: true,
  },
];

export const SECOND_STEP_LIST_OPTIONS: StepProps[] = [
  {
    label: StepLabelEnum.FIRST,
    completed: true,
    blocked: false,
  },
  {
    label: StepLabelEnum.SECOND,
    completed: false,
    blocked: false,
  },
  {
    label: StepLabelEnum.LAST,
    completed: false,
    blocked: true,
  },
];

export const LAST_STEP_LIST_OPTIONS: StepProps[] = [
  {
    label: StepLabelEnum.FIRST,
    completed: true,
    blocked: true,
  },
  {
    label: StepLabelEnum.SECOND,
    completed: true,
    blocked: true,
  },
  {
    label: StepLabelEnum.LAST,
    completed: false,
    blocked: false,
  },
];
