import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';

import {
  UserPartnerCompaniesDTO,
  UserPartnerCompanyProps,
  UserService,
} from '@services/UserService';

import { ITEMS_LIMIT_PER_PAGE } from '@constants';
import { useAuth } from '@hooks';
import { toastifyApiErrors } from '@utils';
import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

import {
  ExtraConfigProps,
  UsePartnerCompanyListProps,
  UserPartnerCompanyQueryKeysEnum,
} from './types';
import {
  getFilterPartnerCompany,
  getUpdateEditedPartnerCompanies,
} from './utils';

export function usePartnerCompanyList({
  searchParam,
}: UsePartnerCompanyListProps) {
  const {
    currentUserId,
    setEditedPartnerCompanies,
    editedPartnerCompanies,
    originalPartnerCompanies,
    setOriginalPartnerCompanies,
    setHasError,
    hasError,
  } = useAccessManagement();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCompany } = useAuth();
  const userService = useMemo(() => new UserService(), []);

  const companyId = selectedCompany?.id ?? '';

  const handleSaveOriginalPartnerCompanies = useCallback(
    (newPartnerCompanies: UserPartnerCompanyProps[]) => {
      const filteredNewPartnerCompanies = getFilterPartnerCompany({
        editedPartnerCompanies: originalPartnerCompanies,
        newPartnerCompanies,
      });

      const mergedPartnerCompanies = [
        ...originalPartnerCompanies,
        ...filteredNewPartnerCompanies,
      ];

      setOriginalPartnerCompanies(mergedPartnerCompanies);
    },
    [originalPartnerCompanies, setOriginalPartnerCompanies],
  );

  const handleUpdateEditedPartnerCompanies = useCallback(
    (newPartnerCompanies: UserPartnerCompanyProps[]) => {
      const newEditedPartnerCompanyList = getUpdateEditedPartnerCompanies({
        editedPartnerCompanies,
        newPartnerCompanies,
      });

      setEditedPartnerCompanies(newEditedPartnerCompanyList);
    },
    [editedPartnerCompanies],
  );

  const handleSuccess = useCallback(
    (data: InfiniteData<UserPartnerCompaniesDTO>) => {
      if (!data?.pages) {
        return;
      }

      if (hasError) setHasError(false);

      const partnerCompanies =
        data.pages.map(item => item.partnerCompanies.itens).flat() ?? [];

      handleSaveOriginalPartnerCompanies(partnerCompanies);

      handleUpdateEditedPartnerCompanies(partnerCompanies);
    },
    [handleSaveOriginalPartnerCompanies, handleUpdateEditedPartnerCompanies],
  );

  const BaseQuery = (queryKey: string, extraConfigs?: ExtraConfigProps) =>
    useInfiniteQuery<UserPartnerCompaniesDTO>({
      queryKey: [queryKey, companyId, searchParam, currentUserId],
      queryFn: ({ pageParam = 1 }) =>
        userService.getUserPartnerCompanies({
          offset: ITEMS_LIMIT_PER_PAGE * (pageParam - 1),
          limit: ITEMS_LIMIT_PER_PAGE,
          companyId,
          search: searchParam.length > 0 ? searchParam : undefined,
          userId: currentUserId,
          ...extraConfigs?.queryParams,
        }),
      getNextPageParam: (lastPage, allPages) => {
        const companiesHasNextPage =
          lastPage.partnerCompanies.total >
          allPages.length * ITEMS_LIMIT_PER_PAGE;

        if (companiesHasNextPage) {
          return allPages.length + 1;
        }

        return undefined;
      },
      onSuccess: data => handleSuccess(data),
      onError: (error: unknown) => {
        toastifyApiErrors(error, enqueueSnackbar);

        if (!hasError) setHasError(true);
      },
      enabled: extraConfigs ? extraConfigs.enabled : true,
    });

  const initialMainPartnerCompaniesState = BaseQuery(
    UserPartnerCompanyQueryKeysEnum.INITIAL_MAIN_PARTNER_COMPANIES_STATE,
    {
      queryParams: {
        'contract-type': 'mother',
      },
    },
  );

  const initialPartnerCompaniesState = BaseQuery(
    UserPartnerCompanyQueryKeysEnum.INITIAL_PARTNER_COMPANIES_STATE,
    {
      enabled: initialMainPartnerCompaniesState.isSuccess,
    },
  );

  return { initialPartnerCompaniesState, initialMainPartnerCompaniesState };
}
