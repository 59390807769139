import { useNavigate } from 'react-router-dom';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import { EmptyResult } from '@components';
import { useAuth } from 'hooks/useAuth';

import { BackofficeUserListRouter } from '../BackofficeUserList/router';
import { useBackofficeUserDetailsState } from './hooks';
import { BackofficeUserEditForm } from './components';

export function BackofficeUserDetailsLayout() {
  const { backofficeUserDetailsState, refreshBackofficeUserDetails } =
    useBackofficeUserDetailsState();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { isLoading, isError } = backofficeUserDetailsState;

  const handleNavigateToBackofficeUserList = () => {
    navigate(BackofficeUserListRouter.path);
  };

  if (isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const isUserNotFoundError =
    backofficeUserDetailsState.failureReason?.response?.status === 404;

  if (isUserNotFoundError) {
    const userName = user?.name ?? '';
    return (
      <EmptyResult
        message={`${userName}, nenhum usuário foi encontrado.`}
        buttonProps={{
          children: 'Voltar para usuários backoffice',
          onClick: handleNavigateToBackofficeUserList,
        }}
      />
    );
  }

  if (isError) {
    return <ErrorLoadingPage onReloadClick={refreshBackofficeUserDetails} />;
  }

  return (
    <BackofficeUserEditForm
      userData={backofficeUserDetailsState.data.content}
    />
  );
}
