import { useCallback, useState } from 'react';

import { LocalStorageKeyEnum } from '@constants';

export function useNewCompanyModal() {
  const [modalHasBeenSeen, setModalHasBeenSeen] = useState<boolean>(() => {
    const storageData = localStorage.getItem(
      LocalStorageKeyEnum.SEEN_NEW_COMPANIES_MODAL,
    );

    if (storageData) {
      return JSON.parse(storageData);
    }

    return false;
  });

  const markAsSeen = useCallback(() => {
    setModalHasBeenSeen(true);
    localStorage.setItem(
      LocalStorageKeyEnum.SEEN_NEW_COMPANIES_MODAL,
      JSON.stringify(true),
    );
  }, [setModalHasBeenSeen]);

  return {
    modalHasBeenSeen,
    markAsSeen,
  };
}
