import { COLORS } from '@hapvida/hapvida-core-components';

import { BackofficeUserListRouter } from '@flows/private/backofficeUser/pages/BackofficeUserList/router';

import { SidebarRouteButton } from '../SidebarRouteButton';

export function BackofficeManagement() {
  return (
    <SidebarRouteButton
      route={BackofficeUserListRouter}
      stackProps={{ bgcolor: COLORS.MONOCHROMATIC.GRAY6 }}
    />
  );
}
