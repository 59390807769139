import { Stack, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loading } from '@hapvida/hapvida-core-components';

import { MovementShippingReviewsContentProps } from './types';
import { useMovementShippingReviews } from './hooks/useMovementShippingReviews';
import { TableWrapper } from './styles';
import { useSortByRow } from './utils';

export function MovementShippingReviewsContent({
  movementId,
  companyId,
}: Readonly<MovementShippingReviewsContentProps>) {
  const { movementShippingReviewsState } = useMovementShippingReviews({
    movementId,
    companyId,
  });

  const { hasNextPage, fetchNextPage, data, isLoading, isError } =
    movementShippingReviewsState;

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" width="450px">
        <Loading
          padding={0}
          width="100%"
          message={
            <Typography variant="subtitle1">
              Estamos carregando as informações. <br /> Em breve, tudo estará
              pronto para você
            </Typography>
          }
        />
      </Stack>
    );
  }

  if (isError) {
    return <Typography variant="subtitle1">Erro ao carregar...</Typography>;
  }

  const movementData = data?.pages.map(item => item.reviews).flat() ?? [];

  const sortedMovementData = useSortByRow(movementData);

  return (
    <>
      <Typography variant="text">
        Para processar as linhas, efetue uma nova movimentação após corrigir o
        problema identificado no seu arquivo. Lembre-se que você pode incluir
        apenas as linhas que tiveram erro.
      </Typography>
      <TableWrapper id="scrollableDiv">
        <InfiniteScroll
          dataLength={sortedMovementData.length}
          hasMore={Boolean(hasNextPage)}
          next={fetchNextPage}
          loader={<Loading animationProps={{ height: 20, width: 20 }} />}
          scrollableTarget="scrollableDiv"
        >
          <Stack gap={1.5} mt={2}>
            {sortedMovementData.map(item => (
              <Typography variant="text" fontWeight={700} key={item.row}>
                {`Linha ${item.row}: ${item.message}`}
              </Typography>
            ))}
          </Stack>
        </InfiniteScroll>
      </TableWrapper>
    </>
  );
}
