import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Mask } from '@hapvida/hapvida-core-components';

import { UserDetailsProps } from '@services/UserService';

import {
  SubmitBackofficeUserFormButton,
  BackofficeUserEditFormFields,
  DeleteBackofficeUserButton,
} from './components';
import { UserActionsStateProvider } from './hooks';
import { BackofficeUserDetailsFormProps } from './types';
import { schema } from './validation';

interface BackofficeUserEditFormProps {
  userData: UserDetailsProps;
}

export function BackofficeUserEditForm({
  userData,
}: BackofficeUserEditFormProps) {
  const userForm = useForm<BackofficeUserDetailsFormProps>({
    defaultValues: {
      phone: userData?.phone ? Mask.phone(userData?.phone) : '',
      document: userData?.document ?? '',
      email: userData?.email ?? '',
      name: userData?.name ?? '',
    },
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { formState, control, reset, handleSubmit } = userForm;

  return (
    <UserActionsStateProvider>
      <Stack component="form" maxWidth="md" margin="auto" gap={3} py={3}>
        <Stack
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          gap={1}
        >
          <Typography variant="h5">Editar usuário backoffice</Typography>
          <Typography color="primary" variant="caption">
            Campos com * são obrigatórios
          </Typography>
        </Stack>

        <BackofficeUserEditFormFields formState={formState} control={control} />

        <Stack direction="row" justifyContent="space-between">
          <DeleteBackofficeUserButton />
          <SubmitBackofficeUserFormButton
            handleSubmit={handleSubmit}
            formState={formState}
            reset={reset}
          />
        </Stack>
      </Stack>
    </UserActionsStateProvider>
  );
}
