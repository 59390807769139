export const BrazilianStates = [
  { name: 'Acre', acronomy: 'AC' },
  { name: 'Alagoas', acronomy: 'AL' },
  { name: 'Amapá', acronomy: 'AP' },
  { name: 'Amazonas', acronomy: 'AM' },
  { name: 'Bahia', acronomy: 'BA' },
  { name: 'Ceará', acronomy: 'CE' },
  { name: 'Distrito Federal', acronomy: 'DF' },
  { name: 'Espírito Santo', acronomy: 'ES' },
  { name: 'Goiás', acronomy: 'GO' },
  { name: 'Maranhão', acronomy: 'MA' },
  { name: 'Mato Grosso', acronomy: 'MT' },
  { name: 'Mato Grosso do Sul', acronomy: 'MS' },
  { name: 'Minas Gerais', acronomy: 'MG' },
  { name: 'Pará', acronomy: 'PA' },
  { name: 'Paraíba', acronomy: 'PB' },
  { name: 'Paraná', acronomy: 'PR' },
  { name: 'Pernambuco', acronomy: 'PE' },
  { name: 'Piauí', acronomy: 'PI' },
  { name: 'Rio de Janeiro', acronomy: 'RJ' },
  { name: 'Rio Grande do Norte', acronomy: 'RN' },
  { name: 'Rio Grande do Sul', acronomy: 'RS' },
  { name: 'Rondônia', acronomy: 'RO' },
  { name: 'Roraima', acronomy: 'RR' },
  { name: 'Santa Catarina', acronomy: 'SC' },
  { name: 'São Paulo', acronomy: 'SP' },
  { name: 'Sergipe', acronomy: 'SE' },
  { name: 'Tocantins', acronomy: 'TO' },
];
