import { useState } from 'react';

import { ProfileProps } from '@services/UserService';

import { ProfileResourcesModal } from '@components';
import { useProfileDetails } from '@hooks';

import { LinkModal } from './styles';

interface ResourcesModalButtonProps {
  profile: ProfileProps;
}

export function ResourcesModalButton({ profile }: ResourcesModalButtonProps) {
  const { getUserProfileDetails } = useProfileDetails();
  const [modalOpen, setModalOpen] = useState(false);

  const [currentProfileDetails, setCurrentProfileDetails] =
    useState<ProfileProps>();

  const handleClickProfile = () => {
    getUserProfileDetails(profile.id).then(profileDetails => {
      if (profileDetails) {
        setCurrentProfileDetails(profileDetails);
        setModalOpen(true);
      }
    });
  };

  return (
    <>
      <LinkModal onClick={handleClickProfile} variant="smallText">
        Saiba mais
      </LinkModal>
      {currentProfileDetails && (
        <ProfileResourcesModal
          profile={currentProfileDetails}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
