const companyDetailsListMock = [
  {
    id: 'c4dcc76a-6f94-4403-9b8c-d19701c761cd',
    status: 'PendingAccess',
    document: '92817613000134',
    name: {
      trade: 'teste dados',
      legal: 'teste Mock',
    },
  },
  {
    id: 'b95125f8-cef8-4e71-b99a-acc9a659bbb3',
    status: 'RegisterAccess',
    document: '92817613000134',
    name: {
      trade: 'teste dados',
      legal: 'teste Mock',
    },
  },
  {
    id: '1dad0f02-b486-4e0a-aaea-6981505cc09f',
    status: 'Active',
    document: '50593072000168',
    name: {
      trade: 'teste dados',
      legal: 'razão 1',
    },
  },
  {
    id: '52669c12-a291-475a-976e-5e914a6075a8',
    status: 'RegisterAccess',
    document: '76829028000106',
    name: {
      trade: 'teste dados',
      legal: 'razao social',
    },
  },
  {
    id: 'c5c15a9c-1cc2-4290-b9f7-d4c18c2bdbf6',
    status: 'Canceled',
    document: '55833286000188',
    name: {
      trade: 'teste dados',
      legal: 'nome',
    },
  },
  {
    id: '40becdad-723e-4de7-a0a7-8866391927a4',
    status: 'Canceled',
    document: '49670780000176',
    name: {
      trade: 'teste dados',
      legal: 'empresa de homologação 5',
    },
  },
  {
    id: '8b2189f5-407e-4b5c-bb63-8f5fa048a13e',
    status: 'PendingAccess',
    document: '92763554000169',
    name: {
      trade: 'teste dados',
      legal: 'boticario a',
    },
  },
  {
    id: '302ec8ae-5aec-40d6-bd14-f1c4fb18049a',
    status: 'Active',
    document: '97987022000129',
    name: {
      trade: 'teste dados',
      legal: 'boticario 23',
    },
  },
  {
    id: '52a86800-543e-4914-8975-1c1f58501cf3',
    status: 'PendingAccess',
    document: '99203440000103',
    name: {
      trade: 'teste dados',
      legal: 'boticario 23',
    },
  },
];

export default companyDetailsListMock;
