import { List, ListItem, styled as muiStyled } from '@mui/material';

export const ListPermissions = muiStyled(List)(() => ({
  marginLeft: 0,
  padding: 0,
}));

export const ListItemPermissions = muiStyled(ListItem)(() => ({
  display: 'list-item',
  padding: 0,
  marginBottom: '8px',
  position: 'relative',
  listStyle: 'none',
  paddingLeft: '20px',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '8px',
    top: '10px',
    width: '3px',
    height: '3px',
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));
