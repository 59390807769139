import { HealthOperatorEnum } from '@constants';

export const CITY_HALL_LINK: Record<HealthOperatorEnum, string> = {
  [HealthOperatorEnum.CLINIPAM]:
    'https://isscuritiba.curitiba.pr.gov.br/NotaCuritibana/NotaRPS/AutenticidadeNota',
  [HealthOperatorEnum.CCG]:
    'https://nfe.portoalegre.rs.gov.br/nfse/pages/consultaNFS-e_cidadao.jsf',
  [HealthOperatorEnum.HAPVIDA]: ' https://iss.fortaleza.ce.gov.br/',
  [HealthOperatorEnum.NDI]: '',
};
