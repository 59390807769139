import { IconButton, styled, TableCell, TableRow } from '@mui/material';

import { Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

interface StyledTableRowProps {
  error?: 'true' | 'false';
  statusProcessed?: boolean;
  allowedCancel?: boolean;
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: prop => {
    return prop !== 'statusProcessed' && prop !== 'error';
  },
})<StyledTableRowProps>(({ error, statusProcessed }) => ({
  background:
    error === 'true' || statusProcessed === true
      ? 'linear-gradient(0deg, rgba(240, 64, 46, 0.02), rgba(240, 64, 46, 0.02)), #FFFFFF'
      : undefined,
  '*:not([error=true]) + & > *, &:first-of-type': {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor:
      error !== 'true' ? 'rgba(224, 224, 224, 1)' : COLORS.SECONDARY.RED.MAIN,
  },
  '& > *': {
    borderBottom: 'unset !important',
  },
}));

export const ErrorTableRow = styled(TableRow)<{ error?: 'true' | 'false' }>(
  ({ error }) => ({
    background:
      error === 'true'
        ? 'linear-gradient(0deg, rgba(240, 64, 46, 0.02), rgba(240, 64, 46, 0.02)), #FFFFFF'
        : undefined,
    '& > *': {
      borderBottomWidth: '1px',
      borderBottomStyle: error !== 'true' ? 'none' : 'solid',
      borderBottomColor: `${COLORS.SECONDARY.RED.MAIN} !important`,
    },
  }),
);

export const StyledTableCell = styled(TableCell)({});

export const EditButton = styled(IconButton)(() => ({
  '&.MuiIconButton-root': {
    border: `1px solid ${COLORS.MONOCHROMATIC.GRAY4}`,

    width: '32px',
    height: '32px',

    '&:hover': {
      background: COLORS.WHITE,
      color: COLORS.PRIMARY.ORANGE.MAIN,
      borderColor: COLORS.PRIMARY.ORANGE.MAIN,
    },
  },
}));

const getBackgroundColorCancel = ({
  allowedCancel,
  error,
}: StyledTableRowProps) => {
  if (error === 'true' && allowedCancel === true) {
    return `linear-gradient(0deg, rgba(245, 182, 127, 0.16) 0%, rgba(245, 182, 127, 0.16) 100%), #FFF; `;
  }
  if (error === 'true') {
    return `linear-gradient(0deg, rgba(240, 64, 46, 0.02), rgba(240, 64, 46, 0.02)), #FFFFFF`;
  }

  if (allowedCancel === true) {
    return `linear-gradient(0deg, rgba(245, 182, 127, 0.16) 0%, rgba(245, 182, 127, 0.16) 100%), #FFF; `;
  }
  return `${COLORS.WHITE}`;
};

export const StyledActionCellCancel = styled(TableCell, {
  shouldForwardProp: prop => {
    return prop !== 'allowedCancel' && prop !== 'error';
  },
})<StyledTableRowProps>(({ allowedCancel, error }) => ({
  '&.MuiTableCell-root': {
    width: '10px',
  },

  width: '30px',
  height: '60px',
  background: getBackgroundColorCancel({ allowedCancel, error }),
  position: 'sticky',
  right: 0,
}));

export const TypographControl = styled(Typography)(() => ({
  paddingTop: '4px',
}));
