import { COLORS } from '@hapvida/hapvida-core-components';

export enum MovementStatusEnum {
  FILE_PROCESSED_WITH_REVIEW = 'FileProcessedWithReview',
  PROCESS_FILE_WITH_PENDENCY = 'ProcessFileWithPendency',
  UNABLE_TO_START = 'UnableToStart',
  FILE_PROCESSED = 'FileProcessed',
  PROCESS_FILE = 'ProcessFile',
  NOT_STARTED = 'NotStarted',
  ERROR = 'Error',
}

export const MOVEMENT_STATUS_NAME: Record<MovementStatusEnum, string> = {
  [MovementStatusEnum.PROCESS_FILE_WITH_PENDENCY]:
    'Processamento com pendência',
  [MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW]: 'Finalizado com críticas',
  [MovementStatusEnum.UNABLE_TO_START]: 'Impossível iniciar',
  [MovementStatusEnum.PROCESS_FILE]: 'Em processamento',
  [MovementStatusEnum.ERROR]: 'Interrompido por erro',
  [MovementStatusEnum.FILE_PROCESSED]: 'Finalizado',
  [MovementStatusEnum.NOT_STARTED]: 'Não iniciado',
};

export const MOVEMENT_STATUS_COLOR: Record<MovementStatusEnum, string> = {
  [MovementStatusEnum.PROCESS_FILE_WITH_PENDENCY]: COLORS.SECONDARY.RED['+1'],
  [MovementStatusEnum.FILE_PROCESSED_WITH_REVIEW]: COLORS.PRIMARY.ORANGE['+1'],
  [MovementStatusEnum.FILE_PROCESSED]: COLORS.SECONDARY.GREEN['+1'],
  [MovementStatusEnum.UNABLE_TO_START]: COLORS.SECONDARY.RED.MAIN,
  [MovementStatusEnum.NOT_STARTED]: COLORS.MONOCHROMATIC.GRAY4,
  [MovementStatusEnum.PROCESS_FILE]: COLORS.PRIMARY.BLUE.MAIN,
  [MovementStatusEnum.ERROR]: COLORS.SECONDARY.RED['+1'],
};
