import styled from '@mui/material/styles/styled';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes';

export const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
});
interface PaginationItemProps {
  height?: number;
}
export const PaginationItem = styled('button')<PaginationItemProps>(
  ({ height = 32 }) => ({
    border: 'transparent',
    background: 'transparent',

    fontSize: '14px',

    height: `${height}px`,
    padding: '0 16px',
    cursor: 'pointer',

    '&[aria-current=true]': {
      background: COLORS.PRIMARY.ORANGE.MAIN,
      color: COLORS.WHITE,
      fontWeight: 900,
    },
  }),
);

export const Ellipsis = styled('div')({
  padding: ' 0px 6px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  color: COLORS.MONOCHROMATIC.GRAY4,

  fontSize: '14px',
  letterSpacing: '0.01071em',
});
