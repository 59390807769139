import { Stack, StackProps, styled as muiStyled } from '@mui/material';
import { COLORS } from '../../../../../themes/colors';

interface RouteItemInterface {
  selected: boolean;
  open: boolean;
  backofficeVision: boolean | undefined;
  disabled: boolean;
}

export const RouteItem = muiStyled(
  ({ ...props }) => <Stack component="li" {...props} />,
  {
    shouldForwardProp: prop => prop !== 'backofficeVision',
  },
)<StackProps & RouteItemInterface>(
  ({ open, selected, backofficeVision, disabled }) => {
    return {
      padding: 16,

      borderRadius: '8px',

      svg: {
        color: COLORS.MONOCHROMATIC.GRAY4,
        minHeight: open ? 0 : 32,
      },

      ':hover': {
        cursor: disabled ? 'not-allowed' : 'pointer',
        background: disabled ? 'transparent' : 'rgba(237, 171, 56, 0.08)',
      },

      ...(disabled && {
        opacity: 0.5,
        background: 'transparent',
      }),

      ...(selected && {
        background: 'rgba(237, 171, 56, 0.08)',
        '.MuiTypography-root': {
          fontWeight: 600,
        },
        svg: {
          color: COLORS.PRIMARY.ORANGE.MAIN,
          minHeight: open ? 0 : 32,
        },
      }),

      ...(!open && {
        width: 32,
        height: 32,
      }),

      ...(backofficeVision && {
        '.MuiTypography-root': {
          color: COLORS.WHITE,
        },

        svg: {
          minHeight: open ? 0 : 32,
          color: disabled
            ? COLORS.MONOCHROMATIC.GRAY2
            : COLORS.PRIMARY.ORANGE.MAIN,
        },
      }),

      ...(backofficeVision &&
        selected && {
          background: 'rgba(255, 255, 255, 0.08)',
        }),
    };
  },
);
