import {
  CalculateSummaryValuesBeneficiariesProps,
  CalculateSummaryValuesInitialValuesProps,
  CalculateSummaryValuesResultProps,
} from './types';

export function calculateSummaryValues(
  beneficiaries: CalculateSummaryValuesBeneficiariesProps[],
  initialValues: CalculateSummaryValuesInitialValuesProps,
): CalculateSummaryValuesResultProps {
  return beneficiaries.reduce(
    (acc, beneficiary) => {
      acc[beneficiary.type] =
        (acc[beneficiary.type] ?? 0) + beneficiary.totalBeneficiaries;
      return acc;
    },
    { ...initialValues, totalBeneficiaries: 0 },
  );
}
