import axios from 'axios';

export interface HapvidaApiResponseError {
  response: {
    data: {
      notifications: Array<{
        message: string;
        type?: string;
        propertyName?: string;
      }>;
    };
    status: number;
  };
}

const HapvidaMockApi = axios.create({
  baseURL: process.env.REACT_APP_MOCK_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY || '',
  },
});

HapvidaMockApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.assign('/sessao-expirada');
    }

    if (error.response.status === 302) {
      return error.response;
    }

    return Promise.reject(error);
  },
);

export default HapvidaMockApi;
