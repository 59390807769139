import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';
import { createContext } from 'react';
import { ModalProps } from '../components/Modal';

export interface IShowConfirmation
  extends Partial<Omit<ModalProps, 'content'>> {
  type: 'default' | 'alert' | 'success';
  title: string;
  icon?: IconName;
  content?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
}

export interface ConfirmationModalContextData {
  showConfirmation(data: IShowConfirmation): void;
}

export const ConfirmationModalContext =
  createContext<ConfirmationModalContextData>(
    {} as ConfirmationModalContextData,
  );
