import { IAppRoute } from 'routes/types';

import { LinkExpired } from './index';

export const LinkExpiredRouter: IAppRoute = {
  Page: LinkExpired,
  path: '/link-expirado',
  name: 'Link Expirado',
  Icon: <></>,
  disabled: false,
};
