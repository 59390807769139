import { Avatar, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  Collapse,
  Loading,
  Icon,
  Mask,
} from '@hapvida/hapvida-core-components';

import { EmptyResult } from '@components';
import { useFilterParams, useAuth } from '@hooks';
import {
  USER_STATUS_ICON_NAME,
  USER_STATUS_COLOR,
  USER_STATUS_NAME,
  USER_COMPANY_TYPE_NAME,
  ITEMS_LIMIT_PER_PAGE,
} from '@constants';
import { BackofficeUserDetailsRouter } from '@flows/private/backofficeUser/pages/BackofficeUserDetails/router';
import { useBackofficeUserState } from '@flows/private/backofficeUser/pages/BackofficeUserList/hooks/useBackofficeUserState';

import { ErrorLoading } from '../ErrorLoading';
import {
  backofficeUserListTableHeadColumns,
  BackofficeUserListColumnNameEnum,
} from '../../constants';

export function BackofficeUserListCardTable() {
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();
  const { backofficeUserState, refreshBackofficeUserList } =
    useBackofficeUserState();
  const { user: loggedUser } = useAuth();
  const navigate = useNavigate();

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleEditUser = (id: string) => {
    navigate(BackofficeUserDetailsRouter.dynamicPath({ id }));
  };

  if (backofficeUserState.isLoading) {
    return (
      <Loading
        padding={5}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (backofficeUserState.isError) {
    return <ErrorLoading onReloadClick={refreshBackofficeUserList} />;
  }

  const totalUsersLength = backofficeUserState.data.total ?? 0;
  const isEmptyUsers = totalUsersLength === 0;

  if (isEmptyUsers) {
    return (
      <EmptyResult
        message={`${
          loggedUser?.name ?? ''
        }, você ainda não tem nenhum usuário backoffice cadastrado.`}
      />
    );
  }

  const userListTableRows = backofficeUserState.data?.users?.map(user => ({
    id: user.id,
    main: {
      [BackofficeUserListColumnNameEnum.PERSONAL_DATA]: (
        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar
            alt={user?.name}
            src={user?.name}
            sx={{ width: 32, height: 32 }}
          />
          <Stack>
            <Typography variant="subtitle1">{user?.name}</Typography>
            {user?.document && (
              <Typography variant="body2">
                {Mask.cpf(user?.document) ?? '-'}
              </Typography>
            )}
          </Stack>
        </Stack>
      ),
      [BackofficeUserListColumnNameEnum.COMPANY_TYPE]: (
        <Typography fontWeight={700}>
          {USER_COMPANY_TYPE_NAME[user.companyType]}
        </Typography>
      ),
      [BackofficeUserListColumnNameEnum.EMAIL]: (
        <Typography overflow="hidden" textOverflow="ellipsis">
          {user.email}
        </Typography>
      ),
      [BackofficeUserListColumnNameEnum.STATUS]: (
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon
            name={USER_STATUS_ICON_NAME[user.status]}
            color={USER_STATUS_COLOR[user.status]}
            size={20}
          />
          <Typography color={USER_STATUS_COLOR[user.status]} fontWeight={600}>
            {user.status ? USER_STATUS_NAME[user.status] : '-'}
          </Typography>
        </Stack>
      ),
    },
    dependents: [],
  }));

  const viewedUsersLength =
    ITEMS_LIMIT_PER_PAGE * (currentPage - 1) +
    backofficeUserState.data.totalPerPage;
  const hasMultiPages = totalUsersLength > ITEMS_LIMIT_PER_PAGE;

  return (
    <Stack>
      <Collapse
        TableHeadColumns={backofficeUserListTableHeadColumns}
        count={Math.ceil(totalUsersLength / ITEMS_LIMIT_PER_PAGE)}
        onOrderChange={handleTableOrderChange}
        onPageChange={handleChangePage}
        showFooter={hasMultiPages}
        currentPage={currentPage}
        data={userListTableRows}
        from={viewedUsersLength}
        onEdit={handleEditUser}
        tableOrder={tableOrder}
        to={totalUsersLength}
        baseTableProps={{
          tableContainerHeight: '100%',
          style: {
            padding: 0,
          },
        }}
        hasCellAction
      />
    </Stack>
  );
}
