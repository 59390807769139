import * as Yup from 'yup';

export const schema = Yup.object({
  password: Yup.string()
    .required('')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Não é seguro, use no mínimo 8 caracteres, letras maiúscula e minúscula, números e símbolos.',
    ),
  passwordConfirmation: Yup.string()
    .required('Confirmação de nova senha inválida.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Confirmação de nova senha inválida.',
    )
    .test(
      'password-matching',
      'Confirmação de nova senha inválida.',
      function confirmedPassword(valuePassword) {
        return this.parent.password === valuePassword;
      },
    ),
});
