import { BaseTable, NoSearchResults } from '@hapvida/hapvida-core-components';

import { MovementStatusEnum } from '@constants';
import { useFilterParams } from '@hooks';

import { useMovementDetailsState } from '../../../../hooks';
import { movementDetailsTableHead } from './constants';
import { MovementBeneficiaryRow } from './components';
import { useMovementDetails } from '../../hooks';

interface MovementBeneficiariesListProps {
  movementStatus: MovementStatusEnum;
  total: number;
}

export function MovementBeneficiariesList({
  movementStatus,
  total,
}: MovementBeneficiariesListProps) {
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();
  const { movementLivesState } = useMovementDetailsState();

  const { beneficiaries = [] } = movementLivesState.data ?? {};

  const { handleCancelMovementSolicitationModal } = useMovementDetails();

  if (total === 0) return <NoSearchResults padding={3} />;

  return (
    <BaseTable
      from={(currentPage - 1) * 10 + beneficiaries.length}
      onPageChange={(_, page) => handlePageChange(page)}
      TableHeadColumns={movementDetailsTableHead}
      onOrderChange={handleTableOrderChange}
      count={Math.ceil(total / 10)}
      tableContainerHeight="100%"
      currentPage={currentPage}
      tableOrder={tableOrder}
      style={{ padding: 0 }}
      to={total ?? 0}
    >
      {beneficiaries.map(el => (
        <MovementBeneficiaryRow
          cancelSolicitationMovement={handleCancelMovementSolicitationModal}
          columnsIsCancelling={movementDetailsTableHead?.length}
          movementStatus={movementStatus}
          key={el.user.id}
          data={el}
        />
      ))}
    </BaseTable>
  );
}
