import * as Yup from 'yup';

import { validateCPF } from '@utils';

export const schema = Yup.object({
  document: Yup.string()
    .min(1, '')
    .required()
    .test(
      'cpf-validation',
      'Número de CPF inválido. Digite novamente.',
      val => {
        if (val) {
          return validateCPF(val);
        }
        return true;
      },
    )
    .default(''),
});
