import { Typography } from '@hapvida/hapvida-core-components';
import { styled as muiStyled } from '@mui/material';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

import { COLORS } from '../../themes/colors';

interface WrapperProps {
  tablecontainerwidth: string;
}

interface StyledTableContainerProps {
  tablecontainerheight: number | string;
}

interface TableProps {
  width: string;
  minwidth: string;
  tablelayout: 'fixed' | 'auto';
}

export const Wrapper = muiStyled(Paper)<WrapperProps>(
  ({ tablecontainerwidth }) => ({
    padding: '16px',
    width: tablecontainerwidth,

    borderRadius: '8px',
  }),
);

export const StyledTableContainer = muiStyled(
  TableContainer,
)<StyledTableContainerProps>(({ tablecontainerheight }) => ({
  overflow: 'auto',

  background: COLORS.WHITE,

  maxHeight: tablecontainerheight,
}));

export const StyledTable = muiStyled(Table)<TableProps>(
  ({ width, minwidth, tablelayout }) => ({
    width,
    minWidth: minwidth,
    tableLayout: tablelayout,
    borderCollapse: 'collapse',
  }),
);

export const StyledTableHead = muiStyled(TableHead)(() => ({
  top: 0,
  zIndex: 999,
  position: 'sticky',
  background: COLORS.WHITE,
}));

export const StyledTableRow = muiStyled(TableRow)(() => ({
  td: {
    borderTop: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
    borderBottom: `1px solid ${COLORS.MONOCHROMATIC.GRAY2}`,
  },
}));

export const TotalItemsLabel = muiStyled(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',

  color: '#767C81',
}));
