import { Stack, css, keyframes, styled } from '@mui/material';
import { COLORS } from '../../themes';

const pulseBefore = keyframes`
  from {
    height: 50%;
    width: 50%;
  }
  to {
    height: 0;
    width: 0;
  }
`;

const pulseAfter = keyframes`
  from {
    opacity: 0.6;
    height: 50%;
    width: 50%;
  }
  to {
    height: 100%;
    width: 100%;
    opacity: 0;
  }
`;

export interface PulsarAnimationProps {
  height?: string | number;
  width?: string | number;
}

export const PulsarAnimation = styled(Stack)<PulsarAnimationProps>`
  position: relative;

  ${({ width, height }) => css`
    height: ${height ?? '70px'};
    width: ${width ?? '70px'};
  `}

  &:before {
    animation: ${pulseBefore} 0.8s linear infinite alternate;
    background-color: ${COLORS.PRIMARY.ORANGE['-1']};
    border-radius: 50px;
    margin: auto;
    content: '';
  }

  &:after {
    background-color: ${COLORS.PRIMARY.ORANGE['-1']};
    animation: ${pulseAfter} 1.6s ease infinite;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
  }
`;
