import { Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export const Text = muiStyled(Typography)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,

  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
}));
