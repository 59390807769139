const affiliateDataMock = {
  content: [
    {
      id: 'jdcdb4ea-a201-407c-94ce-aa099edde249',
      contractNumber: 'ABC123',
      nuControl: '1',
      unityCode: '1',
      company: 'Hapvida',
      name: {
        trade: 'string',
        legal: 'string',
      },
      document: '01610798001470',
      backOffice: {
        name: 'Tatiana Utrera backoffice',
        document: '59050880045',
        profile: 'Adm',
        isCompany: false,
        isCancelled: false,
      },
      type: 'Affiliate',
      totalBeneficiaries: 118,
      financialSituationType: 'Defaulting',
      effectiveDate: '2023-05-17T15:16:25.12',
    },
    {
      id: 'kdcdb4ea-a201-407c-94ce-aa099edde249',
      contractNumber: 'ABC123',
      nuControl: '2',
      unityCode: '2',
      company: 'Hapvida',
      name: {
        trade: 'string',
        legal: 'string',
      },
      document: '01610798001470',
      backOffice: {
        name: 'Tatiana Utrera backoffice',
        document: '59050880045',
        profile: 'Adm',
        isCompany: false,
        isCancelled: false,
      },
      type: 'Affiliate',
      totalBeneficiaries: 118,
      financialSituationType: 'Defaulting',
      effectiveDate: '2023-05-17T15:16:25.12',
    },
    {
      id: 'jdcdb4ea-a201-407c-94ce-aa099edde249',
      contractNumber: 'ABC123',
      nuControl: '1',
      unityCode: '1',
      company: 'Hapvida',
      name: {
        trade: 'string',
        legal: 'string',
      },
      document: '01610798001470',
      backOffice: {
        name: 'Tatiana Utrera backoffice',
        document: '59050880045',
        profile: 'Adm',
        isCompany: false,
        isCancelled: false,
      },
      type: 'Affiliate',
      totalBeneficiaries: 118,
      financialSituationType: 'Defaulting',
      effectiveDate: '2023-05-17T15:16:25.12',
    },
    {
      id: 'kdcdb4ea-a201-407c-94ce-aa099edde249',
      contractNumber: 'ABC123',
      nuControl: '2',
      unityCode: '2',
      company: 'Hapvida',
      name: {
        trade: 'string',
        legal: 'string',
      },
      document: '01610798001470',
      backOffice: {
        name: 'Tatiana Utrera backoffice',
        document: '59050880045',
        profile: 'Adm',
        isCompany: false,
        isCancelled: false,
      },
      type: 'Affiliate',
      totalBeneficiaries: 118,
      financialSituationType: 'Defaulting',
      effectiveDate: '2023-05-17T15:16:25.12',
    },
  ],
};

export default affiliateDataMock;
