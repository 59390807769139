import React from 'react';

import { Button, ErrorPage } from '@hapvida/hapvida-core-components';

import { ReactComponent as SessionExpiredImage } from '@assets/images/session-expired-error.svg';

import { ButtonWrapper, Description } from './styles';

export const LinkExpired: React.FC = () => {
  return (
    <ErrorPage
      svg={<SessionExpiredImage />}
      title="Oops! Parece que seu link expirou."
      imageAlt="Erro 500, servidor indisponível (erro interno)"
      description={
        <>
          <Description>
            Seu link de acesso ao Portal Empresas expirou.
            <br />
            Entre em contato com seu corretor
            <strong> e peça um novo endereço de acesso.</strong>
          </Description>
          <ButtonWrapper>
            <a href={process.env.REACT_APP_LOGIN_URL}>
              <Button variant="contained" color="secondary">
                Voltar para página de login
              </Button>
            </a>
          </ButtonWrapper>
        </>
      }
      loginRoute={process.env.REACT_APP_LOGIN_URL}
    />
  );
};
