import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ReactNode } from 'react';

import {
  CheckboxBody,
  Company,
  Mask,
  Pill,
} from '@hapvida/hapvida-core-components';

import {
  BillListProps,
  BillStatus,
  BillType,
} from '@services/FinancialService/dtos/BillsListDTO';
import FinancialService, {
  InvoiceProps,
  FileData,
} from '@services/FinancialService';

import { useFilterParams, useScreenLoading, useAuth } from '@hooks';
import { BillDetailRouter } from '@flows/private/financial/pages/Detail/router';
import Identifier from '@flows/private/financial/components/Identifier';
import SimpleText from '@flows/private/financial/components/SimpleText';
import { useBill } from '@flows/private/financial/hooks';
import {
  BillsListSortNameEnum,
  BillsListColumns,
} from '@flows/private/financial/constants/columns';
import { HealthOperatorEnum, ITEMS_LIMIT_PER_PAGE } from '@constants';

interface BillTableRow {
  main: Record<BillsListSortNameEnum, ReactNode | string>;
  id: string;
}

interface BillsListCardProps {
  billsList: BillListProps;
}

export function BillsListCard({ billsList }: BillsListCardProps) {
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();
  const { getBillFile, getCoparticipationReportFiles } = useBill();
  const { onScreenLoading } = useScreenLoading();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { selectedCompany } = useAuth();

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleOpenFile = (file?: FileData | InvoiceProps) => {
    if (file?.path) window.open(file.path, '_blank');
  };

  const handleOpenFiles = (files?: FileData[]) => {
    files?.forEach(file => handleOpenFile(file));
  };

  const handleDownload = async (billId: string) => {
    const currentBill = billsList.bills.find(
      ({ billingNumber }) => `${billId}` === `${billingNumber}`,
    );

    if (currentBill) {
      onScreenLoading(true);
      const billFile = await getBillFile(currentBill);
      handleOpenFile(billFile);

      handleOpenFile(currentBill.invoice);

      if (currentBill.coparticipationAnalytical.length > 0) {
        const coparticipationReportFiles = await getCoparticipationReportFiles(
          currentBill,
        );
        handleOpenFiles(coparticipationReportFiles);
      }

      if (currentBill.analyticalStatements) {
        const service = new FinancialService();
        const dataContentAnalytical = await Promise.all(
          currentBill.analyticalStatements.map(async item => {
            const data = await service.getNdiFile({
              companyId: selectedCompany?.id,
              fileName: item.fileName,
            });
            return {
              path: data,
              fileName: item.fileName,
              type: HealthOperatorEnum.NDI,
            };
          }),
        );
        handleOpenFiles(dataContentAnalytical);
      }

      onScreenLoading(false);
    } else {
      enqueueSnackbar(`Fatura não encontrada.`, {
        variant: 'warning',
      });
    }
  };

  const handleDownloadAll = async (billListSelected: string[]) => {
    onScreenLoading(true);
    const selectedBills = billsList.bills.filter(({ billingNumber }) =>
      billListSelected.includes(billingNumber),
    );

    const filterCoparticipationAnalyticalResult = selectedBills
      .filter(({ coparticipationAnalytical }) => coparticipationAnalytical)
      .map(({ coparticipationAnalytical }) => coparticipationAnalytical)
      .flat();

    const filterMonthlyAnalyticalReportResult = selectedBills
      .filter(({ analyticalStatements }) => analyticalStatements)
      .map(({ analyticalStatements }) => analyticalStatements)
      .flat();

    const [personNumberResult] = billsList.bills.map(
      ({ personNumber }) => personNumber,
    );

    if (filterCoparticipationAnalyticalResult && personNumberResult) {
      const service = new FinancialService();
      const dataContentCoparticipationAnalyticalAll = await Promise.all(
        filterCoparticipationAnalyticalResult.map(async item => {
          const data = await service.getCoPaymentReport({
            personNumber: personNumberResult,
            billingExtractId: item.billingExtractId,
            fileName: item.fileName,
          });
          return {
            path: data,
            fileName: item.fileName,
            type: HealthOperatorEnum.HAPVIDA,
          };
        }),
      );

      handleOpenFiles(dataContentCoparticipationAnalyticalAll);
    }

    if (filterMonthlyAnalyticalReportResult) {
      const service = new FinancialService();
      const dataContentMonthlyAnalyticalReportAll = await Promise.all(
        filterMonthlyAnalyticalReportResult.map(async item => {
          const data = await service.getNdiFile({
            companyId: selectedCompany?.id,
            fileName: item?.fileName,
          });
          return {
            path: data,
            fileName: item?.fileName,
            type: HealthOperatorEnum.NDI,
          };
        }),
      );
      handleOpenFiles(dataContentMonthlyAnalyticalReportAll);
    }

    const result = await Promise.all(
      selectedBills.map(async bill => {
        const billFile = await getBillFile(bill);
        return billFile;
      }),
    );

    result.forEach(billFile => {
      handleOpenFile(billFile);
    });

    selectedBills.forEach(bill => {
      handleOpenFile(bill.invoice);
    });

    onScreenLoading(false);
  };

  const handleDetail = (billId: string) => {
    const currentBill = billsList.bills.find(
      bill => Number(billId) === Number(bill.billingNumber),
    )!;

    navigate(
      `${BillDetailRouter.dynamicPath({
        billNumber: currentBill.number,
      })}?company=${currentBill.companyId}&year=${currentBill.year}`,
    );
  };

  const convertBillMonthToCase = (billMonth?: string) => {
    if (billMonth) {
      return (
        billMonth.charAt(0).toUpperCase() + billMonth.toLowerCase().slice(1)
      );
    }
    return '-';
  };

  const billTableRows: BillTableRow[] = billsList.bills.map(bill => {
    const contractNumber = bill.partnerCompany;

    const main = {
      [BillsListSortNameEnum.NUMBER]: <Identifier bill={bill} />,
      [BillsListSortNameEnum.PARTNER_COMPANY]: (
        <SimpleText text={contractNumber} />
      ),
      [BillsListSortNameEnum.DUE_DATE]: (
        <SimpleText text={new Date(bill?.dueDate).toLocaleDateString()} />
      ),

      [BillsListSortNameEnum.MONTH]: (
        <SimpleText text={convertBillMonthToCase(bill?.month)} />
      ),

      [BillsListSortNameEnum.AMOUNT]: (
        <SimpleText
          text={Mask.currency(bill?.amount).toString()}
          fontWeight={700}
        />
      ),
      [BillsListSortNameEnum.TYPE]: BillType[bill?.billType],
      [BillsListSortNameEnum.STATUS]: (
        <Pill
          text={BillStatus[bill.status]?.label}
          color={BillStatus[bill.status]?.color}
          fitContent
        />
      ),
      [BillsListSortNameEnum.HEALTH_OPERATOR]: (
        <Company company={bill.healthOperator} />
      ),
    };

    return {
      id: String(bill.billingNumber),
      main,
    };
  });

  return (
    <CheckboxBody
      from={Math.floor(
        billsList.bills.length % ((currentPage + 1) * ITEMS_LIMIT_PER_PAGE),
      )}
      checkAllLabel="Selecionar todas as faturas"
      onOrderChange={handleTableOrderChange}
      count={Math.ceil(billsList.total / ITEMS_LIMIT_PER_PAGE)}
      allSelectedAction={handleDownloadAll}
      baseTableProps={{
        tableContainerHeight: '100%',
      }}
      onPageChange={handleChangePage}
      onDownload={handleDownload}
      components={billTableRows}
      columns={BillsListColumns}
      currentPage={currentPage}
      tableOrder={tableOrder}
      onDetail={handleDetail}
      footerLabel="faturas"
      to={billsList.total}
    />
  );
}
