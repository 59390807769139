import { Avatar, Badge, ButtonBase, styled } from '@mui/material';

export const CircularButton = styled(ButtonBase)(() => ({
  borderRadius: '50%',
}));

export const StyledAvatar = styled(Avatar)(({ theme: { palette } }) => ({
  padding: 16,
  border: `2px solid ${palette.primary.dark}`,
}));

export const StyledBadge = styled(Badge)(({ theme: { palette } }) => ({
  '& .MuiBadge-badge': {
    width: 28,
    height: 28,
    borderRadius: '50%',
    border: `${palette.grey[100]} solid 3px`,
  },
}));
