import { useCallback, useMemo } from 'react';

import { CompanyStatusEnum } from '@constants';
import { useAuth } from '@hooks';
import { RouteProps, SubRouteProps } from 'routes/types';
import { MENU_LIST } from 'templates/private/components/Sidebar/components/SidebarRouteList/constants';

import { ROUTES } from './constants';

export function useRoutes() {
  const { selectedCompany, user, selectedPartnerCompany } = useAuth();

  const disabledRoutes = useMemo(() => {
    const isCompanyActive =
      selectedCompany?.status === CompanyStatusEnum.ACTIVE;
    if (isCompanyActive) {
      return [];
    }

    const isCompanyPendingAccess =
      selectedCompany?.status === CompanyStatusEnum.PENDING_ACCESS;
    if (isCompanyPendingAccess) {
      return ROUTES.companyPendingAccessDisabled;
    }

    return ROUTES.allMenu;
  }, [selectedCompany?.status]);

  const handleRouteDisabled = useCallback(
    (route?: RouteProps | SubRouteProps): boolean => {
      if (!route?.path) return false;

      const { path, disabled = false } = route;
      const hasDisabledRoutes = disabledRoutes?.some(item => item === path);
      if (hasDisabledRoutes || disabled) {
        return true;
      }

      return false;
    },
    [disabledRoutes],
  );

  const filteredGroupRoutes = useMemo(() => {
    if (!selectedCompany || !selectedPartnerCompany) {
      return [];
    }

    const availableResources = selectedPartnerCompany.profile?.resources ?? {};

    const permittedRoutes = MENU_LIST.filter(
      ({ profileResourceEnum }) => availableResources[profileResourceEnum],
    );

    return permittedRoutes;
  }, [user?.companyType, selectedCompany, selectedPartnerCompany]);

  return { handleRouteDisabled, filteredGroupRoutes };
}
