import { Stack, Typography } from '@mui/material';

import { IconName } from '../../../Icon/types';
import { Pill } from '../../../Pill';
import Icon from '../../../Icon';
import { Label } from './styles';

export interface SummaryItemProps {
  key: string | number;
  iconName?: IconName;
  color?: string;
  label: string;
}

interface SummaryRowItemProps {
  summary: SummaryItemProps;
  pillColor?: boolean;
  value: string;
}

export function SummaryRowItem({
  pillColor,
  summary,
  value,
}: Readonly<SummaryRowItemProps>) {
  const { label, color, iconName } = summary;

  return (
    <Stack gap={2} alignItems="center">
      <Typography variant="h6">{value}</Typography>

      <Stack direction="row" alignItems="center" gap={1}>
        {iconName && <Icon name={iconName} color={color} size={24} />}
        {pillColor ? (
          <Pill text={label} color={color} />
        ) : (
          <Label variant="caption" color={color}>
            {label}
          </Label>
        )}
      </Stack>
    </Stack>
  );
}
