import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import FinancialService from '@services/FinancialService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';

export function useDiscountLetters() {
  const { enqueueSnackbar } = useSnackbar();
  const { invoiceNumber } = useParams();
  const { selectedCompany } = useAuth();

  const financialService = useMemo(() => new FinancialService(), []);

  const companyId = selectedCompany?.id ?? '';

  const discountLettersState = useQuery({
    queryKey: ['discountLettersState'],
    queryFn: () =>
      financialService.getDiscountLetters({
        companyId,
        invoiceNumber: Number(invoiceNumber),
      }),
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const refreshDiscountLetters = () => {
    discountLettersState.remove();
    discountLettersState.refetch();
  };

  return {
    refreshDiscountLetters,
    discountLettersState,
  };
}
