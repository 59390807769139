import { Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

import { IconName } from '@hapvida/hapvida-core-components/src/components/Icon/types';

import { useBillState } from '@flows/private/financial/pages/Detail/hooks/useBillState';
import { PrintDiscountRouter } from '@flows/private/financial/pages/PrintDiscount/router';

import { PrintDiscountModal } from '../PrintDiscountModal';
import { DiscountContainer } from './styles';

interface ButtonLinkProps {
  iconName?: IconName;
}

export function DiscountLink({
  iconName = 'file-check',
}: Readonly<ButtonLinkProps>) {
  const { id } = useParams();
  const { discountLettersState, billDetailsState } = useBillState();
  const discountLetters = discountLettersState.data ?? [];
  const lettersLength = discountLetters.length;

  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => setOpenModal(prev => !prev);

  const invoiceNumber = billDetailsState.data?.invoice?.number;

  const handleNavigate = (letterNumber?: string) => {
    return window.open(
      PrintDiscountRouter.dynamicPath({
        billNumber: id,
        letterNumber,
        invoiceNumber,
      }),
      '_blank',
    );
  };

  const handleOpenTabs = () => {
    let blocked = false;

    discountLetters.forEach(letter => {
      const newWindow = handleNavigate(letter.letterNumber);
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === 'undefined'
      ) {
        blocked = true;
      }
    });

    if (blocked) {
      // eslint-disable-next-line
      alert(
        'Seu navegador bloqueou a abertura de múltiplas abas. Por favor, permita pop-ups para continuar.',
      );
    }
  };

  const printDiscountLetter = (letterNumber?: string) => {
    if (letterNumber) {
      handleNavigate(letterNumber);
      return;
    }

    handleOpenTabs();
  };

  const handlePrint = () => {
    if (lettersLength > 1) {
      toggleModal();
      return;
    }
    printDiscountLetter(discountLetters[0].letterNumber);
  };

  if (!lettersLength) return <Typography variant="body2">-</Typography>;

  return (
    <>
      <DiscountContainer onClick={handlePrint}>
        <Icon name={iconName} size={16} color={COLORS.PRIMARY.BLUE.MAIN} />
        <Typography
          color={COLORS.PRIMARY.BLUE['-1']}
          variant="smallText"
          fontWeight={700}
        >
          CartaDeDesconto.pdf
        </Typography>
        <Typography variant="smallText" color={COLORS.PRIMARY.ORANGE['+1']}>
          ({lettersLength > 1 ? `${lettersLength} arquivos` : '1 arquivo'})
        </Typography>
      </DiscountContainer>
      <PrintDiscountModal
        openModal={openModal}
        toggleModal={toggleModal}
        onPrint={printDiscountLetter}
      />
    </>
  );
}
