import { getUpdatedItems } from './getUpdatedItems';
import { MainPartnerCompany } from './types';

export const getMainPartnerCompany = ({
  mergedPartnerCompanies,
  editedPartnerCompanies,
}: MainPartnerCompany) => {
  const editedMainPartnerCompanies = editedPartnerCompanies.filter(
    editedItem => editedItem.isMainPartnerCompany,
  );

  return mergedPartnerCompanies.map(updatedItem =>
    getUpdatedItems({ updatedItem, editedMainPartnerCompanies }),
  );
};
