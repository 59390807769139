import { styled as muiStyled } from '@mui/material';

import { Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

interface StyledTypographyProps {
  error: string;
}

export const List = muiStyled('ul')(() => ({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  color: COLORS.MONOCHROMATIC.GRAY4,

  marginBottom: '.4rem',
}));

export const ErrorWrapper = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTypography = muiStyled(Typography)<StyledTypographyProps>(
  ({ error = 'false' }) => ({
    fontWeight: '600',
    fontSize: '14px',
    color:
      error === 'true'
        ? COLORS.SECONDARY.RED.MAIN
        : COLORS.SECONDARY.GREEN.MAIN,
    paddingLeft: '.2rem',
  }),
);

export const Container = muiStyled('div')(() => ({
  transform: 'translateY(-15px)',
  paddingLeft: '1px',
}));
