import { Stack, Typography } from '@mui/material';

import { COLORS, Icon } from '@hapvida/hapvida-core-components';

export function MainPartnerCompanyAlert() {
  return (
    <Stack
      paddingX={2}
      paddingY={1}
      gap={2}
      flexDirection="row"
      alignItems="center"
      bgcolor="rgba(0, 84, 184, 0.04)"
    >
      <Icon name="alert-circle" color={COLORS.PRIMARY.BLUE.MAIN} />
      <Typography variant="smallText">
        Ao selecionar o contrato mãe, automaticamente serão selecionados todos
        os contratos filhos que estão abaixo dessa mãe.
      </Typography>
    </Stack>
  );
}
