import React from 'react';

import PasswordDefinitionStep from './steps/PasswordDefinition/step';
import RegistrationCompletedStep from './steps/RegistrationCompleted/step';

export interface IPasswordDefinitionStep {
  name?: string;
  step(props: any): React.ReactElement;
}

export const steps: Array<IPasswordDefinitionStep> = [
  PasswordDefinitionStep,
  RegistrationCompletedStep,
];
