import { Icon } from '@hapvida/hapvida-core-components';

import { IAppRoute } from 'routes/types';

import { CreateBackofficeUserPage } from '.';

export const CreateBackofficeUserRouter: IAppRoute = {
  Page: CreateBackofficeUserPage,
  path: '/usuarios-backoffice/novo',
  name: 'Novo usuário backoffice',
  Icon: <Icon name="account-multiple" />,
  isPrivate: true,
  disabled: false,
};
