import { BillStateProvider } from './hooks';
import { BillLayout } from './layout';

export function BillDetail() {
  return (
    <BillStateProvider>
      <BillLayout />
    </BillStateProvider>
  );
}
