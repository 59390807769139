const invoicesMock = {
  content: {
    totalInvoices: 3801,
    totalPaid: 3987890.88,
    totalRemaining: 30879.9,
    invoices: [
      {
        id: '1',
        invoiceNumber: '167894563878',
        dueDate: '2022-06-10T10:36:38.666',
        value: 100000,
        invoiceType: 'Fatura mensal',
        company: 'Hapvida',
        document: '000000000001',
        status: 'paidOut',
      },
      {
        id: '2',
        invoiceNumber: '167894563878',
        dueDate: '2022-06-10T10:36:38.666',
        value: 20000.01,
        invoiceType: 'Fatura mensal',
        company: 'NDI',
        document: '000000000002',
        status: 'opened',
      },
      {
        id: '3',
        invoiceNumber: '167894563878',
        dueDate: '2022-06-10T10:36:38.666',
        value: 30000,
        invoiceType: 'Fatura mensal',
        company: 'NDI',
        document: '000000000003',
        status: 'partialWriteOff',
      },
      {
        id: '4',
        invoiceNumber: '167894563878',
        dueDate: '2022-06-10T10:36:38.666',
        value: 400000,
        invoiceType: 'Fatura mensal',
        company: 'Hapvida',
        document: '000000000004',
        status: 'canceled',
      },
      {
        id: '5',
        invoiceNumber: '167894563878',
        dueDate: '2022-06-10T10:36:38.666',
        value: 5000,
        invoiceType: 'Fatura mensal',
        company: 'NDI',
        document: '000000000005',
        status: 'renegotiated',
      },
    ],
  },
};

export default invoicesMock;
