import { useInfiniteQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import MovimentationService, {
  MovementShippingReviewsProps,
} from '@services/MovimentationService';

import { toastifyApiErrors } from '@utils';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

import { MovementShippingReviewsContentProps } from '../../types';

export function useMovementShippingReviews({
  movementId,
  companyId,
}: MovementShippingReviewsContentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const movementService = useMemo(() => new MovimentationService(), []);

  const movementShippingReviewsState =
    useInfiniteQuery<MovementShippingReviewsProps>({
      queryKey: ['movementShippingReviewsState', companyId, movementId],
      queryFn: ({ pageParam = 1 }) =>
        movementService.getMovementShippingReviews({
          companyId,
          movementId,
          offset: ITEMS_LIMIT_PER_PAGE * (pageParam - 1),
          limit: ITEMS_LIMIT_PER_PAGE,
        }),
      getNextPageParam: (lastPage, allPages) => {
        const companiesHasNextPage =
          lastPage.total > allPages.length * ITEMS_LIMIT_PER_PAGE;

        if (companiesHasNextPage) {
          return allPages.length + 1;
        }

        return undefined;
      },
      onError: (error: unknown) => {
        toastifyApiErrors(error, enqueueSnackbar);
      },
      enabled: Boolean(companyId) && Boolean(movementId),
    });

  return {
    movementShippingReviewsState,
  };
}
