import { styled as muiStyled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { COLORS } from '@hapvida/hapvida-core-components';

export const DateText = muiStyled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '14px',
  color: COLORS.MONOCHROMATIC.GRAY4,
}));

export const FieldsWrapper = muiStyled(Box)(() => ({
  display: 'flex',
}));

export const FileDiv = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  color: COLORS.PRIMARY.BLUE.MAIN,
  '& .MuiTypography-root': {
    color: COLORS.PRIMARY.BLUE.MAIN,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    margin: '2px 0 0 0.5rem',
  },
}));
