import { DrawerFilterProvider } from '@hapvida/hapvida-core-components';

import { BackofficeUserListLayout } from './layout';

export function BackofficeUserListPage() {
  return (
    <DrawerFilterProvider>
      <BackofficeUserListLayout />
    </DrawerFilterProvider>
  );
}
