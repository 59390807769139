import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export enum CompanyTypeEnum {
  Affiliate = 'Affiliate',
  Company = 'Company',
  Related = 'Related',
}

export const CompanyTypeEnumName: Record<CompanyTypeEnum, string> = {
  [CompanyTypeEnum.Company]: 'Matriz',
  [CompanyTypeEnum.Affiliate]: 'Filial',
  [CompanyTypeEnum.Related]: 'Coligada',
};

export const CompanyTypeEnumNameTranslate: Record<string, string> = {
  Matriz: 'Company',
  Filial: 'Affiliate',
  Coligada: 'Related',
};

export const CompanyTypeEnumColor: Record<CompanyTypeEnum, string> = {
  [CompanyTypeEnum.Company]: COLORS.PRIMARY.BLUE.MAIN,
  [CompanyTypeEnum.Related]: COLORS.PRIMARY.ORANGE.MAIN,
  [CompanyTypeEnum.Affiliate]: COLORS.MONOCHROMATIC.GRAY1,
};

export const CompanyAndAffiliateTypeEnumName: Record<CompanyTypeEnum, string> =
  {
    [CompanyTypeEnum.Company]: 'Matriz',
    [CompanyTypeEnum.Affiliate]: 'Unidade',
    [CompanyTypeEnum.Related]: 'Coligada',
  };
