import Icon from '../../../../Icon';
import { COLORS } from '../../../../../themes/colors';

import {
  StyledActionCell,
  ActionBox,
  CollapsedButton,
  EditButton,
} from './styles';

interface ActionCellProps {
  collapsed?: boolean;
  showCollapseButton: boolean;

  itHasError?: boolean;
  itHasErrorMessage: boolean;

  onEdit(): void;
  onCollapse: () => void;
}

const ActionCell = ({
  showCollapseButton,
  collapsed = false,
  itHasError,
  itHasErrorMessage,
  onEdit,
  onCollapse,
}: ActionCellProps) => {
  return (
    <StyledActionCell
      collapsed={collapsed ? collapsed.toString() : 'false'}
      ithaserror={itHasError ? itHasError.toString() : 'false'}
      ithaserrormessage={
        itHasErrorMessage ? itHasErrorMessage.toString() : 'false'
      }
    >
      <ActionBox>
        {showCollapseButton && (
          <CollapsedButton onClick={onCollapse} disableRipple>
            <Icon
              name={collapsed ? 'chevron-up' : 'chevron-down'}
              color={COLORS.MONOCHROMATIC.GRAY4}
              size={24}
            />
          </CollapsedButton>
        )}
        {!itHasError && (
          <EditButton onClick={onEdit}>
            <Icon
              name="edit"
              size={24}
              style={{
                minWidth: '24px',
              }}
            />
          </EditButton>
        )}
      </ActionBox>
    </StyledActionCell>
  );
};

export default ActionCell;
