import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

import {
  Button,
  Form,
  Icon,
  Mask,
  SelectInput,
  TextInput,
  Typography,
} from '@hapvida/hapvida-core-components';

import { toastifyApiErrors } from '@utils';

import { useQuery } from '../../../../../../hooks/useQuery';
import { useScreenLoading } from '../../../../../../hooks/useScreenLoading';
import AuthService from '../../../../../../infra/services/AuthService';
import AddressService from '../../../../../../infra/services/AddressService';
import { Company } from '../../../../../../infra/services/AuthService/dtos/GetCompanyDetailDTO';
import { BrazilianStates } from '../../../../../../constants/brazilianStates';
import {
  REGISTER_STEPS_KEYS,
  REGISTER_STEPS_VALUES,
} from '../../constants/keys';
import { COMPANY_REGISTER } from '../../../../../../constants/sessionStorageKeys';
import CompanyInfo from './components/CompanyInfo';
import { schema } from './validation';
import { StyledForm } from './styles';

interface CompanyInfoForm {
  city: string;
  state: string;
  number: string;
  street: string;
  zipCode: string;
  complement?: string;
}

interface StartRegisterProps {
  onStepUpdate(step: number, data: any): void;
  setCompanyId: Function;
}

const StartRegisterLayout: React.FC<StartRegisterProps> = ({
  onStepUpdate,
  setCompanyId,
}) => {
  const loginPageUrl = process.env.REACT_APP_LOGIN_URL || '';

  const query = useQuery();
  const token = query.get('token');

  const { enqueueSnackbar } = useSnackbar();
  const { onScreenLoading } = useScreenLoading();

  const [company, setCompany] = useState<Company>();
  const [_stateCities, setStateCities] = useState<
    Array<{ key: string; value: string }>
  >([]);
  const [disableButton, setDisableButton] = useState(true);
  const [quotationId, setQuotationId] = useState('');

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<CompanyInfoForm>({
    mode: 'all',
    defaultValues: {
      city: '',
      state: '',
      number: '',
      zipCode: '',
      street: '',
      complement: '',
    },
    resolver: yupResolver(schema),
  });

  const stateValue = watch('state');

  const validateToken = useCallback(() => {
    try {
      if (!token) throw new Error('Token inválido');
    } catch (err) {
      window.location.replace(loginPageUrl);
    }
  }, [token]);

  const getCompanyInfo = async () => {
    try {
      if (token) {
        onScreenLoading(true);
        const authService = new AuthService();
        const { content } = await authService.getCompanyDetail(token);
        const sessionStorageData = JSON.parse(
          sessionStorage.getItem(COMPANY_REGISTER) ?? '{}',
        );

        setCompany(content.companyDetail);
        setQuotationId(content.quotationId);
        setCompanyId(content.companyDetail.id);

        sessionStorage.setItem(
          COMPANY_REGISTER,
          JSON.stringify({
            ...sessionStorageData,
            companyId: content.companyDetail.id,
          }),
        );

        sessionStorage.setItem(
          COMPANY_REGISTER,
          JSON.stringify({
            ...sessionStorageData,
            companyId: content.companyDetail.id,
          }),
        );

        setValue('state', content.companyDetail.address.state);
        setValue('city', content.companyDetail.address.city);

        onScreenLoading(false);
      } else {
        throw new Error('Token inválido');
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      window.location.replace(loginPageUrl);
    }
  };

  useEffect(() => {
    validateToken();
    getCompanyInfo();
  }, []);

  useEffect(() => {
    if (isValid) setDisableButton(false);
    else setDisableButton(true);
  }, [isValid]);

  const handleStateChange = useCallback(
    async (state: string) => {
      try {
        onScreenLoading(true);

        setStateCities([]);

        if (state === 'UF') {
          return;
        }

        const addressService = new AddressService();

        const response = await addressService.fetchStateCities({
          state,
        });

        const cities = response?.content?.map(el => ({
          key: el.nmCidade,
          value: el.nmCidade,
        }));

        setStateCities(cities);

        onScreenLoading(false);
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (stateValue) {
      handleStateChange(stateValue);
    }
  }, [stateValue]);

  const onSubmit: SubmitHandler<CompanyInfoForm> = data => {
    const step = {
      address: data,
      quotationId,
    };

    onStepUpdate(REGISTER_STEPS_VALUES[REGISTER_STEPS_KEYS.COMPANY_INFO], step);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Form title="Informações da empresa">
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Typography variant="text">
              Complete as informações para finalizar o cadastro da sua empresa
            </Typography>
          </Grid>

          <Grid item sm={12}>
            <CompanyInfo
              name={company?.name.legal}
              document={company?.document}
              fantasyName={company?.name.trade}
              // logo={company.logo}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="zipCode"
              label="CEP"
              placeholder="Digite"
              control={control}
              mask={Mask.cep}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={10}>
            <TextInput
              name="street"
              label="Endereço"
              placeholder="Digite"
              control={control}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={2}>
            <TextInput
              name="number"
              label="Número"
              placeholder="Digite"
              control={control}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={5}>
            <SelectInput
              name="state"
              label="UF"
              placeholder="Selecione"
              options={BrazilianStates?.map(state => ({
                key: state.name,
                value: state.acronomy,
              }))}
              control={control}
              required
            />
          </Grid>

          <Grid item xs={5}>
            <TextInput
              name="city"
              label="Cidade"
              placeholder="Cidade"
              control={control}
              required
            />
          </Grid>

          <Grid item xs={2}>
            <TextInput
              name="complement"
              label="Complemento"
              placeholder="Digite"
              control={control}
              fullWidth
            />
          </Grid>

          <Grid item container justifyContent="flex-end">
            <Button
              type="submit"
              color="success"
              variant="contained"
              disabled={disableButton}
              endIcon={<Icon name="chevron-right" size={16} />}
            >
              Proxima etapa
            </Button>
          </Grid>
        </Grid>
      </Form>
    </StyledForm>
  );
};

export default StartRegisterLayout;
