import { Stack, Typography } from '@mui/material';

import { Mask, Pill } from '@hapvida/hapvida-core-components';

import { PARTNER_COMPANIES_STATUS_DATA } from '@services/PartnerCompanyService/dtos';

import { formatDateDay } from '@utils';

import { LinkRedirect } from '../styles';
import type { GetRowListProps } from './types';

export const getRowList = ({
  company,
  handleCompanyListRedirect,
}: GetRowListProps) => {
  const { document = '', name, status, dueDate, cutoffDate } = company;
  const { legal = '', trade = '' } = name;

  return [
    {
      label: 'Nome fantasia',
      xs: 3,
      value: <Typography variant="text">{trade}</Typography>,
    },
    {
      label: 'Razão social',
      xs: 3,
      value: <Typography variant="text">{legal}</Typography>,
    },
    {
      label: 'CNPJ',
      xs: 3,
      value: <Typography variant="text">{Mask.cnpj(document)}</Typography>,
    },
    {
      label: '',
      xs: 3,
      value: <></>,
    },
    {
      label: 'Data corte',
      xs: 3,
      value: (
        <Typography variant="text">
          {cutoffDate ? formatDateDay(cutoffDate) : '-'}
        </Typography>
      ),
    },
    {
      label: 'Data de vencimento',
      xs: 3,
      value: (
        <Typography variant="text">
          {dueDate ? formatDateDay(dueDate) : '-'}
        </Typography>
      ),
    },
    {
      label: 'Status do contrato',
      xs: 3,
      value: (
        <Pill
          color={PARTNER_COMPANIES_STATUS_DATA[status]?.color}
          text={PARTNER_COMPANIES_STATUS_DATA[status]?.text}
          fitContent
        />
      ),
    },
    {
      label: 'Unidades neste contrato',
      xs: 3,
      value: (
        <Stack flexDirection="row" gap={1}>
          <LinkRedirect variant="text" onClick={handleCompanyListRedirect}>
            Mostrar detalhes
          </LinkRedirect>
        </Stack>
      ),
    },
  ];
};
