import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { Filter, useDrawerFilter } from '../../hooks/useDrawerFilter';
import { Header, StyledCollapse, Wrapper } from './styles';
import { COLORS } from '../../themes/colors';
import { DrawerFilterProps } from './types';
import { FilterProvider } from './hooks';
import Icon from '../Icon';
import {
  SelectAllCheckbox,
  AppliedFilters,
  FilterContent,
  FilterLoading,
  SearchField,
} from './components';
import { updateFilters } from './utils';

export * from './types';

export default function DrawerFilter(
  drawerFilterProps: Readonly<DrawerFilterProps>,
) {
  const {
    filters,
    isMultiple = true,
    searchFieldProps,
    loadNextPage,
    filterKey,
    title,
    disabledSelectAll,
    gender,
    singularLabel,
    label,
  } = drawerFilterProps;

  const [hasMoreToLoad, setHasMoreToLoad] = useState(false);
  const [filtersState, setFiltersState] = useState<Filter[]>([]);
  const [searchFilters, setSearchFilters] = useState(filtersState);
  const {
    unregister,
    register,
    setActiveFilters,
    setInitialFilters,
    setProvFilters,
    initialFilters,
  } = useDrawerFilter();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);

  const hasSearchField = Boolean(searchFieldProps);
  const searchFieldIsEmpty = searchText.length === 0;
  const searchFiltersIsEmpty = searchFilters.length === 0;
  const filtersIsEmpty = filtersState.length === 0;
  const dynamicPage = Boolean(loadNextPage);

  const loadAndSetInitialFilters = async () => {
    if (Array.isArray(filters)) {
      return;
    }
    try {
      setIsLoading(true);
      const initialFiltersLoaded = await filters();
      setFiltersState(initialFiltersLoaded);
      setSearchFilters(initialFiltersLoaded);
      setActiveFilters(previousFiltersGroup =>
        updateFilters({
          newFilters: initialFiltersLoaded,
          previousFiltersGroup,
          filterKey,
        }),
      );

      setProvFilters(previousFiltersGroup =>
        updateFilters({
          newFilters: initialFiltersLoaded,
          previousFiltersGroup,
          filterKey,
        }),
      );

      setInitialFilters(prev => ({
        ...prev,
        [filterKey]: {
          gender,
          singularLabel,
          label,
          filters: initialFiltersLoaded,
        },
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const getInitialFilters = async () => {
    if (dynamicPage) return;

    if (Array.isArray(filters)) {
      setFiltersState(filters);
      setSearchFilters(filters);
      return;
    }

    if (initialFilters[filterKey] !== undefined) {
      const loadedFilters = initialFilters[filterKey].filters ?? [];

      if (loadedFilters.length > 0) {
        setFiltersState(prev => [...prev, ...loadedFilters]);
        setSearchFilters(prev => [...prev, ...loadedFilters]);
      }
      return;
    }

    loadAndSetInitialFilters();
  };

  useEffect(() => {
    register(filterKey);

    return () => {
      unregister(filterKey);
    };
  }, []);

  useEffect(() => {
    getInitialFilters();
  }, []);

  const isFilterListEmpty = searchFiltersIsEmpty || filtersIsEmpty;

  const showSelectAll =
    !disabledSelectAll && !isFilterListEmpty && !hasMoreToLoad && isMultiple;

  return (
    <FilterProvider
      setHasMoreToLoad={setHasMoreToLoad}
      setSearchFilters={setSearchFilters}
      setSearchText={setSearchText}
      hasMoreToLoad={hasMoreToLoad}
      searchFilters={searchFilters}
      setLoading={setIsLoading}
      searchText={searchText}
      setFilters={setFiltersState}
      {...drawerFilterProps}
      filters={filtersState}
    >
      <Wrapper
        disabled={(searchFieldIsEmpty && filtersIsEmpty) || isLoading}
        bgcolor={open ? COLORS.WHITE : COLORS.MONOCHROMATIC.GRAY1}
      >
        <Header
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography variant="text">{title}</Typography>
            <Icon name={open ? 'chevron-up' : 'chevron-down'} size={24} />
          </Stack>

          <AppliedFilters />
        </Header>

        <StyledCollapse in={open}>
          {hasSearchField && dynamicPage && (
            <SearchField {...searchFieldProps} />
          )}

          {showSelectAll && <SelectAllCheckbox />}

          <FilterContent infiniteScroll={dynamicPage} />
        </StyledCollapse>

        {isLoading && <FilterLoading />}
      </Wrapper>
    </FilterProvider>
  );
}
