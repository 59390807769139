import { Icon } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { IAppSubRoute } from 'routes/types';

import { MORE_SERVICES_REDIRECT } from '.';

const MoreServicesRouter: IAppSubRoute = {
  name: 'Mais serviços',
  path: UserRedirectsEnum.MORE_SERVICES,
  isPrivate: true,
  sidebarModalProps: MORE_SERVICES_REDIRECT,
  Icon: <Icon name="table-view" />,
};

export { MoreServicesRouter };
