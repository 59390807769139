import { MappedEditedPartnerCompanies } from './types';

export const mappedEditedPartnerCompanies = ({
  editedItem,
  newItemIsMasterMainPartnerCompany,
  newPartnerCompany,
}: MappedEditedPartnerCompanies) => {
  if (editedItem.partnerCompany === newPartnerCompany.partnerCompany) {
    return newPartnerCompany;
  }
  const editedItemIsChildOfMasterMainPartnerCompany =
    editedItem.mainPartnerCompany === newPartnerCompany.partnerCompany;

  if (
    newItemIsMasterMainPartnerCompany &&
    editedItemIsChildOfMasterMainPartnerCompany
  ) {
    return {
      ...editedItem,
      hasAccess: newPartnerCompany.hasAccess,
      profile: newPartnerCompany.profile,
    };
  }

  return editedItem;
};
