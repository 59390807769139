import { Stack, styled } from '@mui/material';

import { COLORS } from '@hapvida/hapvida-core-components';

interface StepProps {
  active?: boolean;
}

export const StyledIcon = styled(Stack)<StepProps>(({ active, theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.action.secondary.activeIcon}`,

  ':hover': {
    cursor: 'pointer',
  },

  '& .MuiTypography-root': {
    color: theme.palette.action.secondary.activeIcon,
  },

  ...(active && {
    background: theme.palette.action.secondary.selectedIcon,
    border: `1px solid ${theme.palette.action.secondary.selectedIcon}`,
    '& .MuiTypography-root': {
      color: theme.palette.background.default,
      fontWeight: 700,
    },
  }),
}));

export const StyledBox = styled(Stack)<StepProps>`
  & svg {
    color: ${COLORS.SECONDARY.GREEN.MAIN};
  }
`;
