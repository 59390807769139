import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

const MuiAutocompleteTheme: {
  defaultProps?: ComponentsProps['MuiAutocomplete'];
  styleOverrides?: ComponentsOverrides['MuiAutocomplete'];
  variants?: ComponentsVariants['MuiAutocomplete'];
} = {
  variants: [],
  styleOverrides: {
    root: {},
    inputRoot: {
      paddingTop: 0,
    },
  },
};

export default MuiAutocompleteTheme;
