import {
  BENEFICIARY_TYPE_PLURAL_NAME,
  BENEFICIARY_TYPE_COLOR,
  BeneficiaryTypeEnum,
} from './beneficiaryType';

export const BENEFICIARIES_SUMMARY_ITEMS = Object.values(
  BeneficiaryTypeEnum,
).map((beneficiaryType: BeneficiaryTypeEnum) => ({
  label: BENEFICIARY_TYPE_PLURAL_NAME[beneficiaryType],
  color: BENEFICIARY_TYPE_COLOR[beneficiaryType],
  key: beneficiaryType,
}));
