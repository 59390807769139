import { IAppDynamicRoute } from 'routes/types';

import { UserFirstAccessPage } from '.';

export const UserFirstAccessRouter: IAppDynamicRoute = {
  Page: UserFirstAccessPage,
  path: '/definicao-de-senha-usuario/:userPasswordDefinitionStep',
  name: 'Definição de senha',
  dynamicPath: ({ userPasswordDefinitionStep }) =>
    `/definicao-de-senha-usuario/${userPasswordDefinitionStep}`,
  Icon: <></>,
  disabled: false,
};
