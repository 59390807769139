import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthService from '@services/AuthService';
import { PartnerCompanyProps } from '@services/PartnerCompanyService/dtos';

import { CompanyStatusEnum } from '@constants';
import { UserType } from '@contexts';
import { CompanySetupDetailsRouter } from '@flows/private/companySetup/pages/CompanySetupDetails/router';
import { HomeRouter } from '@flows/private/home/router';
import { useAuth, useProfileDetails, useScreenLoading } from '@hooks';
import { toastifyApiErrors } from '@utils';

export function useSelectPartnerCompany() {
  const { updateAccount, selectedCompany, user } = useAuth();
  const { getUserProfileDetails } = useProfileDetails();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { onScreenLoading } = useScreenLoading();

  const handleCreateSession = useCallback(
    async (partnerCompany: string, companyId: string) => {
      try {
        onScreenLoading(true);
        const authService = new AuthService();
        await authService.createSession({ partnerCompany, companyId });
        return true;
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
        return false;
      } finally {
        onScreenLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleSelectPartnerCompany = useCallback(
    async (selectedPartnerCompany?: PartnerCompanyProps) => {
      if (!selectedPartnerCompany) {
        updateAccount({ selectedPartnerCompany: undefined });
        return;
      }

      const { profile } = selectedPartnerCompany;
      const { id = '' } = profile || {};

      const userProfileDetails = await getUserProfileDetails(id);

      const combinedProfile = profile && {
        ...profile,
        resources: userProfileDetails?.resources,
      };
      updateAccount({
        selectedPartnerCompany: {
          ...selectedPartnerCompany,
          profile: combinedProfile,
        },
      });

      const backofficeUser = user?.companyType === UserType.Backoffice;
      const isRegisterAccessCompany =
        selectedCompany?.status === CompanyStatusEnum.REGISTER_ACCESS;

      if (selectedPartnerCompany && selectedCompany) {
        await handleCreateSession(
          selectedPartnerCompany.partnerCompany,
          selectedCompany.id,
        );
      }

      if (backofficeUser && isRegisterAccessCompany) {
        navigate(
          CompanySetupDetailsRouter.dynamicPath({
            companyId: selectedCompany.id,
          }),
        );
        return;
      }

      navigate(HomeRouter.path);
    },
    [navigate, user, selectedCompany],
  );

  return { handleSelectPartnerCompany };
}
