import { Stack, Typography } from '@mui/material';

import { CompanyListProps } from '@services/CompanySetupService/dtos/ListCompanyDTO';

import { useAuth, useFilterParams } from '@hooks';
import { ListGrid } from '@components';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';
import { UserType } from '@contexts';

import {
  CompanySetupListColumnNameEnum,
  companySetupListColumns,
  getCompanySetupListRows,
} from '../../constants';

interface CompanySetupListViewListProps {
  companySetupListData: CompanyListProps;
}

export function CompanySetupListViewList({
  companySetupListData,
}: Readonly<CompanySetupListViewListProps>) {
  const { currentPage, handlePageChange } = useFilterParams();
  const {
    companies = [],
    total = 0,
    totalPerPage = 0,
  } = companySetupListData ?? {};

  const companySetupListRows = getCompanySetupListRows(companies);

  const { user } = useAuth();
  const isBackoffice = user?.companyType === UserType.Backoffice;

  const columns = [...companySetupListColumns];

  if (
    isBackoffice &&
    !columns.some(column => column.id === CompanySetupListColumnNameEnum.STATUS)
  ) {
    columns.splice(3, 0, {
      id: CompanySetupListColumnNameEnum.STATUS,
      item: <Typography variant="text">Status da empresa</Typography>,
    });
  }

  return (
    <Stack gap={3} paddingY={3} flex={1}>
      <ListGrid
        columns={columns}
        rows={companySetupListRows}
        paginationProps={{
          currentPage,
          handlePageChange,
          totalItems: total,
          totalDescription: 'empresas',
          totalPages: Math.ceil(total / ITEMS_LIMIT_PER_PAGE),
          totalPerPage,
        }}
        containerProps={{
          justifyContent: 'space-between',
          flex: 1,
        }}
      />
    </Stack>
  );
}
