import { IAppRoute } from 'routes/types';

import PasswordRecoveryPageStep from '.';

export const StartPasswordRecoveryRouter: IAppRoute = {
  Page: PasswordRecoveryPageStep,
  path: '/recuperacao-de-senha',
  name: 'Recuperação de senha',
  Icon: <></>,
  disabled: false,
};
