import { Stack, Typography } from '@mui/material';

import {
  FilterKeyEnum,
  DrawerFilter,
  Icon,
} from '@hapvida/hapvida-core-components';

import {
  USER_STATUS_COLOR,
  USER_STATUS_ICON_NAME,
  USER_STATUS_NAME,
  UserStatusEnum,
} from '@constants';

export function UserStatusDrawerFilter() {
  const userStatusFilters = Object.values(UserStatusEnum).map(userStatus => {
    const content = (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Icon
          name={USER_STATUS_ICON_NAME[userStatus]}
          color={USER_STATUS_COLOR[userStatus]}
          size={16}
        />
        <Typography variant="text" color={USER_STATUS_COLOR[userStatus]}>
          {USER_STATUS_NAME[userStatus]}
        </Typography>
      </Stack>
    );

    return {
      label: USER_STATUS_NAME[userStatus],
      value: userStatus,
      content,
    };
  });

  return (
    <DrawerFilter
      filterKey={FilterKeyEnum.USER_STATUS}
      filters={userStatusFilters}
      title="Status dos usuários"
      label="status"
      disabledSelectAll
    />
  );
}
