import { addIsOldOnList } from './addIsOldOnList';
import { getFilterPartnerCompany } from './getFilterPartnerCompany';
import { getMainPartnerCompany } from './getMainPartnerCompany';
import { removeIsOldOnList } from './removeIsOldOnList';
import type { UpdateEditedPartnerCompanies } from './types';

export const getUpdateEditedPartnerCompanies = ({
  newPartnerCompanies = [],
  editedPartnerCompanies = [],
}: UpdateEditedPartnerCompanies) => {
  const filteredNewPartnerCompanies = getFilterPartnerCompany({
    newPartnerCompanies,
    editedPartnerCompanies,
  });

  const updatedEditedPartnerCompanies =
    editedPartnerCompanies.map(addIsOldOnList);

  const mergedPartnerCompanies = [
    ...updatedEditedPartnerCompanies,
    ...filteredNewPartnerCompanies,
  ];

  const updatedPartnerCompanies = getMainPartnerCompany({
    mergedPartnerCompanies,
    editedPartnerCompanies,
  });

  return updatedPartnerCompanies.map(removeIsOldOnList);
};
