import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

const MuiDialogTheme: {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides['MuiDialog'];
  variants?: ComponentsVariants['MuiDialog'];
} = {
  variants: [],
  styleOverrides: {
    root: {
      '& .MuiDialog-paper': {
        maxWidth: 556,
        borderRadius: 8,
        width: '100%',
      },
    },
  },
};

export default MuiDialogTheme;
