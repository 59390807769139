import { useState, KeyboardEvent, useCallback, useRef } from 'react';

import { SearchPartnerCompanyProps, KeyboardEvents } from './types';
import { SearchPartnerCompanyLayout } from './layout';

export function SearchPartnerCompany({
  placeholder,
  setSearchParam,
}: Readonly<SearchPartnerCompanyProps>) {
  const [search, setSearch] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const resetField = useCallback(() => {
    setSearch('');
  }, [setSearchParam]);

  const handleSearch = useCallback(() => {
    setSearchParam(search);
  }, [search, setSearchParam]);

  const handlePressEnter = useCallback(
    ({ key }: KeyboardEvent) => {
      const isEnter = key === KeyboardEvents.ENTER;
      if (isEnter) {
        handleSearch();
      }
    },
    [KeyboardEvents, handleSearch],
  );

  const handleClickMagnifier = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handleClickClose = useCallback(() => {
    resetField();
    inputRef.current?.focus();
  }, [inputRef, resetField]);

  return (
    <SearchPartnerCompanyLayout
      handleClickClose={handleClickClose}
      handleClickMagnifier={handleClickMagnifier}
      handlePressEnter={handlePressEnter}
      inputRef={inputRef}
      placeholder={placeholder}
      handleSearch={handleSearch}
      search={search}
      setSearch={setSearch}
      showCloseButton={Boolean(search)}
    />
  );
}
