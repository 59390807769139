import { Button, ButtonProps, Typography } from '@mui/material';
import { PropsWithChildren, useState } from 'react';

import {
  useDrawerFilter,
  HapDrawer,
  Icon,
} from '@hapvida/hapvida-core-components';

import { FooterDrawer, WrapperFilter } from './styles';

interface FiltersDrawerButtonProps extends ButtonProps {
  subtitle?: string;
  title: string;
}

export function FiltersDrawerButton({
  children,
  subtitle,
  title,
  ...buttonProps
}: PropsWithChildren<FiltersDrawerButtonProps>) {
  const { applyFilters, cleanFilters, filterCount } = useDrawerFilter();
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const handleToggleDrawer = () => {
    setVisibleDrawer(!visibleDrawer);
  };

  const handleCleanFilters = () => {
    cleanFilters();
    handleToggleDrawer();
  };

  const handleApplyFilters = () => {
    applyFilters();
    handleToggleDrawer();
  };

  return (
    <>
      <Button
        endIcon={!filterCount && <Icon name="filter" size={20} />}
        variant={!filterCount ? 'outlined' : 'contained'}
        onClick={handleToggleDrawer}
        color="secondary"
        disableRipple
        fullWidth
        {...buttonProps}
      >
        {!filterCount ? 'Aplicar filtro' : 'Ver filtros aplicados'}
      </Button>
      <HapDrawer
        setDrawerOpen={handleToggleDrawer}
        openDrawer={visibleDrawer}
        anchor="right"
        title={title}
      >
        <WrapperFilter>
          {subtitle && <Typography variant="smallText">{subtitle}</Typography>}

          {children}
        </WrapperFilter>

        <FooterDrawer gap={2}>
          <Button onClick={handleCleanFilters} variant="outlined">
            Limpar e fechar
          </Button>
          <Button
            color="secondary"
            onClick={handleApplyFilters}
            variant="contained"
          >
            Filtrar agora
          </Button>
        </FooterDrawer>
      </HapDrawer>
    </>
  );
}
