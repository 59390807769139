import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

type SummaryItens = {
  label: string;
  color?: string;
  key: string;
};

const GRAPHIC_SUMMARY_DATA: SummaryItens[] = [
  {
    label: 'Titulares',
    key: 'holders',
    color: COLORS.PRIMARY.BLUE.MAIN,
  },
  {
    label: 'Dependentes',
    key: 'dependents',
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
  {
    label: 'Agregados',
    key: 'aggregates',
    color: COLORS.PRIMARY.BLUE['-3'],
  },
];

export { SummaryItens, GRAPHIC_SUMMARY_DATA };
