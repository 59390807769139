export enum BeneficiaryMovementStatusEnum {
  UNPROCESSED = 'Unprocessed',
  PROCESSING = 'Processing',
  CANCELING = 'Canceling',
  PROCESSED = 'Processed',
  REVIEWED = 'Reviewed',
  CANCELED = 'Canceled',
}

export const BENEFICIARY_MOVEMENT_STATUS_NAME: Record<
  BeneficiaryMovementStatusEnum,
  string
> = {
  [BeneficiaryMovementStatusEnum.UNPROCESSED]: 'Não processada',
  [BeneficiaryMovementStatusEnum.CANCELING]: 'Cancelando...',
  [BeneficiaryMovementStatusEnum.PROCESSING]: 'Processando',
  [BeneficiaryMovementStatusEnum.PROCESSED]: 'Processada',
  [BeneficiaryMovementStatusEnum.CANCELED]: 'Cancelada',
  [BeneficiaryMovementStatusEnum.REVIEWED]: 'Criticada',
};
